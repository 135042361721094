const AirPortData = [
  {
    airportCode: "BWI",
    airportName: "Baltimore Washington International",
    cityCode: "WAS",
    cityName: "Washington, D.C.",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DCA",
    airportName: "Ronald Reagan Washington National",
    cityCode: "WAS",
    cityName: "Washington, D.C.",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IAD",
    airportName: "Washington Dulles International",
    cityCode: "WAS",
    cityName: "Washington, D.C.",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WAS",
    airportName: "Washington, D.C.",
    cityCode: "WAS",
    cityName: "Washington, D.C.",
    countryCode: "US",
    continent: "North America",
  },
  {
    airportCode: "ABR",
    airportName: "Aberdeen Regional",
    cityCode: "ABR",
    cityName: "Aberdeen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABI",
    airportName: "Abilene Regional",
    cityCode: "ABI",
    cityName: "Abilene",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SPI",
    airportName: "Abraham Lincoln Capital",
    cityCode: "SPI",
    cityName: "Springfield",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ADK",
    airportName: "Adak",
    cityCode: "ADK",
    cityName: "Adak Island",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ADS",
    airportName: "Addison Airport",
    cityCode: "DFW",
    cityName: "Dallas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SLK",
    airportName: "Adirondack Regional",
    cityCode: "SLK",
    cityName: "Saranac Lake",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BQN",
    airportName: "Aguadilla Rafael Hernandez",
    cityCode: "BQN",
    cityName: "Aguadilla",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AKK",
    airportName: "Akhiok",
    cityCode: "AKK",
    cityName: "Akhiok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KKI",
    airportName: "Akiachak",
    cityCode: "KKI",
    cityName: "Akiachak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CAK",
    airportName: "Akron Canton",
    cityCode: "CAK",
    cityName: "Akron",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KQA",
    airportName: "Akutan Seaplane Base",
    cityCode: "KQA",
    cityName: "Akutan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AUK",
    airportName: "Alakanuk",
    cityCode: "AUK",
    cityName: "Alakanuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ALB",
    airportName: "Albany International",
    cityCode: "ALB",
    cityName: "Albany",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OAJ",
    airportName: "Albert J. Ellis",
    cityCode: "OAJ",
    cityName: "Jacksonville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABQ",
    airportName: "Albuquerque International Sunport",
    cityCode: "ABQ",
    cityName: "Albuquerque",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AEX",
    airportName: "Alexandria International",
    cityCode: "AEX",
    cityName: "Alexandria",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AGC",
    airportName: "Allegheny County Airport",
    cityCode: "PIT",
    cityName: "Pittsburgh",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AIA",
    airportName: "Alliance Municipal",
    cityCode: "AIA",
    cityName: "Alliance",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "APN",
    airportName: "Alpena County Regional",
    cityCode: "APN",
    cityName: "Alpena",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABL",
    airportName: "Ambler",
    cityCode: "ABL",
    cityName: "Ambler",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AKP",
    airportName: "Anaktuvuk Pass",
    cityCode: "AKP",
    cityName: "Anaktuvuk Pass",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AGN",
    airportName: "Angoon Seaplane Base",
    cityCode: "AGN",
    cityName: "Angoon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ANI",
    airportName: "Aniak",
    cityCode: "ANI",
    cityName: "Aniak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AMK",
    airportName: "Animas Air Park",
    cityCode: "DRO",
    cityName: "Durango",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GUM",
    airportName: "Antonio B. Won Pat International",
    cityCode: "GUM",
    cityName: "Hagatna",
    countryCode: "US",
    countryName: "United States",
    continent: "Oceania",
  },
  {
    airportCode: "VQS",
    airportName: "Antonio Rivera Rodriguez",
    cityCode: "VQS",
    cityName: "Vieques",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ANV",
    airportName: "Anvik",
    cityCode: "ANV",
    cityName: "Anvik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ATW",
    airportName: "Appleton International",
    cityCode: "ATW",
    cityName: "Appleton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ACV",
    airportName: "Arcata Eureka",
    cityCode: "ACV",
    cityName: "Arcata",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ARC",
    airportName: "Arctic Village",
    cityCode: "ARC",
    cityName: "Arctic Village",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LBE",
    airportName: "Arnold Palmer Regional",
    cityCode: "LBE",
    cityName: "Latrobe",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AVL",
    airportName: "Asheville Regional",
    cityCode: "AVL",
    cityName: "Asheville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ASE",
    airportName: "Aspen Pitkin County",
    cityCode: "ASE",
    cityName: "Aspen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AKB",
    airportName: "Atka",
    cityCode: "AKB",
    cityName: "Atka",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ACY",
    airportName: "Atlantic City International",
    cityCode: "AIY",
    cityName: "Atlantic City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ATT",
    airportName: "Atmautluak",
    cityCode: "ATT",
    cityName: "Atmautluak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ATK",
    airportName: "Atqasuk Edward Burnell Sr. Memorial",
    cityCode: "ATK",
    cityName: "Atqasuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AGS",
    airportName: "Augusta Regional",
    cityCode: "AGS",
    cityName: "Augusta",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AUG",
    airportName: "Augusta State",
    cityCode: "AUG",
    cityName: "Augusta",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AUS",
    airportName: "Austin Bergstrom International",
    cityCode: "AUS",
    cityName: "Austin",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MTN",
    airportName: "Baltimore Glenn L Martin",
    cityCode: "MTN",
    cityName: "Baltimore",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BGR",
    airportName: "Bangor International",
    cityCode: "BGR",
    cityName: "Bangor",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PAH",
    airportName: "Barkley Regional",
    cityCode: "PAH",
    cityName: "Paducah",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HYA",
    airportName: "Barnstable Municipal",
    cityCode: "HYA",
    cityName: "Hyannis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BTI",
    airportName: "Barter Island LRRS",
    cityCode: "BTI",
    cityName: "Barter Island",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BTR",
    airportName: "Baton Rouge Metropolitan",
    cityCode: "BTR",
    cityName: "Baton Rouge",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WBQ",
    airportName: "Beaver",
    cityCode: "WBQ",
    cityName: "Beaver",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BLI",
    airportName: "Bellingham International",
    cityCode: "BLI",
    cityName: "Bellingham",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BJI",
    airportName: "Bemidji Regional",
    cityCode: "BJI",
    cityName: "Bemidji",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CPX",
    airportName: "Benjamin Rivera Noriega",
    cityCode: "CPX",
    cityName: "Culebra",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BTM",
    airportName: "Bert Mooney",
    cityCode: "BTM",
    cityName: "Butte",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BET",
    airportName: "Bethel",
    cityCode: "BET",
    cityName: "Bethel",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BTT",
    airportName: "Bettles",
    cityCode: "BTT",
    cityName: "Bettles",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BIL",
    airportName: "Billings Logan International",
    cityCode: "BIL",
    cityName: "Billings",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BHM",
    airportName: "Birmingham-Shuttlesworth International",
    cityCode: "BHM",
    cityName: "Birmingham",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FNT",
    airportName: "Bishop International",
    cityCode: "FNT",
    cityName: "Flint",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BIS",
    airportName: "Bismarck Municipal",
    cityCode: "BIS",
    cityName: "Bismarck",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LEX",
    airportName: "Blue Grass",
    cityCode: "LEX",
    cityName: "Lexington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IAN",
    airportName: "Bob Baker Memorial",
    cityCode: "IAN",
    cityName: "Kiana",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BFI",
    airportName: "Boeing Field",
    cityCode: "SEA",
    cityName: "Seattle",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BOI",
    airportName: "Boise",
    cityCode: "BOI",
    cityName: "Boise",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HRO",
    airportName: "Boone County",
    cityCode: "HRO",
    cityName: "Harrison",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BLD",
    airportName: "Boulder City Municipal",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BZN",
    airportName: "Bozeman Yellowstone International",
    cityCode: "BZN",
    cityName: "Bozeman",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BDL",
    airportName: "Bradley International",
    cityCode: "HFD",
    cityName: "Hartford",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BRD",
    airportName: "Brainerd Lakes Regional",
    cityCode: "BRD",
    cityName: "Brainerd",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BKG",
    airportName: "Branson",
    cityCode: "BKG",
    cityName: "Branson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KTS",
    airportName: "Brevig Mission",
    cityCode: "KTS",
    cityName: "Brevig Mission",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BRO",
    airportName: "Brownsville/South Padre Island International",
    cityCode: "BRO",
    cityName: "Brownsville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BQK",
    airportName: "Brunswick Golden Isles",
    cityCode: "SSI",
    cityName: "Brunswick",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CCR",
    airportName: "Buchanan Field",
    cityCode: "CCR",
    cityName: "Concord",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BKC",
    airportName: "Buckland",
    cityCode: "BKC",
    cityName: "Buckland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BUF",
    airportName: "Buffalo Niagara International",
    cityCode: "BUF",
    cityName: "Buffalo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BTV",
    airportName: "Burlington International",
    cityCode: "BTV",
    cityName: "Burlington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CCB",
    airportName: "Cable",
    cityCode: "CCB",
    cityName: "Upland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CSL",
    airportName: "Camp San Luis Obispo O'Sullivan AHP",
    cityCode: "CSL",
    cityName: "Camp San Luis Obispo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CNY",
    airportName: "Canyonlands Field",
    cityCode: "CNY",
    cityName: "Moab",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CGI",
    airportName: "Cape Girardeau Regional",
    cityCode: "CGI",
    cityName: "Cape Girardeau",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LUR",
    airportName: "Cape Lisburne LRRS",
    cityCode: "LUR",
    cityName: "Cape Lisburne, Alaska",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LAN",
    airportName: "Capital Region International",
    cityCode: "LAN",
    cityName: "Lansing",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CPR",
    airportName: "Casper Natrona County International",
    cityCode: "CPR",
    cityName: "Casper",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CNM",
    airportName: "Cavern City Air Terminal",
    cityCode: "CNM",
    cityName: "Carlsbad",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CDC",
    airportName: "Cedar City Regional",
    cityCode: "CDC",
    cityName: "Cedar City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "APA",
    airportName: "Centennial Airport",
    cityCode: "DEN",
    cityName: "Denver",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CEM",
    airportName: "Central",
    cityCode: "CEM",
    cityName: "Central",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BMI",
    airportName: "Central Illinois Regional",
    cityCode: "BMI",
    cityName: "Bloomington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GRI",
    airportName: "Central Nebraska Regional",
    cityCode: "GRI",
    cityName: "Grand Island",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CWA",
    airportName: "Central Wisconsin",
    cityCode: "AUW",
    cityName: "Wausau",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CDR",
    airportName: "Chadron Municipal",
    cityCode: "CDR",
    cityName: "Chadron",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CIK",
    airportName: "Chalkyitsik",
    cityCode: "CIK",
    cityName: "Chalkyitsik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MKC",
    airportName: "Charles B. Wheeler Downtown",
    cityCode: "MKC",
    cityName: "Kansas City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STS",
    airportName: "Charles M. Schulz Sonoma County",
    cityCode: "STS",
    cityName: "Santa Rosa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CHS",
    airportName: "Charleston International",
    cityCode: "CHS",
    cityName: "Charleston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SPB",
    airportName: "Charlotte Amalie Harbor Seaplane Base",
    cityCode: "STT",
    cityName: "Saint Thomas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CLT",
    airportName: "Charlotte Douglas International",
    cityCode: "CLT",
    cityName: "Charlotte",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CHO",
    airportName: "Charlottesville Albemarle",
    cityCode: "CHO",
    cityName: "Charlottesville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CHA",
    airportName: "Chattanooga Metropolitan",
    cityCode: "CHA",
    cityName: "Chattanooga",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CYF",
    airportName: "Chefornak",
    cityCode: "CYF",
    cityName: "Chefornak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TVC",
    airportName: "Cherry Capital",
    cityCode: "TVC",
    cityName: "Traverse City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VAK",
    airportName: "Chevak",
    cityCode: "VAK",
    cityName: "Chevak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CYS",
    airportName: "Cheyenne Regional",
    cityCode: "CYS",
    cityName: "Cheyenne",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MDW",
    airportName: "Chicago Midway International",
    cityCode: "CHI",
    cityName: "Chicago",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RFD",
    airportName: "Chicago Rockford International",
    cityCode: "CHI",
    cityName: "Chicago",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KCG",
    airportName: "Chignik Fisheries",
    cityCode: "KCL",
    cityName: "Chignik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KCL",
    airportName: "Chignik Lagoon",
    cityCode: "KCL",
    cityName: "Chignik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KCQ",
    airportName: "Chignik Lake",
    cityCode: "KCL",
    cityName: "Chignik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CIU",
    airportName: "Chippewa County International",
    cityCode: "SSM",
    cityName: "Sault Sainte Marie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EAU",
    airportName: "Chippewa Valley Regional",
    cityCode: "EAU",
    cityName: "Eau Claire",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SSB",
    airportName: "Christiansted Harbor Seaplane Base",
    cityCode: "STX",
    cityName: "Saint Croix",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CHU",
    airportName: "Chuathbaluk",
    cityCode: "CHU",
    cityName: "Chuathbaluk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LUK",
    airportName: "Cincinnati Municipal Lunken",
    cityCode: "CVG",
    cityName: "Cincinnati",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CVG",
    airportName: "Cincinnati/Northern Kentucky International",
    cityCode: "CVG",
    cityName: "Cincinnati",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IRC",
    airportName: "Circle City",
    cityCode: "IRC",
    cityName: "Circle",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CLP",
    airportName: "Clarks Point",
    cityCode: "CLP",
    cityName: "Clark's Point",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BKL",
    airportName: "Cleveland Burke Lakefront",
    cityCode: "CLE",
    cityName: "Cleveland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CLE",
    airportName: "Cleveland Hopkins International",
    cityCode: "CLE",
    cityName: "Cleveland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LIT",
    airportName: "Clinton National",
    cityCode: "LIT",
    cityName: "Little Rock",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CVN",
    airportName: "Clovis Municipal",
    cityCode: "CVN",
    cityName: "Clovis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EWN",
    airportName: "Coastal Carolina Regional",
    cityCode: "EWN",
    cityName: "New Bern",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KCC",
    airportName: "Coffman Cove Sea Plane Base",
    cityCode: "KCC",
    cityName: "Coffman Cove",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CDB",
    airportName: "Cold Bay",
    cityCode: "CDB",
    cityName: "Cold Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CXF",
    airportName: "Coldfoot",
    cityCode: "CXF",
    cityName: "Coldfoot",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DET",
    airportName: "Coleman A. Young Municipal Airport",
    cityCode: "DTT",
    cityName: "Detroit",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "COS",
    airportName: "Colorado Springs",
    cityCode: "COS",
    cityName: "Colorado Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CAE",
    airportName: "Columbia Metropolitan",
    cityCode: "CAE",
    cityName: "Columbia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "COU",
    airportName: "Columbia Regional",
    cityCode: "COU",
    cityName: "Columbia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CSG",
    airportName: "Columbus Metropolitan",
    cityCode: "CSG",
    cityName: "Columbus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "USA",
    airportName: "Concord Regional",
    cityCode: "USA",
    cityName: "Concord",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CRP",
    airportName: "Corpus Christi International",
    cityCode: "CRP",
    cityName: "Corpus Christi",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CEZ",
    airportName: "Cortez Municipal",
    cityCode: "CEZ",
    cityName: "Cortez",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CGA",
    airportName: "Craig Sea Plane Base",
    cityCode: "CGA",
    cityName: "Craig",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LMT",
    airportName: "Crater Lake Klamath Regional",
    cityCode: "LMT",
    cityName: "Klamath Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CKD",
    airportName: "Crooked Creek",
    cityCode: "CKD",
    cityName: "Crooked Creek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STT",
    airportName: "Cyril E. King",
    cityCode: "STT",
    cityName: "Saint Thomas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DAL",
    airportName: "Dallas Love Field",
    cityCode: "DFW",
    cityName: "Dallas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DFW",
    airportName: "Dallas/Fort Worth International",
    cityCode: "DFW",
    cityName: "Dallas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSN",
    airportName: "Dane County Regional",
    cityCode: "MSN",
    cityName: "Madison",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HNL",
    airportName: "Daniel K. Inouye International",
    cityCode: "HNL",
    cityName: "Honolulu",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GDV",
    airportName: "Dawson Community",
    cityCode: "GDV",
    cityName: "Glendive",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DAY",
    airportName: "Dayton International",
    cityCode: "DAY",
    cityName: "Dayton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DAB",
    airportName: "Daytona Beach International",
    cityCode: "DAB",
    cityName: "Daytona Beach",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SCC",
    airportName: "Deadhorse",
    cityCode: "SCC",
    cityName: "Prudhoe Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DEC",
    airportName: "Decatur",
    cityCode: "DEC",
    cityName: "Decatur",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DRG",
    airportName: "Deering",
    cityCode: "DRG",
    cityName: "Deering",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PDK",
    airportName: "DeKalb Peachtree",
    cityCode: "ATL",
    cityName: "Atlanta",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CEC",
    airportName: "Del Norte County",
    cityCode: "CEC",
    cityName: "Crescent City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ESC",
    airportName: "Delta County",
    cityCode: "ESC",
    cityName: "Escanaba",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DEN",
    airportName: "Denver International",
    cityCode: "DEN",
    cityName: "Denver",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DSM",
    airportName: "Des Moines International",
    cityCode: "DSM",
    cityName: "Des Moines",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VPS",
    airportName: "Destin Fort Walton Beach",
    cityCode: "VPS",
    cityName: "Destin",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DTW",
    airportName: "Detroit Metropolitan",
    cityCode: "DTT",
    cityName: "Detroit",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DVL",
    airportName: "Devils Lake Regional",
    cityCode: "DVL",
    cityName: "Devils Lake",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DIK",
    airportName: "Dickinson Theodore Roosevelt Regional",
    cityCode: "DIK",
    cityName: "Dickinson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DLG",
    airportName: "Dillingham",
    cityCode: "DLG",
    cityName: "Dillingham",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DDC",
    airportName: "Dodge City Regional",
    cityCode: "DDC",
    cityName: "Dodge City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DHN",
    airportName: "Dothan Regional",
    cityCode: "DHN",
    cityName: "Dothan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DUJ",
    airportName: "DuBois Regional",
    cityCode: "DUJ",
    cityName: "DuBois",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DBQ",
    airportName: "Dubuque Regional",
    cityCode: "DBQ",
    cityName: "Dubuque",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DLH",
    airportName: "Duluth International",
    cityCode: "DLH",
    cityName: "Duluth",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DRO",
    airportName: "Durango La Plata County",
    cityCode: "DRO",
    cityName: "Durango",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EGE",
    airportName: "Eagle County Regional",
    cityCode: "EGE",
    cityName: "Vail",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GGG",
    airportName: "East Texas Regional",
    cityCode: "GGG",
    cityName: "Longview",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PDT",
    airportName: "Eastern Oregon Regional",
    cityCode: "PDT",
    cityName: "Pendleton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CLL",
    airportName: "Easterwood",
    cityCode: "CLL",
    cityName: "College Station",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EDA",
    airportName: "Edna Bay Sea Plane Base",
    cityCode: "EDA",
    cityName: "Edna Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GAL",
    airportName: "Edward G. Pitka Sr.",
    cityCode: "GAL",
    cityName: "Galena",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EEK",
    airportName: "Eek",
    cityCode: "EEK",
    cityName: "Eek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EGX",
    airportName: "Egegik",
    cityCode: "EGX",
    cityName: "Egegik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KEK",
    airportName: "Ekwok",
    cityCode: "KEK",
    cityName: "Ekwok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ELP",
    airportName: "El Paso International",
    cityCode: "ELP",
    cityName: "El Paso",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ELI",
    airportName: "Elim",
    cityCode: "ELI",
    cityName: "Elim",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EKO",
    airportName: "Elko Regional",
    cityCode: "EKO",
    cityName: "Elko",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ELM",
    airportName: "Elmira/Corning Regional",
    cityCode: "ELM",
    cityName: "Elmira",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EMK",
    airportName: "Emmonak",
    cityCode: "EMK",
    cityName: "Emmonak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OMA",
    airportName: "Eppley Airfield",
    cityCode: "OMA",
    cityName: "Omaha",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ERI",
    airportName: "Erie International",
    cityCode: "ERI",
    cityName: "Erie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PRC",
    airportName: "Ernest A. Love Field",
    cityCode: "PRC",
    cityName: "Prescott",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EUG",
    airportName: "Eugene",
    cityCode: "EUG",
    cityName: "Eugene",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MAZ",
    airportName: "Eugenio Maria de Hostos",
    cityCode: "MAZ",
    cityName: "Mayaguez",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EVV",
    airportName: "Evansville Regional",
    cityCode: "EVV",
    cityName: "Evansville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EXI",
    airportName: "Excursion Inlet Seaplane Base",
    cityCode: "EXI",
    cityName: "Excursion Inlet",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FAI",
    airportName: "Fairbanks International",
    cityCode: "FAI",
    cityName: "Fairbanks",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "INL",
    airportName: "Falls International",
    cityCode: "INL",
    cityName: "International Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KFP",
    airportName: "False Pass",
    cityCode: "KFP",
    cityName: "False Pass",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FAY",
    airportName: "Fayetteville Regional",
    cityCode: "FAY",
    cityName: "Fayetteville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SIG",
    airportName: "Fernando Luis Ribas Dominicci",
    cityCode: "SJU",
    cityName: "San Juan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FLG",
    airportName: "Flagstaff Pulliam",
    cityCode: "GCN",
    cityName: "Grand Canyon Village",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FLO",
    airportName: "Florence Regional",
    cityCode: "FLO",
    cityName: "Florence",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IMT",
    airportName: "Ford",
    cityCode: "IMT",
    cityName: "Iron Mountain",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FOD",
    airportName: "Fort Dodge Regional",
    cityCode: "FOD",
    cityName: "Fort Dodge",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FXE",
    airportName: "Fort Lauderdale Executive",
    cityCode: "FLL",
    cityName: "Fort Lauderdale",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FLL",
    airportName: "Fort Lauderdale Hollywood International",
    cityCode: "FLL",
    cityName: "Fort Lauderdale",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FSM",
    airportName: "Fort Smith Regional",
    cityCode: "FSM",
    cityName: "Fort Smith",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FWA",
    airportName: "Fort Wayne International",
    cityCode: "FWA",
    cityName: "Fort Wayne",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FYU",
    airportName: "Fort Yukon",
    cityCode: "FYU",
    cityName: "Fort Yukon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FMN",
    airportName: "Four Corners Regional",
    cityCode: "FMN",
    cityName: "Farmington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FAT",
    airportName: "Fresno Yosemite International",
    cityCode: "FAT",
    cityName: "Fresno",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SUN",
    airportName: "Friedman Memorial",
    cityCode: "SUN",
    cityName: "Sun Valley",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GNV",
    airportName: "Gainesville Regional",
    cityCode: "GNV",
    cityName: "Gainesville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GAM",
    airportName: "Gambell",
    cityCode: "GAM",
    cityName: "Gambell",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GCK",
    airportName: "Garden City Regional",
    cityCode: "GCK",
    cityName: "Garden City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MKE",
    airportName: "General Mitchell International",
    cityCode: "MKE",
    cityName: "Milwaukee",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIA",
    airportName: "General Wayne A. Downing Peoria International",
    cityCode: "PIA",
    cityName: "Peoria",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IAH",
    airportName: "George Bush Intercontinental",
    cityCode: "HOU",
    cityName: "Houston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GRR",
    airportName: "Gerald R. Ford International",
    cityCode: "GRR",
    cityName: "Grand Rapids",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GCC",
    airportName: "Gillette Campbell County",
    cityCode: "GCC",
    cityName: "Gillette",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FCA",
    airportName: "Glacier Park International",
    cityCode: "FCA",
    cityName: "Kalispell",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GGW",
    airportName: "Glasgow",
    cityCode: "GGW",
    cityName: "Glasgow",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IWD",
    airportName: "Gogebic Iron County",
    cityCode: "IWD",
    cityName: "Ironwood",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GTR",
    airportName: "Golden Triangle Regional",
    cityCode: "UBS",
    cityName: "Columbus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GLV",
    airportName: "Golovin",
    cityCode: "GLV",
    cityName: "Golovin",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GNU",
    airportName: "Goodnews",
    cityCode: "GNU",
    cityName: "Goodnews Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GRN",
    airportName: "Gordon Municipal",
    cityCode: "GRN",
    cityName: "Gordon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GCN",
    airportName: "Grand Canyon National Park",
    cityCode: "GCN",
    cityName: "Grand Canyon Village",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GCW",
    airportName: "Grand Canyon West",
    cityCode: "GCW",
    cityName: "Grand Canyon West, Arizona",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GFK",
    airportName: "Grand Forks International",
    cityCode: "GFK",
    cityName: "Grand Forks",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GJT",
    airportName: "Grand Junction Regional",
    cityCode: "GJT",
    cityName: "Grand Junction",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SVC",
    airportName: "Grant County",
    cityCode: "SVC",
    cityName: "Silver City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KGX",
    airportName: "Grayling",
    cityCode: "KGX",
    cityName: "Grayling",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GTF",
    airportName: "Great Falls International",
    cityCode: "GTF",
    cityName: "Great Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BGM",
    airportName: "Greater Binghamton",
    cityCode: "BGM",
    cityName: "Binghamton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ROC",
    airportName: "Greater Rochester International",
    cityCode: "ROC",
    cityName: "Rochester",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GRB",
    airportName: "Green Bay Austin Straubel International",
    cityCode: "GRB",
    cityName: "Green Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LWB",
    airportName: "Greenbrier Valley",
    cityCode: "LWB",
    cityName: "Lewisburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GSP",
    airportName: "Greenville Spartanburg International",
    cityCode: "GSP",
    cityName: "Greenville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GPT",
    airportName: "Gulfport Biloxi International",
    cityCode: "GPT",
    cityName: "Gulfport",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GUC",
    airportName: "Gunnison Crested Butte Regional",
    cityCode: "GUC",
    cityName: "Gunnison",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GST",
    airportName: "Gustavus",
    cityCode: "GST",
    cityName: "Gustavus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HGR",
    airportName: "Hagerstown Regional",
    cityCode: "HGR",
    cityName: "Hagerstown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HNS",
    airportName: "Haines",
    cityCode: "HNS",
    cityName: "Haines",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HNM",
    airportName: "Hana",
    cityCode: "HNM",
    cityName: "Hana",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BHB",
    airportName: "Hancock County-Bar Harbor",
    cityCode: "BHB",
    cityName: "Bar Harbor",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MDT",
    airportName: "Harrisburg International",
    cityCode: "HAR",
    cityName: "Harrisburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ATL",
    airportName: "Hartsfield Jackson Atlanta International",
    cityCode: "ATL",
    cityName: "Atlanta",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIB",
    airportName: "Hattiesburg Laurel Regional",
    cityCode: "LUL",
    cityName: "Laurel",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HVR",
    airportName: "Havre City County",
    cityCode: "HVR",
    cityName: "Havre",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HYS",
    airportName: "Hays Regional",
    cityCode: "HYS",
    cityName: "Hays",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HKB",
    airportName: "Healy River",
    cityCode: "HKB",
    cityName: "Healy Lake",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FAR",
    airportName: "Hector International",
    cityCode: "FAR",
    cityName: "Fargo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HLN",
    airportName: "Helena Regional",
    cityCode: "HLN",
    cityName: "Helena",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STX",
    airportName: "Henry E. Rohlsen",
    cityCode: "STX",
    cityName: "Saint Croix",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ITO",
    airportName: "Hilo International",
    cityCode: "ITO",
    cityName: "Hilo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HHH",
    airportName: "Hilton Head",
    cityCode: "HHH",
    cityName: "Hilton Head Island",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HYL",
    airportName: "Hollis Seaplane Base",
    cityCode: "HYL",
    cityName: "Hollis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BUR",
    airportName: "Hollywood Burbank",
    cityCode: "BUR",
    cityName: "Burbank",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HCR",
    airportName: "Holy Cross",
    cityCode: "HCR",
    cityName: "Holy Cross",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HOM",
    airportName: "Homer",
    cityCode: "HOM",
    cityName: "Homer",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HNH",
    airportName: "Hoonah",
    cityCode: "HNH",
    cityName: "Hoonah",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HPB",
    airportName: "Hooper Bay",
    cityCode: "HPB",
    cityName: "Hooper Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CMX",
    airportName: "Houghton County Memorial",
    cityCode: "CMX",
    cityName: "Hancock",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HUS",
    airportName: "Hughes",
    cityCode: "HUS",
    cityName: "Hughes",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HSV",
    airportName: "Huntsville International",
    cityCode: "HSV",
    cityName: "Huntsville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HSL",
    airportName: "Huslia",
    cityCode: "HSL",
    cityName: "Huslia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HYG",
    airportName: "Hydaburg Seaplane Base",
    cityCode: "HYG",
    cityName: "Hydaburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IDA",
    airportName: "Idaho Falls Regional",
    cityCode: "IDA",
    cityName: "Idaho Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IGG",
    airportName: "Igiugig",
    cityCode: "IGG",
    cityName: "Igiugig",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ILI",
    airportName: "Iliamna",
    cityCode: "ILI",
    cityName: "Iliamna",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IPL",
    airportName: "Imperial County",
    cityCode: "IPL",
    cityName: "El Centro",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "UTO",
    airportName: "Indian Mountain Lrrs",
    cityCode: "UTO",
    cityName: "Utopia Creek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IND",
    airportName: "Indianapolis International",
    cityCode: "IND",
    cityName: "Indianapolis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ITH",
    airportName: "Ithaca Tompkins Regional",
    cityCode: "ITH",
    cityName: "Ithaca",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BPT",
    airportName: "Jack Brooks Regional",
    cityCode: "BPT",
    cityName: "Beaumont",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JAC",
    airportName: "Jackson Hole",
    cityCode: "JAC",
    cityName: "Jackson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JAN",
    airportName: "Jackson Evers International",
    cityCode: "JAN",
    cityName: "Jackson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JAX",
    airportName: "Jacksonville International",
    cityCode: "JAX",
    cityName: "Jacksonville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JMS",
    airportName: "Jamestown Regional",
    cityCode: "JMS",
    cityName: "Jamestown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JFK",
    airportName: "John F. Kennedy International",
    cityCode: "NYC",
    cityName: "New York City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CMH",
    airportName: "John Glenn Columbus International",
    cityCode: "CMH",
    cityName: "Columbus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SNA",
    airportName: "John Wayne",
    cityCode: "SNA",
    cityName: "Santa Ana",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JST",
    airportName: "Johnstown Cambria County",
    cityCode: "JST",
    cityName: "Johnstown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JBR",
    airportName: "Jonesboro Municipal",
    cityCode: "JBR",
    cityName: "Jonesboro",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JLN",
    airportName: "Joplin Regional",
    cityCode: "JLN",
    cityName: "Joplin",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NRR",
    airportName: "Jose Aponte de la Torre",
    cityCode: "NRR",
    cityName: "Roosevelt Roads Naval Station",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JNU",
    airportName: "Juneau International",
    cityCode: "JNU",
    cityName: "Juneau",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OGG",
    airportName: "Kahului",
    cityCode: "OGG",
    cityName: "Kahului",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KAE",
    airportName: "Kake Sea Plane Base",
    cityCode: "KAE",
    cityName: "Kake",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AZO",
    airportName: "Kalamazoo/Battle Creek International",
    cityCode: "AZO",
    cityName: "Kalamazoo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KLG",
    airportName: "Kalskag",
    cityCode: "KLG",
    cityName: "Upper Kalskag",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KAL",
    airportName: "Kaltag",
    cityCode: "KAL",
    cityName: "Kaltag",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCI",
    airportName: "Kansas City International",
    cityCode: "MKC",
    cityName: "Kansas City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "JHM",
    airportName: "Kapalua",
    cityCode: "JHM",
    cityName: "Kapalua",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KXA",
    airportName: "Kasaan",
    cityCode: "KXA",
    cityName: "Kasaan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KUK",
    airportName: "Kasigluk",
    cityCode: "KUK",
    cityName: "Kasigluk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EAR",
    airportName: "Kearney Regional",
    cityCode: "EAR",
    cityName: "Kearney",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ENA",
    airportName: "Kenai Municipal",
    cityCode: "ENA",
    cityName: "Kenai",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KEH",
    airportName: "Kenmore Air Harbor",
    cityCode: "KEH",
    cityName: "Kenmore",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LKE",
    airportName: "Kenmore Air Harbor Seaplane Base",
    cityCode: "SEA",
    cityName: "Seattle",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KTN",
    airportName: "Ketchikan International",
    cityCode: "KTN",
    cityName: "Ketchikan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EYW",
    airportName: "Key West International",
    cityCode: "EYW",
    cityName: "Key West",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GRK",
    airportName: "Killeen Fort Hood Regional",
    cityCode: "ILE",
    cityName: "Killeen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KVC",
    airportName: "King Cove",
    cityCode: "KVC",
    cityName: "King Cove",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AKN",
    airportName: "King Salmon",
    cityCode: "AKN",
    cityName: "King Salmon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KPN",
    airportName: "Kipnuk",
    cityCode: "KPN",
    cityName: "Kipnuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IRK",
    airportName: "Kirksville Regional",
    cityCode: "IRK",
    cityName: "Kirksville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KVL",
    airportName: "Kivalina",
    cityCode: "KVL",
    cityName: "Kivalina",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KLW",
    airportName: "Klawock",
    cityCode: "KLW",
    cityName: "Klawock",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RKD",
    airportName: "Knox County Regional",
    cityCode: "RKD",
    cityName: "Rockland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OBU",
    airportName: "Kobuk",
    cityCode: "OBU",
    cityName: "Kobuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ADQ",
    airportName: "Kodiak",
    cityCode: "ADQ",
    cityName: "Kodiak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KNK",
    airportName: "Kokhanok",
    cityCode: "KNK",
    cityName: "Kakhonak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KGK",
    airportName: "Koliganek",
    cityCode: "KGK",
    cityName: "Koliganek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KOA",
    airportName: "Kona International",
    cityCode: "KOA",
    cityName: "Kailua",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KKH",
    airportName: "Kongiganak",
    cityCode: "KKH",
    cityName: "Kongiganak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KOT",
    airportName: "Kotlik",
    cityCode: "KOT",
    cityName: "Kotlik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KKA",
    airportName: "Koyuk Alfred Adams",
    cityCode: "KKA",
    cityName: "Koyuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KYU",
    airportName: "Koyukuk",
    cityCode: "KYU",
    cityName: "Koyukuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KWT",
    airportName: "Kwethluk",
    cityCode: "KWT",
    cityName: "Kwethluk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KWK",
    airportName: "Kwigillingok",
    cityCode: "KWK",
    cityName: "Kwigillingok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OLF",
    airportName: "L. M. Clayton",
    cityCode: "OLF",
    cityName: "Wolf Point",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LSE",
    airportName: "La Crosse Regional",
    cityCode: "LSE",
    cityName: "La Crosse",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LFT",
    airportName: "Lafayette Regional",
    cityCode: "LFT",
    cityName: "Lafayette",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LGA",
    airportName: "LaGuardia",
    cityCode: "NYC",
    cityName: "New York City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LCH",
    airportName: "Lake Charles Regional",
    cityCode: "LCH",
    cityName: "Lake Charles",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LNY",
    airportName: "Lanai",
    cityCode: "LNY",
    cityName: "Lanai",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LAR",
    airportName: "Laramie Regional",
    cityCode: "LAR",
    cityName: "Laramie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LRD",
    airportName: "Laredo International",
    cityCode: "LRD",
    cityName: "Laredo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KLN",
    airportName: "Larsen Bay",
    cityCode: "KLN",
    cityName: "Larsen Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IFP",
    airportName: "Laughlin/Bullhead International",
    cityCode: "IFP",
    cityName: "Bullhead City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LAW",
    airportName: "Lawton Fort Sill Regional",
    cityCode: "LAW",
    cityName: "Lawton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HOB",
    airportName: "Lea County Regional",
    cityCode: "HOB",
    cityName: "Hobbs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LEB",
    airportName: "Lebanon Municipal",
    cityCode: "LEB",
    cityName: "Lebanon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABE",
    airportName: "Lehigh Valley International",
    cityCode: "ABE",
    cityName: "Allentown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KLL",
    airportName: "Levelock",
    cityCode: "KLL",
    cityName: "Levelock",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LWS",
    airportName: "Lewiston Nez Perce County",
    cityCode: "LWS",
    cityName: "Lewiston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LBL",
    airportName: "Liberal Mid-America Regional",
    cityCode: "LBL",
    cityName: "Liberal",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LIH",
    airportName: "Lihue",
    cityCode: "LIH",
    cityName: "Kauai",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LNK",
    airportName: "Lincoln",
    cityCode: "LNK",
    cityName: "Lincoln",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BOS",
    airportName: "Logan International",
    cityCode: "BOS",
    cityName: "Boston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LGB",
    airportName: "Long Beach",
    cityCode: "LGB",
    cityName: "Long Beach",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ISP",
    airportName: "Long Island MacArthur",
    cityCode: "ISP",
    cityName: "Islip",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LPS",
    airportName: "Lopez Island",
    cityCode: "LPS",
    cityName: "Lopez Island",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LAX",
    airportName: "Los Angeles International",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSY",
    airportName: "Louis Armstrong New Orleans International",
    cityCode: "MSY",
    cityName: "New Orleans",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SDF",
    airportName: "Louisville International",
    cityCode: "SDF",
    cityName: "Louisville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LBB",
    airportName: "Lubbock Preston Smith International",
    cityCode: "LBB",
    cityName: "Lubbock",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SJU",
    airportName: "Luis Munoz Marin International",
    cityCode: "SJU",
    cityName: "San Juan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LYH",
    airportName: "Lynchburg Regional",
    cityCode: "LYH",
    cityName: "Lynchburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TWF",
    airportName: "Magic Valley Regional",
    cityCode: "TWF",
    cityName: "Twin Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MMH",
    airportName: "Mammoth Yosemite",
    cityCode: "MMH",
    cityName: "Mammoth Lakes",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MHT",
    airportName: "Manchester Boston Regional",
    cityCode: "MHT",
    cityName: "Manchester",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MHK",
    airportName: "Manhattan Regional",
    cityCode: "MHK",
    cityName: "Manhattan",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MBL",
    airportName: "Manistee County Blacker",
    cityCode: "MBL",
    cityName: "Manistee",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MLY",
    airportName: "Manley Hot Springs",
    cityCode: "MLY",
    cityName: "Manley Hot Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AVW",
    airportName: "Marana Regional Airport",
    cityCode: "TUS",
    cityName: "Tucson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MLL",
    airportName: "Marshall Don Hunter Sr.",
    cityCode: "MLL",
    cityName: "Marshall",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MVY",
    airportName: "Martha's Vineyard",
    cityCode: "MVY",
    cityName: "Martha's Vineyard",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCW",
    airportName: "Mason City Municipal",
    cityCode: "MCW",
    cityName: "Mason City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSS",
    airportName: "Massena International",
    cityCode: "MSS",
    cityName: "Massena",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MBS",
    airportName: "MBS International",
    cityCode: "MBS",
    cityName: "Saginaw",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MFE",
    airportName: "McAllen Miller International",
    cityCode: "MFE",
    cityName: "McAllen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LAS",
    airportName: "McCarran International",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CLD",
    airportName: "McClellan Palomar",
    cityCode: "SAN",
    cityName: "San Diego",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCK",
    airportName: "McCook Ben Nelson Regional",
    cityCode: "MCK",
    cityName: "McCook",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TYS",
    airportName: "McGhee Tyson",
    cityCode: "TYS",
    cityName: "Knoxville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCG",
    airportName: "McGrath",
    cityCode: "MCG",
    cityName: "McGrath",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MKL",
    airportName: "McKellar Sipes Regional",
    cityCode: "MKL",
    cityName: "Jackson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BFL",
    airportName: "Meadows Field",
    cityCode: "BFL",
    cityName: "Bakersfield",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MYU",
    airportName: "Mekoryuk",
    cityCode: "MYU",
    cityName: "Mekoryuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HOT",
    airportName: "Memorial Field",
    cityCode: "HOT",
    cityName: "Hot Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MEM",
    airportName: "Memphis International",
    cityCode: "MEM",
    cityName: "Memphis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCE",
    airportName: "Merced Regional",
    cityCode: "MCE",
    cityName: "Merced",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PSE",
    airportName: "Mercedita",
    cityCode: "PSE",
    cityName: "Ponce",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MEI",
    airportName: "Meridian Regional",
    cityCode: "MEI",
    cityName: "Meridian",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CDV",
    airportName: "Merle K. (Mudhole) Smith",
    cityCode: "CDV",
    cityName: "Cordova",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MTM",
    airportName: "Metlakatla Sea Plane Base",
    cityCode: "MTM",
    cityName: "Metlakatla",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WMK",
    airportName: "Meyers Chuck Sea Plane Base",
    cityCode: "WMK",
    cityName: "Meyers Chuck",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MIA",
    airportName: "Miami International",
    cityCode: "MIA",
    cityName: "Miami",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GLH",
    airportName: "Mid Delta Regional",
    cityCode: "GLH",
    cityName: "Greenville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PKB",
    airportName: "Mid-Ohio Valley Regional",
    cityCode: "PKB",
    cityName: "Parkersburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BLV",
    airportName: "MidAmerica St. Louis",
    cityCode: "BLV",
    cityName: "Belleville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCN",
    airportName: "Middle Georgia Regional",
    cityCode: "MCN",
    cityName: "Macon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MAF",
    airportName: "Midland International Air and Space Port",
    cityCode: "MAF",
    cityName: "Midland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LMA",
    airportName: "Minchumina",
    cityCode: "LMA",
    cityName: "Lake Minchumina",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSP",
    airportName: "Minneapolis Saint Paul International",
    cityCode: "MSP",
    cityName: "Minneapolis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MOT",
    airportName: "Minot International",
    cityCode: "MOT",
    cityName: "Minot",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MNT",
    airportName: "Minto Al Wright",
    cityCode: "MNT",
    cityName: "Minto",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSO",
    airportName: "Missoula International",
    cityCode: "MSO",
    cityName: "Missoula",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MOB",
    airportName: "Mobile Regional",
    cityCode: "MOB",
    cityName: "Mobile",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MKK",
    airportName: "Molokai",
    cityCode: "MKK",
    cityName: "Ho?olehua",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MLU",
    airportName: "Monroe Regional",
    cityCode: "MLU",
    cityName: "Monroe",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MRY",
    airportName: "Monterey Regional",
    cityCode: "MRY",
    cityName: "Monterey",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MGM",
    airportName: "Montgomery Regional",
    cityCode: "MGM",
    cityName: "Montgomery",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MTJ",
    airportName: "Montrose Regional",
    cityCode: "MTJ",
    cityName: "Montrose",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MGW",
    airportName: "Morgantown Municipal",
    cityCode: "MGW",
    cityName: "Morgantown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MMU",
    airportName: "Morristown Municipal",
    cityCode: "MMU",
    cityName: "Morristown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MVL",
    airportName: "Morrisville Stowe State",
    cityCode: "MVL",
    cityName: "Stowe",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MOU",
    airportName: "Mountain Village",
    cityCode: "MOU",
    cityName: "Mountain Village",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MKG",
    airportName: "Muskegon County",
    cityCode: "MKG",
    cityName: "Muskegon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MYR",
    airportName: "Myrtle Beach International",
    cityCode: "MYR",
    cityName: "Myrtle Beach",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ACK",
    airportName: "Nantucket Memorial",
    cityCode: "ACK",
    cityName: "Nantucket",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KEB",
    airportName: "Nanwalek",
    cityCode: "KEB",
    cityName: "Nanwalek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WNA",
    airportName: "Napakiak",
    cityCode: "WNA",
    cityName: "Napakiak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PKA",
    airportName: "Napaskiak",
    cityCode: "PKA",
    cityName: "Napaskiak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "APF",
    airportName: "Naples Municipal",
    cityCode: "APF",
    cityName: "Naples",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BNA",
    airportName: "Nashville International",
    cityCode: "BNA",
    cityName: "Nashville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NLG",
    airportName: "Nelson Lagoon",
    cityCode: "NLG",
    cityName: "Nelson Lagoon",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EWB",
    airportName: "New Bedford Regional",
    cityCode: "EWB",
    cityName: "New Bedford",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KNW",
    airportName: "New Stuyahok",
    cityCode: "KNW",
    cityName: "New Stuyahok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EWR",
    airportName: "Newark Liberty International",
    cityCode: "NYC",
    cityName: "New York City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PHF",
    airportName: "Newport News/Williamsburg International",
    cityCode: "PHF",
    cityName: "Newport News",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WWT",
    airportName: "Newtok",
    cityCode: "WWT",
    cityName: "Newtok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IAG",
    airportName: "Niagara Falls International",
    cityCode: "IAG",
    cityName: "Niagara Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NME",
    airportName: "Nightmute",
    cityCode: "NME",
    cityName: "Nightmute",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IKO",
    airportName: "Nikolski Air Station",
    cityCode: "IKO",
    cityName: "Nikolski",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WTK",
    airportName: "Noatak",
    cityCode: "WTK",
    cityName: "Noatak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OME",
    airportName: "Nome",
    cityCode: "OME",
    cityName: "Nome",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NNL",
    airportName: "Nondalton",
    cityCode: "NNL",
    cityName: "Nondalton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORF",
    airportName: "Norfolk International",
    cityCode: "ORF",
    cityName: "Norfolk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CKB",
    airportName: "North Central West Virginia",
    cityCode: "CKB",
    cityName: "Clarksburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LBF",
    airportName: "North Platte Regional",
    cityCode: "LBF",
    cityName: "North Platte",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WWP",
    airportName: "North Whale Seaplane Base",
    cityCode: "WWP",
    cityName: "Whale Pass",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "UST",
    airportName: "Northeast Florida Regional",
    cityCode: "UST",
    cityName: "St. Augustine",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PQI",
    airportName: "Northern Maine Regional Airport at Presque Isle",
    cityCode: "PQI",
    cityName: "Presque Isle",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSL",
    airportName: "Northwest Alabama Regional",
    cityCode: "MSL",
    cityName: "Muscle Shoals",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "XNA",
    airportName: "Northwest Arkansas Regional",
    cityCode: "FYV",
    cityName: "Fayetteville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ECP",
    airportName: "Northwest Florida Beaches International",
    cityCode: "ECP",
    cityName: "Panama City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NUI",
    airportName: "Nuiqsut",
    cityCode: "NUI",
    cityName: "Nuiqsut",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NUL",
    airportName: "Nulato",
    cityCode: "NUL",
    cityName: "Nulato",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NUP",
    airportName: "Nunapitchuk",
    cityCode: "NUP",
    cityName: "Nunapitchuk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORD",
    airportName: "O Hare International",
    cityCode: "CHI",
    cityName: "Chicago",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OAK",
    airportName: "Oakland International",
    cityCode: "OAK",
    cityName: "Oakland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OGD",
    airportName: "Ogden-Hinckley",
    cityCode: "OGD",
    cityName: "Ogden",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OGS",
    airportName: "Ogdensburg International",
    cityCode: "OGS",
    cityName: "Ogdensburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OLH",
    airportName: "Old Harbor",
    cityCode: "OLH",
    cityName: "Old Harbor",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ONT",
    airportName: "Ontario International",
    cityCode: "ONT",
    cityName: "Ontario",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MCO",
    airportName: "Orlando International",
    cityCode: "ORL",
    cityName: "Orlando",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MLB",
    airportName: "Orlando Melbourne International",
    cityCode: "MLB",
    cityName: "Melbourne",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SFB",
    airportName: "Orlando Sanford International",
    cityCode: "ORL",
    cityName: "Orlando",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KOZ",
    airportName: "Ouzinkie",
    cityCode: "KOZ",
    cityName: "Ouzinkie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OWB",
    airportName: "Owensboro Daviess County Regional",
    cityCode: "OWB",
    cityName: "Owensboro",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PGA",
    airportName: "Page Municipal",
    cityCode: "PGA",
    cityName: "Page",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PPG",
    airportName: "Pago Pago International",
    cityCode: "PPG",
    cityName: "Pago Pago",
    countryCode: "US",
    countryName: "United States",
    continent: "Oceania",
  },
  {
    airportCode: "PAE",
    airportName: "Paine Field Everett",
    cityCode: "PAE",
    cityName: "Everett",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PBI",
    airportName: "Palm Beach International",
    cityCode: "PBI",
    cityName: "West Palm Beach",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PSP",
    airportName: "Palm Springs International",
    cityCode: "PSP",
    cityName: "Palm Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "EAT",
    airportName: "Pangborn Memorial",
    cityCode: "EAT",
    cityName: "Wenatchee",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PDB",
    airportName: "Pedro Bay",
    cityCode: "PDB",
    cityName: "Pedro Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PEC",
    airportName: "Pelican Sea Plane Base",
    cityCode: "PEC",
    cityName: "Pelican",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PLN",
    airportName: "Pellston Regional",
    cityCode: "PLN",
    cityName: "Pellston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PNS",
    airportName: "Pensacola International",
    cityCode: "PNS",
    cityName: "Pensacola",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KPV",
    airportName: "Perryville",
    cityCode: "KPV",
    cityName: "Perryville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PSG",
    airportName: "Petersburg James A. Johnson",
    cityCode: "PSG",
    cityName: "Petersburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PHL",
    airportName: "Philadelphia International",
    cityCode: "PHL",
    cityName: "Philadelphia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DVT",
    airportName: "Phoenix Deer Valley Airport",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PHX",
    airportName: "Phoenix Sky Harbor International",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AZA",
    airportName: "Phoenix Mesa Gateway",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GSO",
    airportName: "Piedmont Triad International",
    cityCode: "GSO",
    cityName: "Greensboro",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIR",
    airportName: "Pierre Regional",
    cityCode: "PIR",
    cityName: "Pierre",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIP",
    airportName: "Pilot Point",
    cityCode: "PIP",
    cityName: "Pilot Point",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PGV",
    airportName: "Pitt Greenville",
    cityCode: "PGV",
    cityName: "Greenville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIT",
    airportName: "Pittsburgh International",
    cityCode: "PIT",
    cityName: "Pittsburgh",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PTU",
    airportName: "Platinum",
    cityCode: "PTU",
    cityName: "Platinum",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PBG",
    airportName: "Plattsburgh International",
    cityCode: "PBG",
    cityName: "Plattsburgh",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIH",
    airportName: "Pocatello Regional",
    cityCode: "PIH",
    cityName: "Pocatello",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KPB",
    airportName: "Point Baker Sea Plane Base",
    cityCode: "KPB",
    cityName: "Point Baker",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PHO",
    airportName: "Point Hope",
    cityCode: "PHO",
    cityName: "Point Hope",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIZ",
    airportName: "Point Lay LRRS",
    cityCode: "PIZ",
    cityName: "Point Lay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PTA",
    airportName: "Port Alsworth",
    cityCode: "PTA",
    cityName: "Port Alsworth",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PGM",
    airportName: "Port Graham",
    cityCode: "PGM",
    cityName: "Port Graham",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PTH",
    airportName: "Port Heiden",
    cityCode: "PTH",
    cityName: "Port Heiden",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORI",
    airportName: "Port Lions",
    cityCode: "ORI",
    cityName: "Port Lions",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PML",
    airportName: "Port Moller",
    cityCode: "PML",
    cityName: "Port Moller",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PPV",
    airportName: "Port Protection Seaplane Base",
    cityCode: "PPV",
    cityName: "Port Protection",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PDX",
    airportName: "Portland International",
    cityCode: "PDX",
    cityName: "Portland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PWM",
    airportName: "Portland International Jetport",
    cityCode: "PWM",
    cityName: "Portland",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PSM",
    airportName: "Portsmouth International Airport at Pease",
    cityCode: "PSM",
    cityName: "Portsmouth",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PVC",
    airportName: "Provincetown Municipal",
    cityCode: "PVC",
    cityName: "Provincetown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PVU",
    airportName: "Provo Municipal",
    cityCode: "PVU",
    cityName: "Provo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PUB",
    airportName: "Pueblo Memorial",
    cityCode: "PUB",
    cityName: "Pueblo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PUW",
    airportName: "Pullman Moscow Regional",
    cityCode: "PUW",
    cityName: "Pullman",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PGD",
    airportName: "Punta Gorda",
    cityCode: "PGD",
    cityName: "Punta Gorda",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MLI",
    airportName: "Quad City International",
    cityCode: "MLI",
    cityName: "Moline",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "UIN",
    airportName: "Quincy Regional",
    cityCode: "UIN",
    cityName: "Quincy",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KWN",
    airportName: "Quinhagak",
    cityCode: "KWN",
    cityName: "Quinhagak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BKW",
    airportName: "Raleigh County Memorial",
    cityCode: "BKW",
    cityName: "Beckley",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RDU",
    airportName: "Raleigh Durham International",
    cityCode: "RDU",
    cityName: "Raleigh",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TAL",
    airportName: "Ralph M. Calhoun Memorial",
    cityCode: "TAL",
    cityName: "Tanana",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OTZ",
    airportName: "Ralph Wien Memorial",
    cityCode: "OTZ",
    cityName: "Kotzebue",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RMP",
    airportName: "Rampart",
    cityCode: "RMP",
    cityName: "Rampart",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HIB",
    airportName: "Range Regional",
    cityCode: "HIB",
    cityName: "Hibbing",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RAP",
    airportName: "Rapid City Regional",
    cityCode: "RAP",
    cityName: "Rapid City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RDB",
    airportName: "Red Dog",
    cityCode: "RDB",
    cityName: "Red Dog",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RDD",
    airportName: "Redding Municipal",
    cityCode: "RDD",
    cityName: "Redding",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RNO",
    airportName: "Reno Tahoe International",
    cityCode: "RNO",
    cityName: "Reno",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RHI",
    airportName: "Rhinelander Oneida County",
    cityCode: "RHI",
    cityName: "Rhinelander",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RIC",
    airportName: "Richmond International",
    cityCode: "RIC",
    cityName: "Richmond",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AMA",
    airportName: "Rick Husband Amarillo International",
    cityCode: "AMA",
    cityName: "Amarillo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LCK",
    airportName: "Rickenbacker International",
    cityCode: "CMH",
    cityName: "Columbus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RIW",
    airportName: "Riverton Regional",
    cityCode: "RIW",
    cityName: "Riverton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ROA",
    airportName: "Roanoke Blacksburg Regional",
    cityCode: "ROA",
    cityName: "Roanoke",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORV",
    airportName: "Robert (Bob) Curtis Memorial",
    cityCode: "ORV",
    cityName: "Noorvik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RDM",
    airportName: "Roberts Field",
    cityCode: "RDM",
    cityName: "Redmond",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RCE",
    airportName: "Roche Harbor",
    cityCode: "RCE",
    cityName: "Roche Harbor Grocery Store",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RST",
    airportName: "Rochester International",
    cityCode: "RST",
    cityName: "Rochester",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RKS",
    airportName: "Rock Springs Sweetwater County",
    cityCode: "RKS",
    cityName: "Rock Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BJC",
    airportName: "Rocky Mountain Metropolitan Airport",
    cityCode: "DEN",
    cityName: "Denver",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MFR",
    airportName: "Rogue Valley International Medford",
    cityCode: "MFR",
    cityName: "Medford",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ROW",
    airportName: "Roswell International Air Center",
    cityCode: "ROW",
    cityName: "Roswell",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ROP",
    airportName: "Rota International",
    cityCode: "ROP",
    cityName: "Rota",
    countryCode: "US",
    countryName: "United States",
    continent: "Oceania",
  },
  {
    airportCode: "RBY",
    airportName: "Ruby",
    cityCode: "RBY",
    cityName: "Ruby",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RSH",
    airportName: "Russian Mission",
    cityCode: "RSH",
    cityName: "Russian Mission",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RUT",
    airportName: "Rutland Southern Vermont Regional",
    cityCode: "RUT",
    cityName: "Rutland City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SMF",
    airportName: "Sacramento International",
    cityCode: "SAC",
    cityName: "Sacramento",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SPN",
    airportName: "Saipan International",
    cityCode: "SPN",
    cityName: "Saipan",
    countryCode: "US",
    countryName: "United States",
    continent: "Oceania",
  },
  {
    airportCode: "SLN",
    airportName: "Salina Regional",
    cityCode: "SLN",
    cityName: "Salina",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SBY",
    airportName: "Salisbury Ocean City Wicomico Regional",
    cityCode: "SBY",
    cityName: "Ocean City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SLC",
    airportName: "Salt Lake City International",
    cityCode: "SLC",
    cityName: "Salt Lake City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SJT",
    airportName: "San Angelo Regional",
    cityCode: "SJT",
    cityName: "San Angelo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SAT",
    airportName: "San Antonio International",
    cityCode: "SAT",
    cityName: "San Antonio",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SAN",
    airportName: "San Diego International",
    cityCode: "SAN",
    cityName: "San Diego",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SFO",
    airportName: "San Francisco International",
    cityCode: "SFO",
    cityName: "San Francisco",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SJC",
    airportName: "San Jose International",
    cityCode: "SJC",
    cityName: "San Jose",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SBP",
    airportName: "San Luis Obispo County Regional",
    cityCode: "SBP",
    cityName: "San Luis Obispo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ALS",
    airportName: "San Luis Valley Regional",
    cityCode: "ALS",
    cityName: "Alamosa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SDP",
    airportName: "Sand Point",
    cityCode: "SDP",
    cityName: "Sand Point",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SBA",
    airportName: "Santa Barbara Municipal",
    cityCode: "SBA",
    cityName: "Santa Barbara",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SAF",
    airportName: "Santa Fe Municipal",
    cityCode: "SAF",
    cityName: "Santa Fe",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SMX",
    airportName: "Santa Maria Public",
    cityCode: "SMX",
    cityName: "Santa Maria",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SRQ",
    airportName: "Sarasota Bradenton International",
    cityCode: "SRQ",
    cityName: "Sarasota",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SAV",
    airportName: "Savannah/Hilton Head International",
    cityCode: "SAV",
    cityName: "Savannah",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SVA",
    airportName: "Savoonga",
    cityCode: "SVA",
    cityName: "Savoonga",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MQT",
    airportName: "Sawyer International",
    cityCode: "MQT",
    cityName: "Marquette",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SCM",
    airportName: "Scammon Bay",
    cityCode: "SCM",
    cityName: "Scammon Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SEA",
    airportName: "Seattle Tacoma International",
    cityCode: "SEA",
    cityName: "Seattle",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WLK",
    airportName: "Selawik",
    cityCode: "WLK",
    cityName: "Selawik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SHX",
    airportName: "Shageluk",
    cityCode: "SHX",
    cityName: "Shageluk",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SKK",
    airportName: "Shaktoolik",
    cityCode: "SKK",
    cityName: "Shaktoolik",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SXP",
    airportName: "Sheldon Point",
    cityCode: "SXP",
    cityName: "Nunam Iqua",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SHD",
    airportName: "Shenandoah Valley Regional",
    cityCode: "SHD",
    cityName: "Staunton, Virginia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SHH",
    airportName: "Shishmaref",
    cityCode: "SHH",
    cityName: "Shishmaref",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SOW",
    airportName: "Show Low Regional",
    cityCode: "SOW",
    cityName: "Show Low",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SHV",
    airportName: "Shreveport Regional",
    cityCode: "SHV",
    cityName: "Shreveport",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SHG",
    airportName: "Shungnak",
    cityCode: "SHG",
    cityName: "Shungnak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SDY",
    airportName: "Sidney Richland Municipal",
    cityCode: "SDY",
    cityName: "Sidney",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FSD",
    airportName: "Sioux Falls Regional",
    cityCode: "FSD",
    cityName: "Sioux Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SUX",
    airportName: "Sioux Gateway",
    cityCode: "SUX",
    cityName: "Sioux City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SIT",
    airportName: "Sitka Rocky Gutierrez",
    cityCode: "SIT",
    cityName: "Sitka",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SGY",
    airportName: "Skagway",
    cityCode: "SGY",
    cityName: "Skagway",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SLQ",
    airportName: "Sleetmute",
    cityCode: "SLQ",
    cityName: "Sleetmute",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ISN",
    airportName: "Sloulin Field International",
    cityCode: "ISN",
    cityName: "Williston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "INT",
    airportName: "Smith Reynolds",
    cityCode: "INT",
    cityName: "Winston-Salem",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ELD",
    airportName: "South Arkansas Regional Airport at Goodwin Field",
    cityCode: "ELD",
    cityName: "El Dorado",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SBN",
    airportName: "South Bend International",
    cityCode: "SBN",
    cityName: "South Bend",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WSN",
    airportName: "South Naknek",
    cityCode: "WSN",
    cityName: "South Naknek",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BRL",
    airportName: "Southeast Iowa Regional",
    cityCode: "BRL",
    cityName: "Burlington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "RSW",
    airportName: "Southwest Florida International",
    cityCode: "FMY",
    cityName: "Fort Myers",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABY",
    airportName: "Southwest Georgia Regional",
    cityCode: "ABY",
    cityName: "Albany",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OTH",
    airportName: "Southwest Oregon Regional",
    cityCode: "OTH",
    cityName: "North Bend",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "GEG",
    airportName: "Spokane International",
    cityCode: "GEG",
    cityName: "Spokane",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SGF",
    airportName: "Springfield-Branson National",
    cityCode: "SGF",
    cityName: "Springfield",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STC",
    airportName: "St. Cloud Regional",
    cityCode: "STC",
    cityName: "St. Cloud",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STG",
    airportName: "St. George",
    cityCode: "STG",
    cityName: "St. George",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SGU",
    airportName: "St. George Regional",
    cityCode: "SGU",
    cityName: "St. George",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "STL",
    airportName: "St. Louis Lambert International",
    cityCode: "STL",
    cityName: "St. Louis",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KSM",
    airportName: "St. Mary s",
    cityCode: "KSM",
    cityName: "St. Mary s",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SMK",
    airportName: "St. Michael",
    cityCode: "SMK",
    cityName: "St. Michael",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SNP",
    airportName: "St. Paul Island",
    cityCode: "SNP",
    cityName: "St. Paul",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PIE",
    airportName: "St. Pete Clearwater International",
    cityCode: "TPA",
    cityName: "Tampa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WBB",
    airportName: "Stebbins",
    cityCode: "WBB",
    cityName: "Stebbins",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SWF",
    airportName: "Stewart International",
    cityCode: "NYC",
    cityName: "New York City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SWO",
    airportName: "Stillwater Regional",
    cityCode: "SWO",
    cityName: "Stillwater",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SCK",
    airportName: "Stockton Metropolitan",
    cityCode: "SCK",
    cityName: "Stockton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SRV",
    airportName: "Stony River",
    cityCode: "SRV",
    cityName: "Stony River",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SYR",
    airportName: "Syracuse Hancock International",
    cityCode: "SYR",
    cityName: "Syracuse",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PVD",
    airportName: "T. F. Green",
    cityCode: "PVD",
    cityName: "Providence",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TLH",
    airportName: "Tallahassee International",
    cityCode: "TLH",
    cityName: "Tallahassee",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TPA",
    airportName: "Tampa International",
    cityCode: "TPA",
    cityName: "Tampa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ANC",
    airportName: "Ted Stevens Anchorage International",
    cityCode: "ANC",
    cityName: "Anchorage",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TLA",
    airportName: "Teller",
    cityCode: "TLA",
    cityName: "Teller",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TEX",
    airportName: "Telluride Regional",
    cityCode: "TEX",
    cityName: "Telluride",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TKE",
    airportName: "Tenakee Seaplane Base",
    cityCode: "TKE",
    cityName: "Tenakee Springs",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TXK",
    airportName: "Texarkana Regional",
    cityCode: "TXK",
    cityName: "Texarkana",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CID",
    airportName: "The Eastern Iowa",
    cityCode: "CID",
    cityName: "Cedar Rapids",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TVF",
    airportName: "Thief River Falls Regional",
    cityCode: "TVF",
    cityName: "Thief River Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KTB",
    airportName: "Thorne Bay Seaplane Base",
    cityCode: "KTB",
    cityName: "Thorne Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TNC",
    airportName: "Tin City LRRS",
    cityCode: "TNC",
    cityName: "Tin City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TOG",
    airportName: "Togiak",
    cityCode: "TOG",
    cityName: "Togiak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TKJ",
    airportName: "Tok",
    cityCode: "TKJ",
    cityName: "Tok",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OOK",
    airportName: "Toksook Bay",
    cityCode: "OOK",
    cityName: "Toksook Bay",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TOL",
    airportName: "Toledo Express",
    cityCode: "TOL",
    cityName: "Toledo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TTN",
    airportName: "Trenton Mercer",
    cityCode: "TTN",
    cityName: "Trenton",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "PSC",
    airportName: "Tri-Cities",
    cityCode: "PSC",
    cityName: "Pasco",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TRI",
    airportName: "Tri-Cities Regional",
    cityCode: "TRI",
    cityName: "Bristol",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HTS",
    airportName: "Tri-State",
    cityCode: "HTS",
    cityName: "Huntington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TUS",
    airportName: "Tucson International",
    cityCode: "TUS",
    cityName: "Tucson",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TUL",
    airportName: "Tulsa International",
    cityCode: "TUL",
    cityName: "Tulsa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TLT",
    airportName: "Tuluksak",
    cityCode: "TLT",
    cityName: "Tuluksak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WTL",
    airportName: "Tuntutuliak",
    cityCode: "WTL",
    cityName: "Tuntutuliak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TNK",
    airportName: "Tununak",
    cityCode: "TNK",
    cityName: "Tununak",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TUP",
    airportName: "Tupelo Regional",
    cityCode: "TUP",
    cityName: "Tupelo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HVN",
    airportName: "Tweed New Haven",
    cityCode: "HVN",
    cityName: "New Haven",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TYR",
    airportName: "Tyler Pounds Regional",
    cityCode: "TYR",
    cityName: "Tyler",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "UNK",
    airportName: "Unalakleet",
    cityCode: "UNK",
    cityName: "Unalakleet",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DUT",
    airportName: "Unalaska",
    cityCode: "DUT",
    cityName: "Dutch Harbor",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CMI",
    airportName: "University of Illinois Willard",
    cityCode: "CMI",
    cityName: "Champaign",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SCE",
    airportName: "University Park",
    cityCode: "SCE",
    cityName: "State College",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VDZ",
    airportName: "Valdez",
    cityCode: "VDZ",
    cityName: "Valdez",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VLD",
    airportName: "Valdosta Regional",
    cityCode: "VLD",
    cityName: "Valdosta",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HRL",
    airportName: "Valley International",
    cityCode: "HRL",
    cityName: "Harlingen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VEE",
    airportName: "Venetie",
    cityCode: "VEE",
    cityName: "Venetie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VEL",
    airportName: "Vernal Regional",
    cityCode: "VEL",
    cityName: "Vernal",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VRB",
    airportName: "Vero Beach Regional",
    cityCode: "VRB",
    cityName: "Vero Beach",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "VCT",
    airportName: "Victoria Regional",
    cityCode: "VCT",
    cityName: "Victoria",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ACT",
    airportName: "Waco Regional",
    cityCode: "ACT",
    cityName: "Waco",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MUE",
    airportName: "Waimea-Kohala",
    cityCode: "MUE",
    cityName: "Waimea",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AIN",
    airportName: "Wainwright",
    cityCode: "AIN",
    cityName: "Wainwright",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WAA",
    airportName: "Wales",
    cityCode: "WAA",
    cityName: "Wales",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ALW",
    airportName: "Walla Walla Regional",
    cityCode: "ALW",
    cityName: "Walla Walla",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "KWF",
    airportName: "Waterfall Seaplane Base",
    cityCode: "KWF",
    cityName: "Waterfall, Alaska",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ALO",
    airportName: "Waterloo Regional",
    cityCode: "ALO",
    cityName: "Waterloo",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ART",
    airportName: "Watertown International",
    cityCode: "ART",
    cityName: "Watertown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ATY",
    airportName: "Watertown Regional",
    cityCode: "ATY",
    cityName: "Watertown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "TBN",
    airportName: "Waynesville-St. Robert Regional",
    cityCode: "TBN",
    cityName: "Fort Leonard Wood",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HPN",
    airportName: "Westchester County",
    cityCode: "HPN",
    cityName: "Westchester County, New York",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BFF",
    airportName: "Western Nebraska Regional",
    cityCode: "BFF",
    cityName: "Scottsbluff",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WSX",
    airportName: "Westsound/WSX Seaplane Base",
    cityCode: "WSX",
    cityName: "West Sound, Washington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WMO",
    airportName: "White Mountain",
    cityCode: "WMO",
    cityName: "White Mountain",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ICT",
    airportName: "Wichita Dwight D. Eisenhower National",
    cityCode: "ICT",
    cityName: "Wichita",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SPS",
    airportName: "Wichita Falls Municipal",
    cityCode: "SPS",
    cityName: "Wichita Falls",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BRW",
    airportName: "Wiley Post Will Rogers Memorial",
    cityCode: "BRW",
    cityName: "Barrow",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AVP",
    airportName: "Wilkes-Barre/Scranton International",
    cityCode: "AVP",
    cityName: "Wilkes-Barre",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "OKC",
    airportName: "Will Rogers World",
    cityCode: "OKC",
    cityName: "Oklahoma City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HOU",
    airportName: "William P. Hobby",
    cityCode: "HOU",
    cityName: "Houston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MWA",
    airportName: "Williamson County Regional",
    cityCode: "MWA",
    cityName: "Marion",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "IPT",
    airportName: "Williamsport Regional",
    cityCode: "IPT",
    cityName: "Williamsport",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "YIP",
    airportName: "Willow Run Airport",
    cityCode: "DTT",
    cityName: "Detroit",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ILM",
    airportName: "Wilmington International",
    cityCode: "ILM",
    cityName: "Wilmington",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "BBX",
    airportName: "Wings Field",
    cityCode: "PHL",
    cityName: "Philadelphia",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORH",
    airportName: "Worcester Regional",
    cityCode: "ORH",
    cityName: "Worcester",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WRG",
    airportName: "Wrangell",
    cityCode: "WRG",
    cityName: "Wrangell",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "YKM",
    airportName: "Yakima Air Terminal",
    cityCode: "YKM",
    cityName: "Yakima",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "YAK",
    airportName: "Yakutat",
    cityCode: "YAK",
    cityName: "Yakutat",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HDN",
    airportName: "Yampa Valley",
    cityCode: "HDN",
    cityName: "Hayden",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CRW",
    airportName: "Yeager",
    cityCode: "CRW",
    cityName: "Charleston",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "WYS",
    airportName: "Yellowstone",
    cityCode: "WYS",
    cityName: "West Yellowstone",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "COD",
    airportName: "Yellowstone Regional",
    cityCode: "COD",
    cityName: "Cody",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "YNG",
    airportName: "Youngstown Warren Regional",
    cityCode: "YNG",
    cityName: "Youngstown",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "YUM",
    airportName: "Yuma International",
    cityCode: "YUM",
    cityName: "Yuma",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AIY",
    airportName: "Atlantic City",
    cityCode: "AIY",
    cityName: "Atlantic City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SSI",
    airportName: "Brunswick",
    cityCode: "SSI",
    cityName: "Brunswick",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "CHI",
    airportName: "Chicago",
    cityCode: "CHI",
    cityName: "Chicago",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "UBS",
    airportName: "Columbus",
    cityCode: "UBS",
    cityName: "Columbus",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "DTT",
    airportName: "Detroit",
    cityCode: "DTT",
    cityName: "Detroit",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FYV",
    airportName: "Fayetteville",
    cityCode: "FYV",
    cityName: "Fayetteville",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "FMY",
    airportName: "Fort Myers",
    cityCode: "FMY",
    cityName: "Fort Myers",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HAR",
    airportName: "Harrisburg",
    cityCode: "HAR",
    cityName: "Harrisburg",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "HFD",
    airportName: "Hartford",
    cityCode: "HFD",
    cityName: "Hartford",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ILE",
    airportName: "Killeen",
    cityCode: "ILE",
    cityName: "Killeen",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "LUL",
    airportName: "Laurel",
    cityCode: "LUL",
    cityName: "Laurel",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "MSC",
    airportName: "Mesa",
    cityCode: "MSC",
    cityName: "Mesa",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "NYC",
    airportName: "New York City",
    cityCode: "NYC",
    cityName: "New York City",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ORL",
    airportName: "Orlando",
    cityCode: "ORL",
    cityName: "Orlando",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SAC",
    airportName: "Sacramento",
    cityCode: "SAC",
    cityName: "Sacramento",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "SSM",
    airportName: "Sault Sainte Marie",
    cityCode: "SSM",
    cityName: "Sault Sainte Marie",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "AUW",
    airportName: "Wausau",
    cityCode: "AUW",
    cityName: "Wausau",
    countryCode: "US",
    countryName: "United States",
    continent: "North America",
  },
  {
    airportCode: "ABZ",
    airportName: "Aberdeen",
    cityCode: "ABZ",
    cityName: "Aberdeen",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "VLY",
    airportName: "Anglesey",
    cityCode: "VLY",
    cityName: "Ynys Mon",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BRR",
    airportName: "Barra",
    cityCode: "BRR",
    cityName: "Barra",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BFS",
    airportName: "Belfast International",
    cityCode: "BFS",
    cityName: "Belfast",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BEB",
    airportName: "Benbecula",
    cityCode: "BEB",
    cityName: "Benbecula",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BHX",
    airportName: "Birmingham",
    cityCode: "BHX",
    cityName: "Birmingham",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BLK",
    airportName: "Blackpool",
    cityCode: "BLK",
    cityName: "Blackpool",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BOH",
    airportName: "Bournemouth",
    cityCode: "BOH",
    cityName: "Bournemouth",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BRS",
    airportName: "Bristol",
    cityCode: "BRS",
    cityName: "Bristol",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "CBG",
    airportName: "Cambridge",
    cityCode: "CBG",
    cityName: "Cambridge",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "CAL",
    airportName: "Campbeltown",
    cityCode: "CAL",
    cityName: "Campbeltown",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "CWL",
    airportName: "Cardiff",
    cityCode: "CWL",
    cityName: "Cardiff",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LDY",
    airportName: "City of Derry",
    cityCode: "LDY",
    cityName: "Derry",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NQY",
    airportName: "Cornwall Airport Newquay",
    cityCode: "NQY",
    cityName: "Newquay",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "CVT",
    airportName: "Coventry",
    cityCode: "CVT",
    cityName: "Coventry",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "DSA",
    airportName: "Doncaster Sheffield",
    cityCode: "DSA",
    cityName: "Doncaster",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "DND",
    airportName: "Dundee",
    cityCode: "DND",
    cityName: "Dundee",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "MME",
    airportName: "Durham Tees Valley",
    cityCode: "MME",
    cityName: "Durham, England",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "EMA",
    airportName: "East Midlands",
    cityCode: "NQT",
    cityName: "Nottingham",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "EOI",
    airportName: "Eday",
    cityCode: "EOI",
    cityName: "Eday",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "EDI",
    airportName: "Edinburgh Airport",
    cityCode: "EDI",
    cityName: "Edinburgh",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "EXT",
    airportName: "Exeter",
    cityCode: "EXT",
    cityName: "Exeter",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LGW",
    airportName: "Gatwick",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BHD",
    airportName: "George Best Belfast City",
    cityCode: "BFS",
    cityName: "Belfast",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "GLA",
    airportName: "Glasgow",
    cityCode: "GLA",
    cityName: "Glasgow",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "PIK",
    airportName: "Glasgow Prestwick",
    cityCode: "GLA",
    cityName: "Glasgow",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LHR",
    airportName: "Heathrow",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "HUY",
    airportName: "Humberside",
    cityCode: "HUY",
    cityName: "Kirmington",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "INV",
    airportName: "Inverness",
    cityCode: "INV",
    cityName: "Inverness",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "ILY",
    airportName: "Islay",
    cityCode: "ILY",
    cityName: "Islay",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "MNI",
    airportName: "John A. Osborne",
    cityCode: "MNI",
    cityName: "Brades",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "North America",
  },
  {
    airportCode: "KOI",
    airportName: "Kirkwall",
    cityCode: "KOI",
    cityName: "Kirkwall",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "BDA",
    airportName: "L.F. Wade International",
    cityCode: "BDA",
    cityName: "Bermuda",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "North America",
  },
  {
    airportCode: "LBA",
    airportName: "Leeds Bradford",
    cityCode: "LBA",
    cityName: "Leeds",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LPL",
    airportName: "Liverpool John Lennon",
    cityCode: "LPL",
    cityName: "Liverpool",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LCY",
    airportName: "London City",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "OXF",
    airportName: "London Oxford Airport",
    cityCode: "OXF",
    cityName: "Oxford",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SEN",
    airportName: "London Southend",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "STN",
    airportName: "London Stansted",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LTN",
    airportName: "Luton",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "MAN",
    airportName: "Manchester",
    cityCode: "MAN",
    cityName: "Manchester",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NCL",
    airportName: "Newcastle",
    cityCode: "NCL",
    cityName: "Newcastle upon Tyne",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NRL",
    airportName: "North Ronaldsay",
    cityCode: "NRL",
    cityName: "North Ronaldsay",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NWI",
    airportName: "Norwich",
    cityCode: "NWI",
    cityName: "Norwich",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "PPW",
    airportName: "Papa Westray",
    cityCode: "PPW",
    cityName: "Papa Westray",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "PLH",
    airportName: "Plymouth City",
    cityCode: "PLH",
    cityName: "Plymouth",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NDY",
    airportName: "Sanday",
    cityCode: "NDY",
    cityName: "Sanday, Orkney",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SOU",
    airportName: "Southampton",
    cityCode: "SOU",
    cityName: "Southampton",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SYY",
    airportName: "Stornoway",
    cityCode: "SYY",
    cityName: "Stornoway",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SOY",
    airportName: "Stronsay",
    cityCode: "SOY",
    cityName: "Stronsay",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LSI",
    airportName: "Sumburgh",
    cityCode: "SDZ",
    cityName: "Shetland Islands",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "TRE",
    airportName: "Tiree",
    cityCode: "TRE",
    cityName: "Tiree",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "WRY",
    airportName: "Westray",
    cityCode: "WRY",
    cityName: "Westray",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "WIC",
    airportName: "Wick",
    cityCode: "WIC",
    cityName: "Wick",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "QDH",
    airportName: "Ashford",
    cityCode: "QDH",
    cityName: "Ashford",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XQB",
    airportName: "Basingstoke",
    cityCode: "XQB",
    cityName: "Basingstoke",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "QQX",
    airportName: "Bath",
    cityCode: "QQX",
    cityName: "Bath",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XQG",
    airportName: "Berwick-upon-Tweed",
    cityCode: "XQG",
    cityName: "Berwick-upon-Tweed",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "ZFI",
    airportName: "Chesterfield",
    cityCode: "ZFI",
    cityName: "Chesterfield",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XVG",
    airportName: "Darlington",
    cityCode: "XVG",
    cityName: "Darlington",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "ZEQ",
    airportName: "Dewsbury",
    cityCode: "ZEQ",
    cityName: "Dewsbury",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XPW",
    airportName: "Didcot",
    cityCode: "XPW",
    cityName: "Didcot",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "QQD",
    airportName: "Dover",
    cityCode: "QQD",
    cityName: "Dover",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XGM",
    airportName: "Grantham",
    cityCode: "XGM",
    cityName: "Grantham",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XHU",
    airportName: "Huntingdon",
    cityCode: "XHU",
    cityName: "Huntingdon",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XQL",
    airportName: "Lancaster",
    cityCode: "XQL",
    cityName: "Lancaster",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XQT",
    airportName: "Lichfield",
    cityCode: "XQT",
    cityName: "Lichfield",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "LON",
    airportName: "London",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "ZMA",
    airportName: "Mansfield",
    cityCode: "ZMA",
    cityName: "Mansfield",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "KYN",
    airportName: "Milton Keynes",
    cityCode: "KYN",
    cityName: "Milton Keynes",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XNE",
    airportName: "Newport",
    cityCode: "XNE",
    cityName: "Newport",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "NQT",
    airportName: "Nottingham",
    cityCode: "NQT",
    cityName: "Nottingham",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XNV",
    airportName: "Nuneaton",
    cityCode: "XNV",
    cityName: "Nuneaton",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XPF",
    airportName: "Penrith",
    cityCode: "XPF",
    cityName: "Penrith",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "PSL",
    airportName: "Perth",
    cityCode: "PSL",
    cityName: "Perth",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XVH",
    airportName: "Peterborough",
    cityCode: "XVH",
    cityName: "Peterborough",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XRE",
    airportName: "Reading",
    cityCode: "XRE",
    cityName: "Reading",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XRG",
    airportName: "Rugeley",
    cityCode: "XRG",
    cityName: "Rugeley",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XSR",
    airportName: "Salisbury",
    cityCode: "XSR",
    cityName: "Salisbury",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SZD",
    airportName: "Sheffield",
    cityCode: "SZD",
    cityName: "Sheffield",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "SDZ",
    airportName: "Shetland Islands",
    cityCode: "SDZ",
    cityName: "Shetland Islands",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XVJ",
    airportName: "Stevenage",
    cityCode: "XVJ",
    cityName: "Stevenage",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWB",
    airportName: "Stirling",
    cityCode: "XWB",
    cityName: "Stirling",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XVA",
    airportName: "Stockport",
    cityCode: "XVA",
    cityName: "Stockport",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWH",
    airportName: "Stoke-on-Trent",
    cityCode: "XWH",
    cityName: "Stoke-on-Trent",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWS",
    airportName: "Swindon",
    cityCode: "XWS",
    cityName: "Swindon",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "TTK",
    airportName: "Tottenham",
    cityCode: "TTK",
    cityName: "Tottenham",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWD",
    airportName: "Wakefield",
    cityCode: "XWD",
    cityName: "Wakefield",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWN",
    airportName: "Warrington",
    cityCode: "XWN",
    cityName: "Warrington",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWE",
    airportName: "Wellingborough",
    cityCode: "XWE",
    cityName: "Wellingborough",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWI",
    airportName: "Wigan",
    cityCode: "XWI",
    cityName: "Wigan",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XWO",
    airportName: "Woking",
    cityCode: "XWO",
    cityName: "Woking",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "XVW",
    airportName: "Wolverhampton",
    cityCode: "XVW",
    cityName: "Wolverhampton",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "QQY",
    airportName: "York",
    cityCode: "QQY",
    cityName: "York",
    countryCode: "GB",
    countryName: "United Kingdom",
    continent: "Europe",
  },
  {
    airportCode: "IXA",
    airportName: "Agartala",
    cityCode: "IXA",
    cityName: "Agartala",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AGX",
    airportName: "Agatti Aerodrome",
    cityCode: "AGX",
    cityName: "Agatti Island",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AGR",
    airportName: "Agra",
    cityCode: "AGR",
    cityName: "Agra",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXD",
    airportName: "Allahabad",
    cityCode: "IXD",
    cityName: "Allahabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXU",
    airportName: "Aurangabad",
    cityCode: "IXU",
    cityName: "Aurangabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXB",
    airportName: "Bagdogra",
    cityCode: "IXB",
    cityName: "Bagdogra",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXG",
    airportName: "Belgaum",
    cityCode: "IXG",
    cityName: "Belgaum",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BLR",
    airportName: "Bengaluru International",
    cityCode: "BLR",
    cityName: "Bengaluru",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BHU",
    airportName: "Bhavnagar",
    cityCode: "BHU",
    cityName: "Bhavnagar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BHJ",
    airportName: "Bhuj",
    cityCode: "BHJ",
    cityName: "Bhuj",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BBI",
    airportName: "Biju Patnaik International",
    cityCode: "BBI",
    cityName: "Bhubaneswar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXR",
    airportName: "Birsa Munda",
    cityCode: "IXR",
    cityName: "Ranchi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CCJ",
    airportName: "Calicut International",
    cityCode: "CCJ",
    cityName: "Kozhikode",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXC",
    airportName: "Chandigarh International",
    cityCode: "IXC",
    cityName: "Chandigarh",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "LKO",
    airportName: "Chaudhary Charan Singh International",
    cityCode: "LKO",
    cityName: "Lucknow",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "MAA",
    airportName: "Chennai International",
    cityCode: "MAA",
    cityName: "Chennai",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BOM",
    airportName: "Chhatrapati Shivaji International",
    cityCode: "BOM",
    cityName: "Mumbai",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "COK",
    airportName: "Cochin International",
    cityCode: "COK",
    cityName: "Kochi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CJB",
    airportName: "Coimbatore International",
    cityCode: "CJB",
    cityName: "Coimbatore",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CDP",
    airportName: "Cuddapah",
    cityCode: "CDP",
    cityName: "Kadapa",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DED",
    airportName: "Dehradun",
    cityCode: "DED",
    cityName: "Dehradun",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IDR",
    airportName: "Devi Ahilya Bai Holkar",
    cityCode: "IDR",
    cityName: "Indore",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DHM",
    airportName: "Dharamsala airport",
    cityCode: "DHM",
    cityName: "Dharamshala",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DIB",
    airportName: "Dibrugarh",
    cityCode: "DIB",
    cityName: "Dibrugarh",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DMU",
    airportName: "Dimapur",
    cityCode: "DMU",
    cityName: "Dimapur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DIU",
    airportName: "Diu",
    cityCode: "DIU",
    cityName: "Diu",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "NAG",
    airportName: "Dr. Babasaheb Ambedkar International",
    cityCode: "NAG",
    cityName: "Nagpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "ISK",
    airportName: "Gandhinagar Airport",
    cityCode: "null",
    cityName: "Nashik",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GAY",
    airportName: "Gaya",
    cityCode: "GAY",
    cityName: "Gaya",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GOI",
    airportName: "Goa International",
    cityCode: "GOI",
    cityName: "Goa",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GWL",
    airportName: "Gwalior",
    cityCode: "GWL",
    cityName: "Gwalior",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "HBX",
    airportName: "Hubli",
    cityCode: "HBX",
    cityName: "Hubli",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IMF",
    airportName: "Imphal International",
    cityCode: "IMF",
    cityName: "Imphal",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DEL",
    airportName: "Indira Gandhi International",
    cityCode: "DEL",
    cityName: "New Delhi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JLR",
    airportName: "Jabalpur",
    cityCode: "JLR",
    cityName: "Jabalpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JAI",
    airportName: "Jaipur International",
    cityCode: "JAI",
    cityName: "Jaipur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JSA",
    airportName: "Jaisalmer",
    cityCode: "JSA",
    cityName: "Jaisalmer",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXJ",
    airportName: "Jammu",
    cityCode: "IXJ",
    cityName: "Jammu",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JGA",
    airportName: "Jamnagar",
    cityCode: "JGA",
    cityName: "Jamnagar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PAT",
    airportName: "Jay Prakash Narayan International",
    cityCode: "PAT",
    cityName: "Patna",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JDH",
    airportName: "Jodhpur",
    cityCode: "JDH",
    cityName: "Jodhpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JRH",
    airportName: "Jorhat",
    cityCode: "JRH",
    cityName: "Jorhat",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXY",
    airportName: "Kandla",
    cityCode: "IXY",
    cityName: "Kandla",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "KNU",
    airportName: "Kanpur",
    cityCode: "KNU",
    cityName: "Kanpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "RDP",
    airportName: "Kazi Nazrul Islam",
    cityCode: "RDP",
    cityName: "Durgapur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "HJR",
    airportName: "Khajuraho",
    cityCode: "HJR",
    cityName: "Khajuraho Group of Monuments",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "KUU",
    airportName: "Kullu Manali",
    cityCode: "KUU",
    cityName: "Kullu",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "VNS",
    airportName: "Lal Bahadur Shastri International",
    cityCode: "VNS",
    cityName: "Varanasi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AJL",
    airportName: "Lengpui",
    cityCode: "AJL",
    cityName: "Aizawl",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXI",
    airportName: "Lilabari",
    cityCode: "IXI",
    cityName: "Lakhimpur district",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GAU",
    airportName: "Lokpriya Gopinath Bordoloi International",
    cityCode: "GAU",
    cityName: "Guwahati",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXM",
    airportName: "Madurai",
    cityCode: "IXM",
    cityName: "Madurai",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "UDR",
    airportName: "Maharana Pratap",
    cityCode: "UDR",
    cityName: "Udaipur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GOP",
    airportName: "Mahayogi Gorakhnath",
    cityCode: "GOP",
    cityName: "Gorakhpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXE",
    airportName: "Mangaluru",
    cityCode: "IXE",
    cityName: "Mangalore",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "MYQ",
    airportName: "Mysore",
    cityCode: "MYQ",
    cityName: "Mysore",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BKB",
    airportName: "Nal Airport",
    cityCode: "BKB",
    cityName: "Bikaner",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CCU",
    airportName: "Netaji Subhas Chandra Bose International",
    cityCode: "CCU",
    cityName: "Kolkata",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PGH",
    airportName: "Pantnagar",
    cityCode: "PGH",
    cityName: "Pantnagar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXT",
    airportName: "Pasighat Airport",
    cityCode: "null",
    cityName: "Pasighat",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PNY",
    airportName: "Pondicherry",
    cityCode: "PNY",
    cityName: "Pondicherry",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PBD",
    airportName: "Porbandar",
    cityCode: "PBD",
    cityName: "Porbandar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PNQ",
    airportName: "Pune",
    cityCode: "PNQ",
    cityName: "Pune",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BHO",
    airportName: "Raja Bhoj",
    cityCode: "BHO",
    cityName: "Bhopal",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "RJA",
    airportName: "Rajahmundry",
    cityCode: "RJA",
    cityName: "Rajahmundry",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "HYD",
    airportName: "Rajiv Gandhi International",
    cityCode: "HYD",
    cityName: "Hyderabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "RAJ",
    airportName: "Rajkot",
    cityCode: "RAJ",
    cityName: "Rajkot",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "LUH",
    airportName: "Sahnewal",
    cityCode: "LUH",
    cityName: "Ludhiana",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AMD",
    airportName: "Sardar Vallabhbhai Patel International",
    cityCode: "AMD",
    cityName: "Ahmedabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "SHL",
    airportName: "Shillong",
    cityCode: "SHL",
    cityName: "Shillong",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "NDC",
    airportName: "Shri Guru Gobind Singh Ji",
    cityCode: "NDC",
    cityName: "Nanded",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXS",
    airportName: "Silchar",
    cityCode: "IXS",
    cityName: "Silchar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "ATQ",
    airportName: "Sri Guru Ram Dass Jee International",
    cityCode: "ATQ",
    cityName: "Amritsar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "STV",
    airportName: "Surat",
    cityCode: "STV",
    cityName: "Surat",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "RPR",
    airportName: "Swami Vivekananda",
    cityCode: "RPR",
    cityName: "Raipur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "TEZ",
    airportName: "Tezpur",
    cityCode: "TEZ",
    cityName: "Tezpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "TRZ",
    airportName: "Tiruchirappalli International",
    cityCode: "TRZ",
    cityName: "Tiruchirappalli",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "TIR",
    airportName: "Tirupati",
    cityCode: "TIR",
    cityName: "Tirupati",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "TRV",
    airportName: "Trivandrum International",
    cityCode: "TRV",
    cityName: "Thiruvananthapuram",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "TCR",
    airportName: "Tuticorin",
    cityCode: "TCR",
    cityName: "Thoothukudi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BDQ",
    airportName: "Vadodara",
    cityCode: "BDQ",
    cityName: "Vadodara",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXZ",
    airportName: "Veer Savarkar International",
    cityCode: "IXZ",
    cityName: "Port Blair",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "VGA",
    airportName: "Vijayawada",
    cityCode: "VGA",
    cityName: "Vijayawada",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "VTZ",
    airportName: "Visakhapatnam",
    cityCode: "VTZ",
    cityName: "Visakhapatnam",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DBR",
    airportName: "Darbhanga Airport",
    cityCode: "DBR",
    cityName: "Darbhanga",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CNN",
    airportName: "Kannur International Airport",
    cityCode: "CNN",
    cityName: "Mattanur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "KJB",
    airportName: "Kurnool Airport",
    cityCode: "KJB",
    cityName: "Kurnool",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AIP",
    airportName: "Jalandhar Adampur Air Force Station",
    cityCode: "AIP",
    cityName: "Jalandhar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "SAG",
    airportName: "Shirdi Airport",
    cityCode: "SAG",
    cityName: "Shirdi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "VDY",
    airportName: "Jindal Vijaynagar Airport Karnataka",
    cityCode: "VDY",
    cityName: "Toranagallu",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "SXV",
    airportName: "Salem Airport",
    cityCode: "SXV",
    cityName: "Kamalapuram",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PYG",
    airportName: "Pakyong Airport",
    cityCode: "PYG",
    cityName: "Sikkim",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "KQH",
    airportName: "Kishangarh Airport Ajmer",
    cityCode: "KQH",
    cityName: "Ajmer",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "KLH",
    airportName: "Chhatrapati Rajaram Maharaj Airport",
    cityCode: "KLH",
    cityName: "Kolhapur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JRG",
    airportName: "Veer Surendra Sai Airport",
    cityCode: "JRG",
    cityName: "Jharsuguda",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "JGB",
    airportName: "Maa danteswari Airport",
    cityCode: "JGB",
    cityName: "Jagdalpur",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXP",
    airportName: "Pathankot Airport",
    cityCode: "IXP",
    cityName: "pathankot",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "LCG",
    airportName: "A Coruna",
    cityCode: "LCG",
    cityName: "A Coruna",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "AAL",
    airportName: "Aalborg",
    cityCode: "AAL",
    cityName: "Aalborg",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "AAR",
    airportName: "Aarhus",
    cityCode: "AAR",
    cityName: "Aarhus",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "JEG",
    airportName: "Aasiaat",
    cityCode: "JEG",
    cityName: "Aasiaat",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "JIM",
    airportName: "Aba Segud",
    cityCode: "JIM",
    cityName: "Jimma",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "DIR",
    airportName: "Aba Tenna Dejazmach Yilma International",
    cityCode: "DIR",
    cityName: "Dire Dawa",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "ABD",
    airportName: "Abadan",
    cityCode: "ABD",
    cityName: "Abadan",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ABA",
    airportName: "Abakan International",
    cityCode: "ABA",
    cityName: "Abakan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BJA",
    airportName: "Abane Ramdane",
    cityCode: "BJA",
    cityName: "Bejaia",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "YXX",
    airportName: "Abbotsford International",
    cityCode: "YXX",
    cityName: "Abbotsford",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MLG",
    airportName: "Abdul Rachman Saleh",
    cityCode: "MLG",
    cityName: "Malang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GLK",
    airportName: "Abdullahi Yusuf",
    cityCode: "GLK",
    cityName: "Galkayo",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "ZNZ",
    airportName: "Abeid Amani Karume International",
    cityCode: "ZNZ",
    cityName: "Zanzibar",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "SNU",
    airportName: "Abel Santamaria",
    cityCode: "SNU",
    cityName: "Santa Clara",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "AHB",
    airportName: "Abha Regional",
    cityCode: "AHB",
    cityName: "Abha",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "PSR",
    airportName: "Abruzzo",
    cityCode: "PSR",
    cityName: "Pescara",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "AUH",
    airportName: "Abu Dhabi International",
    cityCode: "AUH",
    cityName: "Abu Dhabi",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "ABS",
    airportName: "Abu Simbel",
    cityCode: "ABS",
    cityName: "Abu Simbel",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "ACA",
    airportName: "Acapulco International",
    cityCode: "ACA",
    cityName: "Acapulco",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "ACU",
    airportName: "Achutupo",
    cityCode: "ACU",
    cityName: "Achutupo",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "AAD",
    airportName: "Adado",
    cityCode: "AAD",
    cityName: "Adado",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "ADA",
    airportName: "Adana Sakirpasa",
    cityCode: "ADA",
    cityName: "Adana",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ADD",
    airportName: "Addis Ababa Bole International",
    cityCode: "ADD",
    cityName: "Addis Ababa",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "ADL",
    airportName: "Adelaide",
    cityCode: "ADL",
    cityName: "Adelaide",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MGQ",
    airportName: "Aden Adde International",
    cityCode: "MGQ",
    cityName: "Mogadishu",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "ADE",
    airportName: "Aden International Airport",
    cityCode: "ADE",
    cityName: "Aden",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "SOC",
    airportName: "Adisumarmo International",
    cityCode: "SOC",
    cityName: "Surakarta",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "JOG",
    airportName: "Adisutjipto International",
    cityCode: "JOG",
    cityName: "Yogyakarta",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ADF",
    airportName: "Adyaman",
    cityCode: "ADF",
    cityName: "Adyaman",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ADB",
    airportName: "Adnan Menderes",
    cityCode: "IZM",
    cityName: "Izmir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "MAD",
    airportName: "Adolfo Suarez Madrid Barajas",
    cityCode: "MAD",
    cityName: "Madrid",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "AEG",
    airportName: "Aek Godang",
    cityCode: "AEG",
    cityName: "Padangsidempuan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "AEP",
    airportName: "Aeroparque Jorge Newbery",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "CWB",
    airportName: "Afonso Pena International",
    cityCode: "CWB",
    cityName: "Curitiba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "AGA",
    airportName: "Agadir Al Massira",
    cityCode: "AGA",
    cityName: "Agadir",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "AGF",
    airportName: "Agen La Garenne",
    cityCode: "AGF",
    cityName: "Agen",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "AJI",
    airportName: "Agr?",
    cityCode: "AJI",
    cityName: "Agr?",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "AGU",
    airportName: "Aguascalientes International",
    cityCode: "AGU",
    cityName: "Aguascalientes",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TMR",
    airportName: "Aguenar Hadj Bey Akhamok",
    cityCode: "TMR",
    cityName: "Tamanrasset",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "AHE",
    airportName: "Ahe",
    cityCode: "AHE",
    cityName: "Ahe",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "SRG",
    airportName: "Ahmad Yani International",
    cityCode: "SRG",
    cityName: "Semarang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "AWZ",
    airportName: "Ahvaz International",
    cityCode: "AWZ",
    cityName: "Ahvaz",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "QSF",
    airportName: "Ain Arnat",
    cityCode: "QSF",
    cityName: "Setif",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "OGX",
    airportName: "Ain Beida",
    cityCode: "OGX",
    cityName: "Ouargla",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "HDS",
    airportName: "Air Force Base Hoedspruit",
    cityCode: "HDS",
    cityName: "Hoedspruit, Limpopo",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "CPH",
    airportName: "Airport Copenhagen",
    cityCode: "CPH",
    cityName: "Copenhagen",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "AIT",
    airportName: "Aitutaki",
    cityCode: "AIT",
    cityName: "Aitutaki",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "AJA",
    airportName: "Ajaccio Napoleon Bonaparte",
    cityCode: "AJA",
    cityName: "Ajaccio",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ENU",
    airportName: "Akanu Ibiam International",
    cityCode: "ENU",
    cityName: "Enugu",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "AXT",
    airportName: "Akita",
    cityCode: "AXT",
    cityName: "Akita",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "LAK",
    airportName: "Aklavik/Freddie Carmichael",
    cityCode: "LAK",
    cityName: "Aklavik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AKU",
    airportName: "Aksu",
    cityCode: "AKU",
    cityName: "Aksu City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SCO",
    airportName: "Aktau",
    cityCode: "SCO",
    cityName: "Aktau",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "PVK",
    airportName: "Aktion National",
    cityCode: "PVK",
    cityName: "Preveza",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "AKX",
    airportName: "Aktobe",
    cityCode: "AKX",
    cityName: "Aktobe",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "AKV",
    airportName: "Akulivik",
    cityCode: "AKV",
    cityName: "Akulivik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AKR",
    airportName: "Akure",
    cityCode: "AKR",
    cityName: "Akure",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "AEY",
    airportName: "Akureyri",
    cityCode: "AEY",
    cityName: "Akureyri",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "QUO",
    airportName: "Akwa Ibom",
    cityCode: "QUO",
    cityName: "Uyo",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "LAQ",
    airportName: "Al Abraq International",
    cityCode: "LAQ",
    cityName: "Al Bayda ",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "AAN",
    airportName: "Al Ain International",
    cityCode: "AAN",
    cityName: "Al Ain",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "DWC",
    airportName: "Al Maktoum International",
    cityCode: "DXB",
    cityName: "Dubai",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "NJF",
    airportName: "Al Najaf International",
    cityCode: "NJF",
    cityName: "Najaf",
    countryCode: "IQ",
    countryName: "Iraq",
    continent: "Asia",
  },
  {
    airportCode: "AQI",
    airportName: "Al Qaisumah/Hafr Al Batin",
    cityCode: "AQI",
    cityName: "Qaisumah",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "EJH",
    airportName: "Al Wajh Domestic",
    cityCode: "EJH",
    cityName: "Al Wajh",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "HOF",
    airportName: "Al-Ahsa International",
    cityCode: "HOF",
    cityName: "Hofuf",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "ABT",
    airportName: "Al-Baha Domestic",
    cityCode: "ABT",
    cityName: "Al Bahah",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "AJF",
    airportName: "Al-Jawf Domestic",
    cityCode: "AJF",
    cityName: "Al Jawf Region",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "NOV",
    airportName: "Albano Machado",
    cityCode: "NOV",
    cityName: "Huambo",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "ALH",
    airportName: "Albany",
    cityCode: "ALH",
    cityName: "Albany",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PAC",
    airportName: "Albrook Marcos A. Gelabert International",
    cityCode: "PTY",
    cityName: "Panama City",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "ABX",
    airportName: "Albury",
    cityCode: "ABX",
    cityName: "Albury",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SRE",
    airportName: "Alcantari International Airport",
    cityCode: "SRE",
    cityName: "Sucre",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "ACD",
    airportName: "Alcides Fernandez",
    cityCode: "ACD",
    cityName: "Acandi",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ADH",
    airportName: "Aldan",
    cityCode: "ADH",
    cityName: "Aldan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ACI",
    airportName: "Alderney",
    cityCode: "ACI",
    cityName: "Alderney",
    countryCode: "GG",
    countryName: "Guernsey",
    continent: "Europe",
  },
  {
    airportCode: "CUZ",
    airportName: "Alejandro Velasco Astete International",
    cityCode: "CUZ",
    cityName: "Cusco",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "AES",
    airportName: "Alesund Airport, Vigra",
    cityCode: "AES",
    cityName: "Alesund",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "AXD",
    airportName: "Alexandroupolis International",
    cityCode: "AXD",
    cityName: "Alexandroupoli",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "HUU",
    airportName: "Alferez FAP David Figueroa Fernandini",
    cityCode: "HUU",
    cityName: "Huanuco",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "CLO",
    airportName: "Alfonso Bonilla Aragon International",
    cityCode: "CLO",
    cityName: "Cali",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "VUP",
    airportName: "Alfonso Lopez Pumarejo",
    cityCode: "VUP",
    cityName: "Valledupar",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LET",
    airportName: "Alfredo Vasquez Cobo International",
    cityCode: "LET",
    cityName: "Leticia, Amazonas",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "AHO",
    airportName: "Alghero-Fertilia",
    cityCode: "AHO",
    cityName: "Alghero",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "ALC",
    airportName: "Alicante Elche",
    cityCode: "ALC",
    cityName: "Alicante",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "ASP",
    airportName: "Alice Springs",
    cityCode: "ASP",
    cityName: "Alice Springs",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "LHE",
    airportName: "Allama Iqbal International",
    cityCode: "LHE",
    cityName: "Lahore",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "ALA",
    airportName: "Almaty International",
    cityCode: "ALA",
    cityName: "Almaty",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "LEI",
    airportName: "Almeria",
    cityCode: "LEI",
    cityName: "Almeria",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "REL",
    airportName: "Almirante Marcos A. Zar",
    cityCode: "REL",
    cityName: "Trelew",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "RCH",
    airportName: "Almirante Padilla",
    cityCode: "RCH",
    cityName: "Riohacha",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ARD",
    airportName: "Alor Island",
    cityCode: "ARD",
    cityName: "Alor Island",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GNB",
    airportName: "Alpes Isere",
    cityCode: "GNB",
    cityName: "Grenoble",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ALF",
    airportName: "Alta",
    cityCode: "ALF",
    cityName: "Alta",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "AFL",
    airportName: "Alta Floresta",
    cityCode: "AFL",
    cityName: "Alta Floresta",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ATM",
    airportName: "Altamira",
    cityCode: "ATM",
    cityName: "Altamira",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "AAT",
    airportName: "Altay",
    cityCode: "AAT",
    cityName: "Altay Prefecture",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MQX",
    airportName: "Alula Aba Nega",
    cityCode: "MQX",
    cityName: "Mek ele",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "NSK",
    airportName: "Alykel",
    cityCode: "NSK",
    cityName: "Norilsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ASJ",
    airportName: "Amami",
    cityCode: "ASJ",
    cityName: "Amami",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "MZH",
    airportName: "Amasya Merzifon",
    cityCode: "MZH",
    cityName: "Amasya",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "MJN",
    airportName: "Amborovy",
    cityCode: "MJN",
    cityName: "Mahajanga",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "ASV",
    airportName: "Amboseli",
    cityCode: "ASV",
    cityName: "Amboseli National Park",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "SID",
    airportName: "Amilcar Cabral International",
    cityCode: "SID",
    cityName: "Sal",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "AMS",
    airportName: "Amsterdam Airport Schiphol",
    cityCode: "AMS",
    cityName: "Amsterdam",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "AAA",
    airportName: "Anaa",
    cityCode: "AAA",
    cityName: "Anaa",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "AOE",
    airportName: "Anadolu",
    cityCode: "ESK",
    cityName: "Eskisehir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "YAA",
    airportName: "Anahim Lake",
    cityCode: "YAA",
    cityName: "Anahim Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AAQ",
    airportName: "Anapa",
    cityCode: "AAQ",
    cityName: "Anapa",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "AUY",
    airportName: "Anatom",
    cityCode: "AUY",
    cityName: "Aneityum",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "ANS",
    airportName: "Andahuaylas",
    cityCode: "ANS",
    cityName: "Andahuaylas",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "AZN",
    airportName: "Andizhan",
    cityCode: "AZN",
    cityName: "Andijan",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "ANX",
    airportName: "Andøya",
    cityCode: "ANX",
    cityName: "Andenes",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "ANF",
    airportName: "Andres Sabella Galvez International",
    cityCode: "ANF",
    cityName: "Antofagasta",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "OUD",
    airportName: "Angads",
    cityCode: "OUD",
    cityName: "Oujda",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "AGH",
    airportName: "Angelholm Helsingborg",
    cityCode: "AGH",
    cityName: "Angelholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "ANE",
    airportName: "Angers Loire",
    cityCode: "ANE",
    cityName: "Angers",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "AWD",
    airportName: "Aniwa",
    cityCode: "AWD",
    cityName: "Aniwa Island",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "VBA",
    airportName: "Ann",
    cityCode: "VBA",
    cityName: "Ann",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "AQG",
    airportName: "Anqing Tianzhushan",
    cityCode: "AQG",
    cityName: "Anqing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "AOG",
    airportName: "Anshan Teng ao",
    cityCode: "AOG",
    cityName: "Anshan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "AVA",
    airportName: "Anshun Huangguoshu",
    cityCode: "AVA",
    cityName: "Anshun",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "AYT",
    airportName: "Antalya",
    cityCode: "AYT",
    cityName: "Antalya",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "CUM",
    airportName: "Antonio Jose de Sucre",
    cityCode: "CUM",
    cityName: "Cumana",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "SCU",
    airportName: "Antonio Maceo",
    cityCode: "SCU",
    cityName: "Santiago de Cuba",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "PSO",
    airportName: "Antonio Narino",
    cityCode: "PSO",
    cityName: "Pasto",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "APO",
    airportName: "Antonio Roldan Betancourt",
    cityCode: "APO",
    cityName: "Apartado",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ANR",
    airportName: "Antwerp International",
    cityCode: "BRU",
    cityName: "City of Brussels",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "AOJ",
    airportName: "Aomori",
    cityCode: "AOJ",
    cityName: "Aomori",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "APK",
    airportName: "Apataki",
    cityCode: "APK",
    cityName: "Apataki",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "ARU",
    airportName: "Aracatuba",
    cityCode: "ARU",
    cityName: "Aracatuba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ARW",
    airportName: "Arad International",
    cityCode: "ARW",
    cityName: "Arad",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "AUX",
    airportName: "Araguaina",
    cityCode: "AUX",
    cityName: "Araguaina",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RAE",
    airportName: "Arar Domestic",
    cityCode: "RAE",
    cityName: "Arar",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "ACR",
    airportName: "Araracuara",
    cityCode: "ACR",
    cityName: "Araracuara",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ASS",
    airportName: "Arathusa Safari Lodge",
    cityCode: "ASS",
    cityName: "Arathusa Safari Lodge",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "RKA",
    airportName: "Aratika-Nord",
    cityCode: "RKA",
    cityName: "Aratika",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "AAX",
    airportName: "Araxa",
    cityCode: "AAX",
    cityName: "Araxa",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "GPA",
    airportName: "Araxos",
    cityCode: "GPA",
    cityName: "Patras",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "AMH",
    airportName: "Arba Minch",
    cityCode: "AMH",
    cityName: "Arba Minch",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "YAB",
    airportName: "Arctic Bay",
    cityCode: "YAB",
    cityName: "Arctic Bay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ADU",
    airportName: "Ardabil",
    cityCode: "ADU",
    cityName: "Ardabil",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "OSD",
    airportName: "Are Ostersund",
    cityCode: "OSD",
    cityName: "Ostersund",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "BVC",
    airportName: "Aristides Pereira International",
    cityCode: "BVC",
    cityName: "Boa Vista",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "ARM",
    airportName: "Armidale",
    cityCode: "ARM",
    cityName: "Armidale",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KIE",
    airportName: "Aropa",
    cityCode: "KIE",
    cityName: "Kieta",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "DIE",
    airportName: "Arrachart",
    cityCode: "DIE",
    cityName: "Antsiranana",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "TAB",
    airportName: "Arthur Napoleon Raymond Robinson International",
    cityCode: "TAB",
    cityName: "Tobago",
    countryCode: "TT",
    countryName: "Trinidad & Tobago",
    continent: "North America",
  },
  {
    airportCode: "ATC",
    airportName: "Arthur's Town",
    cityCode: "ATC",
    cityName: "Arthur's Town",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "VLN",
    airportName: "Arturo Michelena International",
    cityCode: "VLN",
    cityName: "Valencia",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "ARK",
    airportName: "Arusha",
    cityCode: "ARK",
    cityName: "Arusha",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "AXR",
    airportName: "Arutua",
    cityCode: "AXR",
    cityName: "Arutua",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "YEK",
    airportName: "Arviat",
    cityCode: "YEK",
    cityName: "Arviat",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AJR",
    airportName: "Arvidsjaur",
    cityCode: "AJR",
    cityName: "Arvidsjaur",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "ABB",
    airportName: "Asaba International",
    cityCode: "ABB",
    cityName: "Asaba",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "AKJ",
    airportName: "Asahikawa",
    cityCode: "AKJ",
    cityName: "Asahikawa",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ASB",
    airportName: "Ashgabat International",
    cityCode: "ASB",
    cityName: "Ashgabat",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "ASM",
    airportName: "Asmara International",
    cityCode: "ASM",
    cityName: "Asmara",
    countryCode: "ER",
    countryName: "Eritrea",
    continent: "Africa",
  },
  {
    airportCode: "ASO",
    airportName: "Asosa",
    cityCode: "ASO",
    cityName: "Asosa",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "ATZ",
    airportName: "Assiut",
    cityCode: "ATZ",
    cityName: "Asyut",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "TSE",
    airportName: "Astana International",
    cityCode: "TSE",
    cityName: "Astana",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "MDQ",
    airportName: "Astor Piazzolla International",
    cityCode: "MDQ",
    cityName: "Mar del Plata",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "OVD",
    airportName: "Asturias",
    cityCode: "OVD",
    cityName: "Asturias",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "JTY",
    airportName: "Astypalaia Island National",
    cityCode: "JTY",
    cityName: "Astypalaia",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "ASW",
    airportName: "Aswan International",
    cityCode: "ASW",
    cityName: "Aswan",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "ATH",
    airportName: "Athens International",
    cityCode: "ATH",
    cityName: "Athens",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "YAT",
    airportName: "Attawapiskat",
    cityCode: "YAT",
    cityName: "Attawapiskat First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AUQ",
    airportName: "Atuona",
    cityCode: "AUQ",
    cityName: "Hiva Oa",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "GUW",
    airportName: "Atyrau",
    cityCode: "GUW",
    cityName: "Atyrau",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "AKL",
    airportName: "Auckland",
    cityCode: "AKL",
    cityName: "Auckland",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "NGD",
    airportName: "Auguste George",
    cityCode: "NGD",
    cityName: "Anegada",
    countryCode: "VG",
    countryName: "British Virgin Islands",
    continent: "North America",
  },
  {
    airportCode: "MGA",
    airportName: "Augusto C. Sandino International",
    cityCode: "MGA",
    cityName: "Managua",
    countryCode: "NI",
    countryName: "Nicaragua",
    continent: "North America",
  },
  {
    airportCode: "NAT",
    airportName: "Augusto Severo International",
    cityCode: "NAT",
    cityName: "Natal",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "AKS",
    airportName: "Auki Gwaunaru u",
    cityCode: "AKS",
    cityName: "Auki",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "YPJ",
    airportName: "Aupaluk",
    cityCode: "YPJ",
    cityName: "Aupaluk",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AUR",
    airportName: "Aurillac Tronquieres",
    cityCode: "AUR",
    cityName: "Aurillac",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "AVV",
    airportName: "Avalon",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CPC",
    airportName: "Aviador Carlos Campos",
    cityCode: "CPC",
    cityName: "San Martiin de los Andes",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "AVN",
    airportName: "Avignon Provence",
    cityCode: "AVN",
    cityName: "Avignon",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CBO",
    airportName: "Awang",
    cityCode: "CBO",
    cityName: "Cotabato City",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "AWA",
    airportName: "Awasa",
    cityCode: "AWA",
    cityName: "Awasa",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "AXU",
    airportName: "Axum",
    cityCode: "AXU",
    cityName: "Axum",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "AYQ",
    airportName: "Ayers Rock",
    cityCode: "AYQ",
    cityName: "Uluru",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BKM",
    airportName: "Ba kelalan",
    cityCode: "BKM",
    cityName: "Ba kelalan",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "TTE",
    airportName: "Babullah",
    cityCode: "TTE",
    cityName: "Ternate City",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PEW",
    airportName: "Bacha Khan International",
    cityCode: "PEW",
    cityName: "Peshawar",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "CUL",
    airportName: "Bachigualato Federal International",
    cityCode: "CUL",
    cityName: "Culiacan",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "BCO",
    airportName: "Baco",
    cityCode: "BCO",
    cityName: "Jinka",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "BCD",
    airportName: "Bacolod Silay",
    cityCode: "BCD",
    cityName: "Bacolod",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "BJZ",
    airportName: "Badajoz",
    cityCode: "BJZ",
    cityName: "Badajoz",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "BFX",
    airportName: "Bafoussam",
    cityCode: "BFX",
    cityName: "Bafoussam",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "BGW",
    airportName: "Baghdad International",
    cityCode: "BGW",
    cityName: "Baghdad",
    countryCode: "IQ",
    countryName: "Iraq",
    continent: "Asia",
  },
  {
    airportCode: "BHV",
    airportName: "Bahawalpur",
    cityCode: "BHV",
    cityName: "Bahawalpur",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "BFQ",
    airportName: "Bahia Pina",
    cityCode: "BFQ",
    cityName: "Bahia Pinas",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "HUX",
    airportName: "Bahias de Huatulco International",
    cityCode: "HUX",
    cityName: "Huatulco",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "BJR",
    airportName: "Bahir Dar",
    cityCode: "BJR",
    cityName: "Bahir Dar",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "BAH",
    airportName: "Bahrain International",
    cityCode: "BAH",
    cityName: "Bahrain Island",
    countryCode: "BH",
    countryName: "Bahrain",
    continent: "Asia",
  },
  {
    airportCode: "BAY",
    airportName: "Baia Mare Airport",
    cityCode: "BAY",
    cityName: "Baia Mare",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YBC",
    airportName: "Baie-Comeau",
    cityCode: "YBC",
    cityName: "Baie-Comeau",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "UUD",
    airportName: "Baikal International",
    cityCode: "UUD",
    cityName: "Ulan-Ude",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "AEB",
    airportName: "Baise Bama",
    cityCode: "AEB",
    cityName: "Baise",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YBK",
    airportName: "Baker Lake",
    cityCode: "YBK",
    cityName: "Baker Lake, Nunavut",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BAS",
    airportName: "Balalae",
    cityCode: "BAS",
    cityName: "Balalae Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "EDO",
    airportName: "Balikesir Koca Seyit",
    cityCode: "EDO",
    cityName: "Edremit",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "BKN",
    airportName: "Balkanabat",
    cityCode: "BKN",
    cityName: "Balkanabat",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "BXH",
    airportName: "Balkhash",
    cityCode: "BXH",
    cityName: "Balkhash",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "BNK",
    airportName: "Ballina Byron Gateway",
    cityCode: "BNK",
    cityName: "Ballina",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BBA",
    airportName: "Balmaceda",
    cityCode: "BBA",
    cityName: "Balmaceda",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "BKO",
    airportName: "Bamako Senou International",
    cityCode: "BKO",
    cityName: "Bamako",
    countryCode: "ML",
    countryName: "Mali",
    continent: "Africa",
  },
  {
    airportCode: "HOE",
    airportName: "Ban Huoeisay",
    cityCode: "HOE",
    cityName: "Ban Houayxay",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "BXU",
    airportName: "Bancasi",
    cityCode: "BXU",
    cityName: "Butuan",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "BND",
    airportName: "Bandar Abbas International",
    cityCode: "BND",
    cityName: "Bandar Abbas",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "BDH",
    airportName: "Bandar Lengeh",
    cityCode: "BDH",
    cityName: "Bandar Lengeh",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "CMB",
    airportName: "Bandaranaike International",
    cityCode: "CMB",
    cityName: "Colombo",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "FKI",
    airportName: "Bangoka International",
    cityCode: "FKI",
    cityName: "Kisangani",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "BGF",
    airportName: "Bangui M Poko International",
    cityCode: "BGF",
    cityName: "Bangui",
    countryCode: "CF",
    countryName: "Central African Republic",
    continent: "Africa",
  },
  {
    airportCode: "BNX",
    airportName: "Banja Luka International",
    cityCode: "BNX",
    cityName: "Banja Luka",
    countryCode: "BA",
    countryName: "Bosnia & Herzegovina",
    continent: "Europe",
  },
  {
    airportCode: "BJL",
    airportName: "Banjul International",
    cityCode: "BJL",
    cityName: "Banjul",
    countryCode: "GM",
    countryName: "Gambia",
    continent: "Africa",
  },
  {
    airportCode: "BSD",
    airportName: "Baoshan Yunrui",
    cityCode: "BSD",
    cityName: "Baoshan, Yunnan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BAV",
    airportName: "Baotou",
    cityCode: "BAV",
    cityName: "Baotou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BCI",
    airportName: "Barcaldine",
    cityCode: "BCI",
    cityName: "Barcaldine",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BCN",
    airportName: "Barcelona El Prat",
    cityCode: "BCN",
    cityName: "Barcelona",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "BDU",
    airportName: "Bardufoss",
    cityCode: "BDU",
    cityName: "Andselv",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "BRI",
    airportName: "Bari Karol Wojtyla",
    cityCode: "BRI",
    cityName: "Bari",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "BYP",
    airportName: "Barimunya",
    cityCode: "BYP",
    cityName: "Barimunya",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BNS",
    airportName: "Barinas",
    cityCode: "BNS",
    cityName: "Barinas",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "BBN",
    airportName: "Bario",
    cityCode: "BBN",
    cityName: "Bario",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "BZL",
    airportName: "Barisal",
    cityCode: "BZL",
    cityName: "Barisal",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "BAX",
    airportName: "Barnaul",
    cityCode: "BAX",
    cityName: "Barnaul",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BPG",
    airportName: "Barra do Garcas",
    cityCode: "BPG",
    cityName: "Barra do Garcas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BRA",
    airportName: "Barreiras",
    cityCode: "BRA",
    cityName: "Barreiras",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CAW",
    airportName: "Bartolomeu Lysandro",
    cityCode: "CAW",
    cityName: "Campos dos Goytacazes",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BSO",
    airportName: "Basco",
    cityCode: "BSO",
    cityName: "Basco",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "BSR",
    airportName: "Basra International",
    cityCode: "BSR",
    cityName: "Basra",
    countryCode: "IQ",
    countryName: "Iraq",
    continent: "Asia",
  },
  {
    airportCode: "BIA",
    airportName: "Bastia Poretta",
    cityCode: "BIA",
    cityName: "Bastia",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BSG",
    airportName: "Bata",
    cityCode: "BSG",
    cityName: "Bata",
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    continent: "Africa",
  },
  {
    airportCode: "BQJ",
    airportName: "Batagay",
    cityCode: "BQJ",
    cityName: "Batagay",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ZBF",
    airportName: "Bathurst",
    cityCode: "ZBF",
    cityName: "Bathurst",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BHS",
    airportName: "Bathurst",
    cityCode: "BHS",
    cityName: "Bathurst",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BAL",
    airportName: "Batman",
    cityCode: "BAL",
    cityName: "Batman",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "BJF",
    airportName: "Batsfjord",
    cityCode: "BJF",
    cityName: "Batsfjord",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "BTC",
    airportName: "Batticaloa",
    cityCode: "BTC",
    cityName: "Batticaloa",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "BTW",
    airportName: "Batu Licin",
    cityCode: "BTW",
    cityName: "Batulicin",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BUS",
    airportName: "Batumi International",
    cityCode: "BUS",
    cityName: "Batumi",
    countryCode: "GE",
    countryName: "Georgia",
    continent: "Asia",
  },
  {
    airportCode: "BUW",
    airportName: "Baubau",
    cityCode: "BUW",
    cityName: "Bau-Bau",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "VLI",
    airportName: "Bauerfield International",
    cityCode: "VLI",
    cityName: "Port Vila",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "JTC",
    airportName: "Bauru-Arealva",
    cityCode: "JTC",
    cityName: "Bauru",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RLK",
    airportName: "Bayannur Tianjitai",
    cityCode: "RLK",
    cityName: "Bayannur",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XBE",
    airportName: "Bearskin Lake",
    cityCode: "XBE",
    cityName: "Bearskin Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BVA",
    airportName: "Beauvais Tille",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BEU",
    airportName: "Bedourie",
    cityCode: "BEU",
    cityName: "Bedourie",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "NBC",
    airportName: "Begishevo",
    cityCode: "NBC",
    cityName: "Nizhnekamsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "BHY",
    airportName: "Beihai Fucheng",
    cityCode: "BHY",
    cityName: "Beihai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PEK",
    airportName: "Beijing Capital International",
    cityCode: "BJS",
    cityName: "Beijing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NAY",
    airportName: "Beijing Nanyuan",
    cityCode: "BJS",
    cityName: "Beijing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BEW",
    airportName: "Beira",
    cityCode: "BEW",
    cityName: "Beira",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "BEY",
    airportName: "Beirut Rafic Hariri International",
    cityCode: "BEY",
    cityName: "Beirut",
    countryCode: "LB",
    countryName: "Lebanon",
    continent: "Asia",
  },
  {
    airportCode: "BGN",
    airportName: "Belaya Gora",
    cityCode: "BGN",
    cityName: "Belaya Gora, Sakha Republic",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "EGO",
    airportName: "Belgorod International",
    cityCode: "EGO",
    cityName: "Belgorod",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "BEG",
    airportName: "Belgrade Nikola Tesla",
    cityCode: "BEG",
    cityName: "Belgrade",
    countryCode: "RS",
    countryName: "Serbia",
    continent: "Europe",
  },
  {
    airportCode: "TZA",
    airportName: "Belize City Municipal",
    cityCode: "BZE",
    cityName: "Belize City",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "ZEL",
    airportName: "Bella Bella (Campbell Island)",
    cityCode: "ZEL",
    cityName: "Bella Bella",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "QBC",
    airportName: "Bella Coola",
    cityCode: "QBC",
    cityName: "Bella Coola",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BNY",
    airportName: "Bellona/Anua",
    cityCode: "BNY",
    cityName: "Bellona Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "PLU",
    airportName: "Belo Horizonte/Pampulha Carlos Drummond de Andrade",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "EYK",
    airportName: "Beloyarsk",
    cityCode: "EYK",
    cityName: "Beloyarsky",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TLV",
    airportName: "Ben Gurion",
    cityCode: "TLV",
    cityName: "Tel Aviv",
    countryCode: "IL",
    countryName: "Israel",
    continent: "Asia",
  },
  {
    airportCode: "BNC",
    airportName: "Beni Mavivi",
    cityCode: "BNC",
    cityName: "Beni, Democratic Republic of the Congo",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "BEM",
    airportName: "Beni Mellal",
    cityCode: "BEM",
    cityName: "Beni Mellal",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "BNI",
    airportName: "Benin",
    cityCode: "BNI",
    cityName: "Benin City",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "BEN",
    airportName: "Benina International",
    cityCode: "BEN",
    cityName: "Benghazi",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "NVA",
    airportName: "Benito Salas",
    cityCode: "NVA",
    cityName: "Neiva, Huila",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "BJT",
    airportName: "Bentota River SPB",
    cityCode: "BJT",
    cityName: "Bentota",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "BBO",
    airportName: "Berbera Airport",
    cityCode: "BBO",
    cityName: "Berbera",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "YBV",
    airportName: "Berens River",
    cityCode: "YBV",
    cityName: "Berens River, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "EZV",
    airportName: "Berezovo",
    cityCode: "EZV",
    cityName: "Beryozovo, Khanty-Mansi Autonomous Okrug",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BGO",
    airportName: "Bergen Airport, Flesland",
    cityCode: "BGO",
    cityName: "Bergen",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "EGC",
    airportName: "Bergerac Dordogne Perigord",
    cityCode: "EGC",
    cityName: "Bergerac",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BVG",
    airportName: "Berlevag",
    cityCode: "BVG",
    cityName: "Berlevag",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "SXF",
    airportName: "Berlin Schonefeld",
    cityCode: "BER",
    cityName: "Berlin",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "TXL",
    airportName: "Berlin Tegel",
    cityCode: "BER",
    cityName: "Berlin",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "BRN",
    airportName: "Bern",
    cityCode: "BRN",
    cityName: "Bern",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "OGZ",
    airportName: "Beslan",
    cityCode: "OGZ",
    cityName: "Vladikavkaz",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BZR",
    airportName: "Beziers Cap d Agde",
    cityCode: "BZR",
    cityName: "Beziers",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BDP",
    airportName: "Bhadrapur",
    cityCode: "BDP",
    cityName: "Bhadrapur, Mechi",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "BMO",
    airportName: "Bhamo",
    cityCode: "BMO",
    cityName: "Bhamo",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "BHR",
    airportName: "Bharatpur",
    cityCode: "BHR",
    cityName: "Bharatpur",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "BIQ",
    airportName: "Biarritz Pays Basque",
    cityCode: "BIQ",
    cityName: "Biarritz",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BFJ",
    airportName: "Bijie Feixiong",
    cityCode: "BFJ",
    cityName: "Bijie",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BIO",
    airportName: "Bilbao",
    cityCode: "BIO",
    cityName: "Bilbao",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "BLL",
    airportName: "Billund",
    cityCode: "BLL",
    cityName: "Billund",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "YTZ",
    airportName: "Billy Bishop Toronto City",
    cityCode: "YTO",
    cityName: "Toronto",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "GNS",
    airportName: "Binaka",
    cityCode: "GNS",
    cityName: "Gunungsitoli",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BGG",
    airportName: "Bingol",
    cityCode: "BGG",
    cityName: "Bingol",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "BTU",
    airportName: "Bintulu",
    cityCode: "BTU",
    cityName: "Bintulu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "BIR",
    airportName: "Biratnagar",
    cityCode: "BIR",
    cityName: "Biratnagar",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "BVI",
    airportName: "Birdsville",
    cityCode: "BVI",
    cityName: "Birdsville",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "XBJ",
    airportName: "Birjand International",
    cityCode: "XBJ",
    cityName: "Birjand",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "BHH",
    airportName: "Bisha Domestic",
    cityCode: "BHH",
    cityName: "Bisha",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "BSK",
    airportName: "Biskra",
    cityCode: "BSK",
    cityName: "Biskra",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "BKQ",
    airportName: "Blackall",
    cityCode: "BKQ",
    cityName: "Blackall",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "DSS",
    airportName: "Blaise Diagne International Airport",
    cityCode: "DKR",
    cityName: "Dakar",
    countryCode: "SN",
    countryName: "Senegal",
    continent: "Africa",
  },
  {
    airportCode: "BWX",
    airportName: "Blimbingsari",
    cityCode: "BWX",
    cityName: "Banyuwangi",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BFN",
    airportName: "Bloemfontein",
    cityCode: "BFN",
    cityName: "Bloemfontein",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "BVB",
    airportName: "Boa Vista International",
    cityCode: "BVB",
    cityName: "Boa Vista",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BOY",
    airportName: "Bobo Dioulasso",
    cityCode: "BOY",
    cityName: "Bobo-Dioulasso",
    countryCode: "BF",
    countryName: "Burkina Faso",
    continent: "Africa",
  },
  {
    airportCode: "BOC",
    airportName: "Bocas del Toro Isla Colon International",
    cityCode: "BOC",
    cityName: "Bocas Town, Bocas del Toro",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "ODO",
    airportName: "Bodaybo",
    cityCode: "ODO",
    cityName: "Bodaybo",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BOO",
    airportName: "Bodo",
    cityCode: "BOO",
    cityName: "Bodo",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "TOF",
    airportName: "Bogashevo",
    cityCode: "TOF",
    cityName: "Tomsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BJB",
    airportName: "Bojnord",
    cityCode: "BJB",
    cityName: "Bojnord",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "VBP",
    airportName: "Bokpyin",
    cityCode: "VBP",
    cityName: "Bokpyin",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "BLQ",
    airportName: "Bologna Guglielmo Marconi",
    cityCode: "BLQ",
    cityName: "Bologna",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "BYO",
    airportName: "Bonito",
    cityCode: "BYO",
    cityName: "Bonito",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TRW",
    airportName: "Bonriki International",
    cityCode: "TRW",
    cityName: "Bairiki",
    countryCode: "KI",
    countryName: "Kiribati",
    continent: "Oceania",
  },
  {
    airportCode: "BOB",
    airportName: "Bora Bora",
    cityCode: "BOB",
    cityName: "Bora Bora",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "BOD",
    airportName: "Bordeaux Merignac",
    cityCode: "BOD",
    cityName: "Bordeaux",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BMW",
    airportName: "Bordj Mokhtar",
    cityCode: "BMW",
    cityName: "Bordj Badji Mokhtar",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "HBE",
    airportName: "Borg El Arab",
    cityCode: "ALY",
    cityName: "Alexandria",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "RNN",
    airportName: "Bornholm",
    cityCode: "RNN",
    cityName: "Bornholm",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "KBP",
    airportName: "Boryspil International",
    cityCode: "IEV",
    cityName: "Kiev",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "BSA",
    airportName: "Bosaso",
    cityCode: "BSA",
    cityName: "Bosaso",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "BST",
    airportName: "Bost",
    cityCode: "BST",
    cityName: "Lashkargah",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "BYK",
    airportName: "Bouake",
    cityCode: "BYK",
    cityName: "Bouake",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "CBH",
    airportName: "Boudghene Ben Ali Lotfi",
    cityCode: "CBH",
    cityName: "Bechar",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "BQL",
    airportName: "Boulia",
    cityCode: "BQL",
    cityName: "Boulia",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "OTL",
    airportName: "Boutilimit Airport",
    cityCode: "null",
    cityName: "Boutilimit",
    countryCode: "MR",
    countryName: "Mauritania",
    continent: "Africa",
  },
  {
    airportCode: "BWK",
    airportName: "Brac",
    cityCode: "BWK",
    cityName: "Brac",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "YBR",
    airportName: "Brandon Municipal",
    cityCode: "YBR",
    cityName: "Brandon",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BSB",
    airportName: "Brasilia International",
    cityCode: "BSB",
    cityName: "Brasilia",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BTS",
    airportName: "Bratislava Airport",
    cityCode: "BTS",
    cityName: "Bratislava",
    countryCode: "SK",
    countryName: "Slovakia",
    continent: "Europe",
  },
  {
    airportCode: "BTK",
    airportName: "Bratsk",
    cityCode: "BTK",
    cityName: "Bratsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "BRE",
    airportName: "Bremen",
    cityCode: "BRE",
    cityName: "Bremen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "VBS",
    airportName: "Brescia / Montichiari Airport",
    cityCode: "BRZ",
    cityName: "Brescia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "BQT",
    airportName: "Brest",
    cityCode: "BQT",
    cityName: "Brest",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "BES",
    airportName: "Brest Bretagne",
    cityCode: "BES",
    cityName: "Brest",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LUQ",
    airportName: "Brigadier Mayor Cesar Raul Ojeda",
    cityCode: "LUQ",
    cityName: "San Luis",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "BDS",
    airportName: "Brindisi",
    cityCode: "BDS",
    cityName: "Brindisi",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "BNE",
    airportName: "Brisbane",
    cityCode: "BNE",
    cityName: "Brisbane",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "WTB",
    airportName: "Brisbane West Wellcamp",
    cityCode: "TWB",
    cityName: "Toowoomba",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BVE",
    airportName: "Brive Souillac Airport",
    cityCode: "BVE",
    cityName: "Brive-la-Gaillarde",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BRQ",
    airportName: "Brno Turany",
    cityCode: "BRQ",
    cityName: "Brno",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "YBT",
    airportName: "Brochet",
    cityCode: "YBT",
    cityName: "Brochet, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BHQ",
    airportName: "Broken Hill",
    cityCode: "BHQ",
    cityName: "Broken Hill",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BNN",
    airportName: "Bronnoysund Airport, Bronnoy",
    cityCode: "BNN",
    cityName: "Bronnoysund",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "BME",
    airportName: "Broome International",
    cityCode: "BME",
    cityName: "Broome",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BWN",
    airportName: "Brunei International",
    cityCode: "BWN",
    cityName: "Bandar Seri Begawan",
    countryCode: "BN",
    countryName: "Brunei",
    continent: "Asia",
  },
  {
    airportCode: "BRU",
    airportName: "Brussels",
    cityCode: "BRU",
    cityName: "City of Brussels",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "CRL",
    airportName: "Brussels South Charleroi",
    cityCode: "BRU",
    cityName: "City of Brussels",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "BZK",
    airportName: "Bryansk International",
    cityCode: "BZK",
    cityName: "Bryansk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "LUW",
    airportName: "Bubung",
    cityCode: "LUW",
    cityName: "Luwuk",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KWA",
    airportName: "Bucholz Army Airfield",
    cityCode: "KWA",
    cityName: "Kwajalein Atoll",
    countryCode: "MH",
    countryName: "Marshall Islands",
    continent: "Oceania",
  },
  {
    airportCode: "BUD",
    airportName: "Budapest Ferenc Liszt International",
    cityCode: "BUD",
    cityName: "Budapest",
    countryCode: "HU",
    countryName: "Hungary",
    continent: "Europe",
  },
  {
    airportCode: "UUA",
    airportName: "Bugulma",
    cityCode: "UUA",
    cityName: "Bugulma",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "BJM",
    airportName: "Bujumbura International",
    cityCode: "BJM",
    cityName: "Bujumbura",
    countryCode: "BI",
    countryName: "Burundi",
    continent: "Africa",
  },
  {
    airportCode: "BUA",
    airportName: "Buka",
    cityCode: "BUA",
    cityName: "Buka Island",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "BHK",
    airportName: "Bukhara International",
    cityCode: "BHK",
    cityName: "Bukhara",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "BKZ",
    airportName: "Bukoba",
    cityCode: "BKZ",
    cityName: "Bukoba",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "BUL",
    airportName: "Bulolo Airport",
    cityCode: "BUL",
    cityName: "Bulolo",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "BMB",
    airportName: "Bumba",
    cityCode: "BMB",
    cityName: "Bumba, Democratic Republic of the Congo",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "BDB",
    airportName: "Bundaberg",
    cityCode: "BDB",
    cityName: "Bundaberg Region",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BUX",
    airportName: "Bunia",
    cityCode: "BUX",
    cityName: "Bunia",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "BMV",
    airportName: "Buon Ma Thuot",
    cityCode: "BMV",
    cityName: "Buon Ma Thuot",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "BOJ",
    airportName: "Burgas",
    cityCode: "BOJ",
    cityName: "Burgas",
    countryCode: "BG",
    countryName: "Bulgaria",
    continent: "Europe",
  },
  {
    airportCode: "RGS",
    airportName: "Burgos Airport",
    cityCode: "RGS",
    cityName: "Burgos",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "BFV",
    airportName: "Buriram",
    cityCode: "BFV",
    cityName: "Buriram Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "BUC",
    airportName: "Burketown",
    cityCode: "BUC",
    cityName: "Burketown",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BWT",
    airportName: "Burnie",
    cityCode: "BWT",
    cityName: "Burnie",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BUZ",
    airportName: "Bushehr",
    cityCode: "BUZ",
    cityName: "Bushehr",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "BQB",
    airportName: "Busselton Regional",
    cityCode: "BQB",
    cityName: "Busselton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BZG",
    airportName: "Bydgoszcz Ignacy Jan Paderewski",
    cityCode: "BZG",
    cityName: "Bydgoszcz",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "BKA",
    airportName: "Bykovo Airport",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "CAH",
    airportName: "Ca Mau",
    cityCode: "CAH",
    cityName: "Ca Mau Province",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "CAB",
    airportName: "Cabinda",
    cityCode: "CAB",
    cityName: "Cabinda",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "CFB",
    airportName: "Cabo Frio International",
    cityCode: "CFB",
    cityName: "Cabo Frio",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PYH",
    airportName: "Cacique Aramare",
    cityCode: "PYH",
    cityName: "Puerto Ayacucho",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "OAL",
    airportName: "Cacoal",
    cityCode: "OAL",
    cityName: "Cacoal",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TPP",
    airportName: "Cad. FAP Guillermo del Castillo Paredes",
    cityCode: "TPP",
    cityName: "Tarapoto",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "COO",
    airportName: "Cadjehoun",
    cityCode: "COO",
    cityName: "Cotonou",
    countryCode: "BJ",
    countryName: "Benin",
    continent: "Africa",
  },
  {
    airportCode: "CFR",
    airportName: "Caen Carpiquet",
    cityCode: "CFR",
    cityName: "Caen",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CAG",
    airportName: "Cagliari Elmas",
    cityCode: "CAG",
    cityName: "Cagliari",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "CNS",
    airportName: "Cairns",
    cityCode: "CNS",
    cityName: "Cairns",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CAI",
    airportName: "Cairo International",
    cityCode: "CAI",
    cityName: "Cairo",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "CYP",
    airportName: "Calbayog",
    cityCode: "CYP",
    cityName: "Calbayog",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "CLV",
    airportName: "Caldas Novas",
    cityCode: "CLV",
    cityName: "Caldas Novas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "YYC",
    airportName: "Calgary International",
    cityCode: "YYC",
    cityName: "Calgary",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CLY",
    airportName: "Calvi Sainte-Catherine",
    cityCode: "CLY",
    cityName: "Calvi, Haute-Corse",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CXR",
    airportName: "Cam Ranh International",
    cityCode: "NHA",
    cityName: "Nha Trang",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "YCB",
    airportName: "Cambridge Bay",
    cityCode: "YCB",
    cityName: "Cambridge Bay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CGM",
    airportName: "Camiguin",
    cityCode: "CGM",
    cityName: "Camiguin",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "CUC",
    airportName: "Camilo Daza International",
    cityCode: "CUC",
    cityName: "Cucuta",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "YBL",
    airportName: "Campbell River",
    cityCode: "YBL",
    cityName: "Campbell River",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CPE",
    airportName: "Campeche International",
    cityCode: "CPE",
    cityName: "Campeche",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CPV",
    airportName: "Campina Grande",
    cityCode: "CPV",
    cityName: "Campina Grande",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CXJ",
    airportName: "Campo dos Bugres",
    cityCode: "CXJ",
    cityName: "Caxias do Sul",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CGR",
    airportName: "Campo Grande International",
    cityCode: "CGR",
    cityName: "Campo Grande",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "VCA",
    airportName: "Can Tho International",
    cityCode: "VCA",
    cityName: "Can Tho",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "CKZ",
    airportName: "Canakkale",
    cityCode: "CKZ",
    cityName: "Canakkale",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ZOS",
    airportName: "Canal Bajo Carlos Hott Siebert",
    cityCode: "ZOS",
    cityName: "Osorno",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "CBR",
    airportName: "Canberra",
    cityCode: "CBR",
    cityName: "Canberra",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CUN",
    airportName: "Cancun International",
    cityCode: "CUN",
    cityName: "Cancun",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "DCF",
    airportName: "Canefield",
    cityCode: "DOM",
    cityName: "Saint Joseph Parish",
    countryCode: "DM",
    countryName: "Dominica",
    continent: "North America",
  },
  {
    airportCode: "CWJ",
    airportName: "Cangyuan Washan",
    cityCode: "CWJ",
    cityName: "Cangyuan Va Autonomous County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CSK",
    airportName: "Cap Skirring",
    cityCode: "CSK",
    cityName: "Cap Skiring",
    countryCode: "SN",
    countryName: "Senegal",
    continent: "Africa",
  },
  {
    airportCode: "CAP",
    airportName: "Cap-Haitien International",
    cityCode: "CAP",
    cityName: "Cap-Haitien",
    countryCode: "HT",
    countryName: "Haiti",
    continent: "North America",
  },
  {
    airportCode: "TBP",
    airportName: "Cap. FAP Pedro Canga Rodriguez",
    cityCode: "TBP",
    cityName: "Tumbes",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "YTE",
    airportName: "Cape Dorset",
    cityCode: "YTE",
    cityName: "Cape Dorset",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CPT",
    airportName: "Cape Town International",
    cityCode: "CPT",
    cityName: "Cape Town",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "PSZ",
    airportName: "Capitan Av. Salvador Ogaya G. airport",
    cityCode: "PSZ",
    cityName: "Puerto Suarez",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "PDP",
    airportName: "Capitan de Corbeta Carlos A. Curbelo International",
    cityCode: "PDP",
    cityName: "Punta del Este",
    countryCode: "UY",
    countryName: "Uruguay",
    continent: "South America",
  },
  {
    airportCode: "PIO",
    airportName: "Capitan FAP Renan Elias Olivera",
    cityCode: "PIO",
    cityName: "Pisco",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "TYL",
    airportName: "Capitan FAP Victor Montes Arias International",
    cityCode: "TYL",
    cityName: "Talara",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "WPR",
    airportName: "Capitan Fuentes Martinez",
    cityCode: "WPR",
    cityName: "Porvenir",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "TJA",
    airportName: "Capitan Oriel Lea Plaza",
    cityCode: "TJA",
    cityName: "Tarija",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "CIJ",
    airportName: "Captain Anibal Arab",
    cityCode: "CIJ",
    cityName: "Cobija",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "POI",
    airportName: "Captain Nicolas Rojas",
    cityCode: "POI",
    cityName: "Potosi",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "CKS",
    airportName: "Carajas",
    cityCode: "CKS",
    cityName: "Parauapebas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CCF",
    airportName: "Carcassonne",
    cityCode: "CCF",
    cityName: "Carcassonne",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BYM",
    airportName: "Carlos Manuel de Cespedes",
    cityCode: "BYM",
    cityName: "Bayamo",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "CVQ",
    airportName: "Carnarvon",
    cityCode: "CVQ",
    cityName: "Carnarvon",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MVD",
    airportName: "Carrasco International",
    cityCode: "MVD",
    cityName: "Montevideo",
    countryCode: "UY",
    countryName: "Uruguay",
    continent: "South America",
  },
  {
    airportCode: "CCP",
    airportName: "Carriel Sur International",
    cityCode: "CCP",
    cityName: "Concepcion",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "CAC",
    airportName: "Cascavel",
    cityCode: "CAC",
    cityName: "Cascavel",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CXI",
    airportName: "Cassidy International",
    cityCode: "CXI",
    cityName: "Kiritimati",
    countryCode: "KI",
    countryName: "Kiribati",
    continent: "Oceania",
  },
  {
    airportCode: "CDT",
    airportName: "Castellon Costa Azahar",
    cityCode: "CDT",
    cityName: "Castellon de la Plana",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "DCM",
    airportName: "Castres Mazamet",
    cityCode: "DCM",
    cityName: "Castres",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "HPH",
    airportName: "Cat Bi International",
    cityCode: "HPH",
    cityName: "Haiphong",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "YAC",
    airportName: "Cat Lake",
    cityCode: "YAC",
    cityName: "Cat Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CTA",
    airportName: "Catania Fontanarossa",
    cityCode: "CTA",
    cityName: "Catania",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "IGR",
    airportName: "Cataratas del Iguazu International",
    cityCode: "IGR",
    cityName: "Puerto Iguazu",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "CRM",
    airportName: "Catarman National",
    cityCode: "CRM",
    cityName: "Catarman, Northern Samar",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "CBT",
    airportName: "Catumbela",
    cityCode: "CBT",
    cityName: "Catumbela",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "CYZ",
    airportName: "Cauayan",
    cityCode: "CYZ",
    cityName: "Cauayan, Isabela",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "LQM",
    airportName: "Caucaya",
    cityCode: "LQM",
    cityName: "Puerto Leguizamo",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ONQ",
    airportName: "Caycuma",
    cityCode: "ONQ",
    cityName: "Zonguldak",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "CUK",
    airportName: "Caye Caulker",
    cityCode: "CUK",
    cityName: "Caye Caulker",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "CAY",
    airportName: "Cayenne Felix Eboue",
    cityCode: "CAY",
    cityName: "Cayenne",
    countryCode: "FR",
    countryName: "France",
    continent: "South America",
  },
  {
    airportCode: "CED",
    airportName: "Ceduna",
    cityCode: "CED",
    cityName: "Ceduna",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PDA",
    airportName: "Cesar Gaviria Trujillo",
    cityCode: "PDA",
    cityName: "Inirida, Guainia",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "VXE",
    airportName: "Cesaria Evora",
    cityCode: "VXE",
    cityName: "Sao Vicente",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "YBG",
    airportName: "CFB Bagotville",
    cityCode: "YBG",
    cityName: "Saguenay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YQQ",
    airportName: "CFB Comox",
    cityCode: "YQQ",
    cityName: "Comox",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YYR",
    airportName: "CFB Goose Bay",
    cityCode: "YYR",
    cityName: "Happy Valley-Goose Bay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ARI",
    airportName: "Chacalluta International",
    cityCode: "ARI",
    cityName: "Arica",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "CHH",
    airportName: "Chachapoyas Airport",
    cityCode: "null",
    cityName: "Chachapoyas",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "XCR",
    airportName: "Chalons Vatry",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CMF",
    airportName: "Chambery",
    cityCode: "CMF",
    cityName: "Chambery",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CPO",
    airportName: "Chamonate",
    cityCode: "CPO",
    cityName: "Copiapo",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "NBS",
    airportName: "Changbaishan",
    cityCode: "NBS",
    cityName: "Baishan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CGQ",
    airportName: "Changchun Longjia International",
    cityCode: "CGQ",
    cityName: "Changchun",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CGD",
    airportName: "Changde Taohuayuan",
    cityCode: "CGD",
    cityName: "Changde",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CSX",
    airportName: "Changsha Huanghua International",
    cityCode: "CSX",
    cityName: "Changsha",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CHX",
    airportName: "Changuinola Capitan Manuel Nino International",
    cityCode: "CHX",
    cityName: "Changuinola",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "CIH",
    airportName: "Changzhi Wangcun",
    cityCode: "CIH",
    cityName: "Changzhi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CZX",
    airportName: "Changzhou Benniu",
    cityCode: "CZX",
    cityName: "Changzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CHQ",
    airportName: "Chania International",
    cityCode: "CHQ",
    cityName: "Chania",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "CHG",
    airportName: "Chaoyang",
    cityCode: "CHG",
    cityName: "Chaoyang, Liaoning",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XAP",
    airportName: "Chapeco",
    cityCode: "XAP",
    cityName: "Chapeco",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CDG",
    airportName: "Charles de Gaulle Airport",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CYB",
    airportName: "Charles Kirkconnell International",
    cityCode: "CYB",
    cityName: "Cayman Brac",
    countryCode: "KY",
    countryName: "Cayman Islands",
    continent: "North America",
  },
  {
    airportCode: "CTL",
    airportName: "Charleville",
    cityCode: "CTL",
    cityName: "Charleville",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YCL",
    airportName: "Charlo",
    cityCode: "YCL",
    cityName: "Charlo",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YYG",
    airportName: "Charlottetown",
    cityCode: "YYG",
    cityName: "Charlottetown",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CSY",
    airportName: "Cheboksary",
    cityCode: "CSY",
    cityName: "Cheboksary",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "GEO",
    airportName: "Cheddi Jagan International",
    cityCode: "GEO",
    cityName: "Georgetown",
    countryCode: "GY",
    countryName: "Guyana",
    continent: "South America",
  },
  {
    airportCode: "TEE",
    airportName: "Cheikh Larbi Tebessi",
    cityCode: "TEE",
    cityName: "Tebessa",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "CEK",
    airportName: "Chelyabinsk",
    cityCode: "CEK",
    cityName: "Chelyabinsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "CDE",
    airportName: "Chengde Puning",
    cityCode: "CDE",
    cityName: "Chengde",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CTU",
    airportName: "Chengdu Shuangliu International",
    cityCode: "CTU",
    cityName: "Chengdu",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CJJ",
    airportName: "Cheongju International",
    cityCode: "CJJ",
    cityName: "Cheongju",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "CEE",
    airportName: "Cherepovets",
    cityCode: "CEE",
    cityName: "Cherepovets",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "AHU",
    airportName: "Cherif Al Idrissi",
    cityCode: "AHU",
    cityName: "Al Hoceima",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "CWC",
    airportName: "Chernivtsi International",
    cityCode: "CWC",
    cityName: "Chernivtsi",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "CYX",
    airportName: "Chersky",
    cityCode: "CYX",
    cityName: "Chersky",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "YCS",
    airportName: "Chesterfield Inlet",
    cityCode: "YCS",
    cityName: "Chesterfield Inlet, Nunavut",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CTM",
    airportName: "Chetumal International",
    cityCode: "CTM",
    cityName: "Chetumal",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "YHR",
    airportName: "Chevery",
    cityCode: "YHR",
    cityName: "Chevery, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CNX",
    airportName: "Chiang Mai International",
    cityCode: "CNX",
    cityName: "Chiang Mai",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "CEI",
    airportName: "Chiang Rai International",
    cityCode: "CEI",
    cityName: "Chiang Rai Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "CYI",
    airportName: "Chiayi",
    cityCode: "CYI",
    cityName: "Chiayi",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "YMT",
    airportName: "Chibougamau/Chapais",
    cityCode: "YMT",
    cityName: "Chibougamau",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CIF",
    airportName: "Chifeng Yulong",
    cityCode: "CIF",
    cityName: "Chifeng",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CUU",
    airportName: "Chihuahua International",
    cityCode: "CUU",
    cityName: "Chihuahua",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "BLZ",
    airportName: "Chileka International",
    cityCode: "BLZ",
    cityName: "Blantyre",
    countryCode: "MW",
    countryName: "Malawi",
    continent: "Africa",
  },
  {
    airportCode: "VPY",
    airportName: "Chimoio",
    cityCode: "VPY",
    cityName: "Chimoio",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "CCA",
    airportName: "Chimore",
    cityCode: "CCA",
    cityName: "Chimore, Cochabamba",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "TRR",
    airportName: "China Bay",
    cityCode: "TRR",
    cityName: "Trincomalee",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "ULN",
    airportName: "Chinggis Khaan International",
    cityCode: "ULN",
    cityName: "Ulaanbaatar",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "TET",
    airportName: "Chingozi",
    cityCode: "TET",
    cityName: "Tete",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "JKH",
    airportName: "Chios Island National",
    cityCode: "JKH",
    cityName: "Chios",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "YKU",
    airportName: "Chisasibi",
    cityCode: "YKU",
    cityName: "Chisasibi",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KIV",
    airportName: "Chisinau International",
    cityCode: "KIV",
    cityName: "Chisinau",
    countryCode: "MD",
    countryName: "Moldova",
    continent: "Europe",
  },
  {
    airportCode: "CJL",
    airportName: "Chitral",
    cityCode: "CJL",
    cityName: "Chitral",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "CTD",
    airportName: "Chitre Alonso Valderrama",
    cityCode: "CTD",
    cityName: "Chitre",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "JUH",
    airportName: "Chizhou Jiuhuashan",
    cityCode: "JUH",
    cityName: "Chizhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CFK",
    airportName: "Chlef International",
    cityCode: "CFK",
    cityName: "Chlef",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "COQ",
    airportName: "Choibalsan",
    cityCode: "COQ",
    cityName: "Choibalsan",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "CHY",
    airportName: "Choiseul Bay",
    cityCode: "CHY",
    cityName: "Choiseul Bay",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "CKH",
    airportName: "Chokurdakh",
    cityCode: "CKH",
    cityName: "Chokurdakh",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "CKG",
    airportName: "Chongqing Jiangbei International",
    cityCode: "CKG",
    cityName: "Chongqing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CHC",
    airportName: "Christchurch International",
    cityCode: "CHC",
    cityName: "Christchurch",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "XCH",
    airportName: "Christmas Island",
    cityCode: "XCH",
    cityName: "Shire of",
    countryCode: "CX",
    countryName: "Christmas Island",
    continent: "Oceania",
  },
  {
    airportCode: "VCL",
    airportName: "Chu Lai International",
    cityCode: "VCL",
    cityName: "Chu Lai",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "NGO",
    airportName: "Chubu Centrair International",
    cityCode: "NGO",
    cityName: "Nagoya",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NER",
    airportName: "Chulman Neryungri Airport",
    cityCode: "NER",
    cityName: "Neryungri",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "CJM",
    airportName: "Chumphon",
    cityCode: "CJM",
    cityName: "Chumphon Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "YYQ",
    airportName: "Churchill",
    cityCode: "YYQ",
    cityName: "Churchill",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZUM",
    airportName: "Churchill Falls",
    cityCode: "ZUM",
    cityName: "Churchill Falls",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TKK",
    airportName: "Chuuk International",
    cityCode: "TKK",
    cityName: "Chuuk State",
    countryCode: "FM",
    countryName: "Micronesia",
    continent: "Oceania",
  },
  {
    airportCode: "CIA",
    airportName: "Ciampino G. B. Pastine International",
    cityCode: "ROM",
    cityName: "Rome",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "STI",
    airportName: "Cibao International",
    cityCode: "STI",
    cityName: "Santiago de los Caballeros",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "ICI",
    airportName: "Cicia",
    cityCode: "ICI",
    cityName: "Cicia",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "LOH",
    airportName: "Ciudad de Catamayo",
    cityCode: "LOH",
    cityName: "Loja",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "CME",
    airportName: "Ciudad del Carmen International",
    cityCode: "CME",
    cityName: "Ciudad del Carmen",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CJS",
    airportName: "Ciudad Juarez International",
    cityCode: "CJS",
    cityName: "Ciudad Juarez",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CEN",
    airportName: "Ciudad Obregon International",
    cityCode: "CEN",
    cityName: "Ciudad Obregon",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CVM",
    airportName: "Ciudad Victoria International",
    cityCode: "CVM",
    cityName: "Ciudad Victoria",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "GFN",
    airportName: "Clarence Valley Regional",
    cityCode: "GFN",
    cityName: "Grafton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CRK",
    airportName: "Clark International",
    cityCode: "CRK",
    cityName: "Angeles",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "AXA",
    airportName: "Clayton J. Lloyd International",
    cityCode: "AXA",
    cityName: "Anguilla",
    countryCode: "AI",
    countryName: "Anguilla",
    continent: "North America",
  },
  {
    airportCode: "CFE",
    airportName: "Clermont-Ferrand Auvergne",
    cityCode: "CFE",
    cityName: "Clermont-Ferrand",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CNJ",
    airportName: "Cloncurry",
    cityCode: "CNJ",
    cityName: "Cloncurry",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CLJ",
    airportName: "Cluj International",
    cityCode: "CLJ",
    cityName: "Cluj-Napoca",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YCY",
    airportName: "Clyde River",
    cityCode: "YCY",
    cityName: "Clyde River, Nunavut",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CAZ",
    airportName: "Cobar",
    cityCode: "CAZ",
    cityName: "Cobar",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CCK",
    airportName: "Cocos (Keeling) Islands",
    cityCode: "CCK",
    cityName: "Home Island",
    countryCode: "CC",
    countryName: "Cocos (Keeling) Islands",
    continent: "Oceania",
  },
  {
    airportCode: "CFS",
    airportName: "Coffs Harbour",
    cityCode: "CFS",
    cityName: "Coffs Harbour",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CLQ",
    airportName: "Colima",
    cityCode: "CLQ",
    cityName: "Colima",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CGN",
    airportName: "Cologne Bonn Airport",
    cityCode: "CGN",
    cityName: "Cologne",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ESM",
    airportName: "Colonel Carlos Concha Torres",
    cityCode: "ESM",
    cityName: "Esmeraldas",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "CRI",
    airportName: "Colonel Hill",
    cityCode: "CRI",
    cityName: "Crooked Island",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "YCK",
    airportName: "Colville Lake/Tommy Kochon Aerodrome",
    cityCode: "YCK",
    cityName: "Colville Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "FTE",
    airportName: "Comandante Armando Tola International",
    cityCode: "FTE",
    cityName: "El Calafate",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "BHI",
    airportName: "Comandante Espora",
    cityCode: "BHI",
    cityName: "Bahia Blanca",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "ATA",
    airportName: "Comandante FAP German Arias Graziani",
    cityCode: "ATA",
    cityName: "Huaraz",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "DSE",
    airportName: "Combolcha",
    cityCode: "DSE",
    cityName: "Dessie",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "CIY",
    airportName: "Comiso",
    cityCode: "CIY",
    cityName: "Comiso",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "SCL",
    airportName: "Comodoro Arturo Merino Benitez International",
    cityCode: "SCL",
    cityName: "Santiago de Chile",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "VCS",
    airportName: "Con Dao",
    cityCode: "VCS",
    cityName: "Con Son Island",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "CKY",
    airportName: "Conakry International",
    cityCode: "CKY",
    cityName: "Conakry",
    countryCode: "GN",
    countryName: "Guinea",
    continent: "Africa",
  },
  {
    airportCode: "COG",
    airportName: "Condoto Mandinga",
    cityCode: "COG",
    cityName: "Condoto",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "OTD",
    airportName: "Contadora",
    cityCode: "OTD",
    cityName: "Contadora Island",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "CPD",
    airportName: "Coober Pedy",
    cityCode: "CPD",
    cityName: "Coober Pedy",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CTN",
    airportName: "Cooktown",
    cityCode: "CTN",
    cityName: "Cooktown",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "OOM",
    airportName: "Cooma Snowy Mountains",
    cityCode: "OOM",
    cityName: "Cooma",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CJF",
    airportName: "Coondewanna",
    cityCode: "CJF",
    cityName: "Coondewanna",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "WRO",
    airportName: "Copernicus Airport Wroc?aw",
    cityCode: "WRO",
    cityName: "Wroc?aw",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "TCD",
    airportName: "Coposa",
    cityCode: "TCD",
    cityName: "Tarapaca",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "YZS",
    airportName: "Coral Harbour",
    cityCode: "YZS",
    cityName: "Coral Harbour",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CFU",
    airportName: "Corfu International",
    cityCode: "CFU",
    cityName: "Corfu",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "ORK",
    airportName: "Cork",
    cityCode: "ORK",
    cityName: "Cork",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "AYP",
    airportName: "Coronel FAP Alfredo Mendivil Duarte",
    cityCode: "AYP",
    cityName: "Ayacucho",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "TCQ",
    airportName: "Coronel FAP Carlos Ciriani Santa Rosa International",
    cityCode: "TCQ",
    cityName: "Tacna",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "IQT",
    airportName: "Coronel FAP Francisco Secada Vignetta International",
    cityCode: "IQT",
    cityName: "Iquitos",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "CTC",
    airportName: "Coronel Felipe Varela International",
    cityCode: "CTC",
    cityName: "San Fernando del Valle de Catamarca",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "CZH",
    airportName: "Corozal",
    cityCode: "CZH",
    cityName: "Corozal Town",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "CMG",
    airportName: "Corumba International",
    cityCode: "CMG",
    cityName: "Corumba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CVU",
    airportName: "Corvo",
    cityCode: "CVU",
    cityName: "Corvo Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "ECI",
    airportName: "Costa Esmeralda",
    cityCode: "ECI",
    cityName: "Rivas",
    countryCode: "NI",
    countryName: "Nicaragua",
    continent: "North America",
  },
  {
    airportCode: "LTX",
    airportName: "Cotopaxi International",
    cityCode: "LTX",
    cityName: "Latacunga",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "CXB",
    airportName: "Cox's Bazar",
    cityCode: "CXB",
    cityName: "Cox's Bazar",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "CZM",
    airportName: "Cozumel International",
    cityCode: "CZM",
    cityName: "Cozumel",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CCV",
    airportName: "Craig Cove",
    cityCode: "CCV",
    cityName: "Craig Cove",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "CRA",
    airportName: "Craiova International",
    cityCode: "CRA",
    cityName: "Craiova",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YXC",
    airportName: "Cranbrook/Canadian Rockies International",
    cityCode: "YXC",
    cityName: "Cranbrook",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "FNC",
    airportName: "Cristiano Ronaldo International",
    cityCode: "FNC",
    cityName: "Madeira",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "YCR",
    airportName: "Cross Lake (Charlie Sinclair Memorial)",
    cityCode: "YCR",
    cityName: "Cross Lake, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CRV",
    airportName: "Crotone Airport",
    cityCode: "CRV",
    cityName: "Crotone",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "JJD",
    airportName: "Cruz Jericoacoara",
    cityCode: "JJD",
    cityName: "Cruz, Ceara",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CZS",
    airportName: "Cruzeiro do Sul International",
    cityCode: "CZS",
    cityName: "Cruzeiro do Sul",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CUF",
    airportName: "Cuneo International",
    cityCode: "CUF",
    cityName: "Cuneo",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "CMA",
    airportName: "Cunnamulla",
    cityCode: "CMA",
    cityName: "Cunnamulla",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CUR",
    airportName: "Curacao International",
    cityCode: "CUR",
    cityName: "Willemstad",
    countryCode: "CW",
    countryName: "Curacao",
    continent: "North America",
  },
  {
    airportCode: "MEQ",
    airportName: "Cut Nyak Dhien",
    cityCode: "MEQ",
    cityName: "Meulaboh",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "DAD",
    airportName: "Da Nang International",
    cityCode: "DAD",
    cityName: "Da Nang",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "TAE",
    airportName: "Daegu International",
    cityCode: "TAE",
    cityName: "Daegu",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "VIL",
    airportName: "Dakhla",
    cityCode: "VIL",
    cityName: "Dakhla",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Africa",
  },
  {
    airportCode: "BLE",
    airportName: "Dala",
    cityCode: "BLE",
    cityName: "Borlange",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "DLM",
    airportName: "Dalaman",
    cityCode: "DLM",
    cityName: "Dalaman",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "DLZ",
    airportName: "Dalanzadgad",
    cityCode: "DLZ",
    cityName: "Dalanzadgad",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "DBA",
    airportName: "Dalbandin",
    cityCode: "DBA",
    cityName: "Dalbandin",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "DLU",
    airportName: "Dali",
    cityCode: "DLU",
    cityName: "Dali",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DLC",
    airportName: "Dalian Zhoushuizi International",
    cityCode: "DLC",
    cityName: "Dalian",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DAM",
    airportName: "Damascus International",
    cityCode: "DAM",
    cityName: "Damascus",
    countryCode: "SY",
    countryName: "Syria",
    continent: "Asia",
  },
  {
    airportCode: "RSS",
    airportName: "Damazin",
    cityCode: "RSS",
    cityName: "Roseires",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "DDG",
    airportName: "Dandong Langtou",
    cityCode: "DDG",
    cityName: "Dandong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DGA",
    airportName: "Dangriga",
    cityCode: "DGA",
    cityName: "Dangriga",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "LIR",
    airportName: "Daniel Oduber Quiros International",
    cityCode: "LIR",
    cityName: "Liberia",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "TAC",
    airportName: "Daniel Z. Romualdez",
    cityCode: "TAC",
    cityName: "Tacloban",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "DCY",
    airportName: "Daocheng Yading",
    cityCode: "DCY",
    cityName: "Daocheng County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DQA",
    airportName: "Daqing Sartu",
    cityCode: "DQA",
    cityName: "Daqing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DAU",
    airportName: "Daru",
    cityCode: "DAU",
    cityName: "Daru",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "DRW",
    airportName: "Darwin International",
    cityCode: "DRW",
    cityName: "Darwin",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SRY",
    airportName: "Dasht-e Naz",
    cityCode: "SRY",
    cityName: "Sari",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "TAZ",
    airportName: "Dasoguz",
    cityCode: "TAZ",
    cityName: "Dasoguz",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "DAT",
    airportName: "Datong Yungang",
    cityCode: "DAT",
    cityName: "Datong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KUT",
    airportName: "David the Builder Kutaisi International",
    cityCode: "KUT",
    cityName: "Kutaisi",
    countryCode: "GE",
    countryName: "Georgia",
    continent: "Asia",
  },
  {
    airportCode: "DWD",
    airportName: "Dawadmi Domestic",
    cityCode: "DWD",
    cityName: "Dawadmi",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "TVY",
    airportName: "Dawei",
    cityCode: "TVY",
    cityName: "Dawei Township",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "YDA",
    airportName: "Dawson City",
    cityCode: "YDA",
    cityName: "Dawson City",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YDQ",
    airportName: "Dawson Creek",
    cityCode: "YDQ",
    cityName: "Dawson Creek",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "GSM",
    airportName: "Dayrestan",
    cityCode: "GSM",
    cityName: "Qeshm",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "DAX",
    airportName: "Dazhou Heshi",
    cityCode: "DAX",
    cityName: "Dazhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LGI",
    airportName: "Deadman's Cay",
    cityCode: "LGI",
    cityName: "Deadman's Cay Settlement",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "DOL",
    airportName: "Deauville Normandie",
    cityCode: "DOL",
    cityName: "Deauville",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "DEB",
    airportName: "Debrecen International",
    cityCode: "DEB",
    cityName: "Debrecen",
    countryCode: "HU",
    countryName: "Hungary",
    continent: "Europe",
  },
  {
    airportCode: "YVZ",
    airportName: "Deer Lake",
    cityCode: "YVZ",
    cityName: "Deer Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YDF",
    airportName: "Deer Lake Regional",
    cityCode: "YDF",
    cityName: "Deer Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LUM",
    airportName: "Dehong Mangshi",
    cityCode: "LUM",
    cityName: "Mang City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BJX",
    airportName: "Del Bajio International",
    cityCode: "BJX",
    cityName: "Leon",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "DEM",
    airportName: "Dembi Dolo",
    cityCode: "DEM",
    cityName: "Dembidolo",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "DNZ",
    airportName: "Denizli Cardak",
    cityCode: "DNZ",
    cityName: "Denizli",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "PGK",
    airportName: "Depati Amir",
    cityCode: "PGK",
    cityName: "Pangkal Pinang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KRC",
    airportName: "Depati Parbo",
    cityCode: "KRC",
    cityName: "Kerinci",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SSA",
    airportName: "Deputado Luis Eduardo Magalhaes International",
    cityCode: "SSA",
    cityName: "Salvador",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "DPT",
    airportName: "Deputatsky",
    cityCode: "DPT",
    cityName: "Deputatsky, Sakha Republic",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "DEA",
    airportName: "Dera Ghazi Khan International",
    cityCode: "DEA",
    cityName: "Dera Ghazi Khan",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "DPO",
    airportName: "Devonport",
    cityCode: "DPO",
    cityName: "Devonport",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "DHI",
    airportName: "Dhangadhi",
    cityCode: "DHI",
    cityName: "Dhangadhi",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "DRV",
    airportName: "Dharavandhoo",
    cityCode: "DRV",
    cityName: "Dharavandhoo",
    countryCode: "MV",
    countryName: "Maldives",
    continent: "Asia",
  },
  {
    airportCode: "IQQ",
    airportName: "Diego Aracena International",
    cityCode: "IQQ",
    cityName: "Iquique",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "DIN",
    airportName: "Dien Bien Phu",
    cityCode: "DIN",
    cityName: "Dien Bien Phu",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "DKS",
    airportName: "Dikson",
    cityCode: "DKS",
    cityName: "Dikson",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "DLY",
    airportName: "Dillon's Bay",
    cityCode: "DLY",
    cityName: "Dillons Bay",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "DNR",
    airportName: "Dinard Pleurtuit Saint-Malo",
    cityCode: "DNR",
    cityName: "Dinard",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "CCM",
    airportName: "Diomicio Freitas",
    cityCode: "CCM",
    cityName: "Criciuma",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "NIM",
    airportName: "Diori Hamani International",
    cityCode: "NIM",
    cityName: "Niamey",
    countryCode: "NE",
    countryName: "Niger",
    continent: "Africa",
  },
  {
    airportCode: "DPL",
    airportName: "Dipolog",
    cityCode: "DPL",
    cityName: "Dipolog",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "DIG",
    airportName: "Diqing Shangri-La",
    cityCode: "DIG",
    cityName: "Diqing Tibetan Autonomous Prefecture",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DIQ",
    airportName: "Divinopolis",
    cityCode: "DIQ",
    cityName: "Divinopolis",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "DIY",
    airportName: "Diyarbakir",
    cityCode: "DIY",
    cityName: "Diyarbakir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "DJE",
    airportName: "Djerba Zarzis International",
    cityCode: "DJE",
    cityName: "Djerba",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "JIB",
    airportName: "Djibouti Ambouli International",
    cityCode: "JIB",
    cityName: "Balbala",
    countryCode: "DJ",
    countryName: "Djibouti",
    continent: "Africa",
  },
  {
    airportCode: "DNK",
    airportName: "Dnipropetrovsk International",
    cityCode: "DNK",
    cityName: "Dnipro",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "DOB",
    airportName: "Dobo",
    cityCode: "DOB",
    cityName: "Dobo",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "CNQ",
    airportName: "Doctor Fernando Piragine Niveyro International",
    cityCode: "CNQ",
    cityName: "Corrientes",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "DOD",
    airportName: "Dodoma",
    cityCode: "DOD",
    cityName: "Dodoma",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "DLE",
    airportName: "Dole Jura",
    cityCode: "DLE",
    cityName: "Dole",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "SOQ",
    airportName: "Domine Eduard Osok",
    cityCode: "SOQ",
    cityName: "Sorong",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "UAQ",
    airportName: "Domingo Faustino Sarmiento",
    cityCode: "UAQ",
    cityName: "San Juan",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "DME",
    airportName: "Domodedovo International",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "DMK",
    airportName: "Don Mueang International",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "CFN",
    airportName: "Donegal",
    cityCode: "CFN",
    cityName: "Donegal",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "VDH",
    airportName: "Dong Hoi",
    cityCode: "VDH",
    cityName: "Dong Hoi",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "TBB",
    airportName: "Dong Tac",
    cityCode: "TBB",
    cityName: "Tuy Hoa",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "DOY",
    airportName: "Dongying Shengli",
    cityCode: "DOY",
    cityName: "Dongying",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ULZ",
    airportName: "Donoi",
    cityCode: "ULZ",
    cityName: "Uliastai",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "DMD",
    airportName: "Doomadgee",
    cityCode: "DMD",
    cityName: "Doomadgee",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "DTM",
    airportName: "Dortmund",
    cityCode: "DTM",
    cityName: "Dortmund",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "DLA",
    airportName: "Douala International",
    cityCode: "DLA",
    cityName: "Douala",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "DOM",
    airportName: "Douglas Charles",
    cityCode: "DOM",
    cityName: "Saint Joseph Parish",
    countryCode: "DM",
    countryName: "Dominica",
    continent: "North America",
  },
  {
    airportCode: "DOU",
    airportName: "Dourados",
    cityCode: "DOU",
    cityName: "Dourados",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "DRK",
    airportName: "Drake Bay",
    cityCode: "DRK",
    cityName: "Bahia Drake",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "DRS",
    airportName: "Dresden",
    cityCode: "DRS",
    cityName: "Dresden",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "YHD",
    airportName: "Dryden Regional",
    cityCode: "YHD",
    cityName: "Dryden",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "XNB",
    airportName: "Dubai Bus Station",
    cityCode: "DXB",
    cityName: "Dubai",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "DXB",
    airportName: "Dubai International",
    cityCode: "DXB",
    cityName: "Dubai",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "DBO",
    airportName: "Dubbo City Regional",
    cityCode: "DBO",
    cityName: "Dubbo",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "DUB",
    airportName: "Dublin",
    cityCode: "DUB",
    cityName: "Dublin",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "DBV",
    airportName: "Dubrovnik",
    cityCode: "DBV",
    cityName: "Dubrovnik",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "DUE",
    airportName: "Dundo",
    cityCode: "DUE",
    cityName: "Dundo",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "DUD",
    airportName: "Dunedin",
    cityCode: "DUD",
    cityName: "Dunedin",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "DNH",
    airportName: "Dunhuang",
    cityCode: "DNH",
    cityName: "Dunhuang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DQM",
    airportName: "Duqm Jaaluni",
    cityCode: "DQM",
    cityName: "Duqm",
    countryCode: "OM",
    countryName: "Oman",
    continent: "Asia",
  },
  {
    airportCode: "DGO",
    airportName: "Durango International",
    cityCode: "DGO",
    cityName: "Durango",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "DUR",
    airportName: "Durban International",
    cityCode: "DUR",
    cityName: "Durban",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "DYU",
    airportName: "Dushanbe International",
    cityCode: "DYU",
    cityName: "Dushanbe",
    countryCode: "TJ",
    countryName: "Tajikistan",
    continent: "Asia",
  },
  {
    airportCode: "DUS",
    airportName: "Dusseldorf International Airport",
    cityCode: "DUS",
    cityName: "Dusseldorf",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "DZA",
    airportName: "Dzaoudzi Pamandzi International",
    cityCode: "DZA",
    cityName: "Mamoudzou",
    countryCode: "FR",
    countryName: "France",
    continent: "Africa",
  },
  {
    airportCode: "SVD",
    airportName: "E. T. Joshua",
    cityCode: "SVD",
    cityName: "Saint Vincent",
    countryCode: "VC",
    countryName: "St. Vincent & Grenadines",
    continent: "North America",
  },
  {
    airportCode: "KNX",
    airportName: "East Kimberley Regional",
    cityCode: "KNX",
    cityName: "Kununurra",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ELS",
    airportName: "East London",
    cityCode: "ELS",
    cityName: "East London",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "ZEM",
    airportName: "Eastmain River",
    cityCode: "ZEM",
    cityName: "Eastmain, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YEG",
    airportName: "Edmonton International",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SVI",
    airportName: "Eduardo Falla Solano Airport",
    cityCode: "SVI",
    cityName: "San Vicente Del Caguan",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "MAO",
    airportName: "Eduardo Gomes International",
    cityCode: "MAO",
    cityName: "Manaus",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "LYB",
    airportName: "Edward Bodden Airfield",
    cityCode: "LYB",
    cityName: "Little Cayman",
    countryCode: "KY",
    countryName: "Cayman Islands",
    continent: "North America",
  },
  {
    airportCode: "EDR",
    airportName: "Edward River",
    cityCode: "EDR",
    cityName: "Pormpuraaw",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "EGS",
    airportName: "Egilsstaoir",
    cityCode: "EGS",
    cityName: "Egilsstaoir",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "ETH",
    airportName: "Eilat",
    cityCode: "ETH",
    cityName: "Eilat",
    countryCode: "IL",
    countryName: "Israel",
    continent: "Asia",
  },
  {
    airportCode: "EIN",
    airportName: "Eindhoven",
    cityCode: "EIN",
    cityName: "Eindhoven",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "EYP",
    airportName: "El Alcaravan",
    cityCode: "EYP",
    cityName: "Yopal",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LPB",
    airportName: "El Alto International",
    cityCode: "LPB",
    cityName: "La Paz",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "EBG",
    airportName: "El Bagre",
    cityCode: "EBG",
    cityName: "El Bagre",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "UIB",
    airportName: "El Carano",
    cityCode: "UIB",
    cityName: "Quibdo",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "BOG",
    airportName: "El Dorado International",
    cityCode: "BOG",
    cityName: "Bogota",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "AXM",
    airportName: "El Eden International",
    cityCode: "AXM",
    cityName: "Armenia",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "PVA",
    airportName: "El Embrujo",
    cityCode: "PVA",
    cityName: "Providencia Island",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ELF",
    airportName: "El Fasher",
    cityCode: "ELF",
    cityName: "Al-Fashir",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "ELG",
    airportName: "El Golea",
    cityCode: "ELG",
    cityName: "El Golea",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "VDE",
    airportName: "El Hierro",
    cityCode: "VDE",
    cityName: "Valverde",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "JAL",
    airportName: "El Lencero",
    cityCode: "JAL",
    cityName: "Xalapa",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "CJC",
    airportName: "El Loa",
    cityCode: "CJC",
    cityName: "Calama",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "EPA",
    airportName: "El Palomar",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "PAZ",
    airportName: "El Tajin National",
    cityCode: "PAZ",
    cityName: "Poza Rica",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "KOE",
    airportName: "El Tari International",
    cityCode: "KOE",
    cityName: "Kupang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PMY",
    airportName: "El Tehuelche",
    cityCode: "PMY",
    cityName: "Puerto Madryn",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "PMC",
    airportName: "El Tepual International",
    cityCode: "PMC",
    cityName: "Puerto Montt",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "EZS",
    airportName: "Elaz?g",
    cityCode: "EZS",
    cityName: "Elaz?g",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ELC",
    airportName: "Elcho Island",
    cityCode: "ELC",
    cityName: "Elcho Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "EDL",
    airportName: "Eldoret International",
    cityCode: "EDL",
    cityName: "Eldoret",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "ESL",
    airportName: "Elista",
    cityCode: "ESL",
    cityName: "Elista",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "MEC",
    airportName: "Eloy Alfaro International",
    cityCode: "MEC",
    cityName: "Manta",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "EMD",
    airportName: "Emerald",
    cityCode: "EMD",
    cityName: "Emerald",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "NBE",
    airportName: "Enfidha Hammamet International",
    cityCode: "NBE",
    cityName: "Enfidha",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "ENF",
    airportName: "Enontekio",
    cityCode: "ENF",
    cityName: "Enontekio",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "DAV",
    airportName: "Enrique Malek International",
    cityCode: "DAV",
    cityName: "David, Chiriqui",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "ENH",
    airportName: "Enshi Xujiaping",
    cityCode: "ENH",
    cityName: "Enshi City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "EBB",
    airportName: "Entebbe International",
    cityCode: "EBB",
    cityName: "Entebbe",
    countryCode: "UG",
    countryName: "Uganda",
    continent: "Africa",
  },
  {
    airportCode: "AIU",
    airportName: "Enua",
    cityCode: "AIU",
    cityName: "Atiu",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "EBL",
    airportName: "Erbil International",
    cityCode: "EBL",
    cityName: "Erbil",
    countryCode: "IQ",
    countryName: "Iraq",
    continent: "Asia",
  },
  {
    airportCode: "ERG",
    airportName: "Erbogachen",
    cityCode: "ERG",
    cityName: "Yerbogachen",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ECN",
    airportName: "Ercan International",
    cityCode: "ECN",
    cityName: "Northern Cyprus",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "ERL",
    airportName: "Erenhot Saiwusu International",
    cityCode: "ERL",
    cityName: "Erenhot",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ERF",
    airportName: "Erfurt Weimar",
    cityCode: "ERF",
    cityName: "Erfurt",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "YXY",
    airportName: "Erik Nielsen Whitehorse International",
    cityCode: "YXY",
    cityName: "Whitehorse",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ASR",
    airportName: "Erkilet International",
    cityCode: "ASR",
    cityName: "Kayseri",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "BAQ",
    airportName: "Ernesto Cortissoz International",
    cityCode: "BAQ",
    cityName: "Barranquilla",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "ERS",
    airportName: "Eros",
    cityCode: "WDH",
    cityName: "Windhoek",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "ERC",
    airportName: "Erzincan",
    cityCode: "ERC",
    cityName: "Erzincan",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ERZ",
    airportName: "Erzurum",
    cityCode: "ERZ",
    cityName: "Erzurum",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "EBJ",
    airportName: "Esbjerg",
    cityCode: "EBJ",
    cityName: "Esbjerg",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ESB",
    airportName: "Esenboga International",
    cityCode: "ANK",
    cityName: "Ankara",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "EPR",
    airportName: "Esperance",
    cityCode: "EPR",
    cityName: "Esperance",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "EQS",
    airportName: "Esquel",
    cityCode: "EQS",
    cityName: "Esquel",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "ESU",
    airportName: "Essaouira-Mogador",
    cityCode: "ESU",
    cityName: "Essaouira",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "MEB",
    airportName: "Essendon",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "VIX",
    airportName: "Eurico de Aguiar Salles",
    cityCode: "VIX",
    cityName: "Vitoria, Espirito Santo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BSL",
    airportName: "EuroAirport Basel Mulhouse Freiburg",
    cityCode: "EAP",
    cityName: "Basel",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "GGT",
    airportName: "Exuma International",
    cityCode: "GGT",
    cityName: "George Town",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "EUX",
    airportName: "F. D. Roosevelt",
    cityCode: "EUX",
    cityName: "Sint Eustatius",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "North America",
  },
  {
    airportCode: "PPT",
    airportName: "Fa a a International",
    cityCode: "PPT",
    cityName: "Tahiti",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "FAC",
    airportName: "Faaite",
    cityCode: "FAC",
    cityName: "Faaite",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "MVP",
    airportName: "Fabio Alberto Leon Bentley",
    cityCode: "MVP",
    cityName: "Mitu",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LYP",
    airportName: "Faisalabad International",
    cityCode: "LYP",
    cityName: "Faisalabad District",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "FAV",
    airportName: "Fakarava",
    cityCode: "FAV",
    cityName: "Fakarava",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "FKQ",
    airportName: "Fakfak Torea",
    cityCode: "FKQ",
    cityName: "Fakfak",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PMO",
    airportName: "Falcone Borsellino",
    cityCode: "PMO",
    cityName: "Palermo",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "APW",
    airportName: "Faleolo International",
    cityCode: "APW",
    cityName: "Apia",
    countryCode: "WS",
    countryName: "Samoa",
    continent: "Oceania",
  },
  {
    airportCode: "FGU",
    airportName: "Fangatau",
    cityCode: "FGU",
    cityName: "Fangatau",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TRU",
    airportName: "FAP Captain Carlos Martinez de Pinillos International",
    cityCode: "TRU",
    cityName: "Trujillo",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "PCL",
    airportName: "FAP Captain David Abensur Rengifo International",
    cityCode: "PCL",
    cityName: "Pucallpa",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "PIU",
    airportName: "FAP Captain Guillermo Concha Iberico International",
    cityCode: "PIU",
    cityName: "Piura",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "CIX",
    airportName: "FAP Captain Jose Abelardo Quinones Gonzales International",
    cityCode: "CIX",
    cityName: "Chiclayo",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "FAO",
    airportName: "Faro",
    cityCode: "FAO",
    cityName: "Faro",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "NOS",
    airportName: "Fascene",
    cityCode: "NOS",
    cityName: "Nosy Be",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "BKS",
    airportName: "Fatmawati Soekarno",
    cityCode: "BKS",
    cityName: "Bengkulu",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "RMI",
    airportName: "Federico Fellini International",
    cityCode: "RMI",
    cityName: "Rimini",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "GRX",
    airportName: "Federico Garcia Lorca",
    cityCode: "GRX",
    cityName: "Granada",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "FEC",
    airportName: "Feira de Santana",
    cityCode: "FEC",
    cityName: "Feira de Santana",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "FRE",
    airportName: "Fera",
    cityCode: "FRE",
    cityName: "Fera Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "FLZ",
    airportName: "Ferdinand Lumban Tobing",
    cityCode: "FLZ",
    cityName: "Sibolga",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "FEG",
    airportName: "Fergana International",
    cityCode: "FEG",
    cityName: "Fergana",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "FEN",
    airportName: "Fernando De Noronha",
    cityCode: "FEN",
    cityName: "Fernando de Noronha",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "FEZ",
    airportName: "Fes Sais",
    cityCode: "FEZ",
    cityName: "Fes",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "FSC",
    airportName: "Figari Sud Corse",
    cityCode: "FSC",
    cityName: "Figari",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "BON",
    airportName: "Flamingo International",
    cityCode: "BON",
    cityName: "Bonaire",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "North America",
  },
  {
    airportCode: "YFO",
    airportName: "Flin Flon",
    cityCode: "YFO",
    cityName: "Flin Flon",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "FLS",
    airportName: "Flinders Island",
    cityCode: "FLS",
    cityName: "Flinders Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "FLR",
    airportName: "Florence",
    cityCode: "FLR",
    cityName: "Florence",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "FLW",
    airportName: "Flores",
    cityCode: "FLW",
    cityName: "Flores Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "FRO",
    airportName: "Floro",
    cityCode: "FRO",
    cityName: "Floro",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "FDE",
    airportName: "Forde Airport, Bringeland",
    cityCode: "FDE",
    cityName: "Forde",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "FMA",
    airportName: "Formosa International",
    cityCode: "FMA",
    cityName: "Formosa",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "YFA",
    airportName: "Fort Albany",
    cityCode: "YFA",
    cityName: "Fort Albany First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YPY",
    airportName: "Fort Chipewyan",
    cityCode: "YPY",
    cityName: "Fort Chipewyan",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YAG",
    airportName: "Fort Frances Municipal",
    cityCode: "YAG",
    cityName: "Fort Frances",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YGH",
    airportName: "Fort Good Hope",
    cityCode: "YGH",
    cityName: "Fort Good Hope",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YMM",
    airportName: "Fort McMurray International",
    cityCode: "YMM",
    cityName: "Fort McMurray",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZFM",
    airportName: "Fort McPherson",
    cityCode: "ZFM",
    cityName: "Fort McPherson",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YYE",
    airportName: "Fort Nelson",
    cityCode: "YYE",
    cityName: "Fort Nelson",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YFS",
    airportName: "Fort Simpson",
    cityCode: "YFS",
    cityName: "Fort Simpson",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YSM",
    airportName: "Fort Smith",
    cityCode: "YSM",
    cityName: "Fort Smith",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YXJ",
    airportName: "Fort St. John",
    cityCode: "YXJ",
    cityName: "Fort St. John",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IGU",
    airportName: "Foz do Iguacu International",
    cityCode: "IGU",
    cityName: "Foz do Iguacu",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "USU",
    airportName: "Francisco B. Reyes",
    cityCode: "USU",
    cityName: "Busuanga, Palawan",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "DVO",
    airportName: "Francisco Bangoy International",
    cityCode: "DVO",
    cityName: "Davao",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "JAU",
    airportName: "Francisco Carle",
    cityCode: "JAU",
    cityName: "Jauja",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "OCC",
    airportName: "Francisco de Orellana",
    cityCode: "OCC",
    cityName: "Puerto Francisco de Orellana",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "FRW",
    airportName: "Francistown",
    cityCode: "FRW",
    cityName: "Francistown",
    countryCode: "BW",
    countryName: "Botswana",
    continent: "Africa",
  },
  {
    airportCode: "HOG",
    airportName: "Frank Pais",
    cityCode: "HOG",
    cityName: "Holguin",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "FRA",
    airportName: "Frankfurt International Airport",
    cityCode: "FRA",
    cityName: "Frankfurt",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "HHN",
    airportName: "Frankfurt Hahn",
    cityCode: "FRA",
    cityName: "Frankfurt",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "BIK",
    airportName: "Frans Kaisiepo International",
    cityCode: "BIK",
    cityName: "Biak",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "RTG",
    airportName: "Frans Sales Lega",
    cityCode: "RTG",
    cityName: "Ruteng",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MOF",
    airportName: "Frans Xavier Seda",
    cityCode: "MOF",
    cityName: "Maumere",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "YFC",
    airportName: "Fredericton International",
    cityCode: "YFC",
    cityName: "Fredericton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "FDH",
    airportName: "Friedrichshafen",
    cityCode: "FDH",
    cityName: "Friedrichshafen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "TBU",
    airportName: "Fua?amotu International",
    cityCode: "TBU",
    cityName: "Nuku?alofa",
    countryCode: "TO",
    countryName: "Tonga",
    continent: "Oceania",
  },
  {
    airportCode: "FUE",
    airportName: "Fuerteventura",
    cityCode: "FUE",
    cityName: "Fuerteventura",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "FUJ",
    airportName: "Fukue",
    cityCode: "FUJ",
    cityName: "Fukue Island",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FUK",
    airportName: "Fukuoka",
    cityCode: "FUK",
    cityName: "Fukuoka",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FKS",
    airportName: "Fukushima",
    cityCode: "FKS",
    cityName: "Fukushima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FUN",
    airportName: "Funafuti International",
    cityCode: "FUN",
    cityName: "Funafuti",
    countryCode: "TV",
    countryName: "Tuvalu",
    continent: "Oceania",
  },
  {
    airportCode: "FTA",
    airportName: "Futuna",
    cityCode: "FTA",
    cityName: "Futuna Island",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "FUG",
    airportName: "Fuyang Xiguan",
    cityCode: "FUG",
    cityName: "Fuyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "FYJ",
    airportName: "Fuyuan Dongji",
    cityCode: "FYJ",
    cityName: "Fuyuan, Heilongjiang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "FYN",
    airportName: "Fuyun Koktokay",
    cityCode: "FYN",
    cityName: "Fuyun County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "FOC",
    airportName: "Fuzhou Changle International",
    cityCode: "FOC",
    cityName: "Fuzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TME",
    airportName: "Gabriel Vargas Santos",
    cityCode: "TME",
    cityName: "Tame",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "GLX",
    airportName: "Galela Gamarmalamo",
    cityCode: "GLX",
    cityName: "Galela",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GEV",
    airportName: "Gallivare",
    cityCode: "GEV",
    cityName: "Gallivare",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "GMB",
    airportName: "Gambela",
    cityCode: "GMB",
    cityName: "Gambela",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "YRA",
    airportName: "Gameti/Rae Lakes",
    cityCode: "YRA",
    cityName: "Gameti",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YQX",
    airportName: "Gander International",
    cityCode: "YQX",
    cityName: "Gander",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KVD",
    airportName: "Ganja International",
    cityCode: "KVD",
    cityName: "Ganja",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "GXH",
    airportName: "Gannan Xiahe",
    cityCode: "GXH",
    cityName: "Xiahe County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KOW",
    airportName: "Ganzhou Huangjin",
    cityCode: "KOW",
    cityName: "Ganzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GOU",
    airportName: "Garoua International",
    cityCode: "GOU",
    cityName: "Garoua",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "GGR",
    airportName: "Garowe",
    cityCode: "GGR",
    cityName: "Garoe",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "ZGU",
    airportName: "Gaua",
    cityCode: "ZGU",
    cityName: "Gaua",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "BWA",
    airportName: "Gautam Buddha",
    cityCode: "BWA",
    cityName: "Siddharthanagar",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "GZP",
    airportName: "Gazipasa",
    cityCode: "GZP",
    cityName: "Gazipasa",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "GDN",
    airportName: "Gdansk Lech Walesa",
    cityCode: "GDN",
    cityName: "Gdansk",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "GDZ",
    airportName: "Gelendzhik",
    cityCode: "GDZ",
    cityName: "Gelendzhik",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "GMA",
    airportName: "Gemena",
    cityCode: "GMA",
    cityName: "Gemena",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "EGN",
    airportName: "Geneina",
    cityCode: "EGN",
    cityName: "Geneina",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "CRD",
    airportName: "General Enrique Mosconi International",
    cityCode: "CRD",
    cityName: "Comodoro Rivadavia",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "HMO",
    airportName: "General Ignacio Pesqueira Garcia International",
    cityCode: "HMO",
    cityName: "Hermosillo",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "BLA",
    airportName: "General Jose Antonio Anzoategui International",
    cityCode: "BLA",
    cityName: "Barcelona",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "PRA",
    airportName: "General Justo Jose de Urquiza",
    cityCode: "PRA",
    cityName: "Parana",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "REX",
    airportName: "General Lucio Blanco International",
    cityCode: "REX",
    cityName: "Reynosa",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "GES",
    airportName: "General Santos International",
    cityCode: "GES",
    cityName: "General Santos",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "SNC",
    airportName: "General Ulpiano Paez",
    cityCode: "SNC",
    cityName: "Salinas",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "GVA",
    airportName: "Geneva",
    cityCode: "GVA",
    cityName: "Geneva",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "GOA",
    airportName: "Genoa Cristoforo Colombo",
    cityCode: "GOA",
    cityName: "Genoa",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "GRJ",
    airportName: "George",
    cityCode: "GRJ",
    cityName: "George",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "BCM",
    airportName: "George Enescu International",
    cityCode: "BCM",
    cityName: "Bacau",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "SLU",
    airportName: "George F. L. Charles",
    cityCode: "SLU",
    cityName: "Saint Lucia",
    countryCode: "LC",
    countryName: "St. Lucia",
    continent: "North America",
  },
  {
    airportCode: "GET",
    airportName: "Geraldton",
    cityCode: "GET",
    cityName: "Geraldton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BUN",
    airportName: "Gerardo Tobar Lopez",
    cityCode: "BUN",
    cityName: "Buenaventura",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "PCR",
    airportName: "German Olano",
    cityCode: "PCR",
    cityName: "Puerto Carreno",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LKA",
    airportName: "Gewayantana",
    cityCode: "LKA",
    cityName: "Larantuka",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GHT",
    airportName: "Ghat",
    cityCode: "GHT",
    cityName: "Ghat",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "GIB",
    airportName: "Gibraltar International",
    cityCode: "GIB",
    cityName: "Gibraltar",
    countryCode: "GI",
    countryName: "Gibraltar",
    continent: "Europe",
  },
  {
    airportCode: "GIL",
    airportName: "Gilgit",
    cityCode: "GIL",
    cityName: "Gilgit",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "YGX",
    airportName: "Gillam",
    cityCode: "YGX",
    cityName: "Gillam",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PUS",
    airportName: "Gimhae International",
    cityCode: "PUS",
    cityName: "Busan",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "GMP",
    airportName: "Gimpo International",
    cityCode: "SEL",
    cityName: "Seoul",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "GRO",
    airportName: "Girona Costa Brava",
    cityCode: "GRO",
    cityName: "Girona",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "PNP",
    airportName: "Girua",
    cityCode: "PNP",
    cityName: "Popondetta",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "GIS",
    airportName: "Gisborne",
    cityCode: "GIS",
    cityName: "Gisborne",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "YHK",
    airportName: "Gjoa Haven",
    cityCode: "YHK",
    cityName: "Gjoa Haven",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "GLT",
    airportName: "Gladstone",
    cityCode: "GLT",
    cityName: "Gladstone",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "VDM",
    airportName: "Gobernador Edgardo Castello",
    cityCode: "VDM",
    cityName: "Viedma",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "JUJ",
    airportName: "Gobernador Horacio Guzman International",
    cityCode: "JUJ",
    cityName: "San Salvador de Jujuy",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "GDE",
    airportName: "Gode",
    cityCode: "GDE",
    cityName: "Gode",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "MPH",
    airportName: "Godofredo P. Ramos",
    cityCode: "MPH",
    cityName: "Caticlan",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "YGO",
    airportName: "Gods Lake Narrows",
    cityCode: "YGO",
    cityName: "Gods Lake Narrows, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZGI",
    airportName: "Gods River",
    cityCode: "ZGI",
    cityName: "Manto Sipi Cree Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "OOL",
    airportName: "Gold Coast",
    cityCode: "OOL",
    cityName: "Gold Coast",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "GLF",
    airportName: "Golfito",
    cityCode: "GLF",
    cityName: "Golfito",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "TLU",
    airportName: "Golfo de Morrosquillo",
    cityCode: "TLU",
    cityName: "Tolu",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "GOQ",
    airportName: "Golmud",
    cityCode: "GOQ",
    cityName: "Golmud",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GMQ",
    airportName: "Golog Maqin",
    cityCode: "GMQ",
    cityName: "Golog Tibetan Autonomous Prefecture",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LCE",
    airportName: "Goloson International",
    cityCode: "LCE",
    cityName: "La Ceiba",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "GOM",
    airportName: "Goma International",
    cityCode: "GOM",
    cityName: "Goma",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "GMO",
    airportName: "Gombe Lawanti International",
    cityCode: "GMO",
    cityName: "Gombe",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "GME",
    airportName: "Gomel",
    cityCode: "GME",
    cityName: "Gomel",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "GDQ",
    airportName: "Gondar",
    cityCode: "GDQ",
    cityName: "Gondar",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "GBT",
    airportName: "Gorgan",
    cityCode: "GBT",
    cityName: "Gorgan",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "RGK",
    airportName: "Gorno-Altaysk",
    cityCode: "RGK",
    cityName: "Gorno-Altaysk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "GKA",
    airportName: "Goroka",
    cityCode: "GKA",
    cityName: "Goroka",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "GOT",
    airportName: "Goteborg Landvetter",
    cityCode: "GOT",
    cityName: "Gothenburg",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "GSE",
    airportName: "Gothenburg City Airport",
    cityCode: "GOT",
    cityName: "Gothenburg",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "GOV",
    airportName: "Gove",
    cityCode: "GOV",
    cityName: "Nhulunbuy",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PVH",
    airportName: "Governador Jorge Teixeira de Oliveira International",
    cityCode: "PVH",
    cityName: "Porto Velho",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "GVR",
    airportName: "Governador Valadares",
    cityCode: "GVR",
    cityName: "Governador Valadares",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "MDZ",
    airportName: "Governor Francisco Gabrielli International",
    cityCode: "MDZ",
    cityName: "Mendoza",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "GHB",
    airportName: "Governor's Harbour",
    cityCode: "GHB",
    cityName: "Governor's Harbour",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "GRW",
    airportName: "Graciosa",
    cityCode: "GRW",
    cityName: "Graciosa",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "LPA",
    airportName: "Gran Canaria",
    cityCode: "LPA",
    cityName: "Las Palmas",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "FPO",
    airportName: "Grand Bahama International",
    cityCode: "FPO",
    cityName: "Freeport",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "SFG",
    airportName: "Grand Case-Esperance",
    cityCode: "SFG",
    cityName: "Saint Martin (Island)",
    countryCode: "MF",
    countryName: "St. Martin",
    continent: "North America",
  },
  {
    airportCode: "YQU",
    airportName: "Grande Prairie",
    cityCode: "YQU",
    cityName: "Grande Prairie",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "BGI",
    airportName: "Grantley Adams International",
    cityCode: "BGI",
    cityName: "Bridgetown",
    countryCode: "BB",
    countryName: "Barbados",
    continent: "North America",
  },
  {
    airportCode: "GRZ",
    airportName: "Graz",
    cityCode: "GRZ",
    cityName: "Graz",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "HTI",
    airportName: "Great Barrier Reef",
    cityCode: "HTI",
    cityName: "Hamilton Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YQM",
    airportName: "Greater Moncton Romeo LeBlanc International",
    cityCode: "YQM",
    cityName: "Moncton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "POP",
    airportName: "Gregorio Luperon International",
    cityCode: "POP",
    cityName: "Puerto Plata",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "GFF",
    airportName: "Griffith",
    cityCode: "GFF",
    cityName: "Griffith",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YGZ",
    airportName: "Grise Fiord",
    cityCode: "YGZ",
    cityName: "Grise Fiord",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "GRQ",
    airportName: "Groningen Airport Eelde",
    cityCode: "GRQ",
    cityName: "Groningen",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "GTE",
    airportName: "Groote Eylandt",
    cityCode: "GTE",
    cityName: "Groote Eylandt",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "GRS",
    airportName: "Grosseto Air Base",
    cityCode: "GRS",
    cityName: "Grosseto",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "GRV",
    airportName: "Grozny",
    cityCode: "GRV",
    cityName: "Grozny",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "GDL",
    airportName: "Guadalajara International",
    cityCode: "GDL",
    cityName: "Guadalajara",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "GYS",
    airportName: "Guangyuan Panlong",
    cityCode: "GYS",
    cityName: "Guangyuan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CAN",
    airportName: "Guangzhou Baiyun International",
    cityCode: "CAN",
    cityName: "Guangzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GPI",
    airportName: "Guapi",
    cityCode: "GPI",
    cityName: "Guapi",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "AGT",
    airportName: "Guarani International",
    cityCode: "AGT",
    cityName: "Ciudad del Este",
    countryCode: "PY",
    countryName: "Paraguay",
    continent: "South America",
  },
  {
    airportCode: "WPU",
    airportName: "Guardiamarina Zanartu",
    cityCode: "WPU",
    cityName: "Puerto Williams",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "GYA",
    airportName: "Guayaramerin",
    cityCode: "GYA",
    cityName: "Guayaramerin",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "GYM",
    airportName: "Guaymas International",
    cityCode: "GYM",
    cityName: "Guaymas",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "GLN",
    airportName: "Guelmim",
    cityCode: "GLN",
    cityName: "Guelmim",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "ELU",
    airportName: "Guemar",
    cityCode: "ELU",
    cityName: "El Oued",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "GCI",
    airportName: "Guernsey",
    cityCode: "GCI",
    cityName: "Saint Peter Port",
    countryCode: "GG",
    countryName: "Guernsey",
    continent: "Europe",
  },
  {
    airportCode: "KWL",
    airportName: "Guilin Liangjiang International",
    cityCode: "KWL",
    cityName: "Guilin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PPN",
    airportName: "Guillermo Leon Valencia",
    cityCode: "PPN",
    cityName: "Popayan",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "KWE",
    airportName: "Guiyang Longdongbao International",
    cityCode: "KWE",
    cityName: "Guiyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KUV",
    airportName: "Gunsan",
    cityCode: "KUV",
    cityName: "Gunsan",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "URY",
    airportName: "Gurayat Domestic",
    cityCode: "URY",
    cityName: "Qurayyat",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "GUO",
    airportName: "Guriel",
    cityCode: "GUO",
    cityName: "Guri el",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "GUR",
    airportName: "Gurney",
    cityCode: "GUR",
    cityName: "Alotau",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "SBH",
    airportName: "Gustaf III",
    cityCode: "SBH",
    cityName: "Saint Barthelemy",
    countryCode: "BL",
    countryName: "St. Barthelemy",
    continent: "North America",
  },
  {
    airportCode: "FLA",
    airportName: "Gustavo Artunduaga Paredes",
    cityCode: "FLA",
    cityName: "Florencia",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "BCA",
    airportName: "Gustavo Rizo",
    cityCode: "BCA",
    cityName: "Baracoa",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "ADZ",
    airportName: "Gustavo Rojas Pinilla International",
    cityCode: "ADZ",
    cityName: "San Andres",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "KBU",
    airportName: "Gusti Syamsir Alam",
    cityCode: "KBU",
    cityName: "Kotabaru Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GYU",
    airportName: "Guyuan Liupanshan",
    cityCode: "GYU",
    cityName: "Guyuan County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GWD",
    airportName: "Gwadar International",
    cityCode: "GWD",
    cityName: "Gwadar",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "KWJ",
    airportName: "Gwangju",
    cityCode: "KWJ",
    cityName: "Gwangju",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "SMQ",
    airportName: "H. Asan",
    cityCode: "SMQ",
    cityName: "Sampit",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ENE",
    airportName: "H. Hasan Aroeboesman",
    cityCode: "ENE",
    cityName: "Ende",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TJQ",
    airportName: "H.A.S. Hanandjoeddin International",
    cityCode: "TJQ",
    cityName: "Tanjung Pandan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "HAS",
    airportName: "Ha il Regional",
    cityCode: "HAS",
    cityName: "Ha il",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "HAC",
    airportName: "Hachijojima",
    cityCode: "HAC",
    cityName: "Hachijo-jima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "HFA",
    airportName: "Haifa",
    cityCode: "HFA",
    cityName: "Haifa",
    countryCode: "IL",
    countryName: "Israel",
    continent: "Asia",
  },
  {
    airportCode: "HAK",
    airportName: "Haikou Meilan International",
    cityCode: "HAK",
    cityName: "Haikou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YKO",
    airportName: "Hakkari Yuksekova",
    cityCode: "YKO",
    cityName: "Hakkari",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "HKD",
    airportName: "Hakodate",
    cityCode: "HKD",
    cityName: "Hakodate",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "YHZ",
    airportName: "Halifax Stanfield International",
    cityCode: "YHZ",
    cityName: "Halifax",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "HLP",
    airportName: "Halim Perdanakusuma International",
    cityCode: "JKT",
    cityName: "Jakarta",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ABU",
    airportName: "Haliwen",
    cityCode: "ABU",
    cityName: "Atambua",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "YUX",
    airportName: "Hall Beach",
    cityCode: "YUX",
    cityName: "Hall Beach",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "HAD",
    airportName: "Halmstad",
    cityCode: "HAD",
    cityName: "Halmstad",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "KDI",
    airportName: "Haluoleo",
    cityCode: "KDI",
    cityName: "Kendari",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "DOH",
    airportName: "Hamad International",
    cityCode: "DOH",
    cityName: "Doha",
    countryCode: "QA",
    countryName: "Qatar",
    continent: "Asia",
  },
  {
    airportCode: "HDM",
    airportName: "Hamadan International",
    cityCode: "HDM",
    cityName: "Hamadan",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "HAM",
    airportName: "Hamburg",
    cityCode: "HAM",
    cityName: "Hamburg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "HMI",
    airportName: "Hami",
    cityCode: "HMI",
    cityName: "Hami",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KBL",
    airportName: "Hamid Karzai International",
    cityCode: "KBL",
    cityName: "Kabul",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "HLZ",
    airportName: "Hamilton",
    cityCode: "HLZ",
    cityName: "Hamilton",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "HFT",
    airportName: "Hammerfest",
    cityCode: "HFT",
    cityName: "Hammerfest",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "HNA",
    airportName: "Hanamaki",
    cityCode: "HNA",
    cityName: "Hanamaki",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "HDG",
    airportName: "Handan",
    cityCode: "HDG",
    cityName: "Handan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HND",
    airportName: "Haneda",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "BTH",
    airportName: "Hang Nadim",
    cityCode: "BTH",
    cityName: "Batam",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "HGH",
    airportName: "Hangzhou Xiaoshan International",
    cityCode: "HGH",
    cityName: "Hangzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HAJ",
    airportName: "Hannover",
    cityCode: "HAJ",
    cityName: "Hanover",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "HZG",
    airportName: "Hanzhong Xiguan",
    cityCode: "HZG",
    cityName: "Hanzhong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HOI",
    airportName: "Hao",
    cityCode: "HOI",
    cityName: "Hao",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "HRE",
    airportName: "Harare International",
    cityCode: "HRE",
    cityName: "Harare",
    countryCode: "ZW",
    countryName: "Zimbabwe",
    continent: "Africa",
  },
  {
    airportCode: "HRB",
    airportName: "Harbin Taiping International",
    cityCode: "HRB",
    cityName: "Harbin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HGA",
    airportName: "Hargeisa",
    cityCode: "HGA",
    cityName: "Hargeisa",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "LVI",
    airportName: "Harry Mwanga Nkumbula International",
    cityCode: "LVI",
    cityName: "Livingstone",
    countryCode: "ZM",
    countryName: "Zambia",
    continent: "Africa",
  },
  {
    airportCode: "EVE",
    airportName: "Harstad/Narvik Airport, Evenes",
    cityCode: "EVE",
    cityName: "Narvik",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "EUN",
    airportName: "Hassan I",
    cityCode: "EUN",
    cityName: "Laayoune",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Africa",
  },
  {
    airportCode: "HAA",
    airportName: "Hasvik",
    cityCode: "HAA",
    cityName: "Hasvik",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "HDY",
    airportName: "Hat Yai International",
    cityCode: "HDY",
    cityName: "Hat Yai",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "HTY",
    airportName: "Hatay",
    cityCode: "HTY",
    cityName: "Hatay Province",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "NUF",
    airportName: "Hatton Castlereigh Resrvr SPB",
    cityCode: "NUF",
    cityName: "Hatton",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "HAU",
    airportName: "Haugesund Airport, Karmoy",
    cityCode: "HAU",
    cityName: "Haugesund",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "VIN",
    airportName: "Havryshivka Vinnytsia International",
    cityCode: "VIN",
    cityName: "Vinnytsia",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "NPE",
    airportName: "Hawke's Bay",
    cityCode: "NPE",
    cityName: "Napier",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "YHY",
    airportName: "Hay River/Merlyn Carter",
    cityCode: "YHY",
    cityName: "Hay River",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "HIS",
    airportName: "Hayman Island Heliport",
    cityCode: "HIS",
    cityName: "Hayman Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BCV",
    airportName: "Hector Silva Airstrip",
    cityCode: "BZE",
    cityName: "Belize City",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "HFE",
    airportName: "Hefei Xinqiao International",
    cityCode: "HFE",
    cityName: "Hefei",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HEH",
    airportName: "Heho",
    cityCode: "HEH",
    cityName: "Heho",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "HEK",
    airportName: "Heihe",
    cityCode: "HEK",
    cityName: "Heihe",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HEL",
    airportName: "Helsinki",
    cityCode: "HEL",
    cityName: "Helsinki",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "HMV",
    airportName: "Hemavan",
    cityCode: "HMV",
    cityName: "Hemavan",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "PHW",
    airportName: "Hendrik Van Eck",
    cityCode: "PHW",
    cityName: "Phalaborwa",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "HNY",
    airportName: "Hengyang Nanyue",
    cityCode: "HNY",
    cityName: "Hengyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "OTP",
    airportName: "Henri Coanda International",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "HER",
    airportName: "Heraklion International",
    cityCode: "HER",
    cityName: "Heraklion",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "HEA",
    airportName: "Herat International",
    cityCode: "HEA",
    cityName: "Herat",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "FLN",
    airportName: "Hercilio Luz International",
    cityCode: "FLN",
    cityName: "Florianopolis",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "HDF",
    airportName: "Heringsdorf",
    cityCode: "HDF",
    cityName: "Heringsdorf",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "VTU",
    airportName: "Hermanos Ameijeiras",
    cityCode: "VTU",
    cityName: "Las Tunas",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "RGA",
    airportName: "Hermes Quijada International",
    cityCode: "RGA",
    cityName: "Rio Grande",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "HVB",
    airportName: "Hervey Bay",
    cityCode: "HVB",
    cityName: "Hervey Bay",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SOB",
    airportName: "Heviz Balaton",
    cityCode: "SOB",
    cityName: "Heviz",
    countryCode: "HU",
    countryName: "Hungary",
    continent: "Europe",
  },
  {
    airportCode: "UVF",
    airportName: "Hewanorra International",
    cityCode: "SLU",
    cityName: "Saint Lucia",
    countryCode: "LC",
    countryName: "St. Lucia",
    continent: "North America",
  },
  {
    airportCode: "GYD",
    airportName: "Heydar Aliyev International",
    cityCode: "BAK",
    cityName: "Baku",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "YOJ",
    airportName: "High Level",
    cityCode: "YOJ",
    cityName: "High Level",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WLS",
    airportName: "Hihifo",
    cityCode: "WLS",
    cityName: "Wallis Island",
    countryCode: "WF",
    countryName: "Wallis & Futuna",
    continent: "Oceania",
  },
  {
    airportCode: "HHZ",
    airportName: "Hikueru",
    cityCode: "HHZ",
    cityName: "Hikueru",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "HIJ",
    airportName: "Hiroshima",
    cityCode: "HIJ",
    cityName: "Hiroshima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "HBA",
    airportName: "Hobart International",
    cityCode: "HBA",
    cityName: "Hobart",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "HOD",
    airportName: "Hodeidah International Airport",
    cityCode: "HOD",
    cityName: "Hodeida",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "KRF",
    airportName: "Hoga Kusten",
    cityCode: "KRF",
    cityName: "Kramfors Municipality",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "HET",
    airportName: "Hohhot Baita International",
    cityCode: "HET",
    cityName: "Hohhot",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HKK",
    airportName: "Hokitika",
    cityCode: "HKK",
    cityName: "Hokitika",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "HOX",
    airportName: "Homalin",
    cityCode: "HOX",
    cityName: "Homalin",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "HKG",
    airportName: "Hong Kong International",
    cityCode: "HKG",
    cityName: "Hong Kong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HIR",
    airportName: "Honiara International",
    cityCode: "HIR",
    cityName: "Honiara",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "HVG",
    airportName: "Honningsvag Airport, Valan",
    cityCode: "HVG",
    cityName: "Honningsvag",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "YHO",
    airportName: "Hopedale",
    cityCode: "YHO",
    cityName: "Hopedale",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "HID",
    airportName: "Horn Island",
    cityCode: "HID",
    cityName: "Horn Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "HOR",
    airportName: "Horta",
    cityCode: "HOR",
    cityName: "Horta, Azores",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "WDH",
    airportName: "Hosea Kutako International",
    cityCode: "WDH",
    cityName: "Windhoek",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "HKN",
    airportName: "Hoskins",
    cityCode: "HKN",
    cityName: "Hoskins",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "HTN",
    airportName: "Hotan",
    cityCode: "HTN",
    cityName: "Hotan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ALG",
    airportName: "Houari Boumediene",
    cityCode: "ALG",
    cityName: "Algiers",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "BLB",
    airportName: "Howard Air Force Base",
    cityCode: "BLB",
    cityName: "Balboa",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "GNA",
    airportName: "Hrodna",
    cityCode: "GNA",
    cityName: "Grodno",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "HHQ",
    airportName: "Hua Hin",
    cityCode: "HHQ",
    cityName: "Hua Hin District",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "HUH",
    airportName: "Huahine Fare",
    cityCode: "HUH",
    cityName: "Huahine",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "HIA",
    airportName: "Huai an Lianshui",
    cityCode: "HIA",
    cityName: "Huai an",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HJJ",
    airportName: "Huaihua Zhijiang",
    cityCode: "HJJ",
    cityName: "Huaihua",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HUN",
    airportName: "Hualien",
    cityCode: "HUN",
    cityName: "Hualien County",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TXN",
    airportName: "Huangshan Tunxi International",
    cityCode: "TXN",
    cityName: "Huangshan City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HGD",
    airportName: "Hughenden",
    cityCode: "HGD",
    cityName: "Hughenden",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "HUZ",
    airportName: "Huizhou Pingtan",
    cityCode: "HUZ",
    cityName: "Huizhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HLD",
    airportName: "Hulunbuir Hailar",
    cityCode: "HLD",
    cityName: "Hailar",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HUE",
    airportName: "Humera",
    cityCode: "HUE",
    cityName: "Humera",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "HRG",
    airportName: "Hurghada International",
    cityCode: "HRG",
    cityName: "Hurghada",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "BDO",
    airportName: "Husein Sastranegara International",
    cityCode: "BDO",
    cityName: "Bandung",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "IAS",
    airportName: "Iasi International",
    cityCode: "IAS",
    cityName: "Iasi",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "IBA",
    airportName: "Ibadan",
    cityCode: "IBA",
    cityName: "Ibadan",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "IBR",
    airportName: "Ibaraki",
    cityCode: "IBR",
    cityName: "Ibaraki",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "IBZ",
    airportName: "Ibiza",
    cityCode: "IBZ",
    cityName: "Ibiza",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "IAA",
    airportName: "Igarka",
    cityCode: "IAA",
    cityName: "Igarka",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "IGD",
    airportName: "Igdir",
    cityCode: "IGD",
    cityName: "Igdir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "YGT",
    airportName: "Igloolik",
    cityCode: "YGT",
    cityName: "Igloolik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "CMW",
    airportName: "Ignacio Agramonte International",
    cityCode: "CMW",
    cityName: "Camaguey",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "BQS",
    airportName: "Ignatyevo",
    cityCode: "BQS",
    cityName: "Blagoveshchensk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "IRM",
    airportName: "Igrim",
    cityCode: "IRM",
    cityName: "Igrim",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "JIK",
    airportName: "Ikaria Island National",
    cityCode: "JIK",
    cityName: "Icaria",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "IKI",
    airportName: "Iki",
    cityCode: "IKI",
    cityName: "Iki",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "IIL",
    airportName: "Ilam",
    cityCode: "IIL",
    cityName: "Ilam",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "BMY",
    airportName: "Ile Art Waala",
    cityCode: "BMY",
    cityName: "Art Island",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "ILP",
    airportName: "Ile des Pins",
    cityCode: "ILP",
    cityName: "Isle of Pines",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "YGR",
    airportName: "Iles-de-la-Madeleine",
    cityCode: "YGR",
    cityName: "Les Iles-de-la-Madeleine, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IOS",
    airportName: "Ilheus Jorge Amado",
    cityCode: "IOS",
    cityName: "Ilheus",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "WOL",
    airportName: "Illawarra Regional",
    cityCode: "WOL",
    cityName: "City of Wollongong",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ILO",
    airportName: "Iloilo International",
    cityCode: "ILO",
    cityName: "Iloilo City",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "ILR",
    airportName: "Ilorin International",
    cityCode: "ILR",
    cityName: "Ilorin",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "JAV",
    airportName: "Ilulissat",
    cityCode: "JAV",
    cityName: "Ilulissat",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "IMP",
    airportName: "Imperatriz",
    cityCode: "IMP",
    cityName: "Imperatriz",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "IAM",
    airportName: "In Amenas",
    cityCode: "IAM",
    cityName: "In Amenas",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "INZ",
    airportName: "In Salah",
    cityCode: "INZ",
    cityName: "In Salah",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "IGA",
    airportName: "Inagua",
    cityCode: "IGA",
    cityName: "Inagua",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "JUL",
    airportName: "Inca Manco Capac International",
    cityCode: "JUL",
    cityName: "Juliaca",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "ICN",
    airportName: "Incheon International",
    cityCode: "SEL",
    cityName: "Seoul",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "INB",
    airportName: "Independence",
    cityCode: "INB",
    cityName: "Independence and Mango Creek",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "COR",
    airportName: "Ingeniero Aeronautico Ambrosio L.V. Taravella International",
    cityCode: "COR",
    cityName: "Cordoba",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "INH",
    airportName: "Inhambane",
    cityCode: "INH",
    cityName: "Inhambane",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "INN",
    airportName: "Innsbruck",
    cityCode: "INN",
    cityName: "Innsbruck",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "INA",
    airportName: "Inta Airport",
    cityCode: "INA",
    cityName: "Inta",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "IKT",
    airportName: "International Airport Irkutsk",
    cityCode: "IKT",
    cityName: "Irkutsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "YPH",
    airportName: "Inukjuak",
    cityCode: "YPH",
    cityName: "Inukjuak",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YEV",
    airportName: "Inuvik (Mike Zubko)",
    cityCode: "YEV",
    cityName: "Inuvik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IVC",
    airportName: "Invercargill",
    cityCode: "IVC",
    cityName: "Invercargill",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "IOA",
    airportName: "Ioannina National",
    cityCode: "IOA",
    cityName: "Ioannina",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "IPA",
    airportName: "Ipota",
    cityCode: "IPA",
    cityName: "Ipota",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "YFB",
    airportName: "Iqaluit",
    cityCode: "YFB",
    cityName: "Iqaluit",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IHR",
    airportName: "Iranshahr",
    cityCode: "IHR",
    cityName: "Iranshahr",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "BXB",
    airportName: "Irarutu Babo",
    cityCode: "BXB",
    cityName: "Babo",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "NOC",
    airportName: "Ireland West Airport Knock",
    cityCode: "NOC",
    cityName: "Knock, County Mayo",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "IRI",
    airportName: "Iringa Airport",
    cityCode: "IRI",
    cityName: "Nduli",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "IFJ",
    airportName: "Isafjorour",
    cityCode: "IFJ",
    cityName: "Isafjorour",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "IFN",
    airportName: "Isfahan International",
    cityCode: "IFN",
    cityName: "Isfahan",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ISG",
    airportName: "Ishigaki",
    cityCode: "ISG",
    cityName: "Ishigaki, Okinawa",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "PKN",
    airportName: "Iskandar",
    cityCode: "PKN",
    cityName: "Pangkalan Bun",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ISB",
    airportName: "Islamabad International Airport",
    cityCode: "ISB",
    cityName: "Islamabad",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "YIV",
    airportName: "Island Lake",
    cityCode: "YIV",
    cityName: "Island Lake, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IOM",
    airportName: "Isle of Man",
    cityCode: "IOM",
    cityName: "Douglas",
    countryCode: "IM",
    countryName: "Isle of Man",
    continent: "Europe",
  },
  {
    airportCode: "ISE",
    airportName: "Isparta Suleyman Demirel",
    cityCode: "ISE",
    cityName: "Isparta",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "IKU",
    airportName: "Issyk-Kul International",
    cityCode: "IKU",
    cityName: "Tamchy",
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    continent: "Asia",
  },
  {
    airportCode: "IST",
    airportName: "Istanbul Ataturk",
    cityCode: "IST",
    cityName: "Istanbul",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ITM",
    airportName: "Itami",
    cityCode: "OSA",
    cityName: "Osaka",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ITU",
    airportName: "Iturup",
    cityCode: "ITU",
    cityName: "Iturup",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "IVL",
    airportName: "Ivalo",
    cityCode: "IVL",
    cityName: "Ivalo",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "IFO",
    airportName: "Ivano-Frankivsk International",
    cityCode: "IFO",
    cityName: "Ivano-Frankivsk",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "IWA",
    airportName: "Ivanovo Yuzhny",
    cityCode: "IWA",
    cityName: "Ivanovo",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "TNR",
    airportName: "Ivato International",
    cityCode: "TNR",
    cityName: "Antananarivo",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "YIK",
    airportName: "Ivujivik",
    cityCode: "YIK",
    cityName: "Ivujivik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "IWJ",
    airportName: "Iwami",
    cityCode: "IWJ",
    cityName: "Masuda",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ZIH",
    airportName: "Ixtapa-Zihuatanejo International",
    cityCode: "ZIH",
    cityName: "Ixtapa",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "IZT",
    airportName: "Ixtepec",
    cityCode: "IZT",
    cityName: "Ixtepec, Oaxaca",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "IJK",
    airportName: "Izhevsk",
    cityCode: "IJK",
    cityName: "Izhevsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "IZO",
    airportName: "Izumo",
    cityCode: "IZO",
    cityName: "Izumo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "BRM",
    airportName: "Jacinto Lara International",
    cityCode: "BRM",
    cityName: "Barquisimeto",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "POM",
    airportName: "Jacksons International",
    cityCode: "POM",
    cityName: "Port Moresby",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "JAE",
    airportName: "Jaen",
    cityCode: "JAE",
    cityName: "Jaen",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "GDT",
    airportName: "JAGS McCartney International",
    cityCode: "GDT",
    cityName: "Cockburn Town",
    countryCode: "TC",
    countryName: "Turks & Caicos Islands",
    continent: "North America",
  },
  {
    airportCode: "JJG",
    airportName: "Jaguaruna Regional",
    cityCode: "JJG",
    cityName: "Jaguaruna",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CFG",
    airportName: "Jaime Gonzalez",
    cityCode: "CFG",
    cityName: "Cienfuegos",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "GTO",
    airportName: "Jalaluddin",
    cityCode: "GTO",
    cityName: "Gorontalo",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "JKR",
    airportName: "Janakpur",
    cityCode: "JKR",
    cityName: "Janakpur",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "JQE",
    airportName: "Jaque",
    cityCode: "JQE",
    cityName: "Jaque",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "CCC",
    airportName: "Jardines del Rey",
    cityCode: "CCC",
    cityName: "Cayo Coco",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "GIZ",
    airportName: "Jazan",
    cityCode: "GIZ",
    cityName: "Jizan",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "CJU",
    airportName: "Jeju International",
    cityCode: "CJU",
    cityName: "Jeju City",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "XRY",
    airportName: "Jerez",
    cityCode: "XRY",
    cityName: "Jerez de la Frontera",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "JER",
    airportName: "Jersey",
    cityCode: "JER",
    cityName: "Saint Helier",
    countryCode: "JE",
    countryName: "Jersey",
    continent: "Europe",
  },
  {
    airportCode: "JSR",
    airportName: "Jessore",
    cityCode: "JSR",
    cityName: "Jessore",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "JPR",
    airportName: "Ji-Parana",
    cityCode: "JPR",
    cityName: "Ji-Parana",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "JGD",
    airportName: "Jiagedaqi",
    cityCode: "JGD",
    cityName: "Jiagedaqi District",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JMU",
    airportName: "Jiamusi Dongjiao",
    cityCode: "JMU",
    cityName: "Jiamusi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JGN",
    airportName: "Jiayuguan",
    cityCode: "JGN",
    cityName: "Jiayuguan City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SWA",
    airportName: "Jieyang Chaoshan International",
    cityCode: "SWA",
    cityName: "Shantou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GJL",
    airportName: "Jijel Ferhat Abbas",
    cityCode: "GJL",
    cityName: "Jijel",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "JIJ",
    airportName: "Jijiga",
    cityCode: "JIJ",
    cityName: "Jijiga",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "TNA",
    airportName: "Jinan Yaoqiang International",
    cityCode: "TNA",
    cityName: "Jinan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JIC",
    airportName: "Jinchang Jinchuan",
    cityCode: "JIC",
    cityName: "Jinchang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JDZ",
    airportName: "Jingdezhen Luojia",
    cityCode: "JDZ",
    cityName: "Jingdezhen",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JGS",
    airportName: "Jinggangshan",
    cityCode: "JGS",
    cityName: "Jian",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JNG",
    airportName: "Jining Qufu",
    cityCode: "JNG",
    cityName: "Jining",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KHI",
    airportName: "Jinnah International",
    cityCode: "KHI",
    cityName: "Karachi",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "JNZ",
    airportName: "Jinzhou Xiaolingzi",
    cityCode: "JNZ",
    cityName: "Jinzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JZH",
    airportName: "Jiuzhai Huanglong",
    cityCode: "JZH",
    cityName: "Jiuzhaigou County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JXA",
    airportName: "Jixi Xingkaihu",
    cityCode: "JXA",
    cityName: "Jixi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PDL",
    airportName: "Joao Paulo II",
    cityCode: "PDL",
    cityName: "Ponta Delgada",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "JOE",
    airportName: "Joensuu",
    cityCode: "JOE",
    cityName: "Joensuu",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "PBM",
    airportName: "Johan Adolf Pengel International",
    cityCode: "PBM",
    cityName: "Paramaribo",
    countryCode: "SR",
    countryName: "Suriname",
    continent: "South America",
  },
  {
    airportCode: "YHM",
    airportName: "John C. Munro Hamilton International",
    cityCode: "YHM",
    cityName: "Hamilton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KRK",
    airportName: "John Paul II International Airport Krakow Balice",
    cityCode: "KRK",
    cityName: "Krakow",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "JOI",
    airportName: "Joinville-Lauro Carneiro de Loyola",
    cityCode: "JOI",
    cityName: "Joinville",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "NBO",
    airportName: "Jomo Kenyatta International",
    cityCode: "NBO",
    cityName: "Nairobi",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "JKG",
    airportName: "Jonkoping",
    cityCode: "JKG",
    cityName: "Jonkoping",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "LIM",
    airportName: "Jorge Chavez International",
    cityCode: "LIM",
    cityName: "Lima",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "SJE",
    airportName: "Jorge Enrique Gonzalez Torres",
    cityCode: "SJE",
    cityName: "San Jose del Guaviare",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "CBB",
    airportName: "Jorge Wilstermann International",
    cityCode: "CBB",
    cityName: "Cochabamba",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "BSC",
    airportName: "Jose Celestino Mutis",
    cityCode: "BSC",
    cityName: "Bahia Solano",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "GYE",
    airportName: "Jose Joaquin de Olmedo International",
    cityCode: "GYE",
    cityName: "Guayaquil",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "CZE",
    airportName: "Jose Leonardo Chirino",
    cityCode: "CZE",
    cityName: "Coro",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "MDE",
    airportName: "Jose Maria Cordova International",
    cityCode: "MDE",
    cityName: "Medellin",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "HAV",
    airportName: "Jose Marti International",
    cityCode: "HAV",
    cityName: "Havana",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "LSP",
    airportName: "Josefa Camejo International",
    cityCode: "LSP",
    cityName: "Las Piedras",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "BUQ",
    airportName: "Joshua Mqabuko Nkomo International",
    cityCode: "BUQ",
    cityName: "Bulawayo",
    countryCode: "ZW",
    countryName: "Zimbabwe",
    continent: "Africa",
  },
  {
    airportCode: "VRA",
    airportName: "Juan Gualberto Gomez",
    cityCode: "VRA",
    cityName: "Varadero",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "CAQ",
    airportName: "Juan H. White",
    cityCode: "CAQ",
    cityName: "Caucasia",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "RTB",
    airportName: "Juan Manuel Galvez International",
    cityCode: "RTB",
    cityName: "Coxen Hole",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "ORU",
    airportName: "Juan Mendoza",
    cityCode: "ORU",
    cityName: "Oruro",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "VIG",
    airportName: "Juan Pablo Perez Alfonzo",
    cityCode: "VIG",
    cityName: "El Vigia",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "SJO",
    airportName: "Juan Santamaria International",
    cityCode: "SJO",
    cityName: "San Jose",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "SAB",
    airportName: "Juancho E. Yrausquin",
    cityCode: "SAB",
    cityName: "Saba",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "North America",
  },
  {
    airportCode: "SUB",
    airportName: "Juanda International",
    cityCode: "SUB",
    cityName: "Surabaya",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "JDO",
    airportName: "Juazeiro do Norte",
    cityCode: "JDO",
    cityName: "Juazeiro do Norte",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "JUB",
    airportName: "Juba International",
    cityCode: "JUB",
    cityName: "Juba",
    countryCode: "SS",
    countryName: "South Sudan",
    continent: "Africa",
  },
  {
    airportCode: "JCK",
    airportName: "Julia Creek",
    cityCode: "JCK",
    cityName: "Julia Creek",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "DAR",
    airportName: "Julius Nyerere International",
    cityCode: "DAR",
    cityName: "Dar es Salaam",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "TRK",
    airportName: "Juwata International",
    cityCode: "TRK",
    cityName: "Tarakan, North Kalimantan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "JYV",
    airportName: "Jyvaskyla",
    cityCode: "JYV",
    cityName: "Jyvaskyla",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "ABK",
    airportName: "Kabri Dar",
    cityCode: "ABK",
    cityName: "Kebri Dahar",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "HTA",
    airportName: "Kadala",
    cityCode: "HTA",
    cityName: "Chita",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KAD",
    airportName: "Kaduna International",
    cityCode: "KAD",
    cityName: "Kaduna",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "KGE",
    airportName: "Kaghau",
    cityCode: "KGE",
    cityName: "Kaghau Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "KOJ",
    airportName: "Kagoshima",
    cityCode: "KOJ",
    cityName: "Kagoshima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KBH",
    airportName: "Kahama Buzwagi",
    cityCode: "KBH",
    cityName: "Kahama",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "KCM",
    airportName: "Kahramanmaras",
    cityCode: "KCM",
    cityName: "Kahramanmaras",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "KJH",
    airportName: "Kaili Huangping",
    cityCode: "KJH",
    cityName: "Huangping County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KNG",
    airportName: "Kaimana",
    cityCode: "KNG",
    cityName: "Kaimana",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KAJ",
    airportName: "Kajaani",
    cityCode: "KAJ",
    cityName: "Kajaani",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "GGM",
    airportName: "Kakamega",
    cityCode: "GGM",
    cityName: "Kakamega",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "KLX",
    airportName: "Kalamata International",
    cityCode: "KLX",
    cityName: "Kalamata",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KMV",
    airportName: "Kalaymyo",
    cityCode: "KMV",
    cityName: "Kalay",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "FMI",
    airportName: "Kalemie",
    cityCode: "FMI",
    cityName: "Kalemie",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "KGI",
    airportName: "Kalgoorlie-Boulder",
    cityCode: "KGI",
    cityName: "Kalgoorlie",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KLO",
    airportName: "Kalibo International",
    cityCode: "KLO",
    cityName: "Kalibo",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "BEJ",
    airportName: "Kalimarau",
    cityCode: "BEJ",
    cityName: "Tanjung Redeb",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KLR",
    airportName: "Kalmar",
    cityCode: "KLR",
    cityName: "Kalmar",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "KLF",
    airportName: "Kaluga (Grabtsevo)",
    cityCode: "KLF",
    cityName: "Kaluga",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "JKL",
    airportName: "Kalymnos Island National",
    cityCode: "JKL",
    cityName: "Kalymnos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KME",
    airportName: "Kamembe",
    cityCode: "KME",
    cityName: "Cyangugu",
    countryCode: "RW",
    countryName: "Rwanda",
    continent: "Africa",
  },
  {
    airportCode: "YKA",
    airportName: "Kamloops",
    cityCode: "YKA",
    cityName: "Kamloops",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KGA",
    airportName: "Kananga",
    cityCode: "KGA",
    cityName: "Kananga",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "KJI",
    airportName: "Kanas",
    cityCode: "KJI",
    cityName: "Burqin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KDH",
    airportName: "Kandahar International",
    cityCode: "KDH",
    cityName: "Kandahar",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "KDZ",
    airportName: "Kandy Polgolla Reservoir SPB",
    cityCode: "KDW",
    cityName: "Kandy",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "KGT",
    airportName: "Kangding",
    cityCode: "KGT",
    cityName: "Kangding",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SFJ",
    airportName: "Kangerlussuaq",
    cityCode: "SFJ",
    cityName: "Kangerlussuaq",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "XGR",
    airportName: "Kangiqsualujjuaq (Georges River)",
    cityCode: "XGR",
    cityName: "Kangiqsualujjuaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YWB",
    airportName: "Kangiqsujuaq (Wakeham Bay)",
    cityCode: "YWB",
    cityName: "Kangiqsujuaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YKG",
    airportName: "Kangirsuk",
    cityCode: "YKG",
    cityName: "Kangirsuk",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KIX",
    airportName: "Kansai International",
    cityCode: "OSA",
    cityName: "Osaka",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KHH",
    airportName: "Kaohsiung International",
    cityCode: "KHH",
    cityName: "Kaohsiung",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "PPQ",
    airportName: "Kapiti Coast",
    cityCode: "PPQ",
    cityName: "Paraparaumu",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "YYU",
    airportName: "Kapuskasing",
    cityCode: "YYU",
    cityName: "Kapuskasing",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KRY",
    airportName: "Karamay",
    cityCode: "KRY",
    cityName: "Karamay",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LUV",
    airportName: "Karel Sadsuitubun Airport",
    cityCode: "LUV",
    cityName: "Langgur",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KAB",
    airportName: "Kariba International Airport",
    cityCode: "KAB",
    cityName: "Kariba",
    countryCode: "ZW",
    countryName: "Zimbabwe",
    continent: "Africa",
  },
  {
    airportCode: "KLV",
    airportName: "Karlovy Vary",
    cityCode: "KLV",
    cityName: "Karlovy Vary",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "FKB",
    airportName: "Karlsruhe/Baden-Baden",
    cityCode: "FKB",
    cityName: "Karlsruhe",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "KSD",
    airportName: "Karlstad",
    cityCode: "KSD",
    cityName: "Karlstad",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "AOK",
    airportName: "Karpathos Island National",
    cityCode: "AOK",
    cityName: "Karpathos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KTA",
    airportName: "Karratha",
    cityCode: "KTA",
    cityName: "Karratha",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KSY",
    airportName: "Kars Harakani",
    cityCode: "KSY",
    cityName: "Kars",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "KSQ",
    airportName: "Karshi",
    cityCode: "KSQ",
    cityName: "Qarshi",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "KRB",
    airportName: "Karumba",
    cityCode: "KRB",
    cityName: "Karumba",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "BBK",
    airportName: "Kasane",
    cityCode: "BBK",
    cityName: "Kasane",
    countryCode: "BW",
    countryName: "Botswana",
    continent: "Africa",
  },
  {
    airportCode: "ZKE",
    airportName: "Kashechewan",
    cityCode: "ZKE",
    cityName: "Kashechewan",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KHG",
    airportName: "Kashi",
    cityCode: "KHG",
    cityName: "Kashgar",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KSJ",
    airportName: "Kasos Island Public",
    cityCode: "KSJ",
    cityName: "Kasos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KSF",
    airportName: "Kassel",
    cityCode: "KSF",
    cityName: "Kassel",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "KFS",
    airportName: "Kastamonu",
    cityCode: "KFS",
    cityName: "Kastamonu",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "KZS",
    airportName: "Kastellorizo",
    cityCode: "KZS",
    cityName: "Kastellorizo",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KSO",
    airportName: "Kastoria National",
    cityCode: "KSO",
    cityName: "Kastoria",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "MPA",
    airportName: "Katima Mulilo",
    cityCode: "MPA",
    cityName: "Katima Mulilo",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "KXU",
    airportName: "Katiu",
    cityCode: "KXU",
    cityName: "Katiu",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "KTW",
    airportName: "Katowice International",
    cityCode: "KTW",
    cityName: "Katowice",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "KAZ",
    airportName: "Kaubang",
    cityCode: "KAZ",
    cityName: "Kau",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KHZ",
    airportName: "Kauehi",
    cityCode: "KHZ",
    cityName: "Kauehi",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "KKR",
    airportName: "Kaukura",
    cityCode: "KKR",
    cityName: "Kaukura",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "KUN",
    airportName: "Kaunas",
    cityCode: "KUN",
    cityName: "Kaunas",
    countryCode: "LT",
    countryName: "Lithuania",
    continent: "Europe",
  },
  {
    airportCode: "KVA",
    airportName: "Kavala International",
    cityCode: "KVA",
    cityName: "Kavala",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KVR",
    airportName: "Kavalerovo",
    cityCode: "KVR",
    cityName: "Kavalerovo",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KVG",
    airportName: "Kavieng",
    cityCode: "KVG",
    cityName: "Kavieng",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "BKY",
    airportName: "Kavumu",
    cityCode: "BKY",
    cityName: "Bukavu",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "KAW",
    airportName: "Kawthaung",
    cityCode: "KAW",
    cityName: "Kawthaung Township",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "KZN",
    airportName: "Kazan International",
    cityCode: "KZN",
    cityName: "Kazan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "KEF",
    airportName: "Keflavik International",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "ZKG",
    airportName: "Kegaska",
    cityCode: "ZKG",
    cityName: "Kegashka, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YLW",
    airportName: "Kelowna International",
    cityCode: "YLW",
    cityName: "Kelowna",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KEJ",
    airportName: "Kemerovo International",
    cityCode: "KEJ",
    cityName: "Kemerovo",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KEM",
    airportName: "Kemi-Tornio",
    cityCode: "KEM",
    cityName: "Kemi",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "KET",
    airportName: "Kengtung",
    cityCode: "KET",
    cityName: "Kengtung",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "LUN",
    airportName: "Kenneth Kaunda International",
    cityCode: "LUN",
    cityName: "Lusaka",
    countryCode: "ZM",
    countryName: "Zambia",
    continent: "Africa",
  },
  {
    airportCode: "YQK",
    airportName: "Kenora",
    cityCode: "YQK",
    cityName: "Kenora",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KPW",
    airportName: "Keperveyem",
    cityCode: "KPW",
    cityName: "Keperveyem",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "EFL",
    airportName: "Kephalonia International",
    cityCode: "EFL",
    cityName: "Cephalonia",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KKE",
    airportName: "Kerikeri",
    cityCode: "KKE",
    cityName: "Kerikeri",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "KER",
    airportName: "Kerman",
    cityCode: "KER",
    cityName: "Kerman",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "KSH",
    airportName: "Kermanshah",
    cityCode: "KSH",
    cityName: "Kermanshah",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "KIR",
    airportName: "Kerry",
    cityCode: "KIR",
    cityName: "County Kerry",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "KTE",
    airportName: "Kerteh",
    cityCode: "KTE",
    cityName: "Kerteh",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "KTG",
    airportName: "Ketapang Rahadi Osman",
    cityCode: "KTG",
    cityName: "Ketapang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KHV",
    airportName: "Khabarovsk Novy",
    cityCode: "KHV",
    cityName: "Khabarovsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KHM",
    airportName: "Khamti",
    cityCode: "KHM",
    cityName: "Khamti",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "KDY",
    airportName: "Khandyga Teply Klyuch",
    cityCode: "KDY",
    cityName: "Khandyga",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "HMA",
    airportName: "Khanty-Mansiysk",
    cityCode: "HMA",
    cityName: "Khanty-Mansiysk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "HRK",
    airportName: "Kharkiv International",
    cityCode: "HRK",
    cityName: "Kharkiv",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "KRT",
    airportName: "Khartoum International",
    cityCode: "KRT",
    cityName: "Khartoum",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "KHS",
    airportName: "Khasab",
    cityCode: "KHS",
    cityName: "Khasab",
    countryCode: "OM",
    countryName: "Oman",
    continent: "Asia",
  },
  {
    airportCode: "HTG",
    airportName: "Khatanga",
    cityCode: "HTG",
    cityName: "Khatanga",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KHE",
    airportName: "Kherson International",
    cityCode: "KHE",
    cityName: "Kherson",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "KKC",
    airportName: "Khon Kaen",
    cityCode: "KKC",
    cityName: "Khon Kaen",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "KHD",
    airportName: "Khorramabad",
    cityCode: "KHD",
    cityName: "Khorramabad",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "HVD",
    airportName: "Khovd",
    cityCode: "HVD",
    cityName: "Hovd",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "KHY",
    airportName: "Khoy Airport",
    cityCode: "null",
    cityName: "Khoy",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "KGD",
    airportName: "Khrabrovo",
    cityCode: "KGD",
    cityName: "Kaliningrad",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "LBD",
    airportName: "Khujand",
    cityCode: "LBD",
    cityName: "Khujand",
    countryCode: "TJ",
    countryName: "Tajikistan",
    continent: "Asia",
  },
  {
    airportCode: "KGL",
    airportName: "Kigali International",
    cityCode: "KGL",
    cityName: "Kigali",
    countryCode: "RW",
    countryName: "Rwanda",
    continent: "Africa",
  },
  {
    airportCode: "TKQ",
    airportName: "Kigoma",
    cityCode: "TKQ",
    cityName: "Kigoma",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "JRO",
    airportName: "Kilimanjaro International",
    cityCode: "JRO",
    cityName: "Mount Kilimanjaro",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "KIY",
    airportName: "Kilwa Masoko",
    cityCode: "KIY",
    cityName: "Kilwa Kisiwani",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "KIM",
    airportName: "Kimberley",
    cityCode: "KIM",
    cityName: "Kimberley, Northern Cape",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "YLC",
    airportName: "Kimmirut",
    cityCode: "YLC",
    cityName: "Kimmirut",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KND",
    airportName: "Kindu",
    cityCode: "KND",
    cityName: "Kindu",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "JED",
    airportName: "King Abdulaziz International",
    cityCode: "JED",
    cityName: "Jeddah",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "DMM",
    airportName: "King Fahd International",
    cityCode: "DMM",
    cityName: "Dammam",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "AQJ",
    airportName: "King Hussein International",
    cityCode: "AQJ",
    cityName: "Aqaba",
    countryCode: "JO",
    countryName: "Jordan",
    continent: "Asia",
  },
  {
    airportCode: "KNS",
    airportName: "King Island",
    cityCode: "KNS",
    cityName: "King Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "RUH",
    airportName: "King Khalid International",
    cityCode: "RUH",
    cityName: "Riyadh",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "KMC",
    airportName: "King Khalid Military City",
    cityCode: "KMC",
    cityName: "King Khalid Military City",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "SHO",
    airportName: "King Mswati III International",
    cityCode: "MTS",
    cityName: "Manzini",
    countryCode: "SZ",
    countryName: "Swaziland",
    continent: "Africa",
  },
  {
    airportCode: "KGC",
    airportName: "Kingscote",
    cityCode: "KGC",
    cityName: "Kingscote",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YGK",
    airportName: "Kingston/Norman Rogers",
    cityCode: "YGK",
    cityName: "Kingston",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KNH",
    airportName: "Kinmen",
    cityCode: "KNH",
    cityName: "Kinmen",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "IRA",
    airportName: "Kirakira",
    cityCode: "IRA",
    cityName: "Kirakira",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "KCK",
    airportName: "Kirensk",
    cityCode: "KCK",
    cityName: "Kirensk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KKN",
    airportName: "Kirkenes Airport, Hoybuktmoen",
    cityCode: "KKN",
    cityName: "Kirkenes",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "KVK",
    airportName: "Kirovsk-Apatity",
    cityCode: "KVK",
    cityName: "Kirovsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "KRN",
    airportName: "Kiruna",
    cityCode: "KRN",
    cityName: "Kiruna",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "KIH",
    airportName: "Kish International",
    cityCode: "KIH",
    cityName: "Kish Island",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "KMU",
    airportName: "Kisimayu",
    cityCode: "KMU",
    cityName: "Kismayo",
    countryCode: "SO",
    countryName: "Somalia",
    continent: "Africa",
  },
  {
    airportCode: "KIS",
    airportName: "Kisumu International",
    cityCode: "KIS",
    cityName: "Kisumu",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "KKJ",
    airportName: "Kitakyushu",
    cityCode: "KKJ",
    cityName: "Kitakyushu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KTL",
    airportName: "Kitale",
    cityCode: "KTL",
    cityName: "Kitale",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "KIT",
    airportName: "Kithira Island National",
    cityCode: "KIT",
    cityName: "Kythira",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KTT",
    airportName: "Kittila",
    cityCode: "KTT",
    cityName: "Kittila",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "UNG",
    airportName: "Kiunga",
    cityCode: "UNG",
    cityName: "Kiunga",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "KLU",
    airportName: "Klagenfurt",
    cityCode: "KLU",
    cityName: "Klagenfurt",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "UKB",
    airportName: "Kobe",
    cityCode: "UKB",
    cityName: "Kobe",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ZNV",
    airportName: "Koblenz-Winningen Airport",
    cityCode: "ZNV",
    cityName: "Koblenz",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "KCZ",
    airportName: "Kochi",
    cityCode: "KCZ",
    cityName: "Kochi",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KGP",
    airportName: "Kogalym International",
    cityCode: "KGP",
    cityName: "Kogalym",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KCT",
    airportName: "Koggala",
    cityCode: "KCT",
    cityName: "Koggala",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "KOK",
    airportName: "Kokkola-Pietarsaari",
    cityCode: "KOK",
    cityName: "Kokkola",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "KOV",
    airportName: "Kokshetau",
    cityCode: "KOV",
    cityName: "Kokshetau",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "PUM",
    airportName: "Kolaka Pomala",
    cityCode: "PUM",
    cityName: "Pomalaa",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SVX",
    airportName: "Koltsovo",
    cityCode: "SVX",
    cityName: "Yekaterinburg",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KMQ",
    airportName: "Komatsu",
    cityCode: "KMQ",
    cityName: "Komatsu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "LBJ",
    airportName: "Komodo",
    cityCode: "LBJ",
    cityName: "Labuan Bajo",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KXK",
    airportName: "Komsomolsk-on-Amur",
    cityCode: "KXK",
    cityName: "Komsomolsk-on-Amur",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ZBR",
    airportName: "Konarak",
    cityCode: "ZBR",
    cityName: "Chabahar",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "KNQ",
    airportName: "Kone Airport",
    cityCode: "KNQ",
    cityName: "Kone",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "KYA",
    airportName: "Konya",
    cityCode: "KYA",
    cityName: "Konya",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "HGO",
    airportName: "Korhogo",
    cityCode: "HGO",
    cityName: "Korhogo",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "KRL",
    airportName: "Korla",
    cityCode: "KRL",
    cityName: "Korla",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KXF",
    airportName: "Koro Island Airport",
    cityCode: "KXF",
    cityName: "Koro Island",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "KGS",
    airportName: "Kos Island International",
    cityCode: "KGS",
    cityName: "Kos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KSC",
    airportName: "Kosice International",
    cityCode: "KSC",
    cityName: "Kosice",
    countryCode: "SK",
    countryName: "Slovakia",
    continent: "Europe",
  },
  {
    airportCode: "KSA",
    airportName: "Kosrae International",
    cityCode: "KSA",
    cityName: "Kosrae",
    countryCode: "FM",
    countryName: "Micronesia",
    continent: "Oceania",
  },
  {
    airportCode: "KSN",
    airportName: "Kostanay",
    cityCode: "KSN",
    cityName: "Kostanay",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "BKI",
    airportName: "Kota Kinabalu International",
    cityCode: "BKI",
    cityName: "Kota Kinabalu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "KSZ",
    airportName: "Kotlas",
    cityCode: "KSZ",
    cityName: "Kotlas",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "ACC",
    airportName: "Kotoka International",
    cityCode: "ACC",
    cityName: "Accra",
    countryCode: "GH",
    countryName: "Ghana",
    continent: "Africa",
  },
  {
    airportCode: "DWO",
    airportName: "Kotte Diyawanna Oya SPB",
    cityCode: "DWO",
    cityName: "Sri Jayawardenepura Kotte",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "KOC",
    airportName: "Koumac",
    cityCode: "KOC",
    cityName: "Koumac",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "KWM",
    airportName: "Kowanyama",
    cityCode: "KWM",
    cityName: "Kowanyama",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KZI",
    airportName: "Kozani National",
    cityCode: "KZI",
    cityName: "Kozani",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "KBV",
    airportName: "Krabi",
    cityCode: "KBV",
    cityName: "Krabi",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "KRR",
    airportName: "Krasnodar International",
    cityCode: "KRR",
    cityName: "Krasnodar",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "BXY",
    airportName: "Krayniy",
    cityCode: "BXY",
    cityName: "Baikonur",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "KRS",
    airportName: "Kristiansand Airport, Kjevik",
    cityCode: "KRS",
    cityName: "Kristiansand",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "KID",
    airportName: "Kristianstad",
    cityCode: "KID",
    cityName: "Kristianstad",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "KSU",
    airportName: "Kristiansund Airport, Kvernberget",
    cityCode: "KSU",
    cityName: "Kristiansund",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "MQP",
    airportName: "Kruger Mpumalanga International",
    cityCode: "NLP",
    cityName: "Nelspruit",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "KUL",
    airportName: "Kuala Lumpur International",
    cityCode: "KUL",
    cityName: "Kuala Lumpur",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "KNO",
    airportName: "Kualanamu International",
    cityCode: "MES",
    cityName: "Medan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KCH",
    airportName: "Kuching International",
    cityCode: "KCH",
    cityName: "Kuching",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "KUD",
    airportName: "Kudat",
    cityCode: "KUD",
    cityName: "Kudat",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "AKF",
    airportName: "Kufra",
    cityCode: "AKF",
    cityName: "Kufrah",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "YBB",
    airportName: "Kugaaruk",
    cityCode: "YBB",
    cityName: "Kugaaruk",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YCO",
    airportName: "Kugluktuk",
    cityCode: "YCO",
    cityName: "Kugluktuk",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SVP",
    airportName: "Kuito",
    cityCode: "SVP",
    cityName: "Kuito",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "TJU",
    airportName: "Kulob",
    cityCode: "TJU",
    cityName: "Kulob",
    countryCode: "TJ",
    countryName: "Tajikistan",
    continent: "Asia",
  },
  {
    airportCode: "KUS",
    airportName: "Kulusuk",
    cityCode: "KUS",
    cityName: "Kulusuk",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "KMJ",
    airportName: "Kumamoto",
    cityCode: "KMJ",
    cityName: "Kumamoto",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KMS",
    airportName: "Kumasi",
    cityCode: "KMS",
    cityName: "Kumasi",
    countryCode: "GH",
    countryName: "Ghana",
    continent: "Africa",
  },
  {
    airportCode: "UEO",
    airportName: "Kumejima",
    cityCode: "UEO",
    cityName: "Kume Island",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KMG",
    airportName: "Kunming Changshui International",
    cityCode: "KMG",
    cityName: "Kunming",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KUO",
    airportName: "Kuopio",
    cityCode: "KUO",
    cityName: "Kuopio",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "KCA",
    airportName: "Kuqa Qiuci",
    cityCode: "KCA",
    cityName: "Kuqa County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "KRO",
    airportName: "Kurgan",
    cityCode: "KRO",
    cityName: "Kurgan, Kurgan Oblast",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "URS",
    airportName: "Kursk Vostochny",
    cityCode: "URS",
    cityName: "Kursk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "KUF",
    airportName: "Kurumoch International",
    cityCode: "KUF",
    cityName: "Samara",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "KUH",
    airportName: "Kushiro",
    cityCode: "KUH",
    cityName: "Kushiro",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "IXL",
    airportName: "Kushok Bakula Rimpochee",
    cityCode: "IXL",
    cityName: "Leh",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "YVP",
    airportName: "Kuujjuaq",
    cityCode: "YVP",
    cityName: "Kuujjuaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YGW",
    airportName: "Kuujjuarapik",
    cityCode: "YGW",
    cityName: "Kuujjuarapik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KAO",
    airportName: "Kuusamo",
    cityCode: "KAO",
    cityName: "Kuusamo",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "KWI",
    airportName: "Kuwait International",
    cityCode: "KWI",
    cityName: "Kuwait City",
    countryCode: "KW",
    countryName: "Kuwait",
    continent: "Asia",
  },
  {
    airportCode: "KYP",
    airportName: "Kyaukpyu",
    cityCode: "KYP",
    cityName: "Kyaukpyu",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "IEV",
    airportName: "Kyiv International Airport (Zhuliany)",
    cityCode: "IEV",
    cityName: "Kiev",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "KYZ",
    airportName: "Kyzyl",
    cityCode: "KYZ",
    cityName: "Kyzyl",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "KZO",
    airportName: "Kyzylorda",
    cityCode: "KZO",
    cityName: "Kyzylorda",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "LOO",
    airportName: "L Mekrareg",
    cityCode: "LOO",
    cityName: "Laghouat",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "ZCO",
    airportName: "La Araucania International",
    cityCode: "ZCO",
    cityName: "Temuco",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "GUA",
    airportName: "La Aurora International",
    cityCode: "GUA",
    cityName: "Guatemala City",
    countryCode: "GT",
    countryName: "Guatemala",
    continent: "North America",
  },
  {
    airportCode: "MAR",
    airportName: "La Chinita International",
    cityCode: "MAR",
    cityName: "Maracaibo",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "LCR",
    airportName: "La Chorrera",
    cityCode: "LCR",
    cityName: "La Chorrera",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LSC",
    airportName: "La Florida",
    cityCode: "LSC",
    cityName: "La Serena",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "TCO",
    airportName: "La Florida",
    cityCode: "TCO",
    cityName: "Tumaco",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LFR",
    airportName: "La Fria",
    cityCode: "LFR",
    cityName: "La Fria",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "GMZ",
    airportName: "La Gomera",
    cityCode: "GMZ",
    cityName: "San Sebastian de La Gomera",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "YGL",
    airportName: "La Grande Riviere",
    cityCode: "YGL",
    cityName: "La Grande River",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "JBQ",
    airportName: "La Isabela International",
    cityCode: "SDQ",
    cityName: "Santo Domingo",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "GJA",
    airportName: "La Laguna Airport",
    cityCode: "GJA",
    cityName: "Guanaja",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "LMC",
    airportName: "La Macarena",
    cityCode: "LMC",
    cityName: "La Macarena",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "MZL",
    airportName: "La Nubia",
    cityCode: "MZL",
    cityName: "Manizales",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "SPC",
    airportName: "La Palma",
    cityCode: "SPC",
    cityName: "Santa Cruz de La Palma",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "LAP",
    airportName: "La Paz International",
    cityCode: "LAP",
    cityName: "La Paz",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "LPD",
    airportName: "La Pedrera",
    cityCode: "LPD",
    cityName: "La Pedrera",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "IRJ",
    airportName: "La Rioja",
    cityCode: "IRJ",
    cityName: "La Rioja",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "LRH",
    airportName: "La Rochelle Ile de Re",
    cityCode: "LRH",
    cityName: "La Rochelle",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZGS",
    airportName: "La Romaine",
    cityCode: "ZGS",
    cityName: "La Romaine, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LRM",
    airportName: "La Romana International",
    cityCode: "LRM",
    cityName: "La Romana",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "ZLT",
    airportName: "La Tabatiere",
    cityCode: "ZLT",
    cityName: "La Tabatiere, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "NOU",
    airportName: "La Tontouta International",
    cityCode: "NOU",
    cityName: "Noumea",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "VVC",
    airportName: "La Vanguardia",
    cityCode: "VVC",
    cityName: "Villavicencio",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LBS",
    airportName: "Labasa",
    cityCode: "LBS",
    cityName: "Labasa",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "OZC",
    airportName: "Labo",
    cityCode: "OZC",
    cityName: "Ozamiz",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "LBU",
    airportName: "Labuan",
    cityCode: "LBU",
    cityName: "Labuan",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "XLB",
    airportName: "Lac Brochet",
    cityCode: "XLB",
    cityName: "Lac Brochet, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZWH",
    airportName: "Lac Brochet Airport",
    cityCode: "XLB",
    cityName: "Lac Brochet, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LAE",
    airportName: "Lae Nadzab",
    cityCode: "LAE",
    cityName: "Lae",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "LAJ",
    airportName: "Lages",
    cityCode: "LAJ",
    cityName: "Lages",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "LGQ",
    airportName: "Lago Agrio",
    cityCode: "LGQ",
    cityName: "Lago Agrio Canton",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "CGY",
    airportName: "Laguindingan Airport",
    cityCode: "CGY",
    cityName: "Cagayan de Oro",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "LDU",
    airportName: "Lahad Datu",
    cityCode: "LDU",
    cityName: "Lahad Datu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "PXA",
    airportName: "Lahat Pagar Alam",
    cityCode: "PXA",
    cityName: "Lahat Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TER",
    airportName: "Lajes Field",
    cityCode: "TER",
    cityName: "Terceira Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "LKY",
    airportName: "Lake Manyara",
    cityCode: "LKY",
    cityName: "Lake Manyara",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "LKB",
    airportName: "Lakeba",
    cityCode: "LKB",
    cityName: "Lakeba",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "LKL",
    airportName: "Lakselv Airport, Banak",
    cityCode: "LKL",
    cityName: "Lakselv",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "LLI",
    airportName: "Lalibela",
    cityCode: "LLI",
    cityName: "Lalibela",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "LNB",
    airportName: "Lamen Bay",
    cityCode: "LNB",
    cityName: "Lamen Bay",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "LFM",
    airportName: "Lamerd",
    cityCode: "LFM",
    cityName: "Lamerd",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "SUF",
    airportName: "Lamezia Terme International",
    cityCode: "SUF",
    cityName: "Lamezia Terme",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "LPT",
    airportName: "Lampang",
    cityCode: "LPT",
    cityName: "Lampang Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "LMP",
    airportName: "Lampedusa",
    cityCode: "LMP",
    cityName: "Lampedusa",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "LGK",
    airportName: "Langkawi International",
    cityCode: "LGK",
    cityName: "Langkawi",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LLK",
    airportName: "Lankaran International",
    cityCode: "LLK",
    cityName: "Lankaran",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "LAI",
    airportName: "Lannion Cote de Granit",
    cityCode: "LAI",
    cityName: "Lannion",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "HLA",
    airportName: "Lanseria International",
    cityCode: "JNB",
    cityName: "Johannesburg",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "ACE",
    airportName: "Lanzarote",
    cityCode: "ACE",
    cityName: "Lanzarote",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "LHW",
    airportName: "Lanzhou Zhongchuan International",
    cityCode: "LHW",
    cityName: "Lanzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LAO",
    airportName: "Laoag International",
    cityCode: "LAO",
    cityName: "Laoag",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "LPP",
    airportName: "Lappeenranta",
    cityCode: "LPP",
    cityName: "Lappeenranta",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "LRR",
    airportName: "Larestan International",
    cityCode: "LRR",
    cityName: "Lar",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "LCA",
    airportName: "Larnaca International",
    cityCode: "LCA",
    cityName: "Larnaca",
    countryCode: "CY",
    countryName: "Cyprus",
    continent: "Asia",
  },
  {
    airportCode: "SDQ",
    airportName: "Las Americas International",
    cityCode: "SDQ",
    cityName: "Santo Domingo",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "CZU",
    airportName: "Las Brujas",
    cityCode: "CZU",
    cityName: "Corozal, Sucre",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "SFD",
    airportName: "Las Flecheras",
    cityCode: "SFD",
    cityName: "San Fernando de Apure",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "RCU",
    airportName: "Las Higueras",
    cityCode: "RCU",
    cityName: "Rio Cuarto",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "ZAL",
    airportName: "Las Marias",
    cityCode: "ZAL",
    cityName: "Valdivia",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "RHD",
    airportName: "Las Termas",
    cityCode: "RHD",
    cityName: "Termas de Rio Hondo",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "LSH",
    airportName: "Lashio",
    cityCode: "LSH",
    cityName: "Lashio",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "LST",
    airportName: "Launceston",
    cityCode: "LST",
    cityName: "City of Launceston",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PFB",
    airportName: "Lauro Kurtz",
    cityCode: "PFB",
    cityName: "Passo Fundo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "QLS",
    airportName: "Lausanne-la Blecherette Airport",
    cityCode: "null",
    cityName: "Lausanne",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "LWY",
    airportName: "Lawas",
    cityCode: "LWY",
    cityName: "Lawas",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LZC",
    airportName: "Lazaro Cardenas",
    cityCode: "LZC",
    cityName: "Lazaro Cardenas",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "LPY",
    airportName: "Le Puy Loudes",
    cityCode: "LPY",
    cityName: "Le Puy-en-Velay",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LGP",
    airportName: "Legazpi",
    cityCode: "LGP",
    cityName: "Legazpi",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "LEJ",
    airportName: "Leipzig/Halle Airport",
    cityCode: "LEJ",
    cityName: "Leipzig",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "RAO",
    airportName: "Leite Lopes",
    cityCode: "RAO",
    cityName: "Ribeirao Preto",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "LKN",
    airportName: "Leknes",
    cityCode: "LKN",
    cityName: "Leknes",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "LXS",
    airportName: "Lemnos International",
    cityCode: "LXS",
    cityName: "Lemnos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "LEC",
    airportName: "Lencois",
    cityCode: "LEC",
    cityName: "Lencois",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ULK",
    airportName: "Lensk",
    cityCode: "ULK",
    cityName: "Lensk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "OTI",
    airportName: "Leo Wattimena",
    cityCode: "OTI",
    cityName: "Morotai",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "LEN",
    airportName: "Leon",
    cityCode: "LEN",
    cityName: "Leon",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "FCO",
    airportName: "Leonardo da Vinci Fiumicino Airport",
    cityCode: "ROM",
    cityName: "Rome",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "DKR",
    airportName: "Leopold Sedar Senghor International",
    cityCode: "DKR",
    cityName: "Dakar",
    countryCode: "SN",
    countryName: "Senegal",
    continent: "Africa",
  },
  {
    airportCode: "OSR",
    airportName: "Leos Janacek Airport Ostrava",
    cityCode: "OSR",
    cityName: "Ostrava",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "LRS",
    airportName: "Leros Municipal",
    cityCode: "LRS",
    cityName: "Leros",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "GBJ",
    airportName: "Les Bases Airport",
    cityCode: "GBJ",
    cityName: "Grand Bourg",
    countryCode: "FR",
    countryName: "France",
    continent: "North America",
  },
  {
    airportCode: "YQL",
    airportName: "Lethbridge",
    cityCode: "YQL",
    cityName: "Lethbridge",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LXA",
    airportName: "Lhasa Gonggar",
    cityCode: "LXA",
    cityName: "Lhasa",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LYG",
    airportName: "Lianyungang Baitabu",
    cityCode: "LYG",
    cityName: "Lianyungang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PSS",
    airportName: "Libertador General Jose de San Martin",
    cityCode: "PSS",
    cityName: "Posadas",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "LLB",
    airportName: "Libo",
    cityCode: "LLB",
    cityName: "Libo County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LBV",
    airportName: "Libreville International",
    cityCode: "LBV",
    cityName: "Libreville",
    countryCode: "GA",
    countryName: "Gabon",
    continent: "Africa",
  },
  {
    airportCode: "PVR",
    airportName: "Licenciado Gustavo Diaz Ordaz International",
    cityCode: "PVR",
    cityName: "Puerto Vallarta",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "VXC",
    airportName: "Lichinga",
    cityCode: "VXC",
    cityName: "Lichinga",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "LGG",
    airportName: "Liege",
    cityCode: "LGG",
    cityName: "Liege",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "DLI",
    airportName: "Lien Khuong",
    cityCode: "DLI",
    cityName: "Da Lat",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "LPX",
    airportName: "Liepaja International",
    cityCode: "LPX",
    cityName: "Liepaja",
    countryCode: "LV",
    countryName: "Latvia",
    continent: "Europe",
  },
  {
    airportCode: "LNV",
    airportName: "Lihir Island",
    cityCode: "LNV",
    cityName: "Lihir Group",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "LJG",
    airportName: "Lijiang Sanyi International",
    cityCode: "LJG",
    cityName: "Lijiang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LIL",
    airportName: "Lille",
    cityCode: "LIL",
    cityName: "Lille",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LLW",
    airportName: "Lilongwe International",
    cityCode: "LLW",
    cityName: "Lilongwe",
    countryCode: "MW",
    countryName: "Malawi",
    continent: "Africa",
  },
  {
    airportCode: "LMN",
    airportName: "Limbang",
    cityCode: "LMN",
    cityName: "Limbang",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LIG",
    airportName: "Limoges Bellegarde",
    cityCode: "LIG",
    cityName: "Limoges",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LIO",
    airportName: "Limon International",
    cityCode: "LIO",
    cityName: "Puerto Limon",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "LIN",
    airportName: "Linate",
    cityCode: "MIL",
    cityName: "Milan",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "LNJ",
    airportName: "Lincang",
    cityCode: "LNJ",
    cityName: "Lincang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LFQ",
    airportName: "Linfen Qiaoli",
    cityCode: "LFQ",
    cityName: "Linfen",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LPI",
    airportName: "Linkoping City",
    cityCode: "LPI",
    cityName: "Linkoping",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "LYI",
    airportName: "Linyi Shubuling",
    cityCode: "LYI",
    cityName: "Linyi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LNZ",
    airportName: "Linz",
    cityCode: "LNZ",
    cityName: "Linz",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "LPK",
    airportName: "Lipetsk",
    cityCode: "LPK",
    cityName: "Lipetsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "HZH",
    airportName: "Liping",
    cityCode: "HZH",
    cityName: "Liping County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LIQ",
    airportName: "Lisala",
    cityCode: "LIQ",
    cityName: "Lisala",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "LIS",
    airportName: "Lisbon Portela",
    cityCode: "LIS",
    cityName: "Lisbon",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "LSY",
    airportName: "Lismore",
    cityCode: "LSY",
    cityName: "Lismore",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "LPF",
    airportName: "Liupanshui Yuezhao",
    cityCode: "LPF",
    cityName: "Liupanshui",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LZH",
    airportName: "Liuzhou Bailian",
    cityCode: "LZH",
    cityName: "Liuzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LJU",
    airportName: "Ljubljana Joze Pucnik",
    cityCode: "LJU",
    cityName: "Ljubljana",
    countryCode: "SI",
    countryName: "Slovenia",
    continent: "Europe",
  },
  {
    airportCode: "ILD",
    airportName: "Lleida Alguaire",
    cityCode: "ILD",
    cityName: "Lleida",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "YLL",
    airportName: "Lloydminster",
    cityCode: "YLL",
    cityName: "Lloydminster",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LOK",
    airportName: "Lodwar",
    cityCode: "LOK",
    cityName: "Lodwar",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "LCJ",
    airportName: "Lodz Wladyslaw Reymont",
    cityCode: "LCJ",
    cityName: "Lodz",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "LOE",
    airportName: "Loei",
    cityCode: "LOE",
    cityName: "Loei Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "RJL",
    airportName: "Logrono Agoncillo",
    cityCode: "RJL",
    cityName: "Logrono",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "LIW",
    airportName: "Loikaw",
    cityCode: "LIW",
    cityName: "Loikaw",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "LOP",
    airportName: "Lombok International",
    cityCode: "LOP",
    cityName: "Praya, Lombok",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "LFW",
    airportName: "Lome Tokoin International",
    cityCode: "LFW",
    cityName: "Lome",
    countryCode: "TG",
    countryName: "Togo",
    continent: "Africa",
  },
  {
    airportCode: "LDZ",
    airportName: "Londolozi",
    cityCode: "LDZ",
    cityName: "Londolozi Private Game Reserve",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "YXU",
    airportName: "London International",
    cityCode: "YXU",
    cityName: "London",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LDB",
    airportName: "Londrina",
    cityCode: "LDB",
    cityName: "Londrina",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "LKH",
    airportName: "Long Akah",
    cityCode: "LKH",
    cityName: "Long Akah",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LBP",
    airportName: "Long Banga",
    cityCode: "LBP",
    cityName: "Long Banga",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LGL",
    airportName: "Long Lellang",
    cityCode: "LGL",
    cityName: "Long Lellang",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "ODN",
    airportName: "Long Seridan",
    cityCode: "ODN",
    cityName: "Long Seridan",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "LOD",
    airportName: "Longana",
    cityCode: "LOD",
    cityName: "Longana",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "LRE",
    airportName: "Longreach",
    cityCode: "LRE",
    cityName: "Longreach",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "LCX",
    airportName: "Longyan Guanzhishan",
    cityCode: "LCX",
    cityName: "Longyan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LNE",
    airportName: "Lonorore",
    cityCode: "LNE",
    cityName: "Lonorore",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "LDH",
    airportName: "Lord Howe Island",
    cityCode: "LDH",
    cityName: "Lord Howe Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "LTO",
    airportName: "Loreto International",
    cityCode: "LTO",
    cityName: "Loreto",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "LRT",
    airportName: "Lorient South Brittany",
    cityCode: "LRT",
    cityName: "Lorient",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "SJD",
    airportName: "Los Cabos International",
    cityCode: "SJD",
    cityName: "San Jose del Cabo",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "RVE",
    airportName: "Los Colonizadores",
    cityCode: "RVE",
    cityName: "Saravena",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "MTR",
    airportName: "Los Garzones",
    cityCode: "MTR",
    cityName: "Monteria",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LMM",
    airportName: "Los Mochis International",
    cityCode: "LMM",
    cityName: "Los Mochis",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "LSA",
    airportName: "Losuia",
    cityCode: "LSA",
    cityName: "Kiriwina",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "LXG",
    airportName: "Louang Namtha",
    cityCode: "LXG",
    cityName: "Luang Namtha",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "YBX",
    airportName: "Lourdes-de-Blanc-Sablon",
    cityCode: "YBX",
    cityName: "Blanc-Sablon",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LPQ",
    airportName: "Luang Prabang International",
    cityCode: "LPQ",
    cityName: "Luang Prabang",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "SDD",
    airportName: "Lubango",
    cityCode: "SDD",
    cityName: "Lubango",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "LBC",
    airportName: "Lubeck Blankensee Airport",
    cityCode: "LBC",
    cityName: "Lubeck",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "LUZ",
    airportName: "Lublin",
    cityCode: "LUZ",
    cityName: "Lublin",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "FBM",
    airportName: "Lubumbashi International",
    cityCode: "FBM",
    cityName: "Lubumbashi",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "LUD",
    airportName: "Luderitz",
    cityCode: "LUD",
    cityName: "Luderitz",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "LUO",
    airportName: "Luena",
    cityCode: "LUO",
    cityName: "Luena",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "LUG",
    airportName: "Lugano",
    cityCode: "LUG",
    cityName: "Lugano",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "LLA",
    airportName: "Lulea",
    cityCode: "LLA",
    cityName: "Lulea",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "LLV",
    airportName: "Luliang",
    cityCode: "LLV",
    cityName: "Luliang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "FNA",
    airportName: "Lungi International",
    cityCode: "FNA",
    cityName: "Freetown",
    countryCode: "SL",
    countryName: "Sierra Leone",
    continent: "Africa",
  },
  {
    airportCode: "LYA",
    airportName: "Luoyang Beijiao",
    cityCode: "LYA",
    cityName: "Luoyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YSG",
    airportName: "Lutselk'e",
    cityCode: "YSG",
    cityName: "Lutselk'e",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LUX",
    airportName: "Luxembourg",
    cityCode: "LUX",
    cityName: "Luxembourg City",
    countryCode: "LU",
    countryName: "Luxembourg",
    continent: "Europe",
  },
  {
    airportCode: "LXR",
    airportName: "Luxor International",
    cityCode: "LXR",
    cityName: "Luxor",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "LZO",
    airportName: "Luzhou Yunlong",
    cityCode: "LZO",
    cityName: "Luzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LWO",
    airportName: "Lviv Danylo Halytskyi International",
    cityCode: "LWO",
    cityName: "Lviv",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "LYC",
    airportName: "Lycksele",
    cityCode: "LYC",
    cityName: "Lycksele",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "NAS",
    airportName: "Lynden Pindling International",
    cityCode: "NAS",
    cityName: "Nassau",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "LYS",
    airportName: "Lyon Saint-Exupery",
    cityCode: "LYS",
    cityName: "Lyon",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "MST",
    airportName: "Maastricht Aachen",
    cityCode: "MST",
    cityName: "Maastricht",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "MCP",
    airportName: "Macapa International",
    cityCode: "MCP",
    cityName: "Macapa",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "MFM",
    airportName: "Macau International",
    cityCode: "MFM",
    cityName: "Macau",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MKY",
    airportName: "Mackay",
    cityCode: "MKY",
    cityName: "Mackay",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "CEB",
    airportName: "Mactan Cebu International",
    cityCode: "CEB",
    cityName: "Cebu",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "MAG",
    airportName: "Madang",
    cityCode: "MAG",
    cityName: "Madang",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "HGN",
    airportName: "Mae Hong Son",
    cityCode: "HGN",
    cityName: "Mae Hong Son Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "MAQ",
    airportName: "Mae Sot",
    cityCode: "MAQ",
    cityName: "Mae Sot District",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "MWF",
    airportName: "Maewo-Naone",
    cityCode: "MWF",
    cityName: "Maewo",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "MFA",
    airportName: "Mafia",
    cityCode: "MFA",
    cityName: "Mafia Island",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "GYG",
    airportName: "Magan",
    cityCode: "GYG",
    cityName: "Magan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "MQF",
    airportName: "Magnitogorsk International",
    cityCode: "MQF",
    cityName: "Magnitogorsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "MZG",
    airportName: "Magong",
    cityCode: "MZG",
    cityName: "Magong",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "MBD",
    airportName: "Mahikeng",
    cityCode: "MBD",
    cityName: "Mmabatho",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "MIU",
    airportName: "Maiduguri International Airport",
    cityCode: "null",
    cityName: "Maiduguri",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "SBG",
    airportName: "Maimun Saleh",
    cityCode: "SBG",
    cityName: "Sabang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MMO",
    airportName: "Maio",
    cityCode: "MMO",
    cityName: "Maio",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "VAG",
    airportName: "Major Brigadeiro Trompowsky",
    cityCode: "VAG",
    cityName: "Varginha",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "MKP",
    airportName: "Makemo",
    cityCode: "MKP",
    cityName: "Makemo",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "YMN",
    airportName: "Makkovik",
    cityCode: "YMN",
    cityName: "Makkovik",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SSG",
    airportName: "Malabo International",
    cityCode: "SSG",
    cityName: "Malabo",
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    continent: "Africa",
  },
  {
    airportCode: "MKZ",
    airportName: "Malacca International",
    cityCode: "MKZ",
    cityName: "Malacca City",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "AGP",
    airportName: "Malaga",
    cityCode: "AGP",
    cityName: "Malaga",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "MLX",
    airportName: "Malatya Erhac",
    cityCode: "MLX",
    cityName: "Malatya",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "LPM",
    airportName: "Malekoula",
    cityCode: "LPM",
    cityName: "Lamap",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "LSW",
    airportName: "Malikus Saleh",
    cityCode: "LSW",
    cityName: "Lhokseumawe",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MYD",
    airportName: "Malindi",
    cityCode: "MYD",
    cityName: "Malindi",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "KAN",
    airportName: "Mallam Aminu Kano International",
    cityCode: "KAN",
    cityName: "Kano",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "MMX",
    airportName: "Malmo",
    cityCode: "MMA",
    cityName: "Malmo",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "MLA",
    airportName: "Malta International",
    cityCode: "MLA",
    cityName: "Malta",
    countryCode: "MT",
    countryName: "Malta",
    continent: "Europe",
  },
  {
    airportCode: "MJC",
    airportName: "Man",
    cityCode: "MJC",
    cityName: "Man",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "FRU",
    airportName: "Manas International",
    cityCode: "FRU",
    cityName: "Bishkek",
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    continent: "Asia",
  },
  {
    airportCode: "LAU",
    airportName: "Manda",
    cityCode: "LAU",
    cityName: "Lamu",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "MDL",
    airportName: "Mandalay International",
    cityCode: "MDL",
    cityName: "Mandalay",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "MGS",
    airportName: "Mangaia Island Airport",
    cityCode: "MGS",
    cityName: "Mangaia Island",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "XMH",
    airportName: "Manihi",
    cityCode: "XMH",
    cityName: "Manihi",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "MHX",
    airportName: "Manihiki Island Airport",
    cityCode: "null",
    cityName: "Manihiki Island",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "JSU",
    airportName: "Maniitsoq",
    cityCode: "JSU",
    cityName: "Maniitsoq",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "MNG",
    airportName: "Maningrida",
    cityCode: "MNG",
    cityName: "Maningrida",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "AJY",
    airportName: "Mano Dayak International",
    cityCode: "AJY",
    cityName: "Agadez",
    countryCode: "NE",
    countryName: "Niger",
    continent: "Africa",
  },
  {
    airportCode: "PZO",
    airportName: "Manuel Carlos Piar Guayana",
    cityCode: "PZO",
    cityName: "Puerto Ordaz and San Felix",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "NZH",
    airportName: "Manzhouli Xijiao",
    cityCode: "NZH",
    cityName: "Manzhouli",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MPM",
    airportName: "Maputo International",
    cityCode: "MPM",
    cityName: "Maputo",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "MRE",
    airportName: "Mara Serena",
    cityCode: "MRE",
    cityName: "Maasai Mara",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "MAB",
    airportName: "Maraba",
    cityCode: "MAB",
    cityName: "Maraba, Para",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RUS",
    airportName: "Marau",
    cityCode: "RUS",
    cityName: "Marau Sound",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "AOI",
    airportName: "Marche Airport",
    cityCode: "AOI",
    cityName: "Ancona",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "MQM",
    airportName: "Mardin",
    cityCode: "MQM",
    cityName: "Mardin",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "MEE",
    airportName: "Mare Airport",
    cityCode: "MEE",
    cityName: "Mare",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "SLZ",
    airportName: "Marechal Cunha Machado International",
    cityCode: "SLZ",
    cityName: "Sao Luis",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CGB",
    airportName: "Marechal Rondon International",
    cityCode: "CGB",
    cityName: "Cuiaba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CBQ",
    airportName: "Margaret Ekpo International",
    cityCode: "CBQ",
    cityName: "Calabar",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "MGH",
    airportName: "Margate",
    cityCode: "MGH",
    cityName: "Margate, KwaZulu-Natal",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "GAO",
    airportName: "Mariana Grajales",
    cityCode: "GAO",
    cityName: "Guantanamo",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "MBX",
    airportName: "Maribor Edvard Rusjan",
    cityCode: "MBX",
    cityName: "Maribor",
    countryCode: "SI",
    countryName: "Slovenia",
    continent: "Europe",
  },
  {
    airportCode: "MHQ",
    airportName: "Mariehamn",
    cityCode: "MHQ",
    cityName: "Mariehamn",
    countryCode: "AX",
    countryName: "Aland Islands",
    continent: "Europe",
  },
  {
    airportCode: "MII",
    airportName: "Marilia",
    cityCode: "MII",
    cityName: "Marilia",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "EBA",
    airportName: "Marina di Campo",
    cityCode: "EBA",
    cityName: "Campo nell Elba",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "MGF",
    airportName: "Maringa Regional",
    cityCode: "MGF",
    cityName: "Maringa",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CUE",
    airportName: "Mariscal Lamar International",
    cityCode: "CUE",
    cityName: "Cuenca",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "UIO",
    airportName: "Mariscal Sucre International",
    cityCode: "UIO",
    cityName: "Quito",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "WMN",
    airportName: "Maroantsetra",
    cityCode: "WMN",
    cityName: "Maroantsetra",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "RAK",
    airportName: "Marrakesh Menara",
    cityCode: "RAK",
    cityName: "Marrakesh",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "RMF",
    airportName: "Marsa Alam International",
    cityCode: "RMF",
    cityName: "Marsa Alam",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "MRS",
    airportName: "Marseille Provence",
    cityCode: "MRS",
    cityName: "Marseille",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "MHH",
    airportName: "Marsh Harbour",
    cityCode: "MHH",
    cityName: "Marsh Harbour",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "MAJ",
    airportName: "Marshall Islands International",
    cityCode: "MAJ",
    cityName: "Majuro",
    countryCode: "MH",
    countryName: "Marshall Islands",
    continent: "Oceania",
  },
  {
    airportCode: "SLA",
    airportName: "Martin Miguel de Guemes International",
    cityCode: "SLA",
    cityName: "Salta",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "FDF",
    airportName: "Martinique Aime Cesaire International",
    cityCode: "FDF",
    cityName: "Fort-de-France",
    countryCode: "FR",
    countryName: "France",
    continent: "North America",
  },
  {
    airportCode: "MUR",
    airportName: "Marudi",
    cityCode: "MUR",
    cityName: "Marudi",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "MYP",
    airportName: "Mary International",
    cityCode: "MYP",
    cityName: "Mary",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "MHD",
    airportName: "Mashhad International",
    cityCode: "MHD",
    cityName: "Mashhad",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ZMT",
    airportName: "Masset",
    cityCode: "ZMT",
    cityName: "Masset",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MVT",
    airportName: "Mataiva",
    cityCode: "MVT",
    cityName: "Mataiva",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "MWK",
    airportName: "Matak Tarempa",
    cityCode: "MWK",
    cityName: "Matak",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MAM",
    airportName: "Matamoros International",
    cityCode: "MAM",
    cityName: "Matamoros",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "IRP",
    airportName: "Matari",
    cityCode: "IRP",
    cityName: "Isiro",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "IPC",
    airportName: "Mataveri International",
    cityCode: "IPC",
    cityName: "Easter Island",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "PEI",
    airportName: "Matecana International",
    cityCode: "PEI",
    cityName: "Pereira",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "TVU",
    airportName: "Matei",
    cityCode: "TVU",
    cityName: "Taveuni",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "MFK",
    airportName: "Matsu Beigan",
    cityCode: "MFK",
    cityName: "Matsu Islands",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "LZN",
    airportName: "Matsu Nangan",
    cityCode: "LZN",
    cityName: "Nangan, Lienchiang",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "MMJ",
    airportName: "Matsumoto",
    cityCode: "MMJ",
    cityName: "Matsumoto",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "MYJ",
    airportName: "Matsuyama",
    cityCode: "MYJ",
    cityName: "Matsuyama",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "HRI",
    airportName: "Mattala Rajapaksa International",
    cityCode: "HRI",
    cityName: "Hambantota",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "MUK",
    airportName: "Mauke Airport",
    cityCode: "MUK",
    cityName: "Mauke Island",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "MUB",
    airportName: "Maun",
    cityCode: "MUB",
    cityName: "Maun",
    countryCode: "BW",
    countryName: "Botswana",
    continent: "Africa",
  },
  {
    airportCode: "MAU",
    airportName: "Maupiti",
    cityCode: "MAU",
    cityName: "Maupiti",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "GND",
    airportName: "Maurice Bishop International",
    cityCode: "GND",
    cityName: "St George's",
    countryCode: "GD",
    countryName: "Grenada",
    continent: "North America",
  },
  {
    airportCode: "DIW",
    airportName: "Mawella Lagoon",
    cityCode: "DIW",
    cityName: "Dickwella",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "MNU",
    airportName: "Mawlamyine",
    cityCode: "MNU",
    cityName: "Mawlamyine",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "BZV",
    airportName: "Maya-Maya",
    cityCode: "BZV",
    cityName: "Brazzaville",
    countryCode: "CG",
    countryName: "Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "MYG",
    airportName: "Mayaguana",
    cityCode: "MYG",
    cityName: "Mayaguana",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "STD",
    airportName: "Mayor Buenaventura Vivas",
    cityCode: "STD",
    cityName: "Santo Domingo",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "CJA",
    airportName: "Mayor General FAP Armando Revoredo Iglesias",
    cityCode: "CJA",
    cityName: "Cajamarca",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "MZR",
    airportName: "Mazar-i-Sharif International",
    cityCode: "MZR",
    cityName: "Mazar-i-Sharif",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "MZT",
    airportName: "Mazatlan International",
    cityCode: "MZT",
    cityName: "Mazatlan",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MDK",
    airportName: "Mbandaka",
    cityCode: "MDK",
    cityName: "Mbandaka",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "MBI",
    airportName: "Mbeya",
    cityCode: "MBI",
    cityName: "Mbeya",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "MJM",
    airportName: "Mbuji Mayi",
    cityCode: "MJM",
    cityName: "Mbuji-Mayi",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "MCV",
    airportName: "McArthur River Mine",
    cityCode: "MCV",
    cityName: "McArthur River zinc mine",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YXH",
    airportName: "Medicine Hat",
    cityCode: "YXH",
    cityName: "Medicine Hat",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MEH",
    airportName: "Mehamn",
    cityCode: "MEH",
    cityName: "Mehamn",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "THR",
    airportName: "Mehrabad International",
    cityCode: "THR",
    cityName: "Tehran",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "MXZ",
    airportName: "Meixian",
    cityCode: "MXZ",
    cityName: "Meizhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MSU",
    airportName: "Mejametalana",
    cityCode: "MSU",
    cityName: "Maseru",
    countryCode: "LS",
    countryName: "Lesotho",
    continent: "Africa",
  },
  {
    airportCode: "MNA",
    airportName: "Melangguane",
    cityCode: "MNA",
    cityName: "Melonguane",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MEL",
    airportName: "Melbourne",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MLN",
    airportName: "Melilla",
    cityCode: "MLN",
    cityName: "Melilla",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "MMB",
    airportName: "Memanbetsu",
    cityCode: "MMB",
    cityName: "Memanbetsu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FMM",
    airportName: "Memmingen",
    cityCode: "FMM",
    cityName: "Memmingen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "MDU",
    airportName: "Mendi",
    cityCode: "MDU",
    cityName: "Mendi",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "SPP",
    airportName: "Menongue",
    cityCode: "SPP",
    cityName: "Menongue",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "MAH",
    airportName: "Menorca",
    cityCode: "MAH",
    cityName: "Menorca",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "MID",
    airportName: "Merida International",
    cityCode: "MID",
    cityName: "Merida",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MIM",
    airportName: "Merimbula",
    cityCode: "MIM",
    cityName: "Merimbula",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MUH",
    airportName: "Mersa Matruh Airport",
    cityCode: "MUH",
    cityName: "Mersa Matruh",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "ETZ",
    airportName: "Metz Nancy Lorraine",
    cityCode: "ETZ",
    cityName: "Nancy",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "MXL",
    airportName: "Mexicali International",
    cityCode: "MXL",
    cityName: "Mexicali",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MEX",
    airportName: "Mexico City International",
    cityCode: "MEX",
    cityName: "Mexico City",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MIG",
    airportName: "Mianyang Nanjiao",
    cityCode: "MIG",
    cityName: "Mianyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YGP",
    airportName: "Michel-Pouliot Gaspe",
    cityCode: "YGP",
    cityName: "Gaspe",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MMM",
    airportName: "Middlemount",
    cityCode: "MMM",
    cityName: "Middlemount",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KRP",
    airportName: "Midtjyllands",
    cityCode: "KRP",
    cityName: "Karup",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "CND",
    airportName: "Mihail Kogalniceanu International",
    cityCode: "CND",
    cityName: "Constanta",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YGJ",
    airportName: "Miho-Yonago",
    cityCode: "YGJ",
    cityName: "Yonago",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "BGY",
    airportName: "Milan Bergamo International Airport",
    cityCode: "MIL",
    cityName: "Milan",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "MXP",
    airportName: "Milan Malpensa",
    cityCode: "MIL",
    cityName: "Milan",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "BJV",
    airportName: "Milas Bodrum",
    cityCode: "BJV",
    cityName: "Bodrum",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "MQL",
    airportName: "Mildura",
    cityCode: "MQL",
    cityName: "Mildura",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MGT",
    airportName: "Milingimbi",
    cityCode: "MGT",
    cityName: "Milingimbi Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MLO",
    airportName: "Milos Island National",
    cityCode: "MLO",
    cityName: "Plaka, Milos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "PDG",
    airportName: "Minangkabau International",
    cityCode: "PDG",
    cityName: "Padang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MTT",
    airportName: "Minatitlan/Coatzacoalcos International",
    cityCode: "MTT",
    cityName: "Minatitlan",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MRV",
    airportName: "Mineralnye Vody",
    cityCode: "MRV",
    cityName: "Mineralnye Vody",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "EZE",
    airportName: "Ministro Pistarini International",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "MSQ",
    airportName: "Minsk National",
    cityCode: "MSQ",
    cityName: "Minsk",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "MHP",
    airportName: "Minsk-1",
    cityCode: "MSQ",
    cityName: "Minsk",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "MYY",
    airportName: "Miri",
    cityCode: "MYY",
    cityName: "Miri",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "MJZ",
    airportName: "Mirny",
    cityCode: "MJZ",
    cityName: "Mirny",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "MSJ",
    airportName: "Misawa",
    cityCode: "MSJ",
    cityName: "Misawa",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "MIS",
    airportName: "Misima Island",
    cityCode: "MIS",
    cityName: "Misima Island",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "MRA",
    airportName: "Misrata",
    cityCode: "MRA",
    cityName: "Misurata",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "MOI",
    airportName: "Mitiaro Island Airport",
    cityCode: "MOI",
    cityName: "Mitiaro Island",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "MJI",
    airportName: "Mitiga International",
    cityCode: "TIP",
    cityName: "Tripoli",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "MMY",
    airportName: "Miyako",
    cityCode: "MMY",
    cityName: "Miyakojima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "KMI",
    airportName: "Miyazaki",
    cityCode: "KMI",
    cityName: "Miyazaki",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "MQN",
    airportName: "Mo i Rana Airport, Røssvoll",
    cityCode: "MQN",
    cityName: "Mo i Rana",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "MHC",
    airportName: "Mocopulli",
    cityCode: "WCA",
    cityName: "Castro",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "MJD",
    airportName: "Moenjodaro",
    cityCode: "MJD",
    cityName: "Mohenjodaro",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "CZL",
    airportName: "Mohamed Boudiaf International",
    cityCode: "CZL",
    cityName: "Constantine",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "CMN",
    airportName: "Mohammed V International",
    cityCode: "CAS",
    cityName: "Casablanca",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "OHE",
    airportName: "Mohe Gulian",
    cityCode: "OHE",
    cityName: "Mohe County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MBA",
    airportName: "Moi International",
    cityCode: "MBA",
    cityName: "Mombasa",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "MBT",
    airportName: "Moises R. Espinosa",
    cityCode: "MBT",
    cityName: "Masbate City",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "MOL",
    airportName: "Molde Airport, Aro",
    cityCode: "MOL",
    cityName: "Molde",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "MQJ",
    airportName: "Moma",
    cityCode: "MQJ",
    cityName: "Khonuu",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "MAS",
    airportName: "Momote",
    cityCode: "MAS",
    cityName: "Manus Island",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "MCM",
    airportName: "Monaco Heliport",
    cityCode: "MCM",
    cityName: "Monte Carlo",
    countryCode: "MC",
    countryName: "Monaco",
    continent: "Europe",
  },
  {
    airportCode: "MIR",
    airportName: "Monastir Habib Bourguiba International",
    cityCode: "MIR",
    cityName: "Monastir",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "MBE",
    airportName: "Monbetsu",
    cityCode: "MBE",
    cityName: "Monbetsu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "LOV",
    airportName: "Monclova International",
    cityCode: "LOV",
    cityName: "Monclova",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MOG",
    airportName: "Monghsat",
    cityCode: "MOG",
    cityName: "Mong Hsat Township",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "GEM",
    airportName: "Mongomo Mongomeyen",
    cityCode: "GEM",
    cityName: "Mongomo",
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    continent: "Africa",
  },
  {
    airportCode: "SAL",
    airportName: "Monsenor Oscar Arnulfo Romero International",
    cityCode: "SAL",
    cityName: "San Salvador",
    countryCode: "SV",
    countryName: "El Salvador",
    continent: "North America",
  },
  {
    airportCode: "YTM",
    airportName: "Mont Tremblant International",
    cityCode: "YTM",
    cityName: "Mont-Tremblant",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YYY",
    airportName: "Mont-Joli",
    cityCode: "YYY",
    cityName: "Mont-Joli",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MTY",
    airportName: "Monterrey International",
    cityCode: "MTY",
    cityName: "Monterrey",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "MOC",
    airportName: "Montes Claros",
    cityCode: "MOC",
    cityName: "Montes Claros",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "MPL",
    airportName: "Montpellier Mediterranee",
    cityCode: "MPL",
    cityName: "Montpellier",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "YHU",
    airportName: "Montreal Saint-Hubert Longueuil",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YUL",
    airportName: "Montreal Pierre Elliott Trudeau International",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "NYW",
    airportName: "Monywa",
    cityCode: "NYW",
    cityName: "Monywa",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "MOZ",
    airportName: "Moorea",
    cityCode: "MOZ",
    cityName: "Moorea",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "YMO",
    airportName: "Moosonee",
    cityCode: "YMO",
    cityName: "Moosonee",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MKQ",
    airportName: "Mopah International",
    cityCode: "MKQ",
    cityName: "Merauke",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MXX",
    airportName: "Mora Siljan",
    cityCode: "MXX",
    cityName: "Mora",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "MOV",
    airportName: "Moranbah",
    cityCode: "MOV",
    cityName: "Moranbah",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MRZ",
    airportName: "Moree",
    cityCode: "MRZ",
    cityName: "Moree",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MLM",
    airportName: "Morelia International",
    cityCode: "MLM",
    cityName: "Morelia",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "ONG",
    airportName: "Mornington Island",
    cityCode: "ONG",
    cityName: "Mornington Island",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MXH",
    airportName: "Moro",
    cityCode: "MXH",
    cityName: "Iagifu Ridge Camp",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "MXV",
    airportName: "Moron",
    cityCode: "MXV",
    cityName: "Moron",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "MOQ",
    airportName: "Morondava",
    cityCode: "MOQ",
    cityName: "Morondava",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "MYA",
    airportName: "Moruya",
    cityCode: "MYA",
    cityName: "Moruya",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MJF",
    airportName: "Mosjoen Airport, Kjærstad",
    cityCode: "MJF",
    cityName: "Mosjoen",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "RYG",
    airportName: "Moss Airport, Rygge",
    cityCode: "OSL",
    cityName: "Oslo",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "OMO",
    airportName: "Mostar",
    cityCode: "OMO",
    cityName: "Mostar",
    countryCode: "BA",
    countryName: "Bosnia & Herzegovina",
    continent: "Europe",
  },
  {
    airportCode: "BLJ",
    airportName: "Mostepha Ben Boulaid",
    cityCode: "BLJ",
    cityName: "Batna",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "MTV",
    airportName: "Mota Lava",
    cityCode: "MTV",
    cityName: "Mota Lava",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "ERH",
    airportName: "Moulay Ali Cherif",
    cityCode: "ERH",
    cityName: "Errachidia",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "MGB",
    airportName: "Mount Gambier",
    cityCode: "MGB",
    cityName: "Mount Gambier",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "HGU",
    airportName: "Mount Hagen",
    cityCode: "HGU",
    cityName: "Mount Hagen",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "ISA",
    airportName: "Mount Isa",
    cityCode: "ISA",
    cityName: "Mount Isa",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "TIM",
    airportName: "Mozes Kilangin International",
    cityCode: "TIM",
    cityName: "Timika",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "UTT",
    airportName: "Mthatha",
    cityCode: "UTT",
    cityName: "Mthatha",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "MYW",
    airportName: "Mtwara",
    cityCode: "MYW",
    cityName: "Mtwara",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "MWX",
    airportName: "Muan International",
    cityCode: "MWX",
    cityName: "Muan County",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "MDG",
    airportName: "Mudanjiang Hailang International",
    cityCode: "MDG",
    cityName: "Mudanjiang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DGE",
    airportName: "Mudgee",
    cityCode: "DGE",
    cityName: "Mudgee",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "MKM",
    airportName: "Mukah",
    cityCode: "MKM",
    cityName: "Mukah",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "RIY",
    airportName: "Mukalla International Airport",
    cityCode: "RIY",
    cityName: "Riyan",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "MPP",
    airportName: "Mulatupo",
    cityCode: "MPP",
    cityName: "Mulatupo",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "MLH",
    airportName: "Mulhouse/Basel EuroAirport French FR",
    cityCode: "EAP",
    cityName: "Basel",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "MUX",
    airportName: "Multan International",
    cityCode: "MUX",
    cityName: "Multan",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "MZV",
    airportName: "Mulu",
    cityCode: "MZV",
    cityName: "Gunung Mulu National Park",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "MGU",
    airportName: "Munaung",
    cityCode: "MGU",
    cityName: "Manaung",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "MUA",
    airportName: "Munda",
    cityCode: "MUA",
    cityName: "Munda",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "FRS",
    airportName: "Mundo Maya International",
    cityCode: "FRS",
    cityName: "Flores",
    countryCode: "GT",
    countryName: "Guatemala",
    continent: "North America",
  },
  {
    airportCode: "MUC",
    airportName: "Munich",
    cityCode: "MUC",
    cityName: "Munich",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "FMO",
    airportName: "Munster Osnabruck International",
    cityCode: "FMO",
    cityName: "Munster",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "MJV",
    airportName: "Murcia San Javier",
    cityCode: "MJV",
    cityName: "Murcia",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "MMK",
    airportName: "Murmansk",
    cityCode: "MMK",
    cityName: "Murmansk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "LOS",
    airportName: "Murtala Muhammed International",
    cityCode: "LOS",
    cityName: "Lagos",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "MSR",
    airportName: "Mus",
    cityCode: "MSR",
    cityName: "Mus",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "MCT",
    airportName: "Muscat International",
    cityCode: "MCT",
    cityName: "Muscat",
    countryCode: "OM",
    countryName: "Oman",
    continent: "Asia",
  },
  {
    airportCode: "MUZ",
    airportName: "Musoma",
    cityCode: "MUZ",
    cityName: "Musoma",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "PLW",
    airportName: "Mutiara SIS Al-Jufrie",
    cityCode: "PLW",
    cityName: "Palu",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MWZ",
    airportName: "Mwanza",
    cityCode: "MWZ",
    cityName: "Mwanza",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "MGZ",
    airportName: "Myeik",
    cityCode: "MGZ",
    cityName: "Myeik, Myanmar",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "MYT",
    airportName: "Myitkyina",
    cityCode: "MYT",
    cityName: "Myitkyina",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "JMK",
    airportName: "Mykonos Island National",
    cityCode: "JMK",
    cityName: "Mykonos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "MJT",
    airportName: "Mytilene International",
    cityCode: "MJT",
    cityName: "Mytilene",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "NDJ",
    airportName: "N Djamena International",
    cityCode: "NDJ",
    cityName: "N Djamena",
    countryCode: "TD",
    countryName: "Chad",
    continent: "Africa",
  },
  {
    airportCode: "FIH",
    airportName: "N djili",
    cityCode: "FIH",
    cityName: "Kinshasa",
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "NBX",
    airportName: "Nabire",
    cityCode: "NBX",
    cityName: "Nabire Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "MNC",
    airportName: "Nacala",
    cityCode: "MNC",
    cityName: "Nacala",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "NAN",
    airportName: "Nadi International",
    cityCode: "NAN",
    cityName: "Nadi",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "NDR",
    airportName: "Nador International",
    cityCode: "NDR",
    cityName: "Nador",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "NYM",
    airportName: "Nadym",
    cityCode: "NYM",
    cityName: "Nadym",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "WNP",
    airportName: "Naga",
    cityCode: "WNP",
    cityName: "Naga, Camarines Sur",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "NGS",
    airportName: "Nagasaki",
    cityCode: "NGS",
    cityName: "Nagasaki",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NKM",
    airportName: "Nagoya Airfield",
    cityCode: "NGO",
    cityName: "Nagoya",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NAH",
    airportName: "Naha",
    cityCode: "NAH",
    cityName: "Naha",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "OKA",
    airportName: "Naha",
    cityCode: "OKA",
    cityName: "Okinawa Island",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "YDP",
    airportName: "Nain",
    cityCode: "YDP",
    cityName: "Nain",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SHB",
    airportName: "Nakashibetsu",
    cityCode: "SHB",
    cityName: "Nakashibetsu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NAJ",
    airportName: "Nakhchivan International",
    cityCode: "NAJ",
    cityName: "Nakhchivan",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "KOP",
    airportName: "Nakhon Phanom",
    cityCode: "KOP",
    cityName: "Nakhon Phanom Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "NST",
    airportName: "Nakhon Si Thammarat",
    cityCode: "NST",
    cityName: "Nakhon Si Thammarat Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "NAL",
    airportName: "Nalchik",
    cityCode: "NAL",
    cityName: "Nalchik",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "NMA",
    airportName: "Namangan",
    cityCode: "NMA",
    cityName: "Namangan",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "MSZ",
    airportName: "Namibe",
    cityCode: "MSZ",
    cityName: "Namibe",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "APL",
    airportName: "Nampula",
    cityCode: "APL",
    cityName: "Nampula",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "OSY",
    airportName: "Namsos Airport, Hoknesora",
    cityCode: "OSY",
    cityName: "Namsos",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "NNT",
    airportName: "Nan Nakhon",
    cityCode: "NNT",
    cityName: "Nan Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "YCD",
    airportName: "Nanaimo",
    cityCode: "YCD",
    cityName: "Nanaimo",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KHN",
    airportName: "Nanchang Changbei International",
    cityCode: "KHN",
    cityName: "Nanchang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NAO",
    airportName: "Nanchong Gaoping",
    cityCode: "NAO",
    cityName: "Nanchong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NKG",
    airportName: "Nanjing Lukou International",
    cityCode: "NKG",
    cityName: "Nanjing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SHM",
    airportName: "Nanki Shirahama",
    cityCode: "SHM",
    cityName: "Shirahama",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NNG",
    airportName: "Nanning Wuxu International",
    cityCode: "NNG",
    cityName: "Nanning",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NTE",
    airportName: "Nantes Atlantique",
    cityCode: "NTE",
    cityName: "Nantes",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "NTG",
    airportName: "Nantong Xingdong",
    cityCode: "NTG",
    cityName: "Nantong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NNY",
    airportName: "Nanyang Jiangying",
    cityCode: "NNY",
    cityName: "Nanyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NYK",
    airportName: "Nanyuki",
    cityCode: "NYK",
    cityName: "Nanyuki",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "NAP",
    airportName: "Naples International",
    cityCode: "NAP",
    cityName: "Naples",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "NAU",
    airportName: "Napuka",
    cityCode: "NAU",
    cityName: "Napuka",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "NAW",
    airportName: "Narathiwat",
    cityCode: "NAW",
    cityName: "Narathiwat Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "ASF",
    airportName: "Narimanovo",
    cityCode: "ASF",
    cityName: "Astrakhan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "NRT",
    airportName: "Narita International",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NRA",
    airportName: "Narrandera",
    cityCode: "NRA",
    cityName: "Narrandera",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "UAK",
    airportName: "Narsarsuaq",
    cityCode: "UAK",
    cityName: "Narsarsuaq",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "NVK",
    airportName: "Narvik Airport, Framnes",
    cityCode: "EVE",
    cityName: "Narvik",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "NNM",
    airportName: "Naryan-Mar",
    cityCode: "NNM",
    cityName: "Naryan-Mar",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "YNA",
    airportName: "Natashquan",
    cityCode: "YNA",
    cityName: "Natashquan",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YNP",
    airportName: "Natuashish",
    cityCode: "YNP",
    cityName: "Natuashish",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YUT",
    airportName: "Naujaat",
    cityCode: "YUT",
    cityName: "Naujaat",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SUV",
    airportName: "Nausori International",
    cityCode: "SUV",
    cityName: "Suva",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "NVT",
    airportName: "Navegantes",
    cityCode: "NVT",
    cityName: "Navegantes",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "NVI",
    airportName: "Navoi International",
    cityCode: "NVI",
    cityName: "Navoiy",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "JNX",
    airportName: "Naxos Island National",
    cityCode: "JNX",
    cityName: "Naxos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "NYT",
    airportName: "Naypyidaw",
    cityCode: "NYT",
    cityName: "Naypyidaw",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "IGT",
    airportName: "Nazran",
    cityCode: "IGT",
    cityName: "Nazran",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "VOL",
    airportName: "Nea Anchialos National",
    cityCode: "VOL",
    cityName: "Volos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "NSN",
    airportName: "Nelson",
    cityCode: "NSN",
    cityName: "Nelson",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "RAI",
    airportName: "Nelson Mandela International",
    cityCode: "RAI",
    cityName: "Praia",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "YNS",
    airportName: "Nemiscau",
    cityCode: "YNS",
    cityName: "Nemaska",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KEP",
    airportName: "Nepalgunj",
    cityCode: "KEP",
    cityName: "Nepalgunj",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "NAV",
    airportName: "Nevsehir Kapadokya",
    cityCode: "NAV",
    cityName: "Nevsehir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "TBI",
    airportName: "New Bight",
    cityCode: "TBI",
    cityName: "The Bight",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "CTS",
    airportName: "New Chitose",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NPL",
    airportName: "New Plymouth",
    cityCode: "NPL",
    cityName: "New Plymouth",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "TNE",
    airportName: "New Tanegashima",
    cityCode: "TNE",
    cityName: "Tanegashima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "NTL",
    airportName: "Newcastle",
    cityCode: "NTL",
    cityName: "City of Newcastle",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ZNE",
    airportName: "Newman",
    cityCode: "ZNE",
    cityName: "Newman",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "NGL",
    airportName: "Ngala Airport",
    cityCode: "NGL",
    cityName: "Ngala",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "NGE",
    airportName: "Ngaoundere",
    cityCode: "NGE",
    cityName: "Ngaoundere",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "NGQ",
    airportName: "Ngari Gunsa",
    cityCode: "NGQ",
    cityName: "Ngari Prefecture",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DPS",
    airportName: "Ngurah Rai International",
    cityCode: "DPS",
    cityName: "Denpasar",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "NIU",
    airportName: "Niau",
    cityCode: "NIU",
    cityName: "Niau",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "NCE",
    airportName: "Nice Cote d Azur International",
    cityCode: "NCE",
    cityName: "Nice",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "KIJ",
    airportName: "Niigata",
    cityCode: "KIJ",
    cityName: "Niigata",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FNI",
    airportName: "Nimes Ales Camargue Cevennes",
    cityCode: "FNI",
    cityName: "Nimes",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "NGB",
    airportName: "Ningbo Lishe International",
    cityCode: "NGB",
    cityName: "Ningbo",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NLH",
    airportName: "Ninglang Luguhu",
    cityCode: "NLH",
    cityName: "Ninglang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "MNL",
    airportName: "Ninoy Aquino International",
    cityCode: "MNL",
    cityName: "Manila",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "INI",
    airportName: "Nis Constantine the Great",
    cityCode: "INI",
    cityName: "Nis",
    countryCode: "RS",
    countryName: "Serbia",
    continent: "Europe",
  },
  {
    airportCode: "IUE",
    airportName: "Niue International",
    cityCode: "IUE",
    cityName: "Alofi",
    countryCode: "NU",
    countryName: "Niue",
    continent: "Oceania",
  },
  {
    airportCode: "NJC",
    airportName: "Nizhnevartovsk",
    cityCode: "NJC",
    cityName: "Nizhnevartovsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "JOM",
    airportName: "Njombe Airport",
    cityCode: "null",
    cityName: "Njombe",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "ABV",
    airportName: "Nnamdi Azikiwe International",
    cityCode: "ABV",
    cityName: "Abuja",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "NGK",
    airportName: "Nogliki",
    cityCode: "NGK",
    cityName: "Nogliki",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "HAN",
    airportName: "Noi Bai International",
    cityCode: "HAN",
    cityName: "Hanoi",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "DEX",
    airportName: "Nop Goliat Dekai",
    cityCode: "DEX",
    cityName: "Yahukimo Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "NLK",
    airportName: "Norfolk Island",
    cityCode: "NLK",
    cityName: "Burnt Pine",
    countryCode: "NF",
    countryName: "Norfolk Island",
    continent: "Oceania",
  },
  {
    airportCode: "KIN",
    airportName: "Norman Manley International",
    cityCode: "KIN",
    cityName: "Kingston",
    countryCode: "JM",
    countryName: "Jamaica",
    continent: "North America",
  },
  {
    airportCode: "YVQ",
    airportName: "Norman Wells",
    cityCode: "YVQ",
    cityName: "Norman Wells",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "NTN",
    airportName: "Normanton",
    cityCode: "NTN",
    cityName: "Normanton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "NRK",
    airportName: "Norrkoping",
    cityCode: "NRK",
    cityName: "Norrkoping",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "NUS",
    airportName: "Norsup",
    cityCode: "NUS",
    cityName: "Norsup",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "YYB",
    airportName: "North Bay/Jack Garland",
    cityCode: "YYB",
    cityName: "North Bay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ELH",
    airportName: "North Eleuthera",
    cityCode: "ELH",
    cityName: "North Eleuthera",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "YNO",
    airportName: "North Spirit Lake",
    cityCode: "YNO",
    cityName: "North Spirit Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ABM",
    airportName: "Northern Peninsula",
    cityCode: "ABM",
    cityName: "Bamaga",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YXT",
    airportName: "Northwest Regional Airport Terrace-Kitimat",
    cityCode: "YXT",
    cityName: "Terrace",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YNE",
    airportName: "Norway House",
    cityCode: "YNE",
    cityName: "Norway House",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "NOB",
    airportName: "Nosara",
    cityCode: "NOB",
    cityName: "Nosara",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "NSH",
    airportName: "Noshahr",
    cityCode: "NSH",
    cityName: "Nowshahr",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "NTQ",
    airportName: "Noto",
    cityCode: "NTQ",
    cityName: "Wajima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "JBB",
    airportName: "Notohadinegoro",
    cityCode: "JBB",
    cityName: "Jember Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "NDB",
    airportName: "Nouadhibou International",
    cityCode: "NDB",
    cityName: "Nouadhibou",
    countryCode: "MR",
    countryName: "Mauritania",
    continent: "Africa",
  },
  {
    airportCode: "NKC",
    airportName: "Nouakchott International",
    cityCode: "NKC",
    cityName: "Nouakchott",
    countryCode: "MR",
    countryName: "Mauritania",
    continent: "Africa",
  },
  {
    airportCode: "GEA",
    airportName: "Noumea Magenta",
    cityCode: "NOU",
    cityName: "Noumea",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "GHA",
    airportName: "Noumerat Moufdi Zakaria",
    cityCode: "GHA",
    cityName: "Ghardaia",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "NUX",
    airportName: "Novy Urengoy",
    cityCode: "NUX",
    cityName: "Novy Urengoy",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "NOJ",
    airportName: "Noyabrsk",
    cityCode: "NOJ",
    cityName: "Noyabrsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "NLD",
    airportName: "Nuevo Laredo International",
    cityCode: "NLD",
    cityName: "Nuevo Laredo",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "NHV",
    airportName: "Nuku Hiva",
    cityCode: "NHV",
    cityName: "Nuku Hiva",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "NCU",
    airportName: "Nukus",
    cityCode: "NCU",
    cityName: "Nukus",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "NNX",
    airportName: "Nunukan",
    cityCode: "NNX",
    cityName: "Nunukan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "NUE",
    airportName: "Nuremberg",
    cityCode: "NUE",
    cityName: "Nuremberg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "GZO",
    airportName: "Nusatupe",
    cityCode: "GZO",
    cityName: "Gizo",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "GOH",
    airportName: "Nuuk",
    cityCode: "GOH",
    cityName: "Nuuk",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "NYA",
    airportName: "Nyagan",
    cityCode: "NYA",
    cityName: "Nyagan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UYL",
    airportName: "Nyala",
    cityCode: "UYL",
    cityName: "Nyala",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "NYU",
    airportName: "Nyaung U",
    cityCode: "NYU",
    cityName: "Bagan",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "LZY",
    airportName: "Nyingchi Mainling",
    cityCode: "LZY",
    cityName: "Nyingchi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NYR",
    airportName: "Nyurba",
    cityCode: "NYR",
    cityName: "Nyurba",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "JNB",
    airportName: "O. R. Tambo International",
    cityCode: "JNB",
    cityName: "Johannesburg",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "OAX",
    airportName: "Oaxaca International",
    cityCode: "OAX",
    cityName: "Oaxaca",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "ONJ",
    airportName: "Odate Noshiro",
    cityCode: "ONJ",
    cityName: "Odate",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ODS",
    airportName: "Odessa International",
    cityCode: "ODS",
    cityName: "Odessa",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "KEO",
    airportName: "Odienne",
    cityCode: "KEO",
    cityName: "Odienne",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "LAH",
    airportName: "Oesman Sadik",
    cityCode: "LAH",
    cityName: "Labuha",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "OGL",
    airportName: "Ogle",
    cityCode: "GEO",
    cityName: "Georgetown",
    countryCode: "GY",
    countryName: "Guyana",
    continent: "South America",
  },
  {
    airportCode: "GZT",
    airportName: "Oguzeli",
    cityCode: "GZT",
    cityName: "Gaziantep",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "OHD",
    airportName: "Ohrid St. Paul the Apostle ",
    cityCode: "OHD",
    cityName: "Ohrid",
    countryCode: "MK",
    countryName: "Macedonia (FYROM)",
    continent: "Europe",
  },
  {
    airportCode: "OIT",
    airportName: "Oita",
    cityCode: "OIT",
    cityName: "Oita",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "OKD",
    airportName: "Okadama",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "OKJ",
    airportName: "Okayama",
    cityCode: "OKJ",
    cityName: "Okayama",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "OHH",
    airportName: "Okha",
    cityCode: "OHH",
    cityName: "Okha",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "OHO",
    airportName: "Okhotsk",
    cityCode: "OHO",
    cityName: "Okhotsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "OKI",
    airportName: "Oki",
    cityCode: "OKI",
    cityName: "Oki Islands",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "OKL",
    airportName: "Oksibil",
    cityCode: "OKL",
    cityName: "Oksibil",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "OIR",
    airportName: "Okushiri",
    cityCode: "OIR",
    cityName: "Okushiri",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "EOH",
    airportName: "Olaya Herrera",
    cityCode: "MDE",
    cityName: "Medellin",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "OLB",
    airportName: "Olbia Costa Smeralda",
    cityCode: "OLB",
    cityName: "Olbia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "YOC",
    airportName: "Old Crow",
    cityCode: "YOC",
    cityName: "Old Crow, Yukon",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ONK",
    airportName: "Olenyok",
    cityCode: "ONK",
    cityName: "Olenyok",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ULG",
    airportName: "Olgii",
    cityCode: "ULG",
    cityName: "Olgii",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "SZY",
    airportName: "Olsztyn-Mazury",
    cityCode: "SZY",
    cityName: "Szymany, Szczytno County",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "OLZ",
    airportName: "Olyokminsk",
    cityCode: "OLZ",
    cityName: "Olyokminsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "OMS",
    airportName: "Omsk Tsentralny",
    cityCode: "OMS",
    cityName: "Omsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "VPE",
    airportName: "Ondjiva Pereira",
    cityCode: "VPE",
    cityName: "Ondjiva",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "ONS",
    airportName: "Onslow",
    cityCode: "ONS",
    cityName: "Onslow",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "OMR",
    airportName: "Oradea International",
    cityCode: "OMR",
    cityName: "Oradea",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "URA",
    airportName: "Oral Ak Zhol",
    cityCode: "URA",
    cityName: "Oral",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "ORN",
    airportName: "Oran Es Senia",
    cityCode: "ORN",
    cityName: "Oran",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "OAG",
    airportName: "Orange",
    cityCode: "OAG",
    cityName: "Orange",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ORZ",
    airportName: "Orange Walk",
    cityCode: "ORZ",
    cityName: "Orange Walk District",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "OMD",
    airportName: "Oranjemund",
    cityCode: "OMD",
    cityName: "Oranjemund",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "DSN",
    airportName: "Ordos Ejin Horo",
    cityCode: "DSN",
    cityName: "Ordos City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "OGU",
    airportName: "Ordu Giresun",
    cityCode: "OGU",
    cityName: "Giresun",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ORB",
    airportName: "Orebro",
    cityCode: "ORB",
    cityName: "Orebro",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "REN",
    airportName: "Orenburg Tsentralny",
    cityCode: "REN",
    cityName: "Orenburg",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "MOA",
    airportName: "Orestes Acosta",
    cityCode: "MOA",
    cityName: "Moa",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "OLA",
    airportName: "Orland Main Air Station",
    cityCode: "OLA",
    cityName: "Orland",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "OMC",
    airportName: "Ormoc",
    cityCode: "OMC",
    cityName: "Ormoc",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "OER",
    airportName: "Ornskoldsvik",
    cityCode: "OER",
    cityName: "Ornskoldsvik",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "OSW",
    airportName: "Orsk",
    cityCode: "OSW",
    cityName: "Orsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "HOV",
    airportName: "Orsta Volda Airport, Hovden",
    cityCode: "HOV",
    cityName: "Volda",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "OSS",
    airportName: "Osh",
    cityCode: "OSS",
    cityName: "Osh",
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    continent: "Asia",
  },
  {
    airportCode: "OSI",
    airportName: "Osijek",
    cityCode: "OSI",
    cityName: "Osijek",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "UKK",
    airportName: "Oskemen",
    cityCode: "UKK",
    cityName: "Oskemen",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "OSL",
    airportName: "Oslo Airport, Gardermoen",
    cityCode: "OSL",
    cityName: "Oslo",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "ZYL",
    airportName: "Osmani International",
    cityCode: "ZYL",
    cityName: "Sylhet",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "OST",
    airportName: "Ostend-Bruges International",
    cityCode: "OST",
    cityName: "Ostend",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "OXB",
    airportName: "Osvaldo Vieira International",
    cityCode: "OXB",
    cityName: "Bissau",
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    continent: "Africa",
  },
  {
    airportCode: "YOW",
    airportName: "Ottawa Macdonald Cartier International",
    cityCode: "YOW",
    cityName: "Ottawa",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "OUA",
    airportName: "Ouagadougou",
    cityCode: "OUA",
    cityName: "Ouagadougou",
    countryCode: "BF",
    countryName: "Burkina Faso",
    continent: "Africa",
  },
  {
    airportCode: "LIF",
    airportName: "Ouanaham",
    cityCode: "LIF",
    cityName: "Lifou Island",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "OFI",
    airportName: "Ouango Fitini",
    cityCode: "OFI",
    cityName: "Ouango-Fitini",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "AJN",
    airportName: "Ouani",
    cityCode: "AJN",
    cityName: "Anjouan",
    countryCode: "KM",
    countryName: "Comoros",
    continent: "Africa",
  },
  {
    airportCode: "OZZ",
    airportName: "Ouarzazate",
    cityCode: "OZZ",
    cityName: "Ouarzazate",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "ODY",
    airportName: "Oudomsay",
    cityCode: "ODY",
    cityName: "Muang Xay",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "HME",
    airportName: "Oued Irara Krim Belkacem",
    cityCode: "HME",
    cityName: "Hassi Messaoud",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "OUL",
    airportName: "Oulu",
    cityCode: "OUL",
    cityName: "Oulu",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "UVE",
    airportName: "Ouvea",
    cityCode: "UVE",
    cityName: "Ouvea Island",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "VDA",
    airportName: "Ovda",
    cityCode: "VDA",
    cityName: "Ovda",
    countryCode: "IL",
    countryName: "Israel",
    continent: "Asia",
  },
  {
    airportCode: "GCM",
    airportName: "Owen Roberts International",
    cityCode: "GCM",
    cityName: "Grand Cayman",
    countryCode: "KY",
    countryName: "Cayman Islands",
    continent: "North America",
  },
  {
    airportCode: "YOH",
    airportName: "Oxford House",
    cityCode: "YOH",
    cityName: "Oxford House, Manitoba",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PBJ",
    airportName: "Paama",
    cityCode: "PBJ",
    cityName: "Paama",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "JFR",
    airportName: "Paamiut",
    cityCode: "JFR",
    cityName: "Paamiut",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "PAD",
    airportName: "Paderborn Lippstadt",
    cityCode: "PAD",
    cityName: "Paderborn",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "PEM",
    airportName: "Padre Aldamiz International",
    cityCode: "PEM",
    cityName: "Puerto Maldonado",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "PAG",
    airportName: "Pagadian",
    cityCode: "PAG",
    cityName: "Pagadian",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "PYY",
    airportName: "Pai",
    cityCode: "PYY",
    cityName: "Pai",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "PKZ",
    airportName: "Pakse International",
    cityCode: "PKZ",
    cityName: "Pakse",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "PLQ",
    airportName: "Palanga International",
    cityCode: "PLQ",
    cityName: "Palanga",
    countryCode: "LT",
    countryName: "Lithuania",
    continent: "Europe",
  },
  {
    airportCode: "PQM",
    airportName: "Palenque International",
    cityCode: "PQM",
    cityName: "Palenque",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "PMI",
    airportName: "Palma de Mallorca",
    cityCode: "PMI",
    cityName: "Palma, Majorca",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "PMZ",
    airportName: "Palmar Sur",
    cityCode: "PMZ",
    cityName: "Palmar Sur",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "PMW",
    airportName: "Palmas",
    cityCode: "PMW",
    cityName: "Palmas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PMR",
    airportName: "Palmerston North",
    cityCode: "PMR",
    cityName: "Palmerston North",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "BGA",
    airportName: "Palonegro International",
    cityCode: "BGA",
    cityName: "Bucaramanga",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "LLO",
    airportName: "Palopo Lagaligo",
    cityCode: "LLO",
    cityName: "Palopo",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PNA",
    airportName: "Pamplona",
    cityCode: "PNA",
    cityName: "Pamplona",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "YXP",
    airportName: "Pangnirtung",
    cityCode: "YXP",
    cityName: "Pangnirtung",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PSU",
    airportName: "Pangsuma",
    cityCode: "PSU",
    cityName: "Putussibau",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PJG",
    airportName: "Panjgur Airport",
    cityCode: "PJG",
    cityName: "Panjgur",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "PNL",
    airportName: "Pantelleria",
    cityCode: "PNL",
    cityName: "Pantelleria",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "PZI",
    airportName: "Panzhihua Bao anying",
    cityCode: "PZI",
    cityName: "Panzhihua",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PFO",
    airportName: "Paphos International",
    cityCode: "PFO",
    cityName: "Paphos",
    countryCode: "CY",
    countryName: "Cyprus",
    continent: "Asia",
  },
  {
    airportCode: "PBO",
    airportName: "Paraburdoo",
    cityCode: "PBO",
    cityName: "Paraburdoo",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PRS",
    airportName: "Parasi Airport",
    cityCode: "null",
    cityName: "Parasi",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "PED",
    airportName: "Pardubice",
    cityCode: "PED",
    cityName: "Pardubice",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "PIN",
    airportName: "Parintins",
    cityCode: "PIN",
    cityName: "Parintins",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ORY",
    airportName: "Paris Orly",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LBG",
    airportName: "Paris Le Bourget",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PKE",
    airportName: "Parkes",
    cityCode: "PKE",
    cityName: "Parkes",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PMF",
    airportName: "Parma",
    cityCode: "PMF",
    cityName: "Parma",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "PHB",
    airportName: "Parnaiba-Prefeito Dr. Joao Silva Filho International",
    cityCode: "PHB",
    cityName: "Parnaiba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PBH",
    airportName: "Paro",
    cityCode: "PBH",
    cityName: "Paro",
    countryCode: "BT",
    countryName: "Bhutan",
    continent: "Asia",
  },
  {
    airportCode: "PAS",
    airportName: "Paros National",
    cityCode: "PAS",
    cityName: "Parikia",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "PFQ",
    airportName: "Parsabade Moghan Airport",
    cityCode: "null",
    cityName: "Parsabad",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "AMQ",
    airportName: "Pattimura",
    cityCode: "AMQ",
    cityName: "Ambon, Maluku",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PUF",
    airportName: "Pau Pyrenees",
    cityCode: "PUF",
    cityName: "Pau, Pyrenees-Atlantiques",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PAV",
    airportName: "Paulo Afonso",
    cityCode: "PAV",
    cityName: "Paulo Afonso",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PWQ",
    airportName: "Pavlodar",
    cityCode: "PWQ",
    cityName: "Pavlodar",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "YPO",
    airportName: "Peawanuck",
    cityCode: "YPO",
    cityName: "Peawanuck",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PEX",
    airportName: "Pechora",
    cityCode: "PEX",
    cityName: "Pechora",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "PDM",
    airportName: "Pedasi",
    cityCode: "PDM",
    cityName: "Pedasi",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "PET",
    airportName: "Pelotas International",
    cityCode: "PET",
    cityName: "Pelotas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PMA",
    airportName: "Pemba",
    cityCode: "PMA",
    cityName: "Pemba Island",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "POL",
    airportName: "Pemba",
    cityCode: "POL",
    cityName: "Pemba",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "PEN",
    airportName: "Penang International",
    cityCode: "PEN",
    cityName: "Penang",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "YYF",
    airportName: "Penticton Regional",
    cityCode: "YYF",
    cityName: "Penticton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PEZ",
    airportName: "Penza",
    cityCode: "PEZ",
    cityName: "Penza",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "IBE",
    airportName: "Perales",
    cityCode: "IBE",
    cityName: "Ibague",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "PGX",
    airportName: "Perigueux Bassillac",
    cityCode: "PGX",
    cityName: "Perigueux",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PEE",
    airportName: "Perm International",
    cityCode: "PEE",
    cityName: "Perm",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "PGF",
    airportName: "Perpignan Rivesaltes",
    cityCode: "PGF",
    cityName: "Perpignan",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PGU",
    airportName: "Persian Gulf",
    cityCode: "PGU",
    cityName: "Asaluyeh",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "PER",
    airportName: "Perth",
    cityCode: "PER",
    cityName: "Perth",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PNZ",
    airportName: "Petrolina",
    cityCode: "PNZ",
    cityName: "Petrolina",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PPK",
    airportName: "Petropavl",
    cityCode: "PPK",
    cityName: "Petropavl",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "PKC",
    airportName: "Petropavlovsk-Kamchatsky",
    cityCode: "PKC",
    cityName: "Petropavlovsk-Kamchatsky",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "PES",
    airportName: "Petrozavodsk",
    cityCode: "PES",
    cityName: "Petrozavodsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "PPL",
    airportName: "Phaplu Airport",
    cityCode: "null",
    cityName: "Phaplu",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "BZE",
    airportName: "Philip S. W. Goldson International",
    cityCode: "BZE",
    cityName: "Belize City",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "PZL",
    airportName: "Phinda Zulu Inyala",
    cityCode: "PZL",
    cityName: "Phinda Private Game Reserve",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "PHS",
    airportName: "Phitsanulok",
    cityCode: "PHS",
    cityName: "Phitsanulok",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "PNH",
    airportName: "Phnom Penh International",
    cityCode: "PNH",
    cityName: "Phnom Penh",
    countryCode: "KH",
    countryName: "Cambodia",
    continent: "Asia",
  },
  {
    airportCode: "PRH",
    airportName: "Phrae",
    cityCode: "PRH",
    cityName: "Phrae Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "HUI",
    airportName: "Phu Bai International",
    cityCode: "HUI",
    cityName: "Hue",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "UIH",
    airportName: "Phu Cat",
    cityCode: "UIH",
    cityName: "Qui Nhon",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "PQC",
    airportName: "Phu Quoc International Airport",
    cityCode: "PQC",
    cityName: "Phu Quoc",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "HKT",
    airportName: "Phuket International",
    cityCode: "HKT",
    cityName: "Phuket City",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "POS",
    airportName: "Piarco International",
    cityCode: "POS",
    cityName: "Port of Spain",
    countryCode: "TT",
    countryName: "Trinidad & Tobago",
    continent: "North America",
  },
  {
    airportCode: "PIX",
    airportName: "Pico",
    cityCode: "PIX",
    cityName: "Pico Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "PCN",
    airportName: "Picton Aerodrome",
    cityCode: "PCN",
    cityName: "Picton",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "PDS",
    airportName: "Piedras Negras International",
    cityCode: "PDS",
    cityName: "Piedras Negras",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "ZSE",
    airportName: "Pierrefonds",
    cityCode: "ZSE",
    cityName: "Saint-Pierre",
    countryCode: "FR",
    countryName: "France",
    continent: "Africa",
  },
  {
    airportCode: "PZB",
    airportName: "Pietermaritzburg",
    cityCode: "PZB",
    cityName: "Pietermaritzburg",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "YPM",
    airportName: "Pikangikum",
    cityCode: "YPM",
    cityName: "Pikangikum First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "NTY",
    airportName: "Pilanesberg International",
    cityCode: "NTY",
    cityName: "Sun City",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "RGL",
    airportName: "Piloto Civil Norberto Fernandez International",
    cityCode: "RGL",
    cityName: "Rio Gallegos",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "DUM",
    airportName: "Pinang Kampai",
    cityCode: "DUM",
    cityName: "Dumai",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "FOR",
    airportName: "Pinto Martins Fortaleza International",
    cityCode: "FOR",
    cityName: "Fortaleza",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PSA",
    airportName: "Pisa International",
    cityCode: "PSA",
    cityName: "Pisa",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "PTX",
    airportName: "Pitalito",
    cityCode: "PTX",
    cityName: "Pitalito",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "PLJ",
    airportName: "Placencia",
    cityCode: "PLJ",
    cityName: "Placencia",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "SLW",
    airportName: "Plan de Guadalupe International",
    cityCode: "SLW",
    cityName: "Saltillo",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TLY",
    airportName: "Plastun",
    cityCode: "TLY",
    cityName: "Plastun",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ROV",
    airportName: "Platov International Airport",
    cityCode: "ROV",
    cityName: "Rostov-on-Don",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "ZLO",
    airportName: "Playa de Oro International",
    cityCode: "ZLO",
    cityName: "Manzanillo",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "PYC",
    airportName: "Playon Chico",
    cityCode: "PYC",
    cityName: "Ukupseni",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "PXU",
    airportName: "Pleiku",
    cityCode: "PXU",
    cityName: "Pleiku",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "PBZ",
    airportName: "Plettenberg Bay",
    cityCode: "PBZ",
    cityName: "Plettenberg Bay",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "PDV",
    airportName: "Plovdiv",
    cityCode: "PDV",
    cityName: "Plovdiv",
    countryCode: "BG",
    countryName: "Bulgaria",
    continent: "Europe",
  },
  {
    airportCode: "KVX",
    airportName: "Pobedilovo",
    cityCode: "KVX",
    cityName: "Kirov",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "TGD",
    airportName: "Podgorica",
    cityCode: "TGD",
    cityName: "Podgorica",
    countryCode: "ME",
    countryName: "Montenegro",
    continent: "Europe",
  },
  {
    airportCode: "TGP",
    airportName: "Podkamennaya Tunguska",
    cityCode: "TGP",
    cityName: "Podkamennaya Tunguska",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UOL",
    airportName: "Pogogul",
    cityCode: "UOL",
    cityName: "Buol",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KPO",
    airportName: "Pohang",
    cityCode: "KPO",
    cityName: "Pohang",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "PNI",
    airportName: "Pohnpei International",
    cityCode: "PNI",
    cityName: "Pohnpei",
    countryCode: "FM",
    countryName: "Micronesia",
    continent: "Oceania",
  },
  {
    airportCode: "PNR",
    airportName: "Pointe Noire",
    cityCode: "PNR",
    cityName: "Pointe-Noire",
    countryCode: "CG",
    countryName: "Republic of the Congo",
    continent: "Africa",
  },
  {
    airportCode: "FUT",
    airportName: "Pointe Vele",
    cityCode: "FUT",
    cityName: "Futuna",
    countryCode: "WF",
    countryName: "Wallis & Futuna",
    continent: "Oceania",
  },
  {
    airportCode: "PTP",
    airportName: "Pointe-a-Pitre International",
    cityCode: "PTP",
    cityName: "Pointe-a-Pitre",
    countryCode: "FR",
    countryName: "France",
    continent: "North America",
  },
  {
    airportCode: "PIS",
    airportName: "Poitiers Biard",
    cityCode: "PIS",
    cityName: "Poitiers",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PKR",
    airportName: "Pokhara",
    cityCode: "PKR",
    cityName: "Pokhara",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "PTG",
    airportName: "Polokwane International",
    cityCode: "PTG",
    cityName: "Polokwane, Limpopo",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "PYJ",
    airportName: "Polyarny",
    cityCode: "PYJ",
    cityName: "Udachny",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "YIO",
    airportName: "Pond Inlet",
    cityCode: "YIO",
    cityName: "Pond Inlet",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PGZ",
    airportName: "Ponta Grossa A.Amilton Beraldo",
    cityCode: "PGZ",
    cityName: "Ponta Grossa",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TAT",
    airportName: "Poprad Tatry",
    cityCode: "TAT",
    cityName: "Poprad",
    countryCode: "SK",
    countryName: "Slovakia",
    continent: "Europe",
  },
  {
    airportCode: "POR",
    airportName: "Pori",
    cityCode: "POR",
    cityName: "Pori",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "PUG",
    airportName: "Port Augusta",
    cityCode: "PUG",
    cityName: "Port Augusta",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ABJ",
    airportName: "Port Bouet",
    cityCode: "ABJ",
    cityName: "Abidjan",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "PLZ",
    airportName: "Port Elizabeth",
    cityCode: "PLZ",
    cityName: "Port Elizabeth",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "PHC",
    airportName: "Port Harcourt International",
    cityCode: "PHC",
    cityName: "Port Harcourt",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "YZT",
    airportName: "Port Hardy",
    cityCode: "YZT",
    cityName: "Port Hardy",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "PHE",
    airportName: "Port Hedland International",
    cityCode: "PHE",
    cityName: "Port Hedland",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PLO",
    airportName: "Port Lincoln",
    cityCode: "PLO",
    cityName: "Port Lincoln",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PQQ",
    airportName: "Port Macquarie",
    cityCode: "PQQ",
    cityName: "Port Macquarie",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "RCY",
    airportName: "Port Nelson",
    cityCode: "RCY",
    cityName: "Rum Cay District",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "PZU",
    airportName: "Port Sudan New International",
    cityCode: "PZU",
    cityName: "Port",
    countryCode: "SD",
    countryName: "Sudan",
    continent: "Africa",
  },
  {
    airportCode: "POG",
    airportName: "Port-Gentil International",
    cityCode: "POG",
    cityName: "Port-Gentil",
    countryCode: "GA",
    countryName: "Gabon",
    continent: "Africa",
  },
  {
    airportCode: "PTJ",
    airportName: "Portland",
    cityCode: "PTJ",
    cityName: "Portland",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "OPO",
    airportName: "Porto",
    cityCode: "OPO",
    cityName: "Porto",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "PXO",
    airportName: "Porto Santo",
    cityCode: "PXO",
    cityName: "Vila Baleira",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "BPS",
    airportName: "Porto Seguro",
    cityCode: "BPS",
    cityName: "Porto Seguro",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "PSJ",
    airportName: "Poso Kasinguncu",
    cityCode: "PSJ",
    cityName: "Poso",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "YSO",
    airportName: "Postville",
    cityCode: "YSO",
    cityName: "Postville",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YPW",
    airportName: "Powell River",
    cityCode: "YPW",
    cityName: "Powell River, British Columbia",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "POZ",
    airportName: "Poznan Lawica",
    cityCode: "POZ",
    cityName: "Poznan",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "PRI",
    airportName: "Praslin Island",
    cityCode: "PRI",
    cityName: "Praslin",
    countryCode: "SC",
    countryName: "Seychelles",
    continent: "Africa",
  },
  {
    airportCode: "SNE",
    airportName: "Preguica",
    cityCode: "SNE",
    cityName: "Sao Nicolau",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "PUQ",
    airportName: "Presidente Carlos Ibanez del Campo International",
    cityCode: "PUQ",
    cityName: "Punta Arenas",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "JPA",
    airportName: "Presidente Castro Pinto International",
    cityCode: "JPA",
    cityName: "Joao Pessoa, Paraiba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "DIL",
    airportName: "Presidente Nicolau Lobato International",
    cityCode: "DIL",
    cityName: "Dili",
    countryCode: "TL",
    countryName: "Timor-Leste",
    continent: "Asia",
  },
  {
    airportCode: "NQN",
    airportName: "Presidente Peron International",
    cityCode: "NQN",
    cityName: "Neuquen",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "PPB",
    airportName: "Presidente Prudente",
    cityCode: "PPB",
    cityName: "Presidente Prudente, Sao Paulo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ULH",
    airportName: "Prince Abdul Majeed bin Abdulaziz Domestic",
    cityCode: "ULH",
    cityName: "Al-`Ula",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "YXS",
    airportName: "Prince George",
    cityCode: "YXS",
    cityName: "Prince George",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ULD",
    airportName: "Prince Mangosuthu Buthelezi Airport",
    cityCode: "ULD",
    cityName: "Ulundi",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "MED",
    airportName: "Prince Mohammad bin Abdulaziz",
    cityCode: "MED",
    cityName: "Medina",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "ELQ",
    airportName: "Prince Nayef bin Abdulaziz Regional",
    cityCode: "ELQ",
    cityName: "Al-Qassim Region",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "YPR",
    airportName: "Prince Rupert",
    cityCode: "YPR",
    cityName: "Prince Rupert",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "HAH",
    airportName: "Prince Said Ibrahim International",
    cityCode: "YVA",
    cityName: "Moroni",
    countryCode: "KM",
    countryName: "Comoros",
    continent: "Africa",
  },
  {
    airportCode: "SXM",
    airportName: "Princess Juliana International",
    cityCode: "SXM",
    cityName: "Lower Prince's Quarter",
    countryCode: "SX",
    countryName: "Sint Maarten",
    continent: "North America",
  },
  {
    airportCode: "PRN",
    airportName: "Pristina International",
    cityCode: "PRN",
    cityName: "Pristina",
    countryCode: "XK",
    countryName: "Kosovo",
    continent: "Europe",
  },
  {
    airportCode: "PLS",
    airportName: "Providenciales International",
    cityCode: "PLS",
    cityName: "Providenciales",
    countryCode: "TC",
    countryName: "Turks & Caicos Islands",
    continent: "North America",
  },
  {
    airportCode: "PKV",
    airportName: "Pskov",
    cityCode: "PKV",
    cityName: "Pskov",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "SYM",
    airportName: "Pu er Simao",
    cityCode: "SYM",
    cityName: "Simao District",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PBC",
    airportName: "Puebla International",
    cityCode: "PBC",
    cityName: "Puebla",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "PBR",
    airportName: "Puerto Barrios",
    cityCode: "PBR",
    cityName: "Puerto Barrios",
    countryCode: "GT",
    countryName: "Guatemala",
    continent: "North America",
  },
  {
    airportCode: "PXM",
    airportName: "Puerto Escondido International",
    cityCode: "PXM",
    cityName: "Puerto Escondido, Oaxaca",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "PJM",
    airportName: "Puerto Jimenez",
    cityCode: "PJM",
    cityName: "Puerto Jimenez",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "PEU",
    airportName: "Puerto Lempira",
    cityCode: "PEU",
    cityName: "Puerto Lempira",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "PUE",
    airportName: "Puerto Obaldia",
    cityCode: "PUE",
    cityName: "Puerto Obaldia",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "PPS",
    airportName: "Puerto Princesa International",
    cityCode: "PPS",
    cityName: "Puerto Princesa",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "PUY",
    airportName: "Pula",
    cityCode: "PUY",
    cityName: "Pula",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "LED",
    airportName: "Pulkovo International",
    cityCode: "LED",
    cityName: "Saint Petersburg",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "PUJ",
    airportName: "Punta Cana International",
    cityCode: "PUJ",
    cityName: "Punta Cana",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "PND",
    airportName: "Punta Gorda",
    cityCode: "PND",
    cityName: "Punta Gorda",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "PBU",
    airportName: "Putao",
    cityCode: "PBU",
    cityName: "Putao",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "YPX",
    airportName: "Puvirnituq",
    cityCode: "YPX",
    cityName: "Puvirnituq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "FNJ",
    airportName: "Pyongyang International",
    cityCode: "FNJ",
    cityName: "Pyongyang",
    countryCode: "KP",
    countryName: "North Korea",
    continent: "Asia",
  },
  {
    airportCode: "JQA",
    airportName: "Qaarsut",
    cityCode: "JQA",
    cityName: "Qaarsut",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "GBB",
    airportName: "Qabala International",
    cityCode: "GBB",
    cityName: "Qabala",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "BPX",
    airportName: "Qamdo Bamda",
    cityCode: "BPX",
    cityName: "Chengguan, Chamdo",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JIQ",
    airportName: "Qianjiang Wulingshan",
    cityCode: "JIQ",
    cityName: "Qianjiang District",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "IQM",
    airportName: "Qiemo",
    cityCode: "IQM",
    cityName: "Qiemo",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YVM",
    airportName: "Qikiqtarjuaq",
    cityCode: "YVM",
    cityName: "Qikiqtarjuaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TAO",
    airportName: "Qingdao Liuting International",
    cityCode: "TAO",
    cityName: "Qingdao",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "IQN",
    airportName: "Qingyang",
    cityCode: "IQN",
    cityName: "Qingyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BPE",
    airportName: "Qinhuangdao Beidaihe",
    cityCode: "BPE",
    cityName: "Qinhuangdao",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BAR",
    airportName: "Qionghai Bo'ao",
    cityCode: "BAR",
    cityName: "Qionghai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NDG",
    airportName: "Qiqihar Sanjiazi",
    cityCode: "NDG",
    cityName: "Qiqihar",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JJN",
    airportName: "Quanzhou Jinjiang International",
    cityCode: "JJN",
    cityName: "Quanzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YQC",
    airportName: "Quaqtaq",
    cityCode: "YQC",
    cityName: "Quaqtaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "LAD",
    airportName: "Quatro de Fevereiro",
    cityCode: "LAD",
    cityName: "Luanda",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "YQB",
    airportName: "Quebec City Jean Lesage International",
    cityCode: "YQB",
    cityName: "Quebec City",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AMM",
    airportName: "Queen Alia International",
    cityCode: "AMM",
    cityName: "Amman",
    countryCode: "JO",
    countryName: "Jordan",
    continent: "Asia",
  },
  {
    airportCode: "AUA",
    airportName: "Queen Beatrix International",
    cityCode: "AUA",
    cityName: "Aruba",
    countryCode: "AW",
    countryName: "Aruba",
    continent: "North America",
  },
  {
    airportCode: "ZQN",
    airportName: "Queenstown",
    cityCode: "ZQN",
    cityName: "Queenstown",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "UEL",
    airportName: "Quelimane",
    cityCode: "UEL",
    cityName: "Quelimane",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "XQP",
    airportName: "Quepos La Managua",
    cityCode: "XQP",
    cityName: "Quepos",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "QRO",
    airportName: "Queretaro International",
    cityCode: "QRO",
    cityName: "Santiago de Queretaro",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "YQZ",
    airportName: "Quesnel",
    cityCode: "YQZ",
    cityName: "Quesnel",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "UET",
    airportName: "Quetta International",
    cityCode: "UET",
    cityName: "Quetta",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "ULP",
    airportName: "Quilpie",
    cityCode: "ULP",
    cityName: "Quilpie",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "UIP",
    airportName: "Quimper Cornouaille",
    cityCode: "UIP",
    cityName: "Quimper",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "KQT",
    airportName: "Qurghonteppa International",
    cityCode: "KQT",
    cityName: "Qurghonteppa",
    countryCode: "TJ",
    countryName: "Tajikistan",
    continent: "Asia",
  },
  {
    airportCode: "JUZ",
    airportName: "Quzhou",
    cityCode: "JUZ",
    cityName: "Quzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LEA",
    airportName: "RAAF Base Learmonth",
    cityCode: "LEA",
    cityName: "Exmouth",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "KTR",
    airportName: "RAAF Base Tindal",
    cityCode: "KTR",
    cityName: "Katherine",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "AAE",
    airportName: "Rabah Bitat",
    cityCode: "AAE",
    cityName: "Annaba",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "RBA",
    airportName: "Rabat Sale",
    cityCode: "RBA",
    cityName: "Rabat",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "RAB",
    airportName: "Rabaul",
    cityCode: "RAB",
    cityName: "Rabaul",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "VKG",
    airportName: "Rach Gia",
    cityCode: "VKG",
    cityName: "Rach Gia",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "TKG",
    airportName: "Radin Inten II International",
    cityCode: "TKG",
    cityName: "Bandar Lampung",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GAN",
    airportName: "RAF Gan",
    cityCode: "GAN",
    cityName: "Gan",
    countryCode: "MV",
    countryName: "Maldives",
    continent: "Asia",
  },
  {
    airportCode: "MPN",
    airportName: "RAF Mount Pleasant",
    cityCode: "MPN",
    cityName: "Mount Pleasant",
    countryCode: "FK",
    countryName: "Falkland Islands (Islas Malvinas)",
    continent: "South America",
  },
  {
    airportCode: "GER",
    airportName: "Rafael Cabrera Mustelier",
    cityCode: "GER",
    cityName: "Nueva Gerona",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "CTG",
    airportName: "Rafael Nunez International",
    cityCode: "CTG",
    cityName: "Cartagena",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "RAH",
    airportName: "Rafha Domestic",
    cityCode: "RAH",
    cityName: "Rafha",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "RFP",
    airportName: "Raiatea",
    cityCode: "RFP",
    cityName: "Raiatea",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "RVV",
    airportName: "Raivavae",
    cityCode: "RVV",
    cityName: "Raivavae",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TNJ",
    airportName: "Raja Haji Fisabilillah International",
    cityCode: "TNJ",
    cityName: "Tanjung Pinang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "RBV",
    airportName: "Ramata",
    cityCode: "RBV",
    cityName: "Ramata Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "SAP",
    airportName: "Ramon Villeda Morales International",
    cityCode: "SAP",
    cityName: "San Pedro Sula",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "RZR",
    airportName: "Ramsar",
    cityCode: "RZR",
    cityName: "Ramsar, Mazandaran",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "NTX",
    airportName: "Ranai",
    cityCode: "NTX",
    cityName: "Natuna",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "RGI",
    airportName: "Rangiroa",
    cityCode: "RGI",
    cityName: "Rangiroa",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "YRT",
    airportName: "Rankin Inlet",
    cityCode: "YRT",
    cityName: "Rankin Inlet",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "UNN",
    airportName: "Ranong",
    cityCode: "UNN",
    cityName: "Ranong Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "RAR",
    airportName: "Rarotonga International",
    cityCode: "RAR",
    cityName: "Rarotonga",
    countryCode: "CK",
    countryName: "Cook Islands",
    continent: "Oceania",
  },
  {
    airportCode: "RKT",
    airportName: "Ras Al Khaimah International",
    cityCode: "RKT",
    cityName: "Ras al-Khaimah",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "RAS",
    airportName: "Rasht",
    cityCode: "RAS",
    cityName: "Rasht",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "RVT",
    airportName: "Ravensthorpe",
    cityCode: "RVT",
    cityName: "Ravensthorpe",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "REC",
    airportName: "Recife/Guararapes Gilberto Freyre International",
    cityCode: "REC",
    cityName: "Recife",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RCQ",
    airportName: "Reconquista",
    cityCode: "RCQ",
    cityName: "Reconquista",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "YQF",
    airportName: "Red Deer Regional",
    cityCode: "YQF",
    cityName: "Red Deer",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YRL",
    airportName: "Red Lake",
    cityCode: "YRL",
    cityName: "Red Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YRS",
    airportName: "Red Sucker Lake",
    cityCode: "YRS",
    cityName: "Red Sucker Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "REG",
    airportName: "Reggio Calabria",
    cityCode: "REG",
    cityName: "Reggio Calabria",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "YQR",
    airportName: "Regina International",
    cityCode: "YQR",
    cityName: "Regina",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YKF",
    airportName: "Region of Waterloo International",
    cityCode: "YKF",
    cityName: "Waterloo",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "MKW",
    airportName: "Rendani",
    cityCode: "MKW",
    cityName: "Manokwari",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "RNL",
    airportName: "Rennell/Tingoa",
    cityCode: "RNL",
    cityName: "Rennell Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "RNS",
    airportName: "Rennes Saint-Jacques",
    cityCode: "RNS",
    cityName: "Rennes",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "RES",
    airportName: "Resistencia International",
    cityCode: "RES",
    cityName: "Resistencia",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "YRB",
    airportName: "Resolute Bay",
    cityCode: "YRB",
    cityName: "Resolute",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "REU",
    airportName: "Reus",
    cityCode: "REU",
    cityName: "Reus",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "NQU",
    airportName: "Reyes Murillo",
    cityCode: "NQU",
    cityName: "Nuqui",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "RKV",
    airportName: "Reykjavik",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "RHO",
    airportName: "Rhodes International",
    cityCode: "RHO",
    cityName: "Rhodes",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "RIB",
    airportName: "Riberalta",
    cityCode: "RIB",
    cityName: "Riberalta",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "RCB",
    airportName: "Richards Bay",
    cityCode: "RCB",
    cityName: "Richards Bay",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "RCM",
    airportName: "Richmond",
    cityCode: "RCM",
    cityName: "Richmond",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "RIX",
    airportName: "Riga International",
    cityCode: "RIX",
    cityName: "Riga",
    countryCode: "LV",
    countryName: "Latvia",
    continent: "Europe",
  },
  {
    airportCode: "YRG",
    airportName: "Rigolet",
    cityCode: "YRG",
    cityName: "Rigolet",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "RJK",
    airportName: "Rijeka",
    cityCode: "RJK",
    cityName: "Rijeka",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "RMT",
    airportName: "Rimatara",
    cityCode: "RMT",
    cityName: "Rimatara",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "RBR",
    airportName: "Rio Branco International",
    cityCode: "RBR",
    cityName: "Rio Branco, Acre",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "GIG",
    airportName: "Rio de Janeiro Galeao International",
    cityCode: "RIO",
    cityName: "Rio de Janeiro",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RVD",
    airportName: "Rio Verde",
    cityCode: "RVD",
    cityName: "Rio Verde, Goias",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RIS",
    airportName: "Rishiri",
    cityCode: "RIS",
    cityName: "Rishiri",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "RIZ",
    airportName: "Rizhao Shanzihe",
    cityCode: "RIZ",
    cityName: "Rizhao",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "GOB",
    airportName: "Robe Airport",
    cityCode: "null",
    cityName: "Goba",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "SKB",
    airportName: "Robert L. Bradshaw International",
    cityCode: "SKB",
    cityName: "Saint Kitts",
    countryCode: "KN",
    countryName: "St. Kitts & Nevis",
    continent: "North America",
  },
  {
    airportCode: "ROB",
    airportName: "Roberts International",
    cityCode: "MLW",
    cityName: "Monrovia",
    countryCode: "LR",
    countryName: "Liberia",
    continent: "Africa",
  },
  {
    airportCode: "RSD",
    airportName: "Rock Sound International",
    cityCode: "RSD",
    cityName: "Rock Sound",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "ROK",
    airportName: "Rockhampton",
    cityCode: "ROK",
    cityName: "Rockhampton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "RDZ",
    airportName: "Rodez Aveyron",
    cityCode: "RDZ",
    cityName: "Rodez",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "AQP",
    airportName: "Rodriguez Ballon International",
    cityCode: "AQP",
    cityName: "Arequipa",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "ROI",
    airportName: "Roi Et",
    cityCode: "ROI",
    cityName: "Roi Et Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "RUN",
    airportName: "Roland Garros",
    cityCode: "RUN",
    cityName: "Saint-Denis",
    countryCode: "FR",
    countryName: "France",
    continent: "Africa",
  },
  {
    airportCode: "RMA",
    airportName: "Roma",
    cityCode: "RMA",
    cityName: "Roma",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "ROR",
    airportName: "Roman Tmetuchl International",
    cityCode: "ROR",
    cityName: "Koror",
    countryCode: "PW",
    countryName: "Palau",
    continent: "Oceania",
  },
  {
    airportCode: "ROO",
    airportName: "Rondonopolis",
    cityCode: "ROO",
    cityName: "Rondonopolis",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RNB",
    airportName: "Ronneby",
    cityCode: "RNB",
    cityName: "Ronneby",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "RRS",
    airportName: "Roros",
    cityCode: "RRS",
    cityName: "Roros",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "RVK",
    airportName: "Rorvik Airport, Ryum",
    cityCode: "RVK",
    cityName: "Rorvik",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "ROS",
    airportName: "Rosario Islas Malvinas International",
    cityCode: "ROS",
    cityName: "Rosario",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "TJM",
    airportName: "Roshchino International",
    cityCode: "TJM",
    cityName: "Tyumen",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "RET",
    airportName: "Rost",
    cityCode: "RET",
    cityName: "Rost",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "RLG",
    airportName: "Rostock Laage",
    cityCode: "RLG",
    cityName: "Rostock",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "RTI",
    airportName: "Roti David C. Saudale",
    cityCode: "RTI",
    cityName: "Rote Island",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ROT",
    airportName: "Rotorua Regional",
    cityCode: "ROT",
    cityName: "Rotorua",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "RTM",
    airportName: "Rotterdam The Hague",
    cityCode: "RTM",
    cityName: "Rotterdam",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "RTA",
    airportName: "Rotuma",
    cityCode: "RTA",
    cityName: "Rotuma",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "URO",
    airportName: "Rouen",
    cityCode: "URO",
    cityName: "Rouen",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZRJ",
    airportName: "Round Lake (Weagamow Lake)",
    cityCode: "ZRJ",
    cityName: "Weagamow Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YUY",
    airportName: "Rouyn-Noranda",
    cityCode: "YUY",
    cityName: "Rouyn-Noranda",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "RVN",
    airportName: "Rovaniemi",
    cityCode: "RVN",
    cityName: "Rovaniemi",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "RXS",
    airportName: "Roxas",
    cityCode: "RXS",
    cityName: "Roxas, Capiz",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "URG",
    airportName: "Ruben Berta International",
    cityCode: "URG",
    cityName: "Uruguaiana",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "NDU",
    airportName: "Rundu",
    cityCode: "NDU",
    cityName: "Rundu",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "RBQ",
    airportName: "Rurrenabaque",
    cityCode: "RBQ",
    cityName: "Rurrenabaque",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "RUR",
    airportName: "Rurutu",
    cityCode: "RUR",
    cityName: "Rurutu",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "RZE",
    airportName: "Rzeszow Jasionka",
    cityCode: "RZE",
    cityName: "Rzeszow",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "RVH",
    airportName: "Rzhevka",
    cityCode: "LED",
    cityName: "Saint Petersburg",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "SCN",
    airportName: "Saarbrucken",
    cityCode: "SCN",
    cityName: "Saarbrucken",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "SEB",
    airportName: "Sabha",
    cityCode: "SEB",
    cityName: "Sabha",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "SAW",
    airportName: "Sabiha Gokcen International",
    cityCode: "IST",
    cityName: "Istanbul",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "AFZ",
    airportName: "Sabzevar",
    cityCode: "AFZ",
    cityName: "Sabzevar",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "HIN",
    airportName: "Sacheon",
    cityCode: "HIN",
    cityName: "Jinju",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "ZPB",
    airportName: "Sachigo Lake",
    cityCode: "ZPB",
    cityName: "Sachigo Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YSY",
    airportName: "Sachs Harbour (David Nasogaluak Jr. Saaryuaq)",
    cityCode: "YSY",
    cityName: "Sachs Harbour",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SKO",
    airportName: "Sadiq Abubakar III International",
    cityCode: "SKO",
    cityName: "Sokoto",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "HSG",
    airportName: "Saga",
    cityCode: "HSG",
    cityName: "Saga",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "SPD",
    airportName: "Saidpur",
    cityCode: "SPD",
    cityName: "Saidpur",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "HLE",
    airportName: "Saint Helena",
    cityCode: "HLE",
    cityName: "Jamestown",
    countryCode: "SH",
    countryName: "St. Helena",
    continent: "Africa",
  },
  {
    airportCode: "YSJ",
    airportName: "Saint John",
    cityCode: "YSJ",
    cityName: "Saint John",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YIF",
    airportName: "Saint-Augustin",
    cityCode: "YIF",
    cityName: "Saint-Augustin, Cote-Nord, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "EBU",
    airportName: "Saint-Etienne Boutheon",
    cityCode: "EBU",
    cityName: "Saint-Etienne",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "FSP",
    airportName: "Saint-Pierre",
    cityCode: "FSP",
    cityName: "Saint-Pierre",
    countryCode: "PM",
    countryName: "St. Pierre & Miquelon",
    continent: "North America",
  },
  {
    airportCode: "SMS",
    airportName: "Sainte Marie",
    cityCode: "SMS",
    cityName: "Ile Sainte-Marie",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "SNO",
    airportName: "Sakon Nakhon",
    cityCode: "SNO",
    cityName: "Sakon Nakhon",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "MVR",
    airportName: "Salak",
    cityCode: "MVR",
    cityName: "Maroua",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "SLL",
    airportName: "Salalah",
    cityCode: "SLL",
    cityName: "Salalah",
    countryCode: "OM",
    countryName: "Oman",
    continent: "Asia",
  },
  {
    airportCode: "SLM",
    airportName: "Salamanca",
    cityCode: "SLM",
    cityName: "Salamanca",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "SLY",
    airportName: "Salekhard",
    cityCode: "SLY",
    cityName: "Salekhard",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "POA",
    airportName: "Salgado Filho International",
    cityCode: "POA",
    cityName: "Porto Alegre",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "YZG",
    airportName: "Salluit",
    cityCode: "YSW",
    cityName: "Salluit",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SLX",
    airportName: "Salt Cay",
    cityCode: "SLX",
    cityName: "Salt Cay, Turks Islands",
    countryCode: "TC",
    countryName: "Turks & Caicos Islands",
    continent: "North America",
  },
  {
    airportCode: "SZG",
    airportName: "Salzburg",
    cityCode: "SZG",
    cityName: "Salzburg",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "QOW",
    airportName: "Sam Mbakwe",
    cityCode: "QOW",
    cityName: "Owerri",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "MDC",
    airportName: "Sam Ratulangi International",
    cityCode: "MDC",
    cityName: "Manado",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "AZS",
    airportName: "Samana El Catey International",
    cityCode: "AZS",
    cityName: "Samana",
    countryCode: "DO",
    countryName: "Dominican Republic",
    continent: "North America",
  },
  {
    airportCode: "SRI",
    airportName: "Samarinda Temindung",
    cityCode: "SRI",
    cityName: "Samarinda",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SKD",
    airportName: "Samarkand International",
    cityCode: "SKD",
    cityName: "Samarkand",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "SVB",
    airportName: "Sambava",
    cityCode: "SVB",
    cityName: "Sambava",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "UAS",
    airportName: "Samburu",
    cityCode: "UAS",
    cityName: "Samburu County",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "SMI",
    airportName: "Samos International",
    cityCode: "SMI",
    cityName: "Samos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "SZF",
    airportName: "Samsun-Carsamba",
    cityCode: "SZF",
    cityName: "Samsun",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "USM",
    airportName: "Samui",
    cityCode: "USM",
    cityName: "Ko Samui",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "BRC",
    airportName: "San Carlos de Bariloche",
    cityCode: "BRC",
    cityName: "Bariloche",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "SCY",
    airportName: "San Cristobal",
    cityCode: "SCY",
    cityName: "San Cristobal Island",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "PEG",
    airportName: "San Francesco d Assisi",
    cityCode: "PEG",
    cityName: "Perugia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "SJI",
    airportName: "San Jose",
    cityCode: "SJI",
    cityName: "San Jose, Occidental Mindoro",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "SIC",
    airportName: "San Jose Island",
    cityCode: "SIC",
    cityName: "Isla San Jose",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "IPI",
    airportName: "San Luis",
    cityCode: "IPI",
    cityName: "Ipiales",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "SLP",
    airportName: "San Luis Potosi International",
    cityCode: "SLP",
    cityName: "San Luis Potosi",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "SPR",
    airportName: "San Pedro",
    cityCode: "SPR",
    cityName: "San Pedro Town",
    countryCode: "BZ",
    countryName: "Belize",
    continent: "North America",
  },
  {
    airportCode: "SPY",
    airportName: "San Pedro",
    cityCode: "SPY",
    cityName: "San-Pedro, Ivory Coast",
    countryCode: "CI",
    countryName: "Cote d?Ivoire",
    continent: "Africa",
  },
  {
    airportCode: "ZSA",
    airportName: "San Salvador",
    cityCode: "ZSA",
    cityName: "San Salvador Island",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "EAS",
    airportName: "San Sebastian",
    cityCode: "EAS",
    cityName: "Donostia / San Sebastian",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "SOM",
    airportName: "San Tome",
    cityCode: "SOM",
    cityName: "San Tome",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "SAH",
    airportName: "Sana'a International Airport",
    cityCode: "SAH",
    cityName: "Sana'a",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "SDG",
    airportName: "Sanandaj",
    cityCode: "SDG",
    cityName: "Sanandij",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "SDK",
    airportName: "Sandakan",
    cityCode: "SDK",
    cityName: "Sandakan",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "SDN",
    airportName: "Sandane Airport, Anda",
    cityCode: "SDN",
    cityName: "Sandane",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "TRF",
    airportName: "Sandefjord Airport, Torp",
    cityCode: "OSL",
    cityName: "Oslo",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "SSJ",
    airportName: "Sandnessjøen Airport, Stokka",
    cityCode: "SSJ",
    cityName: "Sandnessjoen",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "YZP",
    airportName: "Sandspit",
    cityCode: "YZP",
    cityName: "Sandspit",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZSJ",
    airportName: "Sandy Lake",
    cityCode: "ZSJ",
    cityName: "Sandy Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TWT",
    airportName: "Sanga-Sanga",
    cityCode: "TWT",
    cityName: "Tawi-Tawi",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "MBJ",
    airportName: "Sangster International",
    cityCode: "MBJ",
    cityName: "Montego Bay",
    countryCode: "JM",
    countryName: "Jamaica",
    continent: "North America",
  },
  {
    airportCode: "TTU",
    airportName: "Sania Ramel",
    cityCode: "TTU",
    cityName: "Tetouan",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "YSK",
    airportName: "Sanikiluaq",
    cityCode: "YSK",
    cityName: "Sanikiluaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "GNY",
    airportName: "Sanliurfa",
    cityCode: "SFQ",
    cityName: "Sanl?urfa",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "SQJ",
    airportName: "Sanming Shaxian",
    cityCode: "SQJ",
    cityName: "Sanming",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NNB",
    airportName: "Santa Ana",
    cityCode: "NNB",
    cityName: "Santa Ana Island",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "SCZ",
    airportName: "Santa Cruz/Graciosa Bay/Luova",
    cityCode: "SCZ",
    cityName: "Santa Cruz Islands",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "GYN",
    airportName: "Santa Genoveva",
    cityCode: "GYN",
    cityName: "Goiania",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RIA",
    airportName: "Santa Maria",
    cityCode: "RIA",
    cityName: "Santa Maria",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "SMA",
    airportName: "Santa Maria",
    cityCode: "SMA",
    cityName: "Santa Maria Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "SMG",
    airportName: "Santa Maria",
    cityCode: "SMG",
    cityName: "Santa Maria",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "AJU",
    airportName: "Santa Maria",
    cityCode: "AJU",
    cityName: "Aracaju",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "RSA",
    airportName: "Santa Rosa",
    cityCode: "RSA",
    cityName: "Santa Rosa",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "ETR",
    airportName: "Santa Rosa International",
    cityCode: "ETR",
    cityName: "Santa Rosa Canton",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "SDR",
    airportName: "Santander",
    cityCode: "SDR",
    cityName: "Santander",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "STM",
    airportName: "Santarem-Maestro Wilson Fonseca",
    cityCode: "STM",
    cityName: "Santarem",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "SCQ",
    airportName: "Santiago de Compostela",
    cityCode: "SCQ",
    cityName: "Santiago de Compostela",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "PMV",
    airportName: "Santiago Marino Caribbean International",
    cityCode: "PMV",
    cityName: "Porlamar",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "AUC",
    airportName: "Santiago Perez Quiroz",
    cityCode: "AUC",
    cityName: "Arauca",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "SON",
    airportName: "Santo-Pekoa International",
    cityCode: "SON",
    cityName: "Luganville",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "JTR",
    airportName: "Santorini (Thira) National",
    cityCode: "JTR",
    cityName: "Santorini",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "SDU",
    airportName: "Santos Dumont",
    cityCode: "RIO",
    cityName: "Rio de Janeiro",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "SYX",
    airportName: "Sanya Phoenix International",
    cityCode: "SYX",
    cityName: "Sanya",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SFL",
    airportName: "Sao Filipe",
    cityCode: "SFL",
    cityName: "Sao Filipe",
    countryCode: "CV",
    countryName: "Cape Verde",
    continent: "Africa",
  },
  {
    airportCode: "SJZ",
    airportName: "Sao Jorge",
    cityCode: "SJZ",
    cityName: "Sao Jorge Island",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "SJP",
    airportName: "Sao Jose do Rio Preto",
    cityCode: "SJP",
    cityName: "Sao Jose do Rio Preto",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "CGH",
    airportName: "Sao Paulo Congonhas",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "GRU",
    airportName: "Sao Paulo Guarulhos International",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TMS",
    airportName: "Sao Tome International",
    cityCode: "TMS",
    cityName: "Sao Tome",
    countryCode: "ST",
    countryName: "Sao Tome & Príncipe",
    continent: "Africa",
  },
  {
    airportCode: "SSR",
    airportName: "Sara",
    cityCode: "SSR",
    cityName: "Sara",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "SJJ",
    airportName: "Sarajevo International",
    cityCode: "SJJ",
    cityName: "Sarajevo",
    countryCode: "BA",
    countryName: "Bosnia & Herzegovina",
    continent: "Europe",
  },
  {
    airportCode: "SKX",
    airportName: "Saransk",
    cityCode: "SKX",
    cityName: "Saransk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "RTW",
    airportName: "Saratov Tsentralny",
    cityCode: "RTW",
    cityName: "Saratov",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "YZR",
    airportName: "Sarnia Chris Hadfield",
    cityCode: "YZR",
    cityName: "Sarnia",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KGF",
    airportName: "Sary-Arka",
    cityCode: "KGF",
    cityName: "Karaganda",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "YXE",
    airportName: "Saskatoon John G. Diefenbaker International",
    cityCode: "YXE",
    cityName: "Saskatoon",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SYS",
    airportName: "Saskylakh",
    cityCode: "SYS",
    cityName: "Saskylakh",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SUJ",
    airportName: "Satu Mare International",
    cityCode: "SUJ",
    cityName: "Satu Mare",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "SFN",
    airportName: "Sauce Viejo",
    cityCode: "SFN",
    cityName: "Santa Fe",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "YAM",
    airportName: "Sault Ste. Marie",
    cityCode: "YAM",
    cityName: "Sault Ste. Marie",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SXK",
    airportName: "Saumlaki Olilit",
    cityCode: "SXK",
    cityName: "Saumlaki",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "VHC",
    airportName: "Saurimo",
    cityCode: "VHC",
    cityName: "Saurimo",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "ZVK",
    airportName: "Savannakhet",
    cityCode: "ZVK",
    cityName: "Savannakhet",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "SVL",
    airportName: "Savonlinna",
    cityCode: "SVL",
    cityName: "Savonlinna",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "SVU",
    airportName: "Savusavu",
    cityCode: "SVU",
    cityName: "Savusavu",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "IAO",
    airportName: "Sayak",
    cityCode: "IAO",
    cityName: "Del Carmen",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "GXF",
    airportName: "Sayun",
    cityCode: "GXF",
    cityName: "Seiyun",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "YKL",
    airportName: "Schefferville",
    cityCode: "YKL",
    cityName: "Schefferville",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SDV",
    airportName: "Sde Dov",
    cityCode: "TLV",
    cityName: "Tel Aviv",
    countryCode: "IL",
    countryName: "Israel",
    continent: "Asia",
  },
  {
    airportCode: "EGM",
    airportName: "Seghe",
    cityCode: "EGM",
    cityName: "Seghe",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "SZE",
    airportName: "Semera",
    cityCode: "SZE",
    cityName: "Semera",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "PLX",
    airportName: "Semey",
    cityCode: "PLX",
    cityName: "Semey",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "THE",
    airportName: "Senador Petronio Portella",
    cityCode: "THE",
    cityName: "Teresina",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "JHB",
    airportName: "Senai International",
    cityCode: "JHB",
    cityName: "Johor Bahru",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "SDJ",
    airportName: "Sendai",
    cityCode: "SDJ",
    cityName: "Sendai",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "DJJ",
    airportName: "Sentani International",
    cityCode: "DJJ",
    cityName: "Jayapura",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "GEL",
    airportName: "Sepe Tiaraju",
    cityCode: "GEL",
    cityName: "Santo Angelo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "YZV",
    airportName: "Sept-Iles",
    cityCode: "YZV",
    cityName: "Sept-Iles",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SEU",
    airportName: "Seronera Airstrip",
    cityCode: "SEU",
    cityName: "Seronera",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "SWV",
    airportName: "Severo-Evensk",
    cityCode: "SWV",
    cityName: "Evensk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SVQ",
    airportName: "Seville",
    cityCode: "SVQ",
    cityName: "Seville",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "SEZ",
    airportName: "Seychelles International",
    cityCode: "SEZ",
    cityName: "Mahe",
    countryCode: "SC",
    countryName: "Seychelles",
    continent: "Africa",
  },
  {
    airportCode: "GPS",
    airportName: "Seymour",
    cityCode: "GPS",
    cityName: "Baltra Island",
    countryCode: "EC",
    countryName: "Ecuador",
    continent: "South America",
  },
  {
    airportCode: "SFA",
    airportName: "Sfax Thyna International",
    cityCode: "SFA",
    cityName: "Sfax",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "CGP",
    airportName: "Shah Amanat International",
    cityCode: "CGP",
    cityName: "Chittagong",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "RJH",
    airportName: "Shah Makhdum",
    cityCode: "RJH",
    cityName: "Rajshahi",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "AZD",
    airportName: "Shahid Sadooghi",
    cityCode: "AZD",
    cityName: "Yazd",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "DAC",
    airportName: "Shahjalal International",
    cityCode: "DAC",
    cityName: "Dhaka",
    countryCode: "BD",
    countryName: "Bangladesh",
    continent: "Asia",
  },
  {
    airportCode: "CQD",
    airportName: "Shahrekord Airport",
    cityCode: "CQD",
    cityName: "Shahrekord",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "RYK",
    airportName: "Shaikh Zayed International",
    cityCode: "RYK",
    cityName: "Rahim Yar Khan",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "EKS",
    airportName: "Shakhtersk",
    cityCode: "EKS",
    cityName: "Shakhtyorsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ZTM",
    airportName: "Shamattawa",
    cityCode: "ZTM",
    cityName: "Shamattawa First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SHA",
    airportName: "Shanghai Hongqiao International",
    cityCode: "SHA",
    cityName: "Shanghai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "PVG",
    airportName: "Shanghai Pudong International",
    cityCode: "SHA",
    cityName: "Shanghai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SQD",
    airportName: "Shangrao Sanqingshan",
    cityCode: "SQD",
    cityName: "Shangrao",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SNN",
    airportName: "Shannon",
    cityCode: "SNN",
    cityName: "Shannon, County Clare",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "WGN",
    airportName: "Shaoyang Wugang",
    cityCode: "WGN",
    cityName: "Shaoyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SHJ",
    airportName: "Sharjah International",
    cityCode: "DXB",
    cityName: "Dubai",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    continent: "Asia",
  },
  {
    airportCode: "MJK",
    airportName: "Shark Bay",
    cityCode: "MJK",
    cityName: "Monkey Mia",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SSH",
    airportName: "Sharm el-Sheikh International",
    cityCode: "SSH",
    cityName: "Sharm El Sheikh",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "SHW",
    airportName: "Sharurah Domestic",
    cityCode: "SHW",
    cityName: "Sharurah",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "HPG",
    airportName: "Shennongjia Hongping",
    cityCode: "HPG",
    cityName: "Shennongjia",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SHE",
    airportName: "Shenyang Taoxian International",
    cityCode: "SHE",
    cityName: "Shenyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SZX",
    airportName: "Shenzhen Bao an International",
    cityCode: "SZX",
    cityName: "Shenzhen",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SVO",
    airportName: "Sheremetyevo International",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "RKZ",
    airportName: "Shigatse Peace",
    cityCode: "RKZ",
    cityName: "Shigatse",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SHF",
    airportName: "Shihezi Huayuan",
    cityCode: "SHF",
    cityName: "Shihezi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SJW",
    airportName: "Shijiazhuang Zhengding International",
    cityCode: "SJW",
    cityName: "Shijiazhuang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LWN",
    airportName: "Shirak",
    cityCode: "LWN",
    cityName: "Gyumri",
    countryCode: "AM",
    countryName: "Armenia",
    continent: "Asia",
  },
  {
    airportCode: "SYZ",
    airportName: "Shiraz International",
    cityCode: "SYZ",
    cityName: "Shiraz",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "SHC",
    airportName: "Shire",
    cityCode: "SHC",
    cityName: "Shire",
    countryCode: "ET",
    countryName: "Ethiopia",
    continent: "Africa",
  },
  {
    airportCode: "WDS",
    airportName: "Shiyan Wudangshan",
    cityCode: "WDS",
    cityName: "Shiyan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "FSZ",
    airportName: "Shizuoka",
    cityCode: "FSZ",
    cityName: "Shizuoka",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "SYO",
    airportName: "Shonai",
    cityCode: "SYO",
    cityName: "Shonai",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "CIT",
    airportName: "Shymkent International",
    cityCode: "CIT",
    cityName: "Shymkent",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "SKT",
    airportName: "Sialkot International",
    cityCode: "SKT",
    cityName: "Sialkot",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "SBZ",
    airportName: "Sibiu International",
    cityCode: "SBZ",
    cityName: "Sibiu",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "SBW",
    airportName: "Sibu",
    cityCode: "SBW",
    cityName: "Sibu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "DGT",
    airportName: "Sibulan",
    cityCode: "DGT",
    cityName: "Dumaguete",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "TGR",
    airportName: "Sidi Mahdi",
    cityCode: "TGR",
    cityName: "Touggourt",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "REP",
    airportName: "Siem Reap International",
    cityCode: "REP",
    cityName: "Siem Reap",
    countryCode: "KH",
    countryName: "Cambodia",
    continent: "Asia",
  },
  {
    airportCode: "MZO",
    airportName: "Sierra Maestra",
    cityCode: "MZO",
    cityName: "Manzanillo",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "GIU",
    airportName: "Sigiriya",
    cityCode: "GIU",
    cityName: "Sigiriya",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "KOS",
    airportName: "Sihanoukville International",
    cityCode: "KOS",
    cityName: "Sihanoukville Province",
    countryCode: "KH",
    countryName: "Cambodia",
    continent: "Asia",
  },
  {
    airportCode: "SXZ",
    airportName: "Siirt",
    cityCode: "SXZ",
    cityName: "Siirt",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "DTB",
    airportName: "Silangit International",
    cityCode: "DTB",
    cityName: "Siborong-Borong",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ASU",
    airportName: "Silvio Pettirossi International",
    cityCode: "ASU",
    cityName: "Asuncion",
    countryCode: "PY",
    countryName: "Paraguay",
    continent: "South America",
  },
  {
    airportCode: "SIF",
    airportName: "Simara",
    cityCode: "SIF",
    cityName: "Simara, Bara",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "SIP",
    airportName: "Simferopol International",
    cityCode: "SIP",
    cityName: "Simferopol",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Europe",
  },
  {
    airportCode: "SMR",
    airportName: "Simon Bolivar International",
    cityCode: "SMR",
    cityName: "Santa Marta",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "CCS",
    airportName: "Simon Bolivar International",
    cityCode: "CCS",
    cityName: "Caracas",
    countryCode: "VE",
    countryName: "Venezuela",
    continent: "South America",
  },
  {
    airportCode: "NLA",
    airportName: "Simon Mwansa Kapwepwe International",
    cityCode: "NLA",
    cityName: "Ndola",
    countryCode: "ZM",
    countryName: "Zambia",
    continent: "Africa",
  },
  {
    airportCode: "SIN",
    airportName: "Singapore Changi",
    cityCode: "SIN",
    cityName: "Singapore",
    countryCode: "SG",
    countryName: "Singapore",
    continent: "Asia",
  },
  {
    airportCode: "NOP",
    airportName: "Sinop",
    cityCode: "NOP",
    cityName: "Sinop",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "OPS",
    airportName: "Sinop",
    cityCode: "OPS",
    cityName: "Sinop, Mato Grosso",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "SQG",
    airportName: "Sintang",
    cityCode: "SQG",
    cityName: "Sintang Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SIR",
    airportName: "Sion",
    cityCode: "SIR",
    cityName: "Sion",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "YXL",
    airportName: "Sioux Lookout",
    cityCode: "YXL",
    cityName: "Sioux Lookout",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "RRG",
    airportName: "Sir Gaetan Duval",
    cityCode: "RRG",
    cityName: "Rodrigues",
    countryCode: "MU",
    countryName: "Mauritius",
    continent: "Africa",
  },
  {
    airportCode: "MRU",
    airportName: "Sir Seewoosagur Ramgoolam International",
    cityCode: "MRU",
    cityName: "Mauritius Island",
    countryCode: "MU",
    countryName: "Mauritius",
    continent: "Africa",
  },
  {
    airportCode: "GBE",
    airportName: "Sir Seretse Khama International",
    cityCode: "GBE",
    cityName: "Gaborone",
    countryCode: "BW",
    countryName: "Botswana",
    continent: "Africa",
  },
  {
    airportCode: "SIS",
    airportName: "Sishen",
    cityCode: "SIS",
    cityName: "Dingleton",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "JHS",
    airportName: "Sisimiut",
    cityCode: "JHS",
    cityName: "Sisimiut",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "JSH",
    airportName: "Sitia Public",
    cityCode: "JSH",
    cityName: "Sitia",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "AKY",
    airportName: "Sittwe",
    cityCode: "AKY",
    cityName: "Sittwe",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "VAS",
    airportName: "Sivas",
    cityCode: "VAS",
    cityName: "Sivas",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "EAE",
    airportName: "Siwo",
    cityCode: "EAE",
    cityName: "Emae",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "NKT",
    airportName: "Sirnak",
    cityCode: "NKT",
    cityName: "Sirnak",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "KDU",
    airportName: "Skardu",
    cityCode: "KDU",
    cityName: "Skardu",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "SFT",
    airportName: "Skelleftea",
    cityCode: "SFT",
    cityName: "Skelleftea",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "JSI",
    airportName: "Skiathos Island National",
    cityCode: "JSI",
    cityName: "Skiathos",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "SQW",
    airportName: "Skive Airport",
    cityCode: "null",
    cityName: "Skive",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "SKP",
    airportName: "Skopje Alexander the Great ",
    cityCode: "SKP",
    cityName: "Skopje",
    countryCode: "MK",
    countryName: "Macedonia (FYROM)",
    continent: "Europe",
  },
  {
    airportCode: "SZK",
    airportName: "Skukuza",
    cityCode: "SZK",
    cityName: "Skukuza",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "SKU",
    airportName: "Skyros Island National",
    cityCode: "SKU",
    cityName: "Skyros",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "YYD",
    airportName: "Smithers",
    cityCode: "YYD",
    cityName: "Smithers",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "AER",
    airportName: "Sochi International",
    cityCode: "AER",
    cityName: "Sochi",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "SCT",
    airportName: "Socotra",
    cityCode: "SCT",
    cityName: "Socotra",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "CGK",
    airportName: "Soekarno Hatta International",
    cityCode: "JKT",
    cityName: "Jakarta",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SOF",
    airportName: "Sofia",
    cityCode: "SOF",
    cityName: "Sofia",
    countryCode: "BG",
    countryName: "Bulgaria",
    continent: "Europe",
  },
  {
    airportCode: "SOG",
    airportName: "Sogndal Airport, Haukasen",
    cityCode: "SOG",
    cityName: "Sogndal",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "HMB",
    airportName: "Sohag International",
    cityCode: "HMB",
    cityName: "Sohag",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "OHS",
    airportName: "Sohar",
    cityCode: "OHS",
    cityName: "Sohar",
    countryCode: "OM",
    countryName: "Oman",
    continent: "Asia",
  },
  {
    airportCode: "GDX",
    airportName: "Sokol",
    cityCode: "GDX",
    cityName: "Magadan",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SZZ",
    airportName: "Solidarity Szczecin Goleniow",
    cityCode: "SZZ",
    cityName: "Szczecin",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "CSH",
    airportName: "Solovki",
    cityCode: "CSH",
    cityName: "Solovetsky, Arkhangelsk Oblast",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "SLI",
    airportName: "Solwezi",
    cityCode: "SLI",
    cityName: "Solwezi",
    countryCode: "ZM",
    countryName: "Zambia",
    continent: "Africa",
  },
  {
    airportCode: "SGD",
    airportName: "Sønderborg",
    cityCode: "SGD",
    cityName: "Sonderborg",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "SGX",
    airportName: "Songea",
    cityCode: "SGX",
    cityName: "Songea",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "YSQ",
    airportName: "Songyuan Chaganhu",
    cityCode: "YSQ",
    cityName: "Songyuan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SOJ",
    airportName: "Sorkjosen",
    cityCode: "SOJ",
    cityName: "Sorkjosen",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "SMT",
    airportName: "Sorriso",
    cityCode: "SMT",
    cityName: "Sorriso",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BIM",
    airportName: "South Bimini",
    cityCode: "BIM",
    cityName: "Bimini",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "XSC",
    airportName: "South Caicos",
    cityCode: "XSC",
    cityName: "South Caicos",
    countryCode: "TC",
    countryName: "Turks & Caicos Islands",
    continent: "North America",
  },
  {
    airportCode: "XSI",
    airportName: "South Indian Lake",
    cityCode: "XSI",
    cityName: "South Indian Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SWJ",
    airportName: "South West Bay",
    cityCode: "SWJ",
    cityName: "South West Bay",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "OVS",
    airportName: "Sovetsky",
    cityCode: "OVS",
    cityName: "Sovetsky",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "SZA",
    airportName: "Soyo",
    cityCode: "SZA",
    cityName: "Soyo",
    countryCode: "AO",
    countryName: "Angola",
    continent: "Africa",
  },
  {
    airportCode: "NOZ",
    airportName: "Spichenkovo",
    cityCode: "NOZ",
    cityName: "Novokuznetsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SPU",
    airportName: "Split",
    cityCode: "SPU",
    cityName: "Split",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "MLW",
    airportName: "Spriggs Payne Airport",
    cityCode: "MLW",
    cityName: "Monrovia",
    countryCode: "LR",
    countryName: "Liberia",
    continent: "Africa",
  },
  {
    airportCode: "AXP",
    airportName: "Spring Point",
    cityCode: "AXP",
    cityName: "Spring Point",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "SEK",
    airportName: "Srednekolymsk",
    cityCode: "SEK",
    cityName: "Srednekolymsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SXR",
    airportName: "Srinagar International",
    cityCode: "SXR",
    cityName: "Srinagar",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "SGO",
    airportName: "St George",
    cityCode: "SGO",
    cityName: "St George",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SNR",
    airportName: "St-Nazaire Montoir",
    cityCode: "SNR",
    cityName: "Saint Nazaire",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "YAY",
    airportName: "St. Anthony",
    cityCode: "YAY",
    cityName: "St. Anthony",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ACH",
    airportName: "St. Gallen Altenrhein",
    cityCode: "ACH",
    cityName: "Thal",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "YYT",
    airportName: "St. John's International",
    cityCode: "YYT",
    cityName: "St. John s",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YST",
    airportName: "St. Theresa Point",
    cityCode: "YST",
    cityName: "St. Theresa Point First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SVG",
    airportName: "Stavanger Airport, Sola",
    cityCode: "SVG",
    cityName: "Stavanger",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "STW",
    airportName: "Stavropol Shpakovskoye",
    cityCode: "STW",
    cityName: "Stavropol",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SML",
    airportName: "Stella Maris",
    cityCode: "SML",
    cityName: "Stella Maris",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "YJT",
    airportName: "Stephenville International",
    cityCode: "YJT",
    cityName: "Stephenville",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ARN",
    airportName: "Stockholm Arlanda",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "BMA",
    airportName: "Stockholm Bromma",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "NYO",
    airportName: "Stockholm Skavsta",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "VST",
    airportName: "Stockholm Vasteras",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "SKN",
    airportName: "Stokmarknes Airport, Skagen",
    cityCode: "SKN",
    cityName: "Stokmarknes",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "SRP",
    airportName: "Stord Airport, Sørstokken",
    cityCode: "SRP",
    cityName: "Stord",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "SXB",
    airportName: "Strasbourg",
    cityCode: "SXB",
    cityName: "Strasbourg",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "SWT",
    airportName: "Strezhevoy",
    cityCode: "SWT",
    cityName: "Strzhewoi",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "GOJ",
    airportName: "Strigino International",
    cityCode: "GOJ",
    cityName: "Nizhny Novgorod",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "STR",
    airportName: "Stuttgart",
    cityCode: "STR",
    cityName: "Stuttgart",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "VAO",
    airportName: "Suavanao",
    cityCode: "VAO",
    cityName: "Suavanao",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "SCV",
    airportName: "Suceava International",
    cityCode: "SCV",
    cityName: "Suceava",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YSB",
    airportName: "Sudbury",
    cityCode: "YSB",
    cityName: "Greater Sudbury",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "RAQ",
    airportName: "Sugimanuru",
    cityCode: "RAQ",
    cityName: "Raha",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "THS",
    airportName: "Sukhothai",
    cityCode: "THS",
    cityName: "Sukhothai Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "SKZ",
    airportName: "Sukkur",
    cityCode: "SKZ",
    cityName: "Sukkur",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "ISU",
    airportName: "Sulaimaniyah International",
    cityCode: "ISU",
    cityName: "Sulaymaniyah",
    countryCode: "IQ",
    countryName: "Iraq",
    continent: "Asia",
  },
  {
    airportCode: "SZB",
    airportName: "Sultan Abdul Aziz Shah",
    cityCode: "KUL",
    cityName: "Kuala Lumpur",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "AOR",
    airportName: "Sultan Abdul Halim",
    cityCode: "AOR",
    cityName: "Alor Setar",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "BPN",
    airportName: "Sultan Aji Muhammad Sulaiman",
    cityCode: "BPN",
    cityName: "Balikpapan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "IPH",
    airportName: "Sultan Azlan Shah",
    cityCode: "IPH",
    cityName: "Ipoh",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "TLI",
    airportName: "Sultan Bantilan",
    cityCode: "TLI",
    cityName: "Tolitoli",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KUA",
    airportName: "Sultan Haji Ahmad Shah",
    cityCode: "KUA",
    cityName: "Kuantan",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "UPG",
    airportName: "Sultan Hasanuddin International",
    cityCode: "UPG",
    cityName: "Makassar",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BTJ",
    airportName: "Sultan Iskandar Muda International",
    cityCode: "BTJ",
    cityName: "Banda Aceh",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "KBR",
    airportName: "Sultan Ismail Petra",
    cityCode: "KBR",
    cityName: "Kota Bharu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "TGG",
    airportName: "Sultan Mahmud",
    cityCode: "TGG",
    cityName: "Kuala Terengganu",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "PLM",
    airportName: "Sultan Mahmud Badaruddin II International",
    cityCode: "PLM",
    cityName: "Palembang",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SWQ",
    airportName: "Sultan Muhammad Kaharuddin III",
    cityCode: "SWQ",
    cityName: "Sumbawa Besar",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BMU",
    airportName: "Sultan Muhammad Salahudin",
    cityCode: "BMU",
    cityName: "Bima",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "PKU",
    airportName: "Sultan Syarif Kasim II International",
    cityCode: "PKU",
    cityName: "Pekanbaru",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "DJB",
    airportName: "Sultan Thaha",
    cityCode: "DJB",
    cityName: "Jambi City",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "WUX",
    airportName: "Sunan Shuofang International",
    cityCode: "WUX",
    cityName: "Wuxi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "SDL",
    airportName: "Sundsvall Timra",
    cityCode: "SDL",
    cityName: "Sundsvall",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "MCY",
    airportName: "Sunshine Coast",
    cityCode: "MCY",
    cityName: "Sunshine Coast Region",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SUY",
    airportName: "Suntar",
    cityCode: "SUY",
    cityName: "Suntar",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "PNK",
    airportName: "Supadio International",
    cityCode: "PNK",
    cityName: "Pontianak",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "URT",
    airportName: "Surat Thani International",
    cityCode: "URT",
    cityName: "Surat Thani Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "SGC",
    airportName: "Surgut International",
    cityCode: "SGC",
    cityName: "Surgut",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "SUG",
    airportName: "Surigao",
    cityCode: "SUG",
    cityName: "Surigao City",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "SKH",
    airportName: "Surkhet Airport",
    cityCode: "SKH",
    cityName: "Surkhet",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "BKK",
    airportName: "Suvarnabhumi",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "LYR",
    airportName: "Svalbard Airport, Longyear",
    cityCode: "LYR",
    cityName: "Longyearbyen",
    countryCode: "SJ",
    countryName: "Svalbard & Jan Mayen",
    continent: "Europe",
  },
  {
    airportCode: "EVG",
    airportName: "Sveg",
    cityCode: "EVG",
    cityName: "Sveg",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "SVJ",
    airportName: "Svolvaer Airport, Helle",
    cityCode: "SVJ",
    cityName: "Svolvaer",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "BDJ",
    airportName: "Syamsudin Noor International",
    cityCode: "BDJ",
    cityName: "Banjarmasin",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "SYD",
    airportName: "Sydney",
    cityCode: "SYD",
    cityName: "Sydney",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YQY",
    airportName: "Sydney/J.A. Douglas McCurdy",
    cityCode: "YQY",
    cityName: "Sydney",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SCW",
    airportName: "Syktyvkar",
    cityCode: "SCW",
    cityName: "Syktyvkar",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "GWT",
    airportName: "Sylt",
    cityCode: "GWT",
    cityName: "Westerland",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "JSY",
    airportName: "Syros Island National",
    cityCode: "JSY",
    cityName: "Syros",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "TIF",
    airportName: "Ta if Regional",
    cityCode: "TIF",
    cityName: "Ta if",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "TCX",
    airportName: "Tabas Airport",
    cityCode: "TCX",
    cityName: "Tabas",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "TBT",
    airportName: "Tabatinga International",
    cityCode: "TBT",
    cityName: "Tabatinga",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TBO",
    airportName: "Tabora",
    cityCode: "TBO",
    cityName: "Tabora",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "TBZ",
    airportName: "Tabriz International",
    cityCode: "TBZ",
    cityName: "Tabriz",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "TBG",
    airportName: "Tabubil",
    cityCode: "TBG",
    cityName: "Tabubil",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "TUU",
    airportName: "Tabuk Regional",
    cityCode: "TUU",
    cityName: "Tabuk",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "TCG",
    airportName: "Tacheng",
    cityCode: "TCG",
    cityName: "Tacheng Prefecture",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "THL",
    airportName: "Tachilek",
    cityCode: "THL",
    cityName: "Tachileik",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "XTL",
    airportName: "Tadoule Lake",
    cityCode: "XTL",
    cityName: "Tadoule Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TAG",
    airportName: "Tagbilaran",
    cityCode: "TAG",
    cityName: "Tagbilaran",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "RMQ",
    airportName: "Taichung",
    cityCode: "RMQ",
    cityName: "Taichung",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TNN",
    airportName: "Tainan",
    cityCode: "TNN",
    cityName: "Tainan",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TSA",
    airportName: "Taipei Songshan",
    cityCode: "TPE",
    cityName: "Taipei",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TTT",
    airportName: "Taitung",
    cityCode: "TTT",
    cityName: "Taitung County",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TPE",
    airportName: "Taiwan Taoyuan International",
    cityCode: "TPE",
    cityName: "Taipei",
    countryCode: "TW",
    countryName: "Taiwan",
    continent: "Asia",
  },
  {
    airportCode: "TYN",
    airportName: "Taiyuan Wusu International",
    cityCode: "TYN",
    cityName: "Taiyuan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TAI",
    airportName: "Taiz International",
    cityCode: "TAI",
    cityName: "Taiz Governorate",
    countryCode: "YE",
    countryName: "Yemen",
    continent: "Asia",
  },
  {
    airportCode: "HYN",
    airportName: "Taizhou Luqiao",
    cityCode: "HYN",
    cityName: "Taizhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TAK",
    airportName: "Takamatsu",
    cityCode: "TAK",
    cityName: "Takamatsu",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "TKP",
    airportName: "Takapoto",
    cityCode: "TKP",
    cityName: "Takapoto",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TKX",
    airportName: "Takaroa",
    cityCode: "TKX",
    cityName: "Takaroa",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TXE",
    airportName: "Takengon Rembele",
    cityCode: "TXE",
    cityName: "Takengon",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "VVZ",
    airportName: "Takhamalt",
    cityCode: "VVZ",
    cityName: "Illizi",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "TKD",
    airportName: "Takoradi",
    cityCode: "TKD",
    cityName: "Sekondi-Takoradi",
    countryCode: "GH",
    countryName: "Ghana",
    continent: "Africa",
  },
  {
    airportCode: "ARH",
    airportName: "Talagi",
    cityCode: "ARH",
    cityName: "Arkhangelsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "TLK",
    airportName: "Talakan",
    cityCode: "TLK",
    cityName: "Vitim, Sakha Republic",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TDK",
    airportName: "Taldykorgan",
    cityCode: "TDK",
    cityName: "Taldykorgan",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "TLL",
    airportName: "Tallinn",
    cityCode: "TLL",
    cityName: "Tallinn",
    countryCode: "EE",
    countryName: "Estonia",
    continent: "Europe",
  },
  {
    airportCode: "YYH",
    airportName: "Taloyoak",
    cityCode: "YYH",
    cityName: "Taloyoak",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TML",
    airportName: "Tamale",
    cityCode: "TML",
    cityName: "Tamale",
    countryCode: "GH",
    countryName: "Ghana",
    continent: "Africa",
  },
  {
    airportCode: "TNO",
    airportName: "Tamarindo",
    cityCode: "TNO",
    cityName: "Tamarindo",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "TMC",
    airportName: "Tambolaka",
    cityCode: "TMC",
    cityName: "Tambolaka",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TMU",
    airportName: "Tambor",
    cityCode: "TMU",
    cityName: "Tambor",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "TBW",
    airportName: "Tambov Donskoye",
    cityCode: "TBW",
    cityName: "Tambov",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "MJU",
    airportName: "Tampa Padang",
    cityCode: "MJU",
    cityName: "Mamuju Regency",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TMP",
    airportName: "Tampere Pirkkala",
    cityCode: "TMP",
    cityName: "Tampere",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "TAM",
    airportName: "Tampico International",
    cityCode: "TAM",
    cityName: "Tampico",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TMW",
    airportName: "Tamworth",
    cityCode: "TMW",
    cityName: "Tamworth",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "SGN",
    airportName: "Tan Son Nhat International",
    cityCode: "SGN",
    cityName: "Ho Chi Minh City",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "TTA",
    airportName: "Tan Tan",
    cityCode: "TTA",
    cityName: "Tan-Tan",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "CNF",
    airportName: "Tancredo Neves International",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TDG",
    airportName: "Tandag",
    cityCode: "TDG",
    cityName: "Tandag",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "TGT",
    airportName: "Tanga",
    cityCode: "TGT",
    cityName: "Tanga",
    countryCode: "TZ",
    countryName: "Tanzania",
    continent: "Africa",
  },
  {
    airportCode: "TNG",
    airportName: "Tangier Ibn Battouta",
    cityCode: "TNG",
    cityName: "Tangier",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "TVS",
    airportName: "Tangshan Sannuhe",
    cityCode: "TVS",
    cityName: "Tangshan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TJS",
    airportName: "Tanjung Harapan",
    cityCode: "TJS",
    cityName: "Tanjung Selor",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TGC",
    airportName: "Tanjung Manis",
    cityCode: "TGC",
    cityName: "Tanjung Manis",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "TAP",
    airportName: "Tapachula International",
    cityCode: "TAP",
    cityName: "Tapachula",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "DMB",
    airportName: "Taraz",
    cityCode: "DMB",
    cityName: "Taraz",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "LDE",
    airportName: "Tarbes Lourdes Pyrenees",
    cityCode: "LDE",
    cityName: "Lourdes",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "TRO",
    airportName: "Taree",
    cityCode: "TRO",
    cityName: "Taree",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "TGM",
    airportName: "Targu Mures International",
    cityCode: "TGM",
    cityName: "Targu Mures",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "TIZ",
    airportName: "Tari",
    cityCode: "TIZ",
    cityName: "Tari",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "TAY",
    airportName: "Tartu",
    cityCode: "TAY",
    cityName: "Tartu",
    countryCode: "EE",
    countryName: "Estonia",
    continent: "Europe",
  },
  {
    airportCode: "TAS",
    airportName: "Tashkent International",
    cityCode: "TAS",
    cityName: "Tashkent",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "TSY",
    airportName: "Tasikmalaya Wiriadinata",
    cityCode: "TSY",
    cityName: "Tasikmalaya",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "YTQ",
    airportName: "Tasiujaq",
    cityCode: "YTQ",
    cityName: "Tasiujaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TKV",
    airportName: "Tatakoto",
    cityCode: "TKV",
    cityName: "Tatakoto",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TUO",
    airportName: "Taupo",
    cityCode: "TUO",
    cityName: "Taupo",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "TRG",
    airportName: "Tauranga",
    cityCode: "TRG",
    cityName: "Tauranga",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "TWU",
    airportName: "Tawau",
    cityCode: "TWU",
    cityName: "Tawau",
    countryCode: "MY",
    countryName: "Malaysia",
    continent: "Asia",
  },
  {
    airportCode: "OUZ",
    airportName: "Tazadit",
    cityCode: "OUZ",
    cityName: "Zouerat",
    countryCode: "MR",
    countryName: "Mauritania",
    continent: "Africa",
  },
  {
    airportCode: "TBS",
    airportName: "Tbilisi International",
    cityCode: "TBS",
    cityName: "Tbilisi",
    countryCode: "GE",
    countryName: "Georgia",
    continent: "Asia",
  },
  {
    airportCode: "TFF",
    airportName: "Tefe",
    cityCode: "TFF",
    cityName: "Tefe",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "IKA",
    airportName: "Tehran Imam Khomeini International",
    cityCode: "THR",
    cityName: "Tehran",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "TXF",
    airportName: "Teixeira de Freitas",
    cityCode: "TXF",
    cityName: "Teixeira de Freitas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TEQ",
    airportName: "Tekirdag Corlu",
    cityCode: "TEQ",
    cityName: "Tekirdag",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "TFN",
    airportName: "Tenerife-North",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "TFS",
    airportName: "Tenerife South",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "TCZ",
    airportName: "Tengchong Tuofeng",
    cityCode: "TCZ",
    cityName: "Tengchong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TUC",
    airportName: "Teniente General Benjamin Matienzo International",
    cityCode: "TUC",
    cityName: "San Miguel de Tucuman",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "TDD",
    airportName: "Teniente Jorge Henrich Arauz",
    cityCode: "TDD",
    cityName: "Trinidad",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "PNT",
    airportName: "Teniente Julio Gallardo",
    cityCode: "PNT",
    cityName: "Puerto Natales",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "TCA",
    airportName: "Tennant Creek",
    cityCode: "TCA",
    cityName: "Tennant Creek",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "LUA",
    airportName: "Tenzing Hillary",
    cityCode: "LUA",
    cityName: "Lukla",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "TPQ",
    airportName: "Tepic International",
    cityCode: "TPQ",
    cityName: "Tepic",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TMJ",
    airportName: "Termez",
    cityCode: "TMJ",
    cityName: "Termez",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "EIS",
    airportName: "Terrance B. Lettsome International",
    cityCode: "TOV",
    cityName: "Tortola",
    countryCode: "VG",
    countryName: "British Virgin Islands",
    continent: "North America",
  },
  {
    airportCode: "LSS",
    airportName: "Terre-de-Haut Airport",
    cityCode: "LSS",
    cityName: "Les Saintes",
    countryCode: "FR",
    countryName: "France",
    continent: "North America",
  },
  {
    airportCode: "ZTB",
    airportName: "Tete-a-la-Baleine",
    cityCode: "ZTB",
    cityName: "Tete-a-la-Baleine, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SNW",
    airportName: "Thandwe",
    cityCode: "SNW",
    cityName: "Thandwe",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "ZBL",
    airportName: "Thangool",
    cityCode: "ZBL",
    cityName: "Biloela",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "THD",
    airportName: "Thanh Hoa Tho Xuan",
    cityCode: "THD",
    cityName: "Thanh Hoa Province",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "XTG",
    airportName: "Thargomindah",
    cityCode: "XTG",
    cityName: "Thargomindah",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YQD",
    airportName: "The Pas",
    cityCode: "YQD",
    cityName: "The Pas",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "SKG",
    airportName: "Thessaloniki International Airport Macedonia ",
    cityCode: "SKG",
    cityName: "Thessaloniki",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "YTH",
    airportName: "Thompson",
    cityCode: "YTH",
    cityName: "Thompson",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YQT",
    airportName: "Thunder Bay International",
    cityCode: "YQT",
    cityName: "Thunder Bay",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TSN",
    airportName: "Tianjin Binhai International",
    cityCode: "TSN",
    cityName: "Tianjin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TGJ",
    airportName: "Tiga",
    cityCode: "TGJ",
    cityName: "Tiga Island",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "TIJ",
    airportName: "Tijuana International",
    cityCode: "TIJ",
    cityName: "Tijuana",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TIH",
    airportName: "Tikehau",
    cityCode: "TIH",
    cityName: "Tikehau",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "IKS",
    airportName: "Tiksi",
    cityCode: "IKS",
    cityName: "Tiksi",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TIU",
    airportName: "Timaru",
    cityCode: "TIU",
    cityName: "Timaru",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "TMX",
    airportName: "Timimoun",
    cityCode: "TMX",
    cityName: "Timimoun",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "TSR",
    airportName: "Timisoara Traian Vuia International",
    cityCode: "TSR",
    cityName: "Timisoara",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "YTS",
    airportName: "Timmins/Victor M. Power",
    cityCode: "YTS",
    cityName: "Timmins",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TIN",
    airportName: "Tindouf",
    cityCode: "TIN",
    cityName: "Tindouf",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "TGI",
    airportName: "Tingo Maria",
    cityCode: "TGI",
    cityName: "Tingo Maria",
    countryCode: "PE",
    countryName: "Peru",
    continent: "South America",
  },
  {
    airportCode: "TIA",
    airportName: "Tirana International Airport Nene Tereza",
    cityCode: "TIA",
    cityName: "Tirana",
    countryCode: "AL",
    countryName: "Albania",
    continent: "Europe",
  },
  {
    airportCode: "DJG",
    airportName: "Tiska",
    cityCode: "DJG",
    cityName: "Djanet",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "TIV",
    airportName: "Tivat",
    cityCode: "TIV",
    cityName: "Tivat",
    countryCode: "ME",
    countryName: "Montenegro",
    continent: "Europe",
  },
  {
    airportCode: "PKY",
    airportName: "Tjilik Riwut",
    cityCode: "PKY",
    cityName: "Palangka Raya",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TMM",
    airportName: "Toamasina",
    cityCode: "TMM",
    cityName: "Toamasina",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "TOB",
    airportName: "Tobruk",
    cityCode: "TOB",
    cityName: "Tobruk",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "PTY",
    airportName: "Tocumen International",
    cityCode: "PTY",
    cityName: "Panama City",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "YAZ",
    airportName: "Tofino/Long Beach",
    cityCode: "YAZ",
    cityName: "Tofino",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "OBO",
    airportName: "Tokachi Obihiro",
    cityCode: "OBO",
    cityName: "Obihiro",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "TJK",
    airportName: "Tokat",
    cityCode: "TJK",
    cityName: "Tokat",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "TKN",
    airportName: "Tokunoshima",
    cityCode: "TKN",
    cityName: "Tokunoshima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "TKS",
    airportName: "Tokushima",
    cityCode: "TKS",
    cityName: "Tokushima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "FTU",
    airportName: "Tolanaro",
    cityCode: "FTU",
    cityName: "Tolanaro",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "TLE",
    airportName: "Toliara",
    cityCode: "TLE",
    cityName: "Toliara",
    countryCode: "MG",
    countryName: "Madagascar",
    continent: "Africa",
  },
  {
    airportCode: "OVB",
    airportName: "Tolmachevo",
    cityCode: "OVB",
    cityName: "Novosibirsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TLC",
    airportName: "Toluca International",
    cityCode: "TLC",
    cityName: "Toluca",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TGU",
    airportName: "Toncontin International",
    cityCode: "TGU",
    cityName: "Tegucigalpa",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "TNH",
    airportName: "Tonghua Sanyuanpu",
    cityCode: "TNH",
    cityName: "Tonghua",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TGO",
    airportName: "Tongliao",
    cityCode: "TGO",
    cityName: "Tongliao",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "TGH",
    airportName: "Tongoa",
    cityCode: "TGH",
    cityName: "Tongoa",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "TEN",
    airportName: "Tongren Fenghuang",
    cityCode: "TEN",
    cityName: "Tongren",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YYZ",
    airportName: "Toronto Pearson International",
    cityCode: "YTO",
    cityName: "Toronto",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TRC",
    airportName: "Torreon International",
    cityCode: "TRC",
    cityName: "Torreon",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TOH",
    airportName: "Torres",
    cityCode: "TOH",
    cityName: "Torres Islands",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "TTQ",
    airportName: "Tortuguero",
    cityCode: "TTQ",
    cityName: "Tortuguero",
    countryCode: "CR",
    countryName: "Costa Rica",
    continent: "North America",
  },
  {
    airportCode: "GMR",
    airportName: "Totegegie",
    cityCode: "GMR",
    cityName: "Totegegie",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TTJ",
    airportName: "Tottori",
    cityCode: "TTJ",
    cityName: "Tottori",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "AZR",
    airportName: "Touat-Cheikh Sidi Mohamed Belkebir",
    cityCode: "AZR",
    cityName: "Adrar",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "TOU",
    airportName: "Touho",
    cityCode: "TOU",
    cityName: "Touho",
    countryCode: "NC",
    countryName: "New Caledonia",
    continent: "Oceania",
  },
  {
    airportCode: "TLN",
    airportName: "Toulon Hyeres",
    cityCode: "TLN",
    cityName: "Toulon",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "TLS",
    airportName: "Toulouse Blagnac",
    cityCode: "TLS",
    cityName: "Toulouse",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "TUF",
    airportName: "Tours Val de Loire",
    cityCode: "TUF",
    cityName: "Tours",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "PAP",
    airportName: "Toussaint Louverture International",
    cityCode: "PAP",
    cityName: "Port-au-Prince",
    countryCode: "HT",
    countryName: "Haiti",
    continent: "North America",
  },
  {
    airportCode: "TSV",
    airportName: "Townsville",
    cityCode: "TSV",
    cityName: "Townsville",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "TOY",
    airportName: "Toyama",
    cityCode: "TOY",
    cityName: "Toyama",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "TOE",
    airportName: "Tozeur Nefta International",
    cityCode: "TOE",
    cityName: "Tozeur",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "TZX",
    airportName: "Trabzon",
    cityCode: "TZX",
    cityName: "Trabzon",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "YZZ",
    airportName: "Trail",
    cityCode: "YZZ",
    cityName: "Trail",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TST",
    airportName: "Trang",
    cityCode: "TST",
    cityName: "Trang",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "TDX",
    airportName: "Trat",
    cityCode: "TDX",
    cityName: "Trat Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "TCB",
    airportName: "Treasure Cay",
    cityCode: "TCB",
    cityName: "Treasure Cay",
    countryCode: "BS",
    countryName: "Bahamas",
    continent: "North America",
  },
  {
    airportCode: "PUU",
    airportName: "Tres de Mayo",
    cityCode: "PUU",
    cityName: "Puerto Asis",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "TJL",
    airportName: "Tres Lagoas",
    cityCode: "TJL",
    cityName: "Tres Lagoas",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "TSF",
    airportName: "Treviso",
    cityCode: "VCE",
    cityName: "Venice",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "KTM",
    airportName: "Tribhuvan International",
    cityCode: "KTM",
    cityName: "Kathmandu",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "TRS",
    airportName: "Trieste Friuli Venezia Giulia",
    cityCode: "TRS",
    cityName: "Trieste",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "TIP",
    airportName: "Tripoli International",
    cityCode: "TIP",
    cityName: "Tripoli",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "THN",
    airportName: "Trollhattan Vanersborg",
    cityCode: "THN",
    cityName: "Trollhattan",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "TOS",
    airportName: "Tromso Airport, Langnes",
    cityCode: "TOS",
    cityName: "Tromso",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "TRD",
    airportName: "Trondheim Airport, Vaernes",
    cityCode: "TRD",
    cityName: "Trondheim",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "TSJ",
    airportName: "Tsushima",
    cityCode: "TSJ",
    cityName: "Tsushima",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "TUB",
    airportName: "Tubuai Mataura",
    cityCode: "TUB",
    cityName: "Tubuai",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "TFI",
    airportName: "Tufi",
    cityCode: "TFI",
    cityName: "Tufi",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "TBH",
    airportName: "Tugdan",
    cityCode: "TBH",
    cityName: "Tablas Island",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "TUG",
    airportName: "Tuguegarao",
    cityCode: "TUG",
    cityName: "Tuguegarao",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "YUB",
    airportName: "Tuktoyaktuk/James Gruben",
    cityCode: "YUB",
    cityName: "Tuktoyaktuk",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZFN",
    airportName: "Tulita",
    cityCode: "ZFN",
    cityName: "Tulita",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TMI",
    airportName: "Tumlingtar",
    cityCode: "TMI",
    cityName: "Tumlingtar",
    countryCode: "NP",
    countryName: "Nepal",
    continent: "Asia",
  },
  {
    airportCode: "TUN",
    airportName: "Tunis Carthage International",
    cityCode: "TUN",
    cityName: "Tunis",
    countryCode: "TN",
    countryName: "Tunisia",
    continent: "Africa",
  },
  {
    airportCode: "IAR",
    airportName: "Tunoshna",
    cityCode: "IAR",
    cityName: "Yaroslavl",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "TUI",
    airportName: "Turaif Domestic",
    cityCode: "TUI",
    cityName: "Turaif",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "TUK",
    airportName: "Turbat International",
    cityCode: "TUK",
    cityName: "Turbat",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "BJW",
    airportName: "Turelelo Soa",
    cityCode: "BJW",
    cityName: "Bajawa",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "TRN",
    airportName: "Turin",
    cityCode: "TRN",
    cityName: "Turin",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "CRZ",
    airportName: "Turkmenabat",
    cityCode: "CRZ",
    cityName: "Turkmenabat",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "KRW",
    airportName: "Turkmenbashi",
    cityCode: "KRW",
    cityName: "Turkmenbasy",
    countryCode: "TM",
    countryName: "Turkmenistan",
    continent: "Asia",
  },
  {
    airportCode: "TKU",
    airportName: "Turku",
    cityCode: "TKU",
    cityName: "Turku",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "TLQ",
    airportName: "Turpan Jiaohe",
    cityCode: "TLQ",
    cityName: "Turpan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "THX",
    airportName: "Turukhansk",
    cityCode: "THX",
    cityName: "Turukhansk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TGZ",
    airportName: "Tuxtla Gutierrez International",
    cityCode: "TGZ",
    cityName: "Tuxtla Gutierrez",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "TZL",
    airportName: "Tuzla International",
    cityCode: "TZL",
    cityName: "Tuzla",
    countryCode: "BA",
    countryName: "Bosnia & Herzegovina",
    continent: "Europe",
  },
  {
    airportCode: "TYD",
    airportName: "Tynda",
    cityCode: "TYD",
    cityName: "Tynda",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UTP",
    airportName: "U-Tapao International",
    cityCode: "UTP",
    cityName: "Pattaya",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "UAH",
    airportName: "Ua Huka",
    cityCode: "UAH",
    cityName: "Ua Huka",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "UAP",
    airportName: "Ua Pou",
    cityCode: "UAP",
    cityName: "Ua Pou",
    countryCode: "PF",
    countryName: "French Polynesia",
    continent: "Oceania",
  },
  {
    airportCode: "UBA",
    airportName: "Uberaba",
    cityCode: "UBA",
    cityName: "Uberaba",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "UDI",
    airportName: "Uberlandia",
    cityCode: "UDI",
    cityName: "Uberlandia",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "UBP",
    airportName: "Ubon Ratchathani",
    cityCode: "UBP",
    cityName: "Ubon Ratchathani Province",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "UTH",
    airportName: "Udon Thani International",
    cityCode: "UTH",
    cityName: "Udon Thani",
    countryCode: "TH",
    countryName: "Thailand",
    continent: "Asia",
  },
  {
    airportCode: "UFA",
    airportName: "Ufa International",
    cityCode: "UFA",
    cityName: "Ufa",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "DYR",
    airportName: "Ugolny",
    cityCode: "DYR",
    cityName: "Anadyr",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UCT",
    airportName: "Ukhta",
    cityCode: "UCT",
    cityName: "Ukhta",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "UKA",
    airportName: "Ukunda",
    cityCode: "UKA",
    cityName: "Ukunda",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "ULO",
    airportName: "Ulaangom",
    cityCode: "ULO",
    cityName: "Ulaangom",
    countryCode: "MN",
    countryName: "Mongolia",
    continent: "Asia",
  },
  {
    airportCode: "HLH",
    airportName: "Ulanhot",
    cityCode: "HLH",
    cityName: "Ulan Hot",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "UCB",
    airportName: "Ulanqab Jining",
    cityCode: "UCB",
    cityName: "Ulanqab",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "RNA",
    airportName: "Ulawa",
    cityCode: "RNA",
    cityName: "Arona",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "ULB",
    airportName: "Ulei",
    cityCode: "ULB",
    cityName: "Toak",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "USN",
    airportName: "Ulsan",
    cityCode: "USN",
    cityName: "Ulsan",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "YHI",
    airportName: "Ulukhaktok/Holman",
    cityCode: "YHI",
    cityName: "Ulukhaktok",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ULX",
    airportName: "Ulusaba",
    cityCode: "ULX",
    cityName: "Sabi Sand Game Reserve",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "ULV",
    airportName: "Ulyanovsk Baratayevka",
    cityCode: "ULY",
    cityName: "Ulyanovsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "ULY",
    airportName: "Ulyanovsk Vostochny",
    cityCode: "ULY",
    cityName: "Ulyanovsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "WGP",
    airportName: "Umbu Mehang Kunda",
    cityCode: "WGP",
    cityName: "Waingapu",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "UME",
    airportName: "Umea",
    cityCode: "UME",
    cityName: "Umea",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "YUD",
    airportName: "Umiujaq",
    cityCode: "YUD",
    cityName: "Umiujaq",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "JUV",
    airportName: "Upernavik",
    cityCode: "JUV",
    cityName: "Upernavik",
    countryCode: "GL",
    countryName: "Greenland",
    continent: "North America",
  },
  {
    airportCode: "UTN",
    airportName: "Upington",
    cityCode: "UTN",
    cityName: "Upington",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "URJ",
    airportName: "Uray",
    cityCode: "URJ",
    cityName: "Uray",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UZR",
    airportName: "Urdzhar",
    cityCode: "UZR",
    cityName: "Urzhar",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "UGC",
    airportName: "Urgench International",
    cityCode: "UGC",
    cityName: "Urgench",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    continent: "Asia",
  },
  {
    airportCode: "OMH",
    airportName: "Urmia",
    cityCode: "OMH",
    cityName: "Urmia",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ATD",
    airportName: "Uru Harbour",
    cityCode: "ATD",
    cityName: "Atoifi",
    countryCode: "SB",
    countryName: "Solomon Islands",
    continent: "Oceania",
  },
  {
    airportCode: "UPN",
    airportName: "Uruapan International",
    cityCode: "UPN",
    cityName: "Uruapan",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "URC",
    airportName: "Urumqi Diwopu International",
    cityCode: "URC",
    cityName: "Urumqi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "USQ",
    airportName: "Usak",
    cityCode: "USQ",
    cityName: "Usak",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "USH",
    airportName: "Ushuaia Malvinas Argentinas International",
    cityCode: "USH",
    cityName: "Ushuaia",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "IPN",
    airportName: "Usiminas",
    cityCode: "IPN",
    cityName: "Ipatinga",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "USK",
    airportName: "Usinsk",
    cityCode: "USK",
    cityName: "Usinsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "UKX",
    airportName: "Ust-Kut",
    cityCode: "UKX",
    cityName: "Ust-Kut",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UKG",
    airportName: "Ust-Kuyga",
    cityCode: "UKG",
    cityName: "Ust-Kuyga",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UMS",
    airportName: "Ust-Maya",
    cityCode: "UMS",
    cityName: "Ust-Maya",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "USR",
    airportName: "Ust-Nera",
    cityCode: "USR",
    cityName: "Ust-Nera",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UTS",
    airportName: "Ust-Tsilma",
    cityCode: "UTS",
    cityName: "Ust-Tsilma",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "OGM",
    airportName: "Ustupu-Ogobsucum",
    cityCode: "OGM",
    cityName: "Ustupo",
    countryCode: "PA",
    countryName: "Panama",
    continent: "North America",
  },
  {
    airportCode: "UII",
    airportName: "Utila",
    cityCode: "UII",
    cityName: "Utila",
    countryCode: "HN",
    countryName: "Honduras",
    continent: "North America",
  },
  {
    airportCode: "MCX",
    airportName: "Uytash",
    cityCode: "MCX",
    cityName: "Makhachkala",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "UYU",
    airportName: "Uyuni",
    cityCode: "UYU",
    cityName: "Uyuni",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "ANU",
    airportName: "V. C. Bird International",
    cityCode: "ANU",
    cityName: "Antigua",
    countryCode: "AG",
    countryName: "Antigua & Barbuda",
    continent: "North America",
  },
  {
    airportCode: "VAA",
    airportName: "Vaasa",
    cityCode: "VAA",
    cityName: "Vaasa",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "PRG",
    airportName: "Vaclav Havel Airport Prague",
    cityCode: "PRG",
    cityName: "Prague",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "VDS",
    airportName: "Vadso",
    cityCode: "VDS",
    cityName: "Vadso",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "FAE",
    airportName: "Vagar",
    cityCode: "FAE",
    cityName: "Sorvagur",
    countryCode: "FO",
    countryName: "Faroe Islands",
    continent: "Europe",
  },
  {
    airportCode: "BEL",
    airportName: "Val de Cans International",
    cityCode: "BEL",
    cityName: "Belem",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "YVO",
    airportName: "Val-d Or",
    cityCode: "YVO",
    cityName: "Val-d Or",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "VAL",
    airportName: "Valenca",
    cityCode: "VAL",
    cityName: "Valenca, Bahia",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "VLC",
    airportName: "Valencia",
    cityCode: "VLC",
    cityName: "Valencia",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "VLS",
    airportName: "Valesdir",
    cityCode: "VLS",
    cityName: "Valesdir",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "VLL",
    airportName: "Valladolid",
    cityCode: "VLL",
    cityName: "Valladolid",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "VAN",
    airportName: "Van Ferit Melen",
    cityCode: "VAN",
    cityName: "Van",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "NEV",
    airportName: "Vance W. Amory International",
    cityCode: "NEV",
    cityName: "Nevis",
    countryCode: "KN",
    countryName: "St. Kitts & Nevis",
    continent: "North America",
  },
  {
    airportCode: "CXH",
    airportName: "Vancouver Harbour Flight Centre",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YVR",
    airportName: "Vancouver International",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "VAI",
    airportName: "Vanimo",
    cityCode: "VAI",
    cityName: "Vanimo",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "SLH",
    airportName: "Vanua Lava",
    cityCode: "SLH",
    cityName: "Sola",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "VBV",
    airportName: "Vanuabalavu",
    cityCode: "VBV",
    cityName: "Vanua Balavu",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "VAW",
    airportName: "Vardo Airport, Svartnes",
    cityCode: "VAW",
    cityName: "Vardo",
    countryCode: "NO",
    countryName: "Norway",
    continent: "Europe",
  },
  {
    airportCode: "VAR",
    airportName: "Varna",
    cityCode: "VAR",
    cityName: "Varna",
    countryCode: "BG",
    countryName: "Bulgaria",
    continent: "Europe",
  },
  {
    airportCode: "VAV",
    airportName: "Vava?u International",
    cityCode: "VAV",
    cityName: "Vava?u",
    countryCode: "TO",
    countryName: "Tonga",
    continent: "Oceania",
  },
  {
    airportCode: "VXO",
    airportName: "Vaxjo Smaland",
    cityCode: "VXO",
    cityName: "Vaxjo",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "MLE",
    airportName: "Velana International",
    cityCode: "MLE",
    cityName: "Male",
    countryCode: "MV",
    countryName: "Maldives",
    continent: "Asia",
  },
  {
    airportCode: "VUS",
    airportName: "Veliky Ustyug",
    cityCode: "VUS",
    cityName: "Veliky Ustyug",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "VCE",
    airportName: "Venice Marco Polo",
    cityCode: "VCE",
    cityName: "Venice",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "VER",
    airportName: "Veracruz International",
    cityCode: "VER",
    cityName: "Veracruz",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "VHV",
    airportName: "Verkhnevilyuysk",
    cityCode: "VHV",
    cityName: "Verkhnevilyuysk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "VRN",
    airportName: "Verona Villafranca",
    cityCode: "VRN",
    cityName: "Verona",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "SDE",
    airportName: "Vicecomodoro Angel de la Paz Aragones",
    cityCode: "SDE",
    cityName: "Santiago del Estero",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "VFA",
    airportName: "Victoria Falls International",
    cityCode: "VFA",
    cityName: "Victoria Falls",
    countryCode: "ZW",
    countryName: "Zimbabwe",
    continent: "Africa",
  },
  {
    airportCode: "YWH",
    airportName: "Victoria Inner Harbour SPB",
    cityCode: "YYJ",
    cityName: "Victoria",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YYJ",
    airportName: "Victoria International",
    cityCode: "YYJ",
    cityName: "Victoria",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "VIE",
    airportName: "Vienna International",
    cityCode: "VIE",
    cityName: "Vienna",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "VGO",
    airportName: "Vigo Peinador",
    cityCode: "VGO",
    cityName: "Vigo",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "VRL",
    airportName: "Vila Real",
    cityCode: "VRL",
    cityName: "Vila Real",
    countryCode: "PT",
    countryName: "Portugal",
    continent: "Europe",
  },
  {
    airportCode: "VNX",
    airportName: "Vilankulo",
    cityCode: "VNX",
    cityName: "Vilankulo",
    countryCode: "MZ",
    countryName: "Mozambique",
    continent: "Africa",
  },
  {
    airportCode: "VHM",
    airportName: "Vilhelmina",
    cityCode: "VHM",
    cityName: "Vilhelmina",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "BVH",
    airportName: "Vilhena",
    cityCode: "BVH",
    cityName: "Vilhena",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "VGZ",
    airportName: "Villa Garzon",
    cityCode: "VGZ",
    cityName: "Villagarzon",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "VAM",
    airportName: "Villa International",
    cityCode: "VAM",
    cityName: "Maamigili",
    countryCode: "MV",
    countryName: "Maldives",
    continent: "Asia",
  },
  {
    airportCode: "VSA",
    airportName: "Villahermosa International",
    cityCode: "VSA",
    cityName: "Villahermosa",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "VNO",
    airportName: "Vilnius",
    cityCode: "VNO",
    cityName: "Vilnius",
    countryCode: "LT",
    countryName: "Lithuania",
    continent: "Europe",
  },
  {
    airportCode: "CYO",
    airportName: "Vilo Acuna",
    cityCode: "CYO",
    cityName: "Cayo Largo del Sur",
    countryCode: "CU",
    countryName: "Cuba",
    continent: "North America",
  },
  {
    airportCode: "VYI",
    airportName: "Vilyuysk",
    cityCode: "VYI",
    cityName: "Vilyuysk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "TPS",
    airportName: "Vincenzo Florio Airport Trapani Birgi",
    cityCode: "TPS",
    cityName: "Trapani",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "VII",
    airportName: "Vinh International",
    cityCode: "VII",
    cityName: "Vinh",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "VRC",
    airportName: "Virac",
    cityCode: "VRC",
    cityName: "Virac, Catanduanes",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "VCP",
    airportName: "Viracopos International",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "VIJ",
    airportName: "Virgin Gorda",
    cityCode: "VIJ",
    cityName: "Virgin Gorda",
    countryCode: "VG",
    countryName: "British Virgin Islands",
    continent: "North America",
  },
  {
    airportCode: "VIR",
    airportName: "Virginia Airport",
    cityCode: "DUR",
    cityName: "Durban",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "VVI",
    airportName: "Viru Viru International",
    cityCode: "SRZ",
    cityName: "Santa Cruz de la Sierra",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "VBY",
    airportName: "Visby",
    cityCode: "VBY",
    cityName: "Visby",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "VTB",
    airportName: "Vitebsk Vostochny",
    cityCode: "VTB",
    cityName: "Vitebsk",
    countryCode: "BY",
    countryName: "Belarus",
    continent: "Europe",
  },
  {
    airportCode: "VIT",
    airportName: "Vitoria",
    cityCode: "VIT",
    cityName: "Vitoria-Gasteiz",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "VDC",
    airportName: "Vitoria da Conquista",
    cityCode: "VDC",
    cityName: "Vitoria da Conquista",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "VVO",
    airportName: "Vladivostok International",
    cityCode: "VVO",
    cityName: "Vladivostok",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "VKO",
    airportName: "Vnukovo International",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "VOG",
    airportName: "Volgograd International",
    cityCode: "VOG",
    cityName: "Volgograd",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "VKT",
    airportName: "Vorkuta",
    cityCode: "VKT",
    cityName: "Vorkuta",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "VOZ",
    airportName: "Voronezh International",
    cityCode: "VOZ",
    cityName: "Voronezh",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "KDV",
    airportName: "Vunisea",
    cityCode: "KDV",
    cityName: "Kadavu Island",
    countryCode: "FJ",
    countryName: "Fiji",
    continent: "Oceania",
  },
  {
    airportCode: "YWK",
    airportName: "Wabush",
    cityCode: "YWK",
    cityName: "Wabush",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WAE",
    airportName: "Wadi al-Dawasir Domestic",
    cityCode: "WAE",
    cityName: "Wadi ad-Dawasir",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "WGA",
    airportName: "Wagga Wagga",
    cityCode: "WGA",
    cityName: "Wagga Wagga",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "RJM",
    airportName: "Waisai Marinda",
    cityCode: "RJM",
    cityName: "Waisai",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "WJR",
    airportName: "Wajir",
    cityCode: "WJR",
    cityName: "Wajir",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "WKJ",
    airportName: "Wakkanai",
    cityCode: "WKJ",
    cityName: "Wakkanai",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "WLH",
    airportName: "Walaha",
    cityCode: "WLH",
    cityName: "Walaha",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "WVB",
    airportName: "Walvis Bay",
    cityCode: "WVB",
    cityName: "Walvis Bay",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "WMX",
    airportName: "Wamena",
    cityCode: "WMX",
    cityName: "Wamena",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "WXN",
    airportName: "Wanzhou Wuqiao",
    cityCode: "WXN",
    cityName: "Wanzhou District",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WBM",
    airportName: "Wapenamanda",
    cityCode: "WBM",
    cityName: "Wapenamanda District",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "QRW",
    airportName: "Warri",
    cityCode: "QRW",
    cityName: "Warri",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "WMB",
    airportName: "Warrnambool",
    cityCode: "WMB",
    cityName: "Warrnambool",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "WAW",
    airportName: "Warsaw Chopin",
    cityCode: "WAW",
    cityName: "Warsaw",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "WMI",
    airportName: "Warsaw Modlin",
    cityCode: "WAW",
    cityName: "Warsaw",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "YKQ",
    airportName: "Waskaganish",
    cityCode: "YKQ",
    cityName: "Waskaganish",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WAT",
    airportName: "Waterford Airport",
    cityCode: "WAT",
    cityName: "Waterford",
    countryCode: "IE",
    countryName: "Ireland",
    continent: "Europe",
  },
  {
    airportCode: "VTE",
    airportName: "Wattay International",
    cityCode: "VTE",
    cityName: "Vientiane",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "WRZ",
    airportName: "Weerawila",
    cityCode: "WRZ",
    cityName: "Weerawila",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "NRN",
    airportName: "Weeze",
    cityCode: "DUS",
    cityName: "Dusseldorf",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "WEF",
    airportName: "Weifang",
    cityCode: "WEF",
    cityName: "Weifang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WEH",
    airportName: "Weihai Dashuibo",
    cityCode: "WEH",
    cityName: "Weihai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WEI",
    airportName: "Weipa",
    cityCode: "WEI",
    cityName: "Weipa",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "WLG",
    airportName: "Wellington International",
    cityCode: "WLG",
    cityName: "Wellington",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "YNC",
    airportName: "Wemindji",
    cityCode: "YNC",
    cityName: "Wemindji, Quebec",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WNH",
    airportName: "Wenshan Puzhehei",
    cityCode: "WNH",
    cityName: "Wenshan City",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WNZ",
    airportName: "Wenzhou Longwan International",
    cityCode: "WNZ",
    cityName: "Wenzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YCG",
    airportName: "West Kootenay Regional",
    cityCode: "YCG",
    cityName: "Castlegar",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WSZ",
    airportName: "Westport",
    cityCode: "WSZ",
    cityName: "Westport",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "WWK",
    airportName: "Wewak",
    cityCode: "WWK",
    cityName: "Wewak",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    continent: "Oceania",
  },
  {
    airportCode: "YXN",
    airportName: "Whale Cove",
    cityCode: "YXN",
    cityName: "Whale Cove, Nunavut",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WAG",
    airportName: "Whanganui Airport",
    cityCode: "WAG",
    cityName: "Whanganui",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "WRE",
    airportName: "Whangarei",
    cityCode: "WRE",
    cityName: "Whangarei",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "TAH",
    airportName: "Whitegrass",
    cityCode: "TAH",
    cityName: "Tanna",
    countryCode: "VU",
    countryName: "Vanuatu",
    continent: "Oceania",
  },
  {
    airportCode: "PPP",
    airportName: "Whitsunday Coast",
    cityCode: "PPP",
    cityName: "Proserpine",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "WYA",
    airportName: "Whyalla",
    cityCode: "WYA",
    cityName: "Whyalla",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YWL",
    airportName: "Williams Lake",
    cityCode: "YWL",
    cityName: "Williams Lake",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WIL",
    airportName: "Wilson",
    cityCode: "NBO",
    cityName: "Nairobi",
    countryCode: "KE",
    countryName: "Kenya",
    continent: "Africa",
  },
  {
    airportCode: "WNR",
    airportName: "Windorah",
    cityCode: "WNR",
    cityName: "Windorah",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YQG",
    airportName: "Windsor International",
    cityCode: "YQG",
    cityName: "Windsor",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "YWG",
    airportName: "Winnipeg James Armstrong Richardson International",
    cityCode: "YWG",
    cityName: "Winnipeg",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "WIN",
    airportName: "Winton",
    cityCode: "WIN",
    cityName: "Winton",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "PRY",
    airportName: "Wonderboom",
    cityCode: "PRY",
    cityName: "Pretoria",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "WJU",
    airportName: "Wonju",
    cityCode: "WJU",
    cityName: "Wonju",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "LWE",
    airportName: "Wonopito",
    cityCode: "LWE",
    cityName: "Lewoleba",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "BHE",
    airportName: "Woodbourne",
    cityCode: "BHE",
    cityName: "Blenheim",
    countryCode: "NZ",
    countryName: "New Zealand",
    continent: "Oceania",
  },
  {
    airportCode: "WUA",
    airportName: "Wuhai",
    cityCode: "WUA",
    cityName: "Wuhai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WUH",
    airportName: "Wuhan Tianhe International",
    cityCode: "WUH",
    cityName: "Wuhan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WUS",
    airportName: "Wuyishan",
    cityCode: "WUS",
    cityName: "Wuyishan, Fujian",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XIY",
    airportName: "Xi an Xianyang International",
    cityCode: "SIA",
    cityName: "Xi An",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XMN",
    airportName: "Xiamen Gaoqi International",
    cityCode: "XMN",
    cityName: "Xiamen",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XFN",
    airportName: "Xiangyang Liuji",
    cityCode: "XFN",
    cityName: "Xiangyang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XIC",
    airportName: "Xichang Qingshan",
    cityCode: "XIC",
    cityName: "Xichang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XKH",
    airportName: "Xieng Khouang",
    cityCode: "XKH",
    cityName: "Phonsavan",
    countryCode: "LA",
    countryName: "Laos",
    continent: "Asia",
  },
  {
    airportCode: "XIL",
    airportName: "Xilinhot",
    cityCode: "XIL",
    cityName: "Xilinhot",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ACX",
    airportName: "Xingyi Wanfenglin",
    cityCode: "ACX",
    cityName: "Xingyi, Guizhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XNN",
    airportName: "Xining Caojiabao International",
    cityCode: "XNN",
    cityName: "Xining",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NLT",
    airportName: "Xinyuan Nalati",
    cityCode: "NLT",
    cityName: "Xinyuan County",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "WUT",
    airportName: "Xinzhou Wutaishan",
    cityCode: "WUT",
    cityName: "Xinzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "JHG",
    airportName: "Xishuangbanna Gasa",
    cityCode: "JHG",
    cityName: "Jinghong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "XUZ",
    airportName: "Xuzhou Guanyin International",
    cityCode: "XUZ",
    cityName: "Xuzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BYC",
    airportName: "Yacuiba",
    cityCode: "BYC",
    cityName: "Yacuiba",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "JOS",
    airportName: "Yakubu Gowon",
    cityCode: "JOS",
    cityName: "Jos",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "YKS",
    airportName: "Yakutsk",
    cityCode: "YKS",
    cityName: "Yakutsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "GAJ",
    airportName: "Yamagata",
    cityCode: "GAJ",
    cityName: "Yamagata",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "UBJ",
    airportName: "Yamaguchi Ube",
    cityCode: "UBJ",
    cityName: "Ube",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "ENY",
    airportName: "Yan an Ershilipu",
    cityCode: "ENY",
    cityName: "Yan an",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YNB",
    airportName: "Yanbu",
    cityCode: "YNB",
    cityName: "Yanbu",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    continent: "Asia",
  },
  {
    airportCode: "YNZ",
    airportName: "Yancheng Nanyang International",
    cityCode: "YNZ",
    cityName: "Yancheng",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "RGN",
    airportName: "Yangon International",
    cityCode: "RGN",
    cityName: "Yangon",
    countryCode: "MM",
    countryName: "Myanmar (Burma)",
    continent: "Asia",
  },
  {
    airportCode: "YNY",
    airportName: "Yangyang International",
    cityCode: "YNY",
    cityName: "Yangyang County",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "YTY",
    airportName: "Yangzhou Taizhou International",
    cityCode: "YTY",
    cityName: "Yangzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YNJ",
    airportName: "Yanji Chaoyangchuan International",
    cityCode: "YNJ",
    cityName: "Yanji",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YNT",
    airportName: "Yantai Laishan",
    cityCode: "YNT",
    cityName: "Yantai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "NSI",
    airportName: "Yaounde Nsimalen International",
    cityCode: "YAO",
    cityName: "Yaounde",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "YAP",
    airportName: "Yap International",
    cityCode: "YAP",
    cityName: "Yap",
    countryCode: "FM",
    countryName: "Micronesia",
    continent: "Oceania",
  },
  {
    airportCode: "EJA",
    airportName: "Yariguies",
    cityCode: "EJA",
    cityName: "Barrancabermeja",
    countryCode: "CO",
    countryName: "Colombia",
    continent: "South America",
  },
  {
    airportCode: "YZF",
    airportName: "Yellowknife",
    cityCode: "YZF",
    cityName: "Yellowknife",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "KJA",
    airportName: "Yemelyanovo International",
    cityCode: "KJA",
    cityName: "Krasnoyarsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "YEI",
    airportName: "Yenisehir",
    cityCode: "YEI",
    cityName: "Bursa",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "RSU",
    airportName: "Yeosu",
    cityCode: "RSU",
    cityName: "Yeosu",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "YBP",
    airportName: "Yibin Caiba",
    cityCode: "YBP",
    cityName: "Yibin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YIH",
    airportName: "Yichang Sanxia",
    cityCode: "YIH",
    cityName: "Yichang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "LDS",
    airportName: "Yichun Lindu",
    cityCode: "LDS",
    cityName: "Yichun, Heilongjiang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YIC",
    airportName: "Yichun Mingyueshan",
    cityCode: "YIC",
    cityName: "Yichun, Jiangxi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "INC",
    airportName: "Yinchuan Hedong International",
    cityCode: "INC",
    cityName: "Yinchuan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YKH",
    airportName: "Yingkou Lanqi",
    cityCode: "YKH",
    cityName: "Yingkou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YIN",
    airportName: "Yining",
    cityCode: "YIN",
    cityName: "Ghulja",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YIW",
    airportName: "Yiwu",
    cityCode: "YIW",
    cityName: "Yiwu",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YOL",
    airportName: "Yola",
    cityCode: "YOL",
    cityName: "Yola",
    countryCode: "NG",
    countryName: "Nigeria",
    continent: "Africa",
  },
  {
    airportCode: "LLF",
    airportName: "Yongzhou Lingling",
    cityCode: "LLF",
    cityName: "Yongzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZAC",
    airportName: "York Landing",
    cityCode: "ZAC",
    cityName: "York Factory First Nation",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "JOK",
    airportName: "Yoshkar-Ola",
    cityCode: "JOK",
    cityName: "Yoshkar-Ola",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "UYN",
    airportName: "Yulin Yuyang",
    cityCode: "UYN",
    cityName: "Yulin",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YCU",
    airportName: "Yuncheng Guangong",
    cityCode: "YCU",
    cityName: "Yuncheng",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YUS",
    airportName: "Yushu Batang",
    cityCode: "YUS",
    cityName: "Yushu City, Qinghai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DEE",
    airportName: "Yuzhno-Kurilsk Mendeleyevo",
    cityCode: "DEE",
    cityName: "Yuzhno-Kurilsk",
    countryCode: "ZZ",
    countryName: "Unknown Region",
    continent: "Asia",
  },
  {
    airportCode: "UUS",
    airportName: "Yuzhno-Sakhalinsk",
    cityCode: "UUS",
    cityName: "Yuzhno-Sakhalinsk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "ACZ",
    airportName: "Zabol",
    cityCode: "ACZ",
    cityName: "Zabol",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ZCL",
    airportName: "Zacatecas International",
    cityCode: "ZCL",
    cityName: "Zacatecas",
    countryCode: "MX",
    countryName: "Mexico",
    continent: "North America",
  },
  {
    airportCode: "ZAD",
    airportName: "Zadar",
    cityCode: "ZAD",
    cityName: "Zadar",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "KZR",
    airportName: "Zafer",
    cityCode: "KZR",
    cityName: "Kutahya",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "OZG",
    airportName: "Zagora",
    cityCode: "OZG",
    cityName: "Zagora",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "ZAG",
    airportName: "Zagreb",
    cityCode: "ZAG",
    cityName: "Zagreb",
    countryCode: "HR",
    countryName: "Croatia",
    continent: "Europe",
  },
  {
    airportCode: "ZAH",
    airportName: "Zahedan",
    cityCode: "ZAH",
    cityName: "Zahedan",
    countryCode: "IR",
    countryName: "Iran",
    continent: "Asia",
  },
  {
    airportCode: "ZTH",
    airportName: "Zakynthos International",
    cityCode: "ZTH",
    cityName: "Zakynthos Island",
    countryCode: "GR",
    countryName: "Greece",
    continent: "Europe",
  },
  {
    airportCode: "ZAM",
    airportName: "Zamboanga International",
    cityCode: "ZAM",
    cityName: "Zamboanga City",
    countryCode: "PH",
    countryName: "Philippines",
    continent: "Asia",
  },
  {
    airportCode: "OZH",
    airportName: "Zaporizhia International",
    cityCode: "OZH",
    cityName: "Zaporizhia",
    countryCode: "UA",
    countryName: "Ukraine",
    continent: "Europe",
  },
  {
    airportCode: "ZAZ",
    airportName: "Zaragoza",
    cityCode: "ZAZ",
    cityName: "Zaragoza",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "ZAJ",
    airportName: "Zaranj",
    cityCode: "ZAJ",
    cityName: "Zaranj",
    countryCode: "AF",
    countryName: "Afghanistan",
    continent: "Asia",
  },
  {
    airportCode: "SZI",
    airportName: "Zaysan",
    cityCode: "SZI",
    cityName: "Zaysan",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "TLM",
    airportName: "Zenata Messali El Hadj",
    cityCode: "TLM",
    cityName: "Tlemcen",
    countryCode: "DZ",
    countryName: "Algeria",
    continent: "Africa",
  },
  {
    airportCode: "DYG",
    airportName: "Zhangjiajie Hehua International",
    cityCode: "DYG",
    cityName: "Zhangjiajie",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZQZ",
    airportName: "Zhangjiakou Ningyuan",
    cityCode: "ZQZ",
    cityName: "Zhangjiakou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YZY",
    airportName: "Zhangye Ganzhou",
    cityCode: "YZY",
    cityName: "Zhangye",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZHA",
    airportName: "Zhanjiang",
    cityCode: "ZHA",
    cityName: "Zhanjiang",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZAT",
    airportName: "Zhaotong",
    cityCode: "ZAT",
    cityName: "Zhaotong",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "CGO",
    airportName: "Zhengzhou Xinzheng International",
    cityCode: "CGO",
    cityName: "Zhengzhou",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "DZN",
    airportName: "Zhezkazgan",
    cityCode: "DZN",
    cityName: "Jezkazgan",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    continent: "Asia",
  },
  {
    airportCode: "ZIX",
    airportName: "Zhigansk",
    cityCode: "ZIX",
    cityName: "Zhigansk",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "PZH",
    airportName: "Zhob",
    cityCode: "PZH",
    cityName: "Zhob",
    countryCode: "PK",
    countryName: "Pakistan",
    continent: "Asia",
  },
  {
    airportCode: "ZHY",
    airportName: "Zhongwei Shapotou",
    cityCode: "ZHY",
    cityName: "Zhongwei",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "HSN",
    airportName: "Zhoushan Putuoshan",
    cityCode: "HSN",
    cityName: "Zhoushan",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZUH",
    airportName: "Zhuhai Jinwan",
    cityCode: "ZUH",
    cityName: "Zhuhai",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZIA",
    airportName: "Zhukovsky International",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "IEG",
    airportName: "Zielona Gora",
    cityCode: "IEG",
    cityName: "Zielona Gora",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "ZIG",
    airportName: "Ziguinchor",
    cityCode: "ZIG",
    cityName: "Ziguinchor",
    countryCode: "SN",
    countryName: "Senegal",
    continent: "Africa",
  },
  {
    airportCode: "ZND",
    airportName: "Zinder",
    cityCode: "ZND",
    cityName: "Zinder",
    countryCode: "NE",
    countryName: "Niger",
    continent: "Africa",
  },
  {
    airportCode: "ZIS",
    airportName: "Zintan",
    cityCode: "ZIS",
    cityName: "Zintan",
    countryCode: "LY",
    countryName: "Libya",
    continent: "Africa",
  },
  {
    airportCode: "IZA",
    airportName: "Zona da Mata Regional",
    cityCode: "JDF",
    cityName: "Juiz de Fora",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "MCZ",
    airportName: "Zumbi dos Palmares International",
    cityCode: "MCZ",
    cityName: "Maceio",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ZYI",
    airportName: "Zunyi Xinzhou",
    cityCode: "ZYI",
    cityName: "Zunyi",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "ZRH",
    airportName: "Zurich Airport",
    cityCode: "ZRH",
    cityName: "Zurich",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "EVN",
    airportName: "Zvartnots International",
    cityCode: "EVN",
    cityName: "Yerevan",
    countryCode: "AM",
    countryName: "Armenia",
    continent: "Asia",
  },
  {
    airportCode: "ZKP",
    airportName: "Zyryanka West",
    cityCode: "ZKP",
    cityName: "Zyryanka, Sakha Republic",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Asia",
  },
  {
    airportCode: "XNR",
    airportName: "Aabenraa",
    cityCode: "XNR",
    cityName: "Aabenraa",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "AAH",
    airportName: "Aachen",
    cityCode: "AAH",
    cityName: "Aachen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XOA",
    airportName: "Aalbaek",
    cityCode: "XOA",
    cityName: "Aalbaek",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZDB",
    airportName: "Adelboden",
    cityCode: "ZDB",
    cityName: "Adelboden",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "ABC",
    airportName: "Albacete",
    cityCode: "ABC",
    cityName: "Albacete",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "ALY",
    airportName: "Alexandria",
    cityCode: "ALY",
    cityName: "Alexandria",
    countryCode: "EG",
    countryName: "Egypt",
    continent: "Africa",
  },
  {
    airportCode: "ANK",
    airportName: "Ankara",
    cityCode: "ANK",
    cityName: "Ankara",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ZAF",
    airportName: "Arles",
    cityCode: "ZAF",
    cityName: "Arles",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZYM",
    airportName: "Arnhem",
    cityCode: "ZYM",
    cityName: "Arnhem",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "BAK",
    airportName: "Baku",
    cityCode: "BAK",
    cityName: "Baku",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    continent: "Asia",
  },
  {
    airportCode: "BBH",
    airportName: "Barth",
    cityCode: "BBH",
    cityName: "Barth",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "EAP",
    airportName: "Basel",
    cityCode: "EAP",
    cityName: "Basel",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "BJS",
    airportName: "Beijing",
    cityCode: "BJS",
    cityName: "Beijing",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "BHZ",
    airportName: "Belo Horizonte",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "BER",
    airportName: "Berlin",
    cityCode: "BER",
    cityName: "Berlin",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "QWZ",
    airportName: "Best",
    cityCode: "QWZ",
    cityName: "Best",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "QBO",
    airportName: "Bochum",
    cityCode: "QBO",
    cityName: "Bochum",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "null",
    airportName: "Bolzano",
    cityCode: "null",
    cityName: "Bolzano",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "ZMW",
    airportName: "Brande",
    cityCode: "ZMW",
    cityName: "Brande",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "XGZ",
    airportName: "Bregenz",
    cityCode: "XGZ",
    cityName: "Bregenz",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "BRZ",
    airportName: "Brescia",
    cityCode: "BRZ",
    cityName: "Brescia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "XAQ",
    airportName: "Bronderslev",
    cityCode: "XAQ",
    cityName: "Bronderslev",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZGJ",
    airportName: "Bruges",
    cityCode: "ZGJ",
    cityName: "Bruges",
    countryCode: "BE",
    countryName: "Belgium",
    continent: "Europe",
  },
  {
    airportCode: "BUH",
    airportName: "Bucharest",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "BUE",
    airportName: "Buenos Aires",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryCode: "AR",
    countryName: "Argentina",
    continent: "South America",
  },
  {
    airportCode: "CAS",
    airportName: "Casablanca",
    cityCode: "CAS",
    cityName: "Casablanca",
    countryCode: "MA",
    countryName: "Morocco",
    continent: "Africa",
  },
  {
    airportCode: "QTC",
    airportName: "Caserta",
    cityCode: "QTC",
    cityName: "Caserta",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "WCA",
    airportName: "Castro",
    cityCode: "WCA",
    cityName: "Castro",
    countryCode: "CL",
    countryName: "Chile",
    continent: "South America",
  },
  {
    airportCode: "ZAK",
    airportName: "Chiusa/Klausen",
    cityCode: "ZAK",
    cityName: "Chiusa/Klausen",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "ZDT",
    airportName: "Chur",
    cityCode: "ZDT",
    cityName: "Chur",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "CQM",
    airportName: "Ciudad Real",
    cityCode: "CQM",
    cityName: "Ciudad Real",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "ODB",
    airportName: "Cordoba",
    cityCode: "ODB",
    cityName: "Cordoba",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "XED",
    airportName: "Disneyland Paris",
    cityCode: "XED",
    cityName: "Disneyland Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "QYC",
    airportName: "Drachten",
    cityCode: "QYC",
    cityName: "Drachten",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "YEA",
    airportName: "Edmonton",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ENS",
    airportName: "Enschede",
    cityCode: "ENS",
    cityName: "Enschede",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "ESK",
    airportName: "Eskisehir",
    cityCode: "ESK",
    cityName: "Eskisehir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "ZHD",
    airportName: "Fluelen",
    cityCode: "ZHD",
    cityName: "Fluelen",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "FOG",
    airportName: "Foggia",
    cityCode: "FOG",
    cityName: "Foggia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "QZF",
    airportName: "Font-Romeu",
    cityCode: "QZF",
    cityName: "Font-Romeu",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "QFH",
    airportName: "Frederikshavn",
    cityCode: "QFH",
    cityName: "Frederikshavn",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QFB",
    airportName: "Freiburg",
    cityCode: "QFB",
    cityName: "Freiburg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZHF",
    airportName: "Fribourg",
    cityCode: "ZHF",
    cityName: "Fribourg",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "ZEI",
    airportName: "Garmisch-Partenkirchen",
    cityCode: "ZEI",
    cityName: "Garmisch-Partenkirchen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZEJ",
    airportName: "Gelsenkirchen",
    cityCode: "ZEJ",
    cityName: "Gelsenkirchen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZEU",
    airportName: "Goettingen",
    cityCode: "ZEU",
    cityName: "Goettingen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XRA",
    airportName: "Graasten",
    cityCode: "XRA",
    cityName: "Graasten",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZHJ",
    airportName: "Grindelwald",
    cityCode: "ZHJ",
    cityName: "Grindelwald",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "ZFK",
    airportName: "Guderup",
    cityCode: "ZFK",
    cityName: "Guderup",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZEX",
    airportName: "Guetersloh",
    cityCode: "ZEX",
    cityName: "Guetersloh",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "QYZ",
    airportName: "Heerenveen",
    cityCode: "QYZ",
    cityName: "Heerenveen",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "QHD",
    airportName: "Heidelberg",
    cityCode: "QHD",
    cityName: "Heidelberg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZRY",
    airportName: "Herning",
    cityCode: "ZRY",
    cityName: "Herning",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "XHI",
    airportName: "Herzogenaurach",
    cityCode: "XHI",
    cityName: "Herzogenaurach",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XAJ",
    airportName: "Hirtshals",
    cityCode: "XAJ",
    cityName: "Hirtshals",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QHJ",
    airportName: "Hjorring",
    cityCode: "QHJ",
    cityName: "Hjorring",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QWO",
    airportName: "Holstebro",
    cityCode: "QWO",
    cityName: "Holstebro",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZIL",
    airportName: "Horsens",
    cityCode: "ZIL",
    cityName: "Horsens",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QHU",
    airportName: "Husum",
    cityCode: "QHU",
    cityName: "Husum",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "QLZ",
    airportName: "Ikast",
    cityCode: "QLZ",
    cityName: "Ikast",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "IGS",
    airportName: "Ingolstadt",
    cityCode: "IGS",
    cityName: "Ingolstadt",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XOI",
    airportName: "Ischgl",
    cityCode: "XOI",
    cityName: "Ischgl",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "IZM",
    airportName: "Izmir",
    cityCode: "IZM",
    cityName: "Izmir",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "KCO",
    airportName: "Izmit",
    cityCode: "KCO",
    cityName: "Izmit",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "JKT",
    airportName: "Jakarta",
    cityCode: "JKT",
    cityName: "Jakarta",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "JDF",
    airportName: "Juiz de Fora",
    cityCode: "JDF",
    cityName: "Juiz de Fora",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "KDW",
    airportName: "Kandy",
    cityCode: "KDW",
    cityName: "Kandy",
    countryCode: "LK",
    countryName: "Sri Lanka",
    continent: "Asia",
  },
  {
    airportCode: "XJS",
    airportName: "Kitzbuehl",
    cityCode: "XJS",
    cityName: "Kitzbuehl",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "QJW",
    airportName: "Kjellerup",
    cityCode: "QJW",
    cityName: "Kjellerup",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QJY",
    airportName: "Kolobrzeg",
    cityCode: "QJY",
    cityName: "Kolobrzeg",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "XYN",
    airportName: "Kristinehamn",
    cityCode: "XYN",
    cityName: "Kristinehamn",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "XCC",
    airportName: "Le Creusot/Montceau",
    cityCode: "XCC",
    cityName: "Le Creusot/Montceau",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "LME",
    airportName: "Le Mans",
    cityCode: "LME",
    cityName: "Le Mans",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZJD",
    airportName: "Lenzerheide/Lai",
    cityCode: "ZJD",
    cityName: "Lenzerheide/Lai",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "XGX",
    airportName: "Levi",
    cityCode: "XGX",
    cityName: "Levi",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "XJR",
    airportName: "Lorca",
    cityCode: "XJR",
    cityName: "Lorca",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "LGJ",
    airportName: "Lugoj",
    cityCode: "LGJ",
    cityName: "Lugoj",
    countryCode: "RO",
    countryName: "Romania",
    continent: "Europe",
  },
  {
    airportCode: "MMA",
    airportName: "Malmo",
    cityCode: "MMA",
    cityName: "Malmo",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "MHG",
    airportName: "Mannheim",
    cityCode: "MHG",
    cityName: "Mannheim",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "MTS",
    airportName: "Manzini",
    cityCode: "MTS",
    cityName: "Manzini",
    countryCode: "SZ",
    countryName: "Swaziland",
    continent: "Africa",
  },
  {
    airportCode: "MES",
    airportName: "Medan",
    cityCode: "MES",
    cityName: "Medan",
    countryCode: "ID",
    countryName: "Indonesia",
    continent: "Asia",
  },
  {
    airportCode: "ZMR",
    airportName: "Merano/Meran",
    cityCode: "ZMR",
    cityName: "Merano/Meran",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "ZMC",
    airportName: "Miedzyzdroje",
    cityCode: "ZMC",
    cityName: "Miedzyzdroje",
    countryCode: "PL",
    countryName: "Poland",
    continent: "Europe",
  },
  {
    airportCode: "MIL",
    airportName: "Milan",
    cityCode: "MIL",
    cityName: "Milan",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "ZMO",
    airportName: "Modena",
    cityCode: "ZMO",
    cityName: "Modena",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "YMQ",
    airportName: "Montreal",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZJP",
    airportName: "Montreux",
    cityCode: "ZJP",
    cityName: "Montreux",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "YVA",
    airportName: "Moroni",
    cityCode: "YVA",
    cityName: "Moroni",
    countryCode: "KM",
    countryName: "Comoros",
    continent: "Africa",
  },
  {
    airportCode: "MOW",
    airportName: "Moscow",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    countryName: "Russia",
    continent: "Europe",
  },
  {
    airportCode: "NLP",
    airportName: "Nelspruit",
    cityCode: "NLP",
    cityName: "Nelspruit",
    countryCode: "ZA",
    countryName: "South Africa",
    continent: "Africa",
  },
  {
    airportCode: "NHA",
    airportName: "Nha Trang",
    cityCode: "NHA",
    cityName: "Nha Trang",
    countryCode: "VN",
    countryName: "Vietnam",
    continent: "Asia",
  },
  {
    airportCode: "ZAW",
    airportName: "Nykobing Mors",
    cityCode: "ZAW",
    cityName: "Nykobing Mors",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "ZOY",
    airportName: "Oberhausen",
    cityCode: "ZOY",
    cityName: "Oberhausen",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "OLO",
    airportName: "Olomouc",
    cityCode: "OLO",
    cityName: "Olomouc",
    countryCode: "CZ",
    countryName: "Czechia",
    continent: "Europe",
  },
  {
    airportCode: "ZJU",
    airportName: "Olten",
    cityCode: "ZJU",
    cityName: "Olten",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "OND",
    airportName: "Ondangwa",
    cityCode: "OND",
    cityName: "Ondangwa",
    countryCode: "NA",
    countryName: "Namibia",
    continent: "Africa",
  },
  {
    airportCode: "OSA",
    airportName: "Osaka",
    cityCode: "OSA",
    cityName: "Osaka",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "PAR",
    airportName: "Paris",
    cityCode: "PAR",
    cityName: "Paris",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZPF",
    airportName: "Passau",
    cityCode: "ZPF",
    cityName: "Passau",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "QYT",
    airportName: "Paterswolde",
    cityCode: "QYT",
    cityName: "Paterswolde",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "ZRW",
    airportName: "Rastatt",
    cityCode: "ZRW",
    cityName: "Rastatt",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZPM",
    airportName: "Regensburg",
    cityCode: "ZPM",
    cityName: "Regensburg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZRO",
    airportName: "Reggio nell'Emilia",
    cityCode: "ZRO",
    cityName: "Reggio nell'Emilia",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "XIZ",
    airportName: "Reims",
    cityCode: "XIZ",
    cityName: "Reims",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "REK",
    airportName: "Reykjavik",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryCode: "IS",
    countryName: "Iceland",
    continent: "Europe",
  },
  {
    airportCode: "RIO",
    airportName: "Rio de Janeiro",
    cityCode: "RIO",
    cityName: "Rio de Janeiro",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "ROM",
    airportName: "Rome",
    cityCode: "ROM",
    cityName: "Rome",
    countryCode: "IT",
    countryName: "Italy",
    continent: "Europe",
  },
  {
    airportCode: "XHD",
    airportName: "Ruka",
    cityCode: "XHD",
    cityName: "Ruka",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "XGQ",
    airportName: "Saariselka",
    cityCode: "XGQ",
    cityName: "Saariselka",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "ZKI",
    airportName: "Saas-Fee",
    cityCode: "ZKI",
    cityName: "Saas-Fee",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "YSW",
    airportName: "Salluit",
    cityCode: "YSW",
    cityName: "Salluit",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "ZRC",
    airportName: "San Pedro de Alcantara",
    cityCode: "ZRC",
    cityName: "San Pedro de Alcantara",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "SFQ",
    airportName: "Sanl?urfa",
    cityCode: "SFQ",
    cityName: "Sanl?urfa",
    countryCode: "TR",
    countryName: "Turkey",
    continent: "Asia",
  },
  {
    airportCode: "SRZ",
    airportName: "Santa Cruz de la Sierra",
    cityCode: "SRZ",
    cityName: "Santa Cruz de la Sierra",
    countryCode: "BO",
    countryName: "Bolivia",
    continent: "South America",
  },
  {
    airportCode: "SAO",
    airportName: "Sao Paulo",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryCode: "BR",
    countryName: "Brazil",
    continent: "South America",
  },
  {
    airportCode: "SPK",
    airportName: "Sapporo",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "SZW",
    airportName: "Schwerin",
    cityCode: "SZW",
    cityName: "Schwerin",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XOW",
    airportName: "Seefeld",
    cityCode: "XOW",
    cityName: "Seefeld",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "XOU",
    airportName: "Segovia",
    cityCode: "XOU",
    cityName: "Segovia",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "SEL",
    airportName: "Seoul",
    cityCode: "SEL",
    cityName: "Seoul",
    countryCode: "KR",
    countryName: "South Korea",
    continent: "Asia",
  },
  {
    airportCode: "ZPY",
    airportName: "Siegburg",
    cityCode: "ZPY",
    cityName: "Siegburg",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "XOO",
    airportName: "Soelden",
    cityCode: "XOO",
    cityName: "Soelden",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "XJO",
    airportName: "Soria",
    cityCode: "XJO",
    cityName: "Soria",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "XQS",
    airportName: "Sotkamo",
    cityCode: "XQS",
    cityName: "Sotkamo",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "ANT",
    airportName: "St Anton",
    cityCode: "ANT",
    cityName: "St Anton",
    countryCode: "AT",
    countryName: "Austria",
    continent: "Europe",
  },
  {
    airportCode: "QXK",
    airportName: "St-Genis",
    cityCode: "QXK",
    cityName: "St-Genis",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "STO",
    airportName: "Stockholm",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    countryName: "Sweden",
    continent: "Europe",
  },
  {
    airportCode: "QWQ",
    airportName: "Struer",
    cityCode: "QWQ",
    cityName: "Struer",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "QYQ",
    airportName: "Sulsted",
    cityCode: "QYQ",
    cityName: "Sulsted",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "TCI",
    airportName: "Tenerife",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "HAG",
    airportName: "The Hague",
    cityCode: "HAG",
    cityName: "The Hague",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "TYO",
    airportName: "Tokyo",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryCode: "JP",
    countryName: "Japan",
    continent: "Asia",
  },
  {
    airportCode: "XTJ",
    airportName: "Toledo",
    cityCode: "XTJ",
    cityName: "Toledo",
    countryCode: "ES",
    countryName: "Spain",
    continent: "Europe",
  },
  {
    airportCode: "TWB",
    airportName: "Toowoomba",
    cityCode: "TWB",
    cityName: "Toowoomba",
    countryCode: "AU",
    countryName: "Australia",
    continent: "Oceania",
  },
  {
    airportCode: "YTO",
    airportName: "Toronto",
    cityCode: "YTO",
    cityName: "Toronto",
    countryCode: "CA",
    countryName: "Canada",
    continent: "North America",
  },
  {
    airportCode: "TOV",
    airportName: "Tortola",
    cityCode: "TOV",
    cityName: "Tortola",
    countryCode: "VG",
    countryName: "British Virgin Islands",
    continent: "North America",
  },
  {
    airportCode: "UTC",
    airportName: "Utrecht",
    cityCode: "UTC",
    cityName: "Utrecht",
    countryCode: "NL",
    countryName: "Netherlands",
    continent: "Europe",
  },
  {
    airportCode: "VAF",
    airportName: "Valence",
    cityCode: "VAF",
    cityName: "Valence",
    countryCode: "FR",
    countryName: "France",
    continent: "Europe",
  },
  {
    airportCode: "ZKY",
    airportName: "Verbier",
    cityCode: "ZKY",
    cityName: "Verbier",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "QXF",
    airportName: "Vestbjerg",
    cityCode: "QXF",
    cityName: "Vestbjerg",
    countryCode: "DK",
    countryName: "Denmark",
    continent: "Europe",
  },
  {
    airportCode: "XVM",
    airportName: "Vuokatti",
    cityCode: "XVM",
    cityName: "Vuokatti",
    countryCode: "FI",
    countryName: "Finland",
    continent: "Europe",
  },
  {
    airportCode: "UWE",
    airportName: "Wiesbaden",
    cityCode: "UWE",
    cityName: "Wiesbaden",
    countryCode: "DE",
    countryName: "Germany",
    continent: "Europe",
  },
  {
    airportCode: "ZLI",
    airportName: "Winterthur",
    cityCode: "ZLI",
    cityName: "Winterthur",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
  {
    airportCode: "SIA",
    airportName: "Xi An",
    cityCode: "SIA",
    cityName: "Xi An",
    countryCode: "CN",
    countryName: "China",
    continent: "Asia",
  },
  {
    airportCode: "YAO",
    airportName: "Yaounde",
    cityCode: "YAO",
    cityName: "Yaounde",
    countryCode: "CM",
    countryName: "Cameroon",
    continent: "Africa",
  },
  {
    airportCode: "ZLJ",
    airportName: "Yverdon-les-Bains",
    cityCode: "ZLJ",
    cityName: "Yverdon-les-Bains",
    countryCode: "CH",
    countryName: "Switzerland",
    continent: "Europe",
  },
];

export default AirPortData;
