import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { hostname, scrapping_Host } from "src/HostName";
import { SignIn } from "src/redux/slices/userSignIn";
import Alert from "src/theme/overrides/Alert";
import { encryptData } from "src/utils/encryptcookie";
import { otpVerificationApi } from "src/Api/OtpVerification";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    // localStorage.setItem("isloggedIn", true);
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  LOGOUT: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  Otp: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  scrappy_login: () => Promise.resolve(),
  scrappy_register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getRegisteredData, setRegisterData] = useState("");
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/account/my-account");
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, navigate) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "key",
      "1f67cc92078c59d28345211501c21b3e98b23de4aabe94ccfe872fc6d8a7d5253530e7"
    );

    var raw = JSON.stringify({
      username: email,
      password: password,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const callingapi = await fetch(`${hostname}agent/login`, requestOptions);
    const response = await callingapi.json();
    const user = response;
    // localStorage.setItem("LoginData", JSON.stringify(user));
    encryptData("_b60-g6fr(+", user); // Stored  encrypted UserData in cookies here

    if (response.status === true) {
      navigate("/auth/otpverify");
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      return {
        status: 1,
        message: "Valid User",
        response: response,
      };
    } else {
      return {
        status: 0,
        message: "Inavlid User",
        response: response,
      };
    }
  };

  const Otp = async (
    navigate,
    otp,
    userId,
    role,
    userDataFromCookie,
    setOtpResponse
  ) => {
    const data = await otpVerificationApi(otp, userDataFromCookie.userid);
    setOtpResponse(data.data);
    if (data?.data?.status === true) {
      encryptData("@s_t2_ai_te", true); // Stored  encrypted UserData in cookies here

      navigate(
        userDataFromCookie?.role === null
          ? "/dashboard"
          : userDataFromCookie?.role === "admin"
          ? "/dashboard/manager-info"
          : userDataFromCookie?.role === "manager"
          ? "/dashboard/add-info"
          : userDataFromCookie?.role === "agent"
          ? "/dashboard/sub-agent-info"
          : userDataFromCookie?.role === "sub-agent"
          ? "/dashboard/bookingsHistory"
          : userDataFromCookie?.role === "accounts"
          ? "/dashboard/accounts-all-requests"
          : "/dashboard"
      );
    }
  };
  const scrappy_login = async (email, password) => {
    var raw = JSON.stringify({
      userName: email,
      password: password,
    });
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    fetch(`${scrapping_Host}agentLogin`, options)
      .then((response) => response.json())
      .then((user) => {
        if (user.status === true) {
          localStorage.setItem("LoginState", true);
          localStorage.setItem("LoginData", JSON.stringify(user));
          dispatch({
            type: "LOGIN",
            payload: {
              user,
            },
          });
        } else {
          return (
            <Alert severity="info" sx={{ mb: 3 }}>
              Invalid User
            </Alert>
          );
        }
      })
      .catch((err) => console.error(err));
  };
  const scrappy_register = async (
    email,
    password,
    metaPermission,
    currencyPermission
  ) => {
    var meta = [metaPermission];
    var currency = [currencyPermission];

    var raw = JSON.stringify({
      userName: email,
      password: password,
      metaPermission: meta,
      currencyPermission: currency,
      role: "scrappy_user",
    });
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(`${scrapping_Host}createAgent`, options)
      .then((response) => response.json())
      .then((user) => setRegisterData(user))
      .catch((err) => console.error(err));
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    localStorage.setItem("LoginState", false);
    dispatch({ type: "LOGOUT", payload: false });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        Otp,
        logout,
        register,
        scrappy_login,
        scrappy_register,
        getRegisteredData,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
