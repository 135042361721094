import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import image from "../assets/Image/Banner2.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #3b82f6 20%, #1e3a8a 90%)",
    border: 0,
    borderRadius: 3,

    color: "white",
    height: 40,

    marginLeft: "9rem",
    padding: "0 30px",
  },
});
export default function Passanger(props) {
  // Modal
  const [shows, setShow] = useState(false);
  const [paxErrorMessage, setPaxErrorMessage] = useState("");
  const classes = useStyles();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setadult(1);
    setchildren(0);
    setInfant(0);
    // setCount(0);
    setgenerate("none");
  };

  const [selected, setSelected] = useState(true);
  const [generate, setgenerate] = useState("none");
  const [children, setchildren] = useState(0);
  const [adult, setadult] = useState(1);

  const [Infant, setInfant] = useState(0);
  // const [count, setCount] = useState(1);
  // console.log("count", count);

  const count = adult + Infant + children;

  function addAdult() {
    setadult(adult + 1);
    // setCount(count + 1);
    setSelected();
    if (count >= 9) {
      // setCount(count + 0);
      setadult(adult + 0);
      setPaxErrorMessage("Sorry, we do not support more than 9 passengers.");
    } else {
      setPaxErrorMessage("");
    }
  }

  function minusAdult() {
    setadult(adult - 1);
    // setCount(count - 1);
    if (adult === Infant) {
      setInfant(Infant - 1);
      // setCount(count - 1);
    }
    if (
      paxErrorMessage === "Sorry, we do not support more than 9 passengers."
    ) {
      setPaxErrorMessage("");
    }
    if (paxErrorMessage === "Number of Infants Can Not Be More Than Adults") {
      setPaxErrorMessage("");
    }
  }

  function minuschildren() {
    setchildren(children - 1);
    // setCount(count - 1);
    if (
      paxErrorMessage === "Sorry, we do not support more than 9 passengers."
    ) {
      setPaxErrorMessage("");
    }
  }

  function addchildren() {
    setchildren(children + 1);
    // setCount(count + 1);
    if (count >= 9) {
      setchildren(children + 0);
      // setCount(count + 0);
      setPaxErrorMessage("Sorry, we do not support more than 9 passengers.");
    }
  }
  function addInfant() {
    setInfant(Infant + 1);
    // setCount(count + 1);
    if (count >= 9) {
      setInfant(Infant + 0);
      // setCount(count + 0);
      setPaxErrorMessage("Sorry, we do not support more than 9 passengers.");
    } else if (Infant >= adult) {
      setInfant(Infant + 0);
      // setCount(count + 0);
      setPaxErrorMessage("Number of Infants Can Not Be More Than Adults");
    } else {
      setPaxErrorMessage("");
    }
  }
  function minusInfant() {
    setInfant(Infant - 1);
    // setCount(count - 1);
    if (
      paxErrorMessage === "Sorry, we do not support more than 9 passengers."
    ) {
      setPaxErrorMessage("");
    }
    if (paxErrorMessage === "Number of Infants Can Not Be More Than Adults") {
      setPaxErrorMessage("");
    }
  }

  function done() {
    props.setDisplayPax("none");
  }
  function show() {
    props.setDisplayPax("block");
    props.setDisplayFrom("none");
    props.setDisplayTo("none");
    props.setDisplayClass("none");
  }

  props.settravelleradult(adult);
  props.settravellerchildren(children);
  props.settravellerInfant(Infant);

  return (
    <>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left down border border-gray-100 mb-1 w-full py-1 rounded-[8px] cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span
              onClick={() => show()}
              className="block truncate text-sm text-black py-1  font-semibold"
            >
              {" "}
              <i class="fas fa-users text-[#e95419]"></i> &nbsp; {adult} Adult,{" "}
              {children} Children, {Infant} Infant
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <div
            style={{ display: props.displaypax }}
            className="absolute pl-0 w-full py-1 mt-1  text-base bg-white rounded-md shadow-lg max-h-auto z-50 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div className="py-2">
              {/* Adult */}
              <h1 className="text-black text-lg border-2  mb-1 border-white px-2 mx-3 rounded-xl">
                Adult
              </h1>
              {adult === 1 ? (
                <>
                  <div className="px-4 py-2 mb-3">
                    <button
                      type="button"
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-minus " aria-hidden="true"></i>
                    </button>

                    <span className="text-sm text-black font-bold">
                      {" "}
                      &nbsp; {props.adult === undefined ? adult : props.adult}
                      &nbsp;{" "}
                    </span>
                    <button
                      type="button"
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                      onClick={() => addAdult()}
                    >
                      <i class="fa fa-plus " aria-hidden="true"></i>
                    </button>
                    <span className="text-xs text-black ">
                      {" "}
                      &nbsp; &nbsp; 12+ years
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-2 mb-3">
                    <button
                      type="button"
                      onClick={() => minusAdult()}
                      class="button hollow circle border-2 text-blue-500 border-blue-500 rounded-2xl px-2 py-1 hover:border-white hover:bg-blue-500 hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-minus " aria-hidden="true"></i>
                    </button>

                    <span className="text-sm text-black font-bold">
                      {" "}
                      &nbsp; {props.adult === undefined
                        ? adult
                        : props.adult}{" "}
                      &nbsp;{" "}
                    </span>
                    <button
                      type="button"
                      class="button hollow circle border-2 text-blue-500 border-blue-500 rounded-2xl px-2 py-1 hover:border-white hover:bg-blue-500 hover:text-white"
                      data-quantity="plus"
                      onClick={() => addAdult()}
                      data-field="quantity"
                    >
                      <i class="fa fa-plus " aria-hidden="true"></i>
                    </button>
                    <span className="text-xs text-black ">
                      {" "}
                      &nbsp; &nbsp; 12+ years
                    </span>
                  </div>
                </>
              )}

              {/* children */}
              <h1 className="text-black text-lg px-2 mb-1 mx-3 ">Children</h1>
              {children === 0 ? (
                <>
                  <div className="px-4 py-2 mb-3">
                    <button
                      type="button"
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-minus " aria-hidden="true"></i>
                    </button>

                    <span className="text-sm text-black font-bold">
                      {" "}
                      &nbsp;{" "}
                      {props.children === undefined
                        ? children
                        : props.children}{" "}
                      &nbsp;{" "}
                    </span>
                    <button
                      type="button"
                      onClick={() => addchildren()}
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-plus " aria-hidden="true"></i>
                    </button>
                    <span className="text-xs text-black ">
                      {" "}
                      &nbsp; &nbsp; 2-12 years
                    </span>
                  </div>
                </>
              ) : (
                <div className="px-4 py-2 mb-3">
                  <button
                    type="button"
                    onClick={() => minuschildren()}
                    class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                    data-quantity="plus"
                    data-field="quantity"
                  >
                    <i class="fa fa-minus " aria-hidden="true"></i>
                  </button>

                  <span className="text-sm text-black font-bold">
                    {" "}
                    &nbsp;{" "}
                    {props.children === undefined
                      ? children
                      : props.children}{" "}
                    &nbsp;{" "}
                  </span>
                  <button
                    type="button"
                    onClick={() => addchildren()}
                    class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                    data-quantity="plus"
                    data-field="quantity"
                  >
                    <i class="fa fa-plus " aria-hidden="true"></i>
                  </button>
                  <span className="text-xs text-black ">
                    {" "}
                    &nbsp; &nbsp; 2-12 years
                  </span>
                </div>
              )}

              {/* infants */}

              <h1 className="text-black text-lg px-2 mb-1 mx-3 ">
                Infant (On Lap)
              </h1>
              {Infant === 0 ? (
                <>
                  {" "}
                  <div className="px-4 py-2">
                    <button
                      type="button"
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-minus " aria-hidden="true"></i>
                    </button>

                    <span className="text-sm text-black font-bold">
                      {" "}
                      &nbsp;{" "}
                      {props.infant === undefined ? Infant : props.infant}{" "}
                      &nbsp;{" "}
                    </span>
                    <button
                      type="button"
                      onClick={() => addInfant()}
                      class="button hollow circle border-2 text-[#ea4f31] border-[#ea4f31] rounded-2xl px-2 py-1 hover:border-[#ea4f31] hover:bg-[#ea4f31] hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                      disabled={adult === Infant ? true : false}
                    >
                      <i class="fa fa-plus " aria-hidden="true"></i>
                    </button>
                    <span className="text-xs text-black ">
                      {" "}
                      &nbsp; &nbsp; 0-2 years
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="px-4 py-2">
                    <button
                      type="button"
                      onClick={() => minusInfant()}
                      class="button hollow circle border-2 text-blue-500 border-blue-500 rounded-2xl px-2 py-1 hover:border-white hover:bg-blue-500 hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-minus " aria-hidden="true"></i>
                    </button>

                    <span className="text-sm text-black font-bold">
                      {" "}
                      &nbsp;{" "}
                      {props.infant === undefined ? Infant : props.infant}{" "}
                    </span>
                    <button
                      type="button"
                      onClick={() => addInfant()}
                      class="button hollow circle border-2 text-blue-500 border-blue-500 rounded-2xl px-2 py-1 hover:border-white hover:bg-blue-500 hover:text-white"
                      data-quantity="plus"
                      data-field="quantity"
                    >
                      <i class="fa fa-plus " aria-hidden="true"></i>
                    </button>
                    <span className="text-xs text-black ">
                      {" "}
                      &nbsp; &nbsp; 0-2 years
                    </span>
                  </div>
                </>
              )}
              <div
                className="flex justify-content-center align-items-center text-center text-bold"
                style={
                  paxErrorMessage === ""
                    ? { display: "none" }
                    : { display: "flex" }
                }
              >
                <div
                  className="alert alert-danger w-100 text-black font-weight-bold"
                  role="alert"
                >
                  {paxErrorMessage}
                </div>
              </div>

              {/* Note */}
              <div className="px-4 py-2">
                <span className="text-xs ">
                  Your age at time of travel must be valid for the age category
                  booked. Airlines have restrictions on under 12 travelling
                  alone.
                </span>
              </div>

              {/* button */}
              <div class="flex flex-row-reverse mr-5 from-blue-500 to-blue-900 mt-2">
                <button
                  className={`${classes.root} !rounded-[8px] tabBgColor`}
                  onClick={() => done()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </Listbox>

      {/* <Modal show={shows} onHide={handleClose}>
        <img src={image} />
        <p>{paxErrorMessage}</p>
      </Modal> */}
    </>
  );
}
