import { NavLink as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Button, AppBar, Toolbar, Container } from "@material-ui/core";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// components
import Logo from "../../components/Logo";
import { Link as ScrollLink } from "react-scroll";
import Label from "../../components/Label";
import { MHidden } from "../../components/@material-extend";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { useNavigate } from "react-router";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const agreePage = "/auth/agreement";
  console.log(pathname, "pathname");

  return (
    <AppBar
      sx={{ boxShadow: 0, bgcolor: "transparent" }}
      className={pathname === agreePage ? "bg-white" : ""}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            v2.5.0
          </Label> */}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>
          {/* <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => navigate("/")}
          >
            Home
          </Button> */}
          {/* <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => navigate("/about-us")}
          >
            About us
          </Button> */}
          {/* <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </Button> */}
          {/* <ScrollLink to="OurServices">
            <Button
              variant="outlined"
              onClick={() => navigate("/")}
              sx={{ marginRight: 2 }}
            >
              Services
            </Button>
          </ScrollLink> */}
          {/* <ScrollLink to="OurProducts">
            <Button
              onClick={() => navigate("/")}
              variant="outlined"
              sx={{ marginRight: 2 }}
            >
              Products
            </Button> */}
          {/* </ScrollLink> */}
          <Button
            variant="outlined"
            target="_blank"
            onClick={() => navigate("/auth/enquiry-form")}
          >
            Become our Partner !
          </Button>

          <MHidden width="mdUp">
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
