import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";
import { hostname } from "src/HostName";
import { TripType, staticStop, PAX } from "src/utils/stop";

const EditAirport = ({
  Data,
  setEditAirportMarkup,
  fullscreendata,
  fullscreendataprovider,
  ruleName,
  handleEditAirportMarkupclose,
}) => {
  const handleEditObjChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "capAmount" ||
      name === "minAmount" ||
      name === "maxAmount" ||
      name === "noOfPAX" ||
      name === "cappingDays" ||
      name === "arrTimeMarkup" ||
      name === "depTimeMarkup"
    ) {
      setEditAirportMarkup({ ...Data, [name]: Number(value) });
    } else {
      setEditAirportMarkup({ ...Data, [name]: value });
    }
  };

  const editAirportMarkupSumbit = () => {
    if (Data.ruleName.length < 3) {
      return alert("ruleName is required");
    }

    const options = {
      method: "PATCH",
      url: `${hostname}update/airport/markup`,
      headers: { "Content-Type": "application/json" },
      data: {
        userid: fullscreendataprovider.userid,
        gdsType: fullscreendata.gdsType,
        ruleName: ruleName,
        airportMarkup: {
          tripType: Data.tripType,
          noOfPAX: Data.noOfPAX,
          From: Data.From,
          To: Data.To,
          originCountry: Data.originCountry,
          destinationCountry: Data.destinationCountry,
          Amount: Data.Amount,
          capAmount: Data.capAmount,
          RuleDateFrom: moment(Data.RuleDateFrom).format("YYYY-MM-DD"),
          RuleDateTo: moment(Data.RuleDateTo).format("YYYY-MM-DD"),
          cabinSegment: Data.cabinSegment,
          AirCode: Data.AirCode,
          minAmount: Data.minAmount,
          maxAmount: Data.maxAmount,
          stops: Data.stops,
          ruleName: Data.ruleName,
          cappingDays: Data.cappingDays,
          cabinSegmentInbound: Data.cabinSegmentInbound,
          arrTimeMarkup: Data.arrTimeMarkup,
          depTimeMarkup: Data.depTimeMarkup,
          cabinClass: Data.cabinClass,
          markupType: Data.markupType,
        },
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Markup Updated Successfully");
          handleEditAirportMarkupclose();
        } else {
          alert("Markup Not Updated");
          handleEditAirportMarkupclose();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="ruleName"
              value={Data.ruleName}
              type="text"
              id="ruleName"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="RuleName*"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="tripType">Select TripType</InputLabel>
              <Select
                labelId="tripType"
                id="tripType"
                name="tripType"
                value={Data.tripType}
                onChange={(e) => handleEditObjChange(e)}
              >
                {TripType.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="tripType">Select Stops</InputLabel>
              <Select
                labelId="stops"
                id="stops"
                name="stops"
                value={Data.stops}
                onChange={(e) => handleEditObjChange(e)}
              >
                {staticStop.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="tripType">Select PAX</InputLabel>
              <Select
                labelId="noOfPAX"
                id="noOfPAX"
                name="noOfPAX"
                value={Data.noOfPAX}
                onChange={(e) => handleEditObjChange(e)}
              >
                {PAX.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="From"
              value={Data.From}
              type="text"
              id="From"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Origin Airport"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="To"
              value={Data.To}
              type="text"
              id="To"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Destination Airport"
              variant="filled"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="originCountry"
              value={Data.originCountry}
              id="originCountry"
              type="text"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Origin Country"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="destinationCountry"
              value={Data.destinationCountry}
              id="destinationCountry"
              type="text"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Destination Country"
              variant="filled"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="RuleDateFrom"
              value={moment(Data.RuleDateFrom).format("YYYY-MM-DD")}
              id="RuleDateFrom"
              type="date"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Travel Start"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="RuleDateTo"
              value={moment(Data.RuleDateTo).format("YYYY-MM-DD")}
              id="RuleDateTo"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Travel End"
              variant="filled"
              type="date"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="AirCode"
              value={Data.AirCode}
              id="AirCode"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Airline"
              variant="filled"
              type="text"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinSegment"
              value={Data.cabinSegment}
              id="cabinSegment"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="CabinSegment"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid
          container
          spacing={2}
          className="align-items-center flex-nowrap justify-content-center"
        >
          <Grid item xs={12} xl={6} md={12} className="d-flex">
            <div className="d-flex align-items-center">
              <label className="mr-3" style={{ fontSize: "13px" }}>
                Markup Type
              </label>
              <div className="d-flex justify-content-center align-items-center">
                <div className="mr-4">
                  <input
                    name="markupType"
                    type="radio"
                    value={"%"}
                    checked={Data.markupType === "%"}
                    id="markupType"
                    onChange={(e) => handleEditObjChange(e)}
                    className="mr-2 cursor-pointer"
                  />
                  <label>%</label>
                </div>
                <div>
                  <input
                    name="markupType"
                    type="radio"
                    checked={Data.markupType === "FLAT"}
                    value={"FLAT"}
                    id="markupType"
                    placeholder="Fare Type"
                    onChange={(e) => handleEditObjChange(e)}
                    className="mr-2 cursor-pointer"
                  />
                  <label style={{ fontSize: "13px" }}>FLAT</label>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="Amount"
              value={Data.Amount}
              id="Amount"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="Amount"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="capAmount"
              value={Data.capAmount}
              id="capAmount"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="CapAmount"
              variant="filled"
              type="text"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinClass"
              value={Data.cabinClass}
              id="cabinClass"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="CabinClass"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="minAmount"
              value={Data.minAmount}
              id="minAmount"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="MinAmount"
              variant="filled"
              type="text"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="maxAmount"
              value={Data.maxAmount}
              id="maxAmount"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="MaxAmount"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="arrTimeMarkup"
              value={Data.arrTimeMarkup}
              id="arrTimeMarkup"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="ArrTimeMarkup"
              variant="filled"
              type="text"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="depTimeMarkup"
              value={Data.depTimeMarkup}
              id="depTimeMarkup"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="depTimeMarkup"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cappingDays"
              value={Data.cappingDays}
              id="cappingDays"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="CappingDays"
              variant="filled"
              type="text"
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinSegmentInbound"
              value={Data.cabinSegmentInbound}
              id="cabinSegmentInbound"
              onChange={(e) => handleEditObjChange(e)}
              sx={{ width: "100%" }}
              label="CabinSegment Inbound"
              variant="filled"
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <div className="text-center">
        <Button
          variant="contained"
          onClick={() => {
            editAirportMarkupSumbit();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditAirport;
