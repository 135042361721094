import moment from "moment";
const minDate = moment(new Date()).format("yyyy-mm-dd");
export const EditAirportMarkupArray = () => [
  // {
  //   seq: 1,
  //   name: "RuleDateFrom",
  //   value: "",
  //   id: "RuleDateFrom",
  //   placeholder: "Travel Start",
  //   label: "Travel Start",
  //   variant: "filled",
  //   type: "date",
  //   // max: minDate,
  // },
  // {
  //   seq: 2,
  //   name: "RuleDateTo",
  //   value: "",
  //   id: "RuleDateTo",
  //   placeholder: "Travel End",
  //   label: "Travel End",
  //   variant: "filled",
  //   type: "date",
  //   // max: minDate,
  // },
  {
    seq: 3,
    name: "AirCode",
    value: "",
    id: "AirCode",
    placeholder: "AirCode",
    label: "AirCode",
    variant: "filled",
    type: "text",
  },
  // {
  //   seq: 4,
  //   name: "cabinSegment",
  //   value: "",
  //   id: "cabinSegment",
  //   placeholder: "Cabin Class (V,E,L)",
  //   label: "Cabin Segment (V,E,L)",
  //   variant: "filled",
  //   type: "text",
  // },
  // {
  //   seq: 5,
  //   name: "minAmount",
  //   value: "",
  //   id: "minAmount",
  //   placeholder: "MinAmount",
  //   label: "MinAmount",
  //   variant: "filled",
  //   type: "number",
  // },
  // {
  //   seq: 6,
  //   name: "maxAmount",
  //   value: "",
  //   id: "maxAmount",
  //   placeholder: "maxAmount",
  //   label: "MaxAmount",
  //   variant: "filled",
  //   type: "number",
  // },
  // {
  //   seq: 7,
  //   name: "cappingDays",
  //   value: "",
  //   id: "Capping Days",
  //   placeholder: "Capping Days",
  //   label: "Capping Days",
  //   variant: "filled",
  //   type: "number",
  // },
];
