import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inboundFlight: {},
  outBoundFlight: {},
};

export const roundWayDetails = createSlice({
  name: "roundWayDetails",
  initialState,
  reducers: {
    outboundDetails: (state, action) => {
      state.outBoundFlight = action.payload;
    },
    inBoundDetails: (state, action) => {
      state.inboundFlight = action.payload;
    },
  },
});

export const { outboundDetails, inBoundDetails } = roundWayDetails.actions;
export default roundWayDetails.reducer;
