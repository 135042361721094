import { LoadingButton } from "@material-ui/lab";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";
import { decryptData } from "src/utils/decryptcookie";

const OTPVerification = () => {
  const navigate = useNavigate();
  const { Otp } = useAuth();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpResponse, setOtpResponse] = useState({});
  const LoginData = localStorage.getItem("LoginData");
  let parsedData = JSON.parse(LoginData);
  const otpInputRefs = [];
  const userDataFromCookie = decryptData("_b60-g6fr(+"); // userData decrypted here from cookie
  const handleOtpChange = (index, event) => {
    const otpValue = event.target.value;
    if (isNaN(otpValue)) {
      return;
    }
    const otpCopy = [...otp];
    otpCopy[index] = otpValue;
    setOtp(otpCopy);
    if (otpValue && otpInputRefs[index + 1]) {
      otpInputRefs[index + 1].focus();
    }
  };
  const handleOtpKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && otpInputRefs[index - 1]) {
      otpInputRefs[index - 1].focus();
    }
  };
  const handleOtpPaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, 4 - otp.join("").length)
      .split("");
    const otpCopy = [...otp];
    otpInputRefs.forEach((ref, index) => {
      if (pastedData.length > index) {
        const otpValue = pastedData[index];
        if (!isNaN(otpValue)) {
          otpCopy[index] = otpValue;
        }
      }
    });
    setOtp(otpCopy);
  };

  useEffect(() => {
    otpInputRefs[0].focus();
  }, []);
  return (
    <div className="login-pagesite">
      <div className="d-flex">
        <div
          style={{
            maxWidth: 480,
            margin: "auto",
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <Box sx={{ width: 150 }}>
            <img src="/static/brand/Travomint.png" class="img 2" alt="login" />
          </Box>
          <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4">Verify OTP</Typography>
              <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
                Please enter OTP received on your Email.
              </Typography>
            </Box>
          </Stack>
          <div className="gfrom-login">
            <div className="form-group mt-4">
              <div className={"form-group position-relative ssss"}>
                {otp.map((otpValue, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    required={true}
                    value={otpValue}
                    onChange={(event) => handleOtpChange(index, event)}
                    onKeyDown={(event) => handleOtpKeyDown(index, event)}
                    onPaste={handleOtpPaste}
                    ref={(ref) => (otpInputRefs[index] = ref)}
                  />
                ))}
              </div>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={async () => {
                  const data = await Otp(
                    navigate,
                    otp,
                    parsedData?.userid,
                    parsedData?.role,
                    userDataFromCookie,
                    setOtpResponse
                  );
                }}
                className="mt-4"
                // loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
