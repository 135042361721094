import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
import Logo from "src/components/Logo";
import Background from "../components-overview/extra/animate/background";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "60%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "40px 0 0 40px",
  boxShadow: "none",
  backgroundImage: "url('/static/airportscreen-login.jpg')",
  backgroundPosition: "top left",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  margin: "70px 0 10px",
  minHeight: "100%",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="login-pagesite">
      <RootStyle title="Login | Travomint ">
        <Container maxWidth="sm">
          <ContentStyle>
            <a href="/">
              <Box sx={{ width: 150 }}>
                <img
                  src="/static/brand/Travomint.png"
                  class="img 2"
                  alt="login"
                />
              </Box>
            </a>

            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4">Sign In</Typography>
                <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
                  Please Enter Your Account Details.
                </Typography>
              </Box>
            </Stack>
            <div className="gfrom-login">
              <LoginForm />
            </div>
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                >
                  Get started
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>

        <MHidden width="mdDown">
          <SectionStyle>
            {/* <Typography variant="subtitle1" sx={{ px: 5, mt: 0, mb: 0 }}>
            Travel Software Solution
          </Typography>  */}

            {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
          </SectionStyle>
        </MHidden>
      </RootStyle>
    </div>
  );
}
