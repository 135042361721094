const Favourite = [
  {
    airportCode: "BOM",
    airportName: "Chhatrapati Shivaji International",
    cityCode: "BOM",
    cityName: "Mumbai",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "DEL",
    airportName: "Indira Gandhi International",
    cityCode: "DEL",
    cityName: "New Delhi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "AMD",
    airportName: "Sardar Vallabhbhai Patel International",
    cityCode: "AMD",
    cityName: "Ahmedabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "ATQ",
    airportName: "Sri Guru Ram Dass Jee International",
    cityCode: "ATQ",
    cityName: "Amritsar",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "IXB",
    airportName: "Bagdogra",
    cityCode: "IXB",
    cityName: "Bagdogra",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "BLR",
    airportName: "Bengaluru International",
    cityCode: "BLR",
    cityName: "Bengaluru",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "MAA",
    airportName: "Chennai International",
    cityCode: "MAA",
    cityName: "Chennai",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "GOI",
    airportName: "Goa International",
    cityCode: "GOI",
    cityName: "Goa",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "HYD",
    airportName: "Rajiv Gandhi International",
    cityCode: "HYD",
    cityName: "Hyderabad",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "CCU",
    airportName: "Netaji Subhas Chandra Bose International",
    cityCode: "CCU",
    cityName: "Kolkata",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "COK",
    airportName: "Cochin International",
    cityCode: "COK",
    cityName: "Kochi",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "PAT",
    airportName: "Jay Prakash Narayan International",
    cityCode: "PAT",
    cityName: "Patna",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
  {
    airportCode: "LKO",
    airportName: "Chaudhary Charan Singh International",
    cityCode: "LKO",
    cityName: "Lucknow",
    countryCode: "IN",
    countryName: "India",
    continent: "Asia",
  },
];

export default Favourite;
