import { Link as ScrollLink } from "react-scroll";
import { useLocation, Outlet } from "react-router-dom";
// material
import { Box, Link, Container, Typography } from "@material-ui/core";
// components
import Logo from "../../components/Logo";
//
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === "/";

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <>
          <MainFooter />
          <Box
            sx={{
              // py: 5,
              textAlign: "center",
              position: "relative",
              bgcolor: "background.default",
            }}
          ></Box>
        </>
      ) : (
        <Box
          sx={{
            // py: 5,
            textAlign: "center",
            position: "relative",
            bgcolor: "background.default",
          }}
        ></Box>
      )}
    </>
  );
}
