import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateTransactionTable: true,
};

const updateTransactionSlice = createSlice({
  name: "updateTransactionTable",
  initialState: initialState,
  reducers: {
    updateTransactionTable: (state, action) => {
      console.log("action", action);
      state.updateTransactionTable = action.payload;
    },
  },
});

export const { UpdateTransactionTable } = updateTransactionSlice.actions;
export default updateTransactionSlice.reducer;
