import { Box, Grid, Stack, TextField, Typography } from "@material-ui/core";
import { Alert, Button, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { hostname } from "src/HostName";
import { EditAirportMarkupArray } from "./InputArray/EditAirportMarkupArray";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { staticStop, TripType, PAX } from "src/utils/stop";
import moment from "moment";

const EditAirportMarkup = ({
  airportMarkup,
  data,
  providerIndex,
  handleAirlineClose,
  filterApiprovider,
}) => {
  const airportMarkupArray = airportMarkup.airportMarkup;
  const [addMarkupinAirport, setAddMarkupinAirport] = useState({
    AirCode: "",
    Amount: "",
    From: "",
    RuleDateFrom: moment().format("YYYY-MM-DD"),
    RuleDateTo: moment().format("YYYY-MM-DD"),
    To: "",
    capAmount: 0,
    originCountry: "",
    destinationCountry: "",
    minAmount: 0,
    maxAmount: 50000,
    stops: -1,
    tripType: "",
    noOfPAX: 1,
    cabinSegment: "",
    ruleName: "",
    cappingDays: 2,
    cabinSegmentInbound: "",
    arrTimeMarkup: 0,
    depTimeMarkup: 0,
    cabinClass: "Y",
    markupType: "FLAT",
  });
  const [showToast, setShowToast] = useState(false);
  const [horizontal, setHorizontal] = useState("center");
  const [vertical, setVertical] = useState("top");
  const [filter, setFilter] = useState({});
  console.log("addMarkupinAirport", addMarkupinAirport);
  const handleFareObjChange = (e) => {
    const { value, name } = e.target;
    if (
      name === "capAmount" ||
      name === "minAmount" ||
      name === "maxAmount" ||
      name === "noOfPAX" ||
      name === "cappingDays" ||
      name === "arrTimeMarkup" ||
      name === "depTimeMarkup"
    ) {
      setAddMarkupinAirport({ ...addMarkupinAirport, [name]: Number(value) });
    } else {
      setAddMarkupinAirport({ ...addMarkupinAirport, [name]: value });
    }
  };

  const submit = () => {
    if (addMarkupinAirport.ruleName.length < 3) {
      return alert("please fill the Rule Name");
    }
    const findAirportMarkup = airportMarkup.airportMarkup.find(
      (item) =>
        item.tripType === addMarkupinAirport.tripType &&
        item.noOfPAX === addMarkupinAirport.noOfPAX &&
        item.From === addMarkupinAirport.From &&
        item.To === addMarkupinAirport.To &&
        item.AirCode === addMarkupinAirport.AirCode
    );

    if (findAirportMarkup === undefined) {
      airportMarkupArray.push(addMarkupinAirport);
      const abc = [];
      for (let index = 0; index < data.apiprovider.length; index++) {
        const element = data.apiprovider[index];
        if (index === providerIndex) {
          const { airportMarkup, ...rest } = element;
          const abcD = { ...rest, airportMarkup: airportMarkupArray };
          abc.push(abcD);
        }
      }
      const Apiprovider = filterApiprovider.concat(abc);
      if (abc.length !== 0) {
        const Data = {
          _id: data._id,
          provider: Apiprovider,
          blockedAirportSource: data.blockedAirportSource,
          rule: data.rule,
          amount: data.amount,
          fromDate: data.fromDate,
          toDate: data.toDate,
          blockedCountrySource: data.blockedCountrySource,
          tripTypeSource: data.tripTypeSource,
          noOfPAXSource: data.noOfPAXSource,
        };
        const options = {
          method: "PATCH",
          url: `${hostname}update/farerule/info`,
          headers: { "Content-Type": "application/json" },
          data: Data,
        };
        axios
          .request(options)
          .then(function (response) {
            alert("Fare Rule updated");
            handleAirlineClose();
          })
          .catch(function (error) {
            console.error("error");
          });
      }
    } else {
      setShowToast(true);
      setFilter(findAirportMarkup);
    }
    // airportMarkupArray.push(addMarkupinAirport);
    // const abc = [];
    // for (let index = 0; index < data.apiprovider.length; index++) {
    //   const element = data.apiprovider[index];
    //   if (index === providerIndex) {
    //     const { airportMarkup, ...rest } = element;
    //     const abcD = { ...rest, airportMarkup: airportMarkupArray };
    //     abc.push(abcD);
    //   }
    // }
    // const Apiprovider = filterApiprovider.concat(abc);
    // if (abc.length !== 0) {
    //   const Data = {
    //     _id: data._id,
    //     provider: Apiprovider,
    //     blockedAirportSource: data.blockedAirportSource,
    //     rule: data.rule,
    //     amount: data.amount,
    //     fromDate: data.fromDate,
    //     toDate: data.toDate,
    //     blockedCountrySource: data.blockedCountrySource,
    //     tripTypeSource: data.tripTypeSource,
    //     noOfPAXSource: data.noOfPAXSource,
    //   };
    //   const options = {
    //     method: "PATCH",
    //     url: `${hostname}update/farerule/info`,
    //     headers: { "Content-Type": "application/json" },
    //     data: Data,
    //   };
    //   axios
    //     .request(options)
    //     .then(function (response) {
    //       alert("Fare Rule updated");
    //       handleAirlineClose();
    //     })
    //     .catch(function (error) {
    //       console.error("error");
    //     });
    // }
  };

  const handleSnackbar = () => {
    setShowToast(false);
  };

  const AddAirportMarkup = () => {
    airportMarkupArray.push(addMarkupinAirport);
    const abc = [];
    for (let index = 0; index < data.apiprovider.length; index++) {
      const element = data.apiprovider[index];
      if (index === providerIndex) {
        const { airportMarkup, ...rest } = element;
        const abcD = { ...rest, airportMarkup: airportMarkupArray };
        abc.push(abcD);
      }
    }
    const Apiprovider = filterApiprovider.concat(abc);
    if (abc.length !== 0) {
      const Data = {
        _id: data._id,
        provider: Apiprovider,
        blockedAirportSource: data.blockedAirportSource,
        rule: data.rule,
        amount: data.amount,
        fromDate: data.fromDate,
        toDate: data.toDate,
        blockedCountrySource: data.blockedCountrySource,
        tripTypeSource: data.tripTypeSource,
        noOfPAXSource: data.noOfPAXSource,
      };
      const options = {
        method: "PATCH",
        url: `${hostname}update/farerule/info`,
        headers: { "Content-Type": "application/json" },
        data: Data,
      };
      axios
        .request(options)
        .then(function (response) {
          alert("Fare Rule updated");
          handleAirlineClose();
          handleSnackbar();
        })
        .catch(function (error) {
          console.error("error");
        });
    }
  };

  const buttonStyle = {
    marginBottom: "20px",
    marginTop: 2,
    height: "45px",
    fontSize: "16px",
    width: "140px",
  };

  return (
    <div>
      <TextField
        type="text"
        value={addMarkupinAirport.ruleName}
        name="ruleName"
        id="ruleName"
        onChange={(e) => {
          handleFareObjChange(e);
        }}
        label="Rule Name*"
        variant="filled"
        sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
      />
      <Box>
        <FormControl
          variant="filled"
          className="mb-4 me-4"
          style={{ width: "15.1875rem" }}
        >
          <InputLabel id="tripType">Select TripType</InputLabel>
          <Select
            labelId="tripType"
            id="tripType"
            name="tripType"
            value={addMarkupinAirport.tripType}
            onChange={(e) => handleFareObjChange(e)}
          >
            {TripType.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="filled" className="w-50 mb-4 ">
          <InputLabel id="noOfPAX">Select No of PAX</InputLabel>
          <Select
            labelId="noOfPAX"
            id="noOfPAX"
            name="noOfPAX"
            value={addMarkupinAirport.noOfPAX}
            onChange={(e) => handleFareObjChange(e)}
          >
            {PAX.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Grid
          container
          spacing={2}
          className="align-items-center flex-nowrap justify-content-center"
        >
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="From"
              id="From"
              type="text"
              // placeholder="Origin"
              value={addMarkupinAirport.From}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              disabled={addMarkupinAirport.originCountry === "" ? false : true}
              label="Origin Airport"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Typography className="pl-3">OR</Typography>
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="originCountry"
              id="originCountry"
              type="text"
              // placeholder="Origin Country"
              value={addMarkupinAirport.originCountry}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              disabled={addMarkupinAirport.From === "" ? false : true}
              label="Origin Country"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          className="align-items-center flex-nowrap justify-content-center"
        >
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="To"
              id="To"
              type="text"
              // placeholder="Destination"
              value={addMarkupinAirport.To}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              label="Destination Airport"
              variant="filled"
              disabled={
                addMarkupinAirport.destinationCountry === "" ? false : true
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Typography className="pl-3">OR</Typography>
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="destinationCountry"
              id="destinationCountry"
              type="text"
              // placeholder="DestinationCountry"
              value={addMarkupinAirport.destinationCountry}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              disabled={addMarkupinAirport.To === "" ? false : true}
              label="Destination Country"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="d-flex">
        <Grid
          container
          spacing={2}
          className="align-items-center flex-nowrap justify-content-center"
        >
          <Grid item xs={12} xl={6} md={12} className="d-flex">
            <div className="d-flex align-items-center pb-3">
              <label className="mr-3" style={{ fontSize: "13px" }}>
                Markup Type
              </label>
              <div className="d-flex justify-content-center align-items-center">
                <div className="mr-4">
                  <input
                    name="markupType"
                    type="radio"
                    value={"%"}
                    checked={addMarkupinAirport.markupType === "%"}
                    id="markupType"
                    onChange={(e) => handleFareObjChange(e)}
                    className="mr-2 cursor-pointer"
                  />
                  <label>%</label>
                </div>
                <div>
                  <input
                    name="markupType"
                    type="radio"
                    checked={addMarkupinAirport.markupType === "FLAT"}
                    value={"FLAT"}
                    id="markupType"
                    placeholder="Fare Type"
                    onChange={(e) => handleFareObjChange(e)}
                    className="mr-2 cursor-pointer"
                  />
                  <label style={{fontSize:"13px"}}>FLAT</label>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="Amount"
              id="Amount"
              type="text"
              // placeholder="Amount"
              value={addMarkupinAirport.Amount}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              label="Amount"
              disabled={addMarkupinAirport.capAmount !== 0 ? true : false}
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="d-flex">
        <Grid
          container
          spacing={2}
          className="align-items-center flex-nowrap justify-content-center"
        >
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="capAmount"
              id="capAmount"
              type="text"
              placeholder="CapAmount"
              value={addMarkupinAirport.capAmount}
              onChange={(e) => handleFareObjChange(e)}
              sx={{
                marginBottom: "24px",
                width: "100%",
                borderRadius: "0",
              }}
              disabled={addMarkupinAirport.Amount === "" ? false : true}
              label="CapAmount"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} xl={6} md={12}>
            <TextField
              name="cappingDays"
              id="cappingDays"
              type="text"
              value={addMarkupinAirport.cappingDays}
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="Capping Days"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="minAmount"
              value={addMarkupinAirport.minAmount}
              id="minAmount"
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="MinAmount"
              variant="filled"
              InputLabelProps={{ shrink: true }}
              disabled={addMarkupinAirport.capAmount !== 0 ? true : false}
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="maxAmount"
              id="maxAmount"
              type="text"
              value={addMarkupinAirport.maxAmount}
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="MaxAmount"
              variant="filled"
              disabled={addMarkupinAirport.capAmount !== 0 ? true : false}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinSegment"
              value={addMarkupinAirport.cabinSegment}
              id="cabinSegment"
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="Cabin Segment (V,E,L)"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinSegmentInbound"
              id="cabinSegmentInbound"
              value={addMarkupinAirport.cabinSegmentInbound}
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="Cabin Segment Inbound"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="arrTimeMarkup"
              value={addMarkupinAirport.arrTimeMarkup}
              id="arrTimeMarkup"
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="ArrTime Markup"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="depTimeMarkup"
              id="depTimeMarkup"
              value={addMarkupinAirport.depTimeMarkup}
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="DepTime Markup"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="RuleDateFrom"
              value={addMarkupinAirport.RuleDateFrom}
              id="RuleDateFrom"
              type="date"
              min={moment().format("YYYY-MM-DD")}
              max={moment().add(1, "years").format("YYYY-MM-DD")}
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="Travel Start"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="RuleDateTo"
              id="RuleDateTo"
              value={addMarkupinAirport.RuleDateTo}
              type="date"
              onChange={(e) => handleFareObjChange(e)}
              min={moment().format("YYYY-MM-DD")}
              max={moment().add(1, "years").format("YYYY-MM-DD")}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="Travel End"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      {EditAirportMarkupArray().map((item) => {
        return (
          <Box>
            <Grid item xs={12} xl={12} md={12}>
              <TextField
                name={item.name}
                id={item.id}
                type={item.type}
                // placeholder={item.placeholder}
                onChange={(e) => handleFareObjChange(e)}
                sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
                label={item.label}
                variant={item.variant}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Box>
        );
      })}

      {/* <FormControl variant="filled" className="w-full">
        <InputLabel id="stops">Select Stop</InputLabel>
        <Select
          labelId="stops"
          id="stops"
          name="stops"
          value={addMarkupinAirport.stops}
          onChange={(e) => handleFareObjChange(e)}
        >
          {staticStop.map((item) => (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} md={6}>
            <FormControl
              variant="filled"
              className="mb-4 me-4"
              style={{ width: "15.1875rem" }}
            >
              <InputLabel id="stops">Select Stop</InputLabel>
              <Select
                labelId="stops"
                id="stops"
                name="stops"
                value={addMarkupinAirport.stops}
                onChange={(e) => handleFareObjChange(e)}
              >
                {staticStop.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={6} md={6}>
            <TextField
              name="cabinClass"
              id="cabinClass"
              value={addMarkupinAirport.cabinClass}
              type="text"
              onChange={(e) => handleFareObjChange(e)}
              sx={{ marginBottom: "24px", width: "100%", borderRadius: "0" }}
              label="CabinClass"
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={showToast}
        onClose={handleSnackbar}
        key={horizontal + vertical}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="warning" sx={{ width: "100%", marginTop: "10rem" }}>
          {filter.ruleName} is already exist, Please ensure to add this rule
          again...
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-around",
              padding: 1,
            }}
          >
            <Button
              size="small"
              onClick={() => handleSnackbar()}
              color="error"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => AddAirportMarkup()}
              variant="contained"
            >
              Confirm
            </Button>
          </Stack>
        </Alert>
      </Snackbar>
      <div className="text-center">
        <Button variant="contained" onClick={() => submit()} sx={buttonStyle}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditAirportMarkup;
