import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { choiceexcleSiteDownLoad } from "src/utils/excelsheet";
import { TripType } from "src/utils/stop";

const FullViewDisplayForm = ({
  filter,
  handleChangeFilter,
  checkedairportMarkup,
  buttonBorder,
  handleSelectAll,
  isCheckAll,
  handlemultipleDelete,
}) => {
  return (
    <div className="mb-3 d-flex justify-content-between align-items-center p-3 pb-1">
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <TextField
            placeholder="Origin"
            className="me-2"
            label="Origin"
            variant="filled"
            id="Origin"
            name="Origin"
            style={{ width: "150px" }}
            type="text"
            value={filter.Origin}
            onChange={(e) => handleChangeFilter(e)}
          />
          <TextField
            placeholder="Destination"
            className="me-2"
            label="Destination"
            variant="filled"
            id="Destination"
            name="Destination"
            style={{ width: "150px" }}
            type="text"
            value={filter.Destination}
            onChange={(e) => handleChangeFilter(e)}
          />
          <TextField
            className="me-2"
            label="RuleName"
            variant="filled"
            placeholder="RuleName"
            id="RuleName"
            name="RuleName"
            type="text"
            style={{ width: "150px" }}
            value={filter.RuleName}
            onChange={(e) => handleChangeFilter(e)}
          />
          <TextField
            className="me-2"
            label="CappingDays "
            variant="filled"
            placeholder="CappingDays"
            id="CappingAmount"
            type="text"
            name="CappingAmount"
            style={{ width: "130px" }}
            value={filter.CappingAmount}
            onChange={(e) => handleChangeFilter(e)}
          />
          <TextField
            placeholder="Airline"
            label="Airline"
            className="me-2"
            variant="filled"
            name="Airline"
            id="Airline"
            type="text"
            style={{ width: "130px" }}
            value={filter.Airline}
            onChange={(e) => handleChangeFilter(e)}
          />
          <FormControl variant="filled" style={{ width: "10rem" }}>
            <InputLabel id="tripType">Select TripType</InputLabel>
            <Select
              labelId="tripType"
              id="TripType"
              name="TripType"
              value={filter.TripType}
              onChange={(e) => handleChangeFilter(e)}
            >
              {TripType.map((item) => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="d-flex align-items-center ml-3">
            <Checkbox
              type="checkbox"
              name="selectAll"
              id="selectAll"
              onChange={() => handleSelectAll()}
              Checked={isCheckAll}
            />
            <h6 className="pt-1">
              Select All{" "}
              {checkedairportMarkup.length > 0
                ? checkedairportMarkup.length
                : null}
            </h6>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center pt-4">
        <Button
          style={checkedairportMarkup.length !== 0 ? buttonBorder : {}}
          className="me-2"
          disabled={checkedairportMarkup.length !== 0 ? false : true}
          onClick={() => {
            handlemultipleDelete(checkedairportMarkup);
          }}
        >
          MultDelete
        </Button>
        <Button
          style={checkedairportMarkup.length !== 0 ? buttonBorder : {}}
          disabled={checkedairportMarkup.length !== 0 ? false : true}
          onClick={() => {
            choiceexcleSiteDownLoad(checkedairportMarkup);
          }}
        >
          MultDownload
        </Button>
      </div>
    </div>
  );
};

export default FullViewDisplayForm;
