import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  FormControl,
  Button,
  MenuItem,
  Typography,
  CardHeader,
  InputLabel,
  Container,
  Select,
  Snackbar,
  Dialog,
  DialogTitle,
  TextField,
  styled,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Alert,
  Stack,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  IconButton,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import Page from "../../Page";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { hostname } from "src/HostName";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useSettings from "src/hooks/useSettings";
import { useLottie } from "lottie-react";
import empty from "../../../LottieView/8021-empty-and-lost.json";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import MyAvatar from "src/components/MyAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  faCalendarDays,
  faMoneyBill,
  faMoneyBill1Wave,
} from "@fortawesome/free-solid-svg-icons";
import EditAirportMarkup from "src/components/ModalBody/EditAirportMarkup";
import { updateFarerule } from "src/Api/CallApi/UpdateFarerule";
import EditAirport from "src/components/ModalBody/EditAirport";
import { PAX, TripType } from "src/utils/stop";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from "@mui/material/Tab";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Modal from "react-bootstrap/Modal";
import { Checkbox } from "@mui/material";
import { choiceexcleSiteDownLoad } from "src/utils/excelsheet";
import FullViewDisplayForm from "src/components/ModalBody/FullViewDisplayForm";
import FullViewdisplayMarkup from "src/components/ModalBody/FullViewdisplayMarkup";

const RootStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  boxShadow: "none",
  position: "relative",
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DIV = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default function AppNewInvoice() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [ruleData, setRuleData] = useState([]);
  const [agents, setAgents] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [apiProvider, setApiProvider] = useState([]);
  const [modalData, setModalData] = useState({});
  const [Airlineopen, setAirlineopen] = useState(false);
  const [airportMarkupAllData, setAirportMarkupAllData] = useState({});
  const [airportMarkup, setAirportMarkup] = useState([]);
  const [airportMarkupIndex, setAirportMarkupIndex] = useState("");
  const [indexProvider, setIndexProvider] = useState();
  const [filterApiprovider, setFilterApiprovider] = useState();
  const [editAirportMarkup, setEditAirportMarkup] = useState({});
  const [airportopen, setAirportopen] = useState(false);
  const [aiportedit, setAiportedit] = useState(false);
  const [bulkAirport, setBulkAirport] = useState({});
  const [bulkopen, setBulkopen] = useState(false);
  const [editairportopen, setEditairportopen] = useState(true);
  const [bulkdata, setBulkData] = useState();
  const [value, setValue] = useState("1");
  const [showamrk, setShowamrk] = useState(false);
  const [fullscreendata, setFullscreendata] = useState({});
  const [fullscreendataprovider, setFullscreendataprovider] = useState({});
  // const [slecterule, setSlecterule] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedairportMarkup, setCheckedairportMarkup] = useState([]);
  const [checkedairportMarkupRuleName, setCheckedairportMarkupRuleName] =
    useState([]);
  const [checkedairportMarkupdata, setCheckedairportMarkupdata] = useState([]);
  const [checkedairportMarkupIndx, setCheckedairportMarkupindex] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [ruleName, setRuleName] = useState();
  const [filter, setFilter] = useState({
    Origin: "",
    Destination: "",
    RuleName: "",
    CappingAmount: "",
    Airline: "",
    TripType: "",
  });

  const handleChangeFilter = (e) => {
    if (e.target.name == "CappingAmount") {
      // setFilter({ ...filter, [e.target.name]: Number(e.target.value) });
      setFilter({ ...filter, [e.target.name]: e.target.value });
    } else if (e.target.name == "RuleName") {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    } else {
      setFilter({ ...filter, [e.target.name]: e.target.value.toUpperCase() });
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setChecked(!checked);
    setCheckedairportMarkupRuleName(
      fullscreendata.airportMarkup &&
        fullscreendata.airportMarkup
          .sort((x, y) => x.capAmount - y.capAmount)
          .filter(
            (ite, ind) =>
              ite.From.includes(filter.Origin) &&
              ite.To.includes(filter.Destination) &&
              ite.AirCode.includes(filter.Airline) &&
              ite.ruleName.includes(filter.RuleName) &&
              ite.cappingDays.toString().includes(filter.CappingAmount) &&
              // ite.cappingDays === filter.CappingAmount &&
              // ite.tripType === filter.TripType
              ite.tripType.includes(filter.TripType)
          )
          .map((data) => data.ruleName)
    );
    setCheckedairportMarkup(
      fullscreendata.airportMarkup &&
        fullscreendata.airportMarkup
          .sort((x, y) => x.capAmount - y.capAmount)
          .filter(
            (ite, ind) =>
              ite.From.includes(filter.Origin) &&
              ite.To.includes(filter.Destination) &&
              ite.AirCode.includes(filter.Airline) &&
              ite.ruleName.includes(filter.RuleName) &&
              // ite.cappingDays === filter.CappingAmount &&
              ite.cappingDays.toString().includes(filter.CappingAmount) &&
              // ite.tripType === filter.TripType
              ite.tripType.includes(filter.TripType)
          )
    );
    if (isCheckAll) {
      setCheckedairportMarkup([]);
      setCheckedairportMarkupRuleName([]);
    }
  };

  console.log("isCheckAll", checkedairportMarkupRuleName);
  // console.log("fullscreendata.airportMarkup", fullscreendata.airportMarkup);
  console.log("checkedairportMarkup", checkedairportMarkup);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const ActionStyle = {
    color: "#00ab55",
  };
  const { themeStretch } = useSettings();
  const { state } = useLocation();
  const options = {
    animationData: empty,
    autoPlay: true,
    loop: true,
  };
  const { View } = useLottie(options);

  /* ------------------------------------- Api Call --------------------------------------------- */
  const GetAllRuleMaded = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    // const fetchingRule = await fetch(
    //   `${hostname}get/farerule/info`,
    //   requestOptions`
    // );

    const fetchingRule = await fetch(
      `${hostname}get/farerule/info`,
      requestOptions
    );
    const RuleFetchedOverHere = await fetchingRule.json();
    if (RuleFetchedOverHere.status === true) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }
    if (RuleFetchedOverHere) {
      setRuleData(RuleFetchedOverHere);
      setIsloading(false);
    }
  };
  const buttonBorder = {
    borderRadius: 10,
    border: "1px solid ",
    borderColor: theme.palette.primary.light,
  };
  // console.log(
  //   "ruleData",
  //   ruleData.data.filter((item) => item.userid === state.userid)
  // );
  const GetAgents = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const fetchingData = await fetch(
      `${hostname}get/b2bAgents`,
      requestOptions
    );
    // const fetchingData = await fetch(
    //   `${hostname}get/b2bAgents`,
    //   requestOptions
    // );

    const ApiResp = await fetchingData.json();

    if (ApiResp) {
      setAgents(ApiResp);
      setIsloading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAirlineClose = () => {
    setAirlineopen(false);
  };

  const handleEditAirportMarkupclose = () => {
    setAiportedit(false);
  };

  const handleEditAirportMarkup = (item, data) => {
    const rule = item.ruleName;
    setEditAirportMarkup(item);
    setRuleName(rule);
    setAiportedit(true);
  };

  const handleChangeOpen = (data1, data, id) => {
    const provider = data.apiprovider.filter((item) => item.gdsType !== id);
    // const findIndexProvider = data.apiprovider.findIndex(
    //   (item) => item.gdsType == id
    // );
    // console.log("findIndexProvider", findIndexProvider);
    // setIndexProvider(findIndexProvider);
    setOpen(true);
    setFilterApiprovider(provider);
    setModalData({ ...data1, ...data });
  };

  // console.log("modalData", modalData);

  const ads = {
    name: modalData.name,
    gdsType: modalData.gdsType,
    code: modalData.code == "" ? "ALL" : modalData.code,
    fareType: modalData.fareType == "" ? "ALL" : modalData.fareType,
    airlineMarkup: modalData.airlineMarkup,
    fareTypeMarkup: modalData.fareTypeMarkup,
    blockedAutoTicketAirline: modalData.blockedAutoTicketAirline,
    blockedAirport: modalData.blockedAirport,
    airportMarkup: modalData.airportMarkup,
    opaqueData: modalData.opaqueData,
  };
  const UpdatedFareRuleObj = async (modalData) => {
    const concatednatedArr = filterApiprovider.concat(ads);
    // console.log("concatednatedArr", concatednatedArr);
    if (concatednatedArr) {
      await updateFarerule(modalData, concatednatedArr, handleClose);
    }
  };
  /* ------------------------------------- Api Call --------------------------------------------- */

  useEffect(() => (GetAgents(), GetAllRuleMaded()), []);

  const handleInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...apiProvider];
    list[index][name] = value;
    setApiProvider(list);
  };

  const handleFareObjChange = (e, name) => {
    if (name === "amount" || name === "noOfPAXSource") {
      setModalData({ ...modalData, [name]: Number(e.target.value) });
    } else if (name === "From") {
      setModalData({ ...modalData, [name]: e.target.value });
    } else {
      setModalData({ ...modalData, [name]: e.target.value });
    }
  };

  const handleairportMarkup = (data, data2, proIndex, gdsType) => {
    const provider = data.apiprovider.filter(
      (item) => item.gdsType !== gdsType
    );

    setFilterApiprovider(provider);
    setAirlineopen(true);
    setAirportMarkupAllData(data);
    setAirportMarkup(data2);
    setBulkData(data2);
    setAirportMarkupIndex(proIndex);
  };

  const handleBulkAirport = (e) => {
    e.preventDefault();
    setBulkAirport(e.target.files);
  };

  const AddBulkAirportMarkup = (data) => {
    // console.log("data", data);
    const form = new FormData();
    form.append("uploadexcel", bulkAirport[0], bulkAirport[0].name);
    form.append("userid", state.userid);
    form.append("gdsType", data.gdsType);

    const options = {
      method: "POST",
      url: `${hostname}add/bulk/airport/markup`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log("apiBulk", response.data);
        alert("Uploaded BulkAirportMarkup");
        handleAirlineClose();
        setBulkAirport({});
      })
      .catch(function (error) {
        console.error(error.message);
      });
  };
  const fullScreenhandle = (provd, row) => {
    setFullscreendata(provd);
    setFullscreendataprovider(row);
    setShowamrk(!showamrk);
  };

  const handleslecterule = (e, item, ind) => {
    const data = checkedairportMarkupRuleName.includes(item.ruleName);
    if (data) {
      const filteredArray = checkedairportMarkupRuleName.filter(
        (itemValue, index) => itemValue !== item.ruleName
      );
      setCheckedairportMarkupRuleName(filteredArray);
      const filtercheckedairportMarkup = checkedairportMarkup.filter(
        (items) => items.ruleName !== item.ruleName
      );
      setCheckedairportMarkup(filtercheckedairportMarkup);
    } else {
      setCheckedairportMarkup((oldArray) => [...oldArray, item]);
      setCheckedairportMarkupRuleName((oldArray) => [
        ...oldArray,
        item.ruleName,
      ]);
    }
  };

  // console.log("fullscreendata.airportMarkup", fullscreendata.airportMarkup);
  console.log("checkedairportMarkupdata", checkedairportMarkupRuleName);
  const handlemultipleDelete = (data) => {
    // const aftercheckedairportMarkup = fullscreendata.airportMarkup.filter(
    //   (ite, index) => {
    //     return checkedairportMarkupIndx.indexOf(index) == -1;
    //   }
    // );
    // const aftercheckedairportMarkup = fullscreendata.airportMarkup.filter(
    //   ({ ruleName: ruleName1 }) =>
    //     !checkedairportMarkupdata.some(
    //       ({ ruleName: ruleName2 }) => ruleName2 === ruleName1
    //     )
    // );
    const aftercheckedairportMarkup = fullscreendata.airportMarkup.filter(
      ({ ruleName: ruleName1 }) =>
        !data.some(({ ruleName: ruleName2 }) => ruleName2 === ruleName1)
    );

    const provider = fullscreendataprovider.apiprovider.filter(
      (item) => item.gdsType !== fullscreendata.gdsType
    );
    const remainData = {
      name: fullscreendata.name,
      gdsType: fullscreendata.gdsType,
      code: fullscreendata.code == "" ? "ALL" : fullscreendata.code,
      fareType: fullscreendata.fareType == "" ? "ALL" : fullscreendata.fareType,
      airlineMarkup: fullscreendata.airlineMarkup,
      fareTypeMarkup: fullscreendata.fareTypeMarkup,
      blockedAutoTicketAirline: fullscreendata.blockedAutoTicketAirline,
      blockedAirport: fullscreendata.blockedAirport,
      airportMarkup: aftercheckedairportMarkup,
      opaqueData: fullscreendata.opaqueData,
    };
    const ApiProvider = provider.concat(remainData);
    if (ApiProvider) {
      const Data = {
        _id: fullscreendataprovider._id,
        provider: ApiProvider,
        blockedAirportSource: fullscreendataprovider.blockedAirportSource,
        rule: fullscreendataprovider.rule,
        amount: fullscreendataprovider.amount,
        fromDate: fullscreendataprovider.fromDate,
        toDate: fullscreendataprovider.toDate,
        blockedCountrySource: fullscreendataprovider.blockedCountrySource,
        noOfPAXSource: fullscreendataprovider.noOfPAXSource,
        tripTypeSource: fullscreendataprovider.tripTypeSource,
      };

      const options = {
        method: "PATCH",
        url: `${hostname}update/farerule/info`,
        headers: { "Content-Type": "application/json" },
        data: Data,
      };

      axios
        .request(options)
        .then(function (response) {
          alert("airportMarkup Rule Deleted");
        })
        .catch(function (error) {
          console.error("error");
        });
    }
  };

  const handleDeleteairportMarkup = (data, ind, row, provd, item) => {
    const provider = row.apiprovider.filter(
      (item) => item.gdsType !== provd.gdsType
    );
    // console.log("provider", provider);
    console.log("item", item);
    console.log("data", data);
    var abc = data.filter((it, i) => {
      if (it.ruleName !== item.ruleName) {
        return it;
      }
    });

    const remainData = {
      name: provd.name,
      gdsType: provd.gdsType,
      code: provd.code == "" ? "ALL" : provd.code,
      fareType: provd.fareType == "" ? "ALL" : provd.fareType,
      airlineMarkup: provd.airlineMarkup,
      fareTypeMarkup: provd.fareTypeMarkup,
      blockedAutoTicketAirline: provd.blockedAutoTicketAirline,
      blockedAirport: provd.blockedAirport,
      airportMarkup: abc,
      opaqueData: provd.opaqueData,
    };
    const ApiProvider = provider.concat(remainData);
    if (ApiProvider) {
      const Data = {
        _id: row._id,
        provider: ApiProvider,
        blockedAirportSource: row.blockedAirportSource,
        rule: row.rule,
        amount: row.amount,
        fromDate: row.fromDate,
        toDate: row.toDate,
        blockedCountrySource: row.blockedCountrySource,
        noOfPAXSource: row.noOfPAXSource,
        tripTypeSource: row.tripTypeSource,
      };

      const options = {
        method: "PATCH",
        url: `${hostname}update/farerule/info`,
        headers: { "Content-Type": "application/json" },
        data: Data,
      };

      axios
        .request(options)
        .then(function (response) {
          alert("airportMarkup Rule Deleted");
        })
        .catch(function (error) {
          console.error("error");
        });
    }
  };

  // console.log("state.userid", state.userid);
  if (isloading) {
    return <Typography>Wait Your data is Loading</Typography>;
  }

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : "xxxl"} spacing={1}>
        <HeaderBreadcrumbs
          heading="All Fare Rule"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Applied Rule",
              href: PATH_DASHBOARD.farerule.AppliedRule,
            },
            {
              name: state.name,
            },
          ]}
        />

        {ruleData.status === true ? (
          <Card style={{ width: "100%" }}>
            <DIV style={{ marginBottom: 15, overflow: "hidden" }}>
              <CardHeader
                title={state.name}
                sx={{ mb: 3 }}
                titleTypographyProps={{ variant: "h4" }}
              />
            </DIV>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {ruleData.data
                .filter((item) => item.userid === state.userid)
                .map((row, i) => (
                  <Grid
                    item
                    xs={11}
                    style={{
                      marginLeft: 45,
                      marginBottom: 20,
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    {console.log("row", row)}
                    {row.apiprovider
                      .sort((a, b) => a.gdsType - b.gdsType)
                      .map((provd, proIndex) => (
                        <Grid
                          key={i}
                          className="p-3"
                          style={{
                            borderRadius: 10,
                            border: "1px solid ",
                            borderColor: "#dee1df",
                          }}
                        >
                          <div
                            className="d-flex align-items-center "
                            style={{
                              borderBottom: "1px solid rgb(241 241 241)",
                              borderColor: theme.palette.primary.light,
                              paddingBottom: "14px",
                              marginBottom: "14px",
                            }}
                          >
                            <div className="mr-3 d-flex align-items-center">
                              <MyAvatar />
                            </div>
                            <div className="d-flex  justify-content-center flex-column mx-1">
                              <Typography
                                sx={{
                                  color: "primary.main",
                                  letterSpacing: "1px",
                                  textTransform: "uppercase",
                                }}
                                className=" font-bolder"
                                variant="h6"
                              >
                                {provd.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="text-gray-700 font-bold mb-1"
                              >
                                Provider{" "}
                              </Typography>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="text-gray-400 pe-2 fa-sm"
                            />
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Amount
                            </Typography>
                            <Typography
                              style={{ fontSize: "17px" }}
                              className="text-gray-700 font-bold"
                            >
                              {row.amount} {row.rule}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              className="text-gray-400 pe-2"
                            />
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Valid From
                            </Typography>
                            <Typography
                              style={{ fontSize: "17px" }}
                              className="text-gray-700 font-bold"
                            >
                              {moment(row.fromDate).format("MMM DD, YYYY")}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              className="text-gray-400 pe-2"
                            />
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Valid To
                            </Typography>
                            <Typography
                              style={{ fontSize: "17px" }}
                              className="text-gray-700 font-bold"
                            >
                              {moment(row.toDate, "DD/MM/YYYY").format(
                                "MMM DD, YYYY"
                              )}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="text-gray-700 font-bold"
                              style={{
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                                minWidth: "220px",
                              }}
                            >
                              Allowed Airlines{" "}
                            </Typography>
                            <Typography
                              style={{ fontSize: "17px" }}
                              className="text-gray-700 font-bold"
                            >
                              {provd.code == "" ? "N/A" : <>{provd.code}</>}
                              {/* {provd.code} */}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <FontAwesomeIcon
                              icon={faMoneyBill1Wave}
                              className="text-gray-400 pe-2 fa-sm"
                            />
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Fare type
                            </Typography>

                            <Typography style={{ wordBreak: "break-all" }}>
                              {provd.fareType}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Airline Markup
                            </Typography>

                            <Typography style={{ wordBreak: "break-all" }}>
                              {provd.airlineMarkup == "" ? (
                                "N/A"
                              ) : (
                                <>{provd.airlineMarkup}</>
                              )}
                              {/* {provd.airlineMarkup} */}
                            </Typography>
                          </div>

                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <FontAwesomeIcon
                              icon={faMoneyBill1Wave}
                              className="text-gray-400 pe-2  fa-sm"
                            />
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Fare Markup
                            </Typography>
                            <Typography style={{ wordBreak: "break-all" }}>
                              {provd.fareTypeMarkup == "" ? (
                                "N/A"
                              ) : (
                                <>{provd.fareTypeMarkup}</>
                              )}
                              {/* {provd.fareTypeMarkup} */}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              TripType Source
                            </Typography>

                            <Typography>
                              {row.tripTypeSource == "" ? (
                                "N/A"
                              ) : (
                                <>{row.tripTypeSource}</>
                              )}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              PAX Source
                            </Typography>

                            <Typography>
                              {row.noOfPAXSource === 0 ? (
                                "Any"
                              ) : (
                                <>{row.noOfPAXSource}</>
                              )}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Opaque Data
                            </Typography>

                            <Typography style={{ wordBreak: "break-all" }}>
                              {provd.opaqueData == "" ? (
                                "N/A"
                              ) : (
                                <>{provd.opaqueData}</>
                              )}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="  text-gray-700 font-bold"
                              style={{
                                minWidth: "220px",
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              BlockCountrySource
                            </Typography>

                            <Typography style={{ wordBreak: "break-all" }}>
                              {row.blockedCountrySource == "" ? (
                                "N/A"
                              ) : (
                                <>{row.blockedCountrySource}</>
                              )}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="text-gray-700 font-bold"
                              style={{
                                fontSize: "17px",
                                letterSpacing: "0.5px",
                                minWidth: "220px",
                              }}
                            >
                              BlockAirportSource{" "}
                            </Typography>
                            <Typography style={{ wordBreak: "break-all" }}>
                              {row.blockedAirportSource === "" ? (
                                "N/A"
                              ) : (
                                <>{row.blockedAirportSource}</>
                              )}
                              {/* {row.blockedAirportSource} */}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                            <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                            <Typography
                              className="text-gray-700 font-bold"
                              style={{
                                fontSize: "17px",
                                letterSpacing: "0.6px",
                                minWidth: "220px",
                              }}
                            >
                              Allowed Airport
                            </Typography>
                            <Typography style={{ wordBreak: "break-all" }}>
                              {provd.blockedAirport}
                            </Typography>
                          </div>
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <Typography
                                className="pe-4 mb-4 mt-4 text-gray-700 font-bolder"
                                style={{
                                  fontSize: "20px",
                                  letterSpacing: "0.5px",
                                  textDecoration: "underline",
                                  margin: "0",
                                  fontWeight: "600",
                                }}
                              >
                                Airport Markup
                              </Typography>
                              {/* <div>
                              <Input
                                type="file"
                                placeholder="Upload BulkAirportMarkup"
                                name="bulkAirport"
                                id="bulkAirport"
                                sx={{ width: "225px", marginRight: "20px" }}
                                // value={bulkAirport}
                                onChange={handleBulkAirport}
                              />
                              <Button
                                disabled={
                                  Object.keys(bulkAirport).length !== 0
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  AddBulkAirportMarkup(provd);
                                }}
                              >
                                Bulk AirportMarkup
                              </Button>
                            </div> */}
                              <div>
                                <Button
                                  style={buttonBorder}
                                  className="me-3"
                                  onClick={() => {
                                    fullScreenhandle(provd, row);
                                  }}
                                >
                                  Full Screen
                                </Button>

                                <Button
                                  onClick={() =>
                                    choiceexcleSiteDownLoad(provd.airportMarkup)
                                  }
                                  style={buttonBorder}
                                  className="me-3"
                                >
                                  Download{" "}
                                  <ArrowDownwardIcon className="ml-2 " />
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleairportMarkup(
                                      row,
                                      provd,
                                      proIndex,
                                      provd.gdsType
                                    );
                                  }}
                                  style={buttonBorder}
                                >
                                  Add <AddIcon />
                                </Button>
                              </div>
                            </div>
                            <div>
                              <TableContainer className="tableairp-responsive">
                                <Table className="table-airp">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Rule Name</TableCell>
                                      <TableCell>TripType</TableCell>
                                      <TableCell>CabinClass</TableCell>
                                      <TableCell>
                                        CabinSeg. &nbsp;/ &nbsp;CabinInbound
                                      </TableCell>
                                      <TableCell>
                                        ArrTimeMrkp &nbsp;/ &nbsp;DepTimeMrkp
                                      </TableCell>
                                      <TableCell>Origin</TableCell>
                                      <TableCell>Destination</TableCell>
                                      <TableCell>Travel Start</TableCell>
                                      <TableCell>Travel End</TableCell>
                                      <TableCell>Airline</TableCell>
                                      <TableCell>Amount</TableCell>
                                      <TableCell>CapAmount</TableCell>
                                      <TableCell>Ori.Ctry</TableCell>
                                      <TableCell>Dest.Ctry</TableCell>
                                      <TableCell>MinAmount</TableCell>
                                      <TableCell>MaxAmount</TableCell>
                                      <TableCell>CappingDays</TableCell>
                                      <TableCell>Stops</TableCell>
                                      <TableCell>PAX</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  {provd.airportMarkup === undefined ? (
                                    []
                                  ) : (
                                    <TableBody>
                                      {provd.airportMarkup.map((item, ind) => {
                                        return (
                                          <TableRow key={ind}>
                                            <TableCell>
                                              {item.ruleName}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.tripType === undefined
                                                ? null
                                                : item.tripType === "1"
                                                ? 1
                                                : item.tripType === "2"
                                                ? 2
                                                : "Both"}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.cabinClass}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.cabinSegment} &nbsp;/ &nbsp;
                                              {item.cabinSegmentInbound}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.arrTimeMarkup} &nbsp;/
                                              &nbsp;
                                              {item.depTimeMarkup}
                                            </TableCell>
                                            <TableCell className="maxw-eliptr">
                                              {item.From}
                                            </TableCell>
                                            <TableCell className="maxw-eliptr">
                                              {item.To}
                                            </TableCell>
                                            <TableCell>
                                              {moment(item.RuleDateFrom).format(
                                                "MMM DD, YYYY"
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {moment(item.RuleDateTo).format(
                                                "MMM DD, YYYY"
                                              )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.AirCode}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.Amount}&nbsp;
                                              {item.markupType}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.capAmount === undefined ? (
                                                "N/A"
                                              ) : (
                                                <>{item.capAmount}</>
                                              )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.originCountry}
                                            </TableCell>{" "}
                                            <TableCell className="text-center">
                                              {item.destinationCountry}
                                            </TableCell>{" "}
                                            <TableCell className="text-center">
                                              {item.minAmount}
                                            </TableCell>{" "}
                                            <TableCell className="text-center">
                                              {item.maxAmount}
                                            </TableCell>{" "}
                                            <TableCell className="text-center">
                                              {item.cappingDays}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.stops === -1 ? (
                                                "ALL"
                                              ) : (
                                                <>{item.stops}</>
                                              )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {item.noOfPAX}
                                            </TableCell>
                                            <TableCell>
                                              <Button
                                                onClick={() =>
                                                  handleDeleteairportMarkup(
                                                    provd.airportMarkup,
                                                    ind,
                                                    row,
                                                    provd,
                                                    item
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                          <div className="text-right">
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => {
                                handleChangeOpen(provd, row, provd.gdsType);
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                ))}
            </Grid>

            <Dialog
              fullWidth={"600px"}
              onClose={handleAirlineClose}
              open={Airlineopen}
            >
              <Box>
                <TabContext value={value}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className="d-flex justify-content-between m-4"
                  >
                    <TabList onChange={handleChangeTab}>
                      <Tab label="Add Mannual" value="1" />
                      <Tab label="Add Bulk" value="2" />
                    </TabList>

                    <CloseIcon
                      onClick={handleAirlineClose}
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    />
                  </Box>
                  <TabPanel value="1" className="mx-4">
                    <EditAirportMarkup
                      airportMarkup={airportMarkup}
                      data={airportMarkupAllData}
                      providerIndex={airportMarkupIndex}
                      filterApiprovider={filterApiprovider}
                      handleAirlineClose={handleAirlineClose}
                    />
                  </TabPanel>
                  <TabPanel value="2" className="mx-4">
                    <div className="d-flex flex-column justify-content-center ">
                      <Input
                        type="file"
                        placeholder="Upload BulkAirportMarkup"
                        name="bulkAirport"
                        id="bulkAirport"
                        sx={{ width: "300px", marginRight: "20px" }}
                        // value={bulkAirport}
                        onChange={handleBulkAirport}
                      />
                      <div className="text-center">
                        <Button
                          variant="contained"
                          sx={{
                            marginBottom: "20px",
                            marginTop: 2,
                            height: "45px",
                            fontSize: "16px",
                            width: "140px",
                          }}
                          disabled={
                            Object.keys(bulkAirport).length !== 0 ? false : true
                          }
                          onClick={() => {
                            AddBulkAirportMarkup(bulkdata);
                          }}
                        >
                          Sumbit
                        </Button>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </Dialog>

            <Dialog
              fullWidth={true}
              onClose={handleClose}
              open={open}
              fullScreen={true}
              sx={{ margin: "40px" }}
              style={{ borderRadius: 10 }}
            >
              <DialogTitle sx={{ marginBottom: "20px" }}>
                Edit Fare Rule{" "}
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fromDate"
                        type="text"
                        value={modalData.fromDate}
                        id="fromDate"
                        label="From Date (MM/DD/YYYY)"
                        onChange={(e) => handleFareObjChange(e, "fromDate")}
                        sx={{ margin: 1, width: "100%" }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="toDate"
                        value={modalData.toDate}
                        type="text"
                        id="toDate"
                        label="To Date (MM/DD/YYYY)"
                        onChange={(e) => handleFareObjChange(e, "toDate")}
                        sx={{ margin: 1, width: "100%" }}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          paddingLeft: "10px",
                        }}
                      >
                        <label
                          style={{
                            marginRight: "30px",
                          }}
                        >
                          Markup Type
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            name="fareType"
                            type="radio"
                            value={"%"}
                            checked={modalData.rule === "%"}
                            id="farerType"
                            onChange={(e) => handleFareObjChange(e, "rule")}
                            style={{
                              height: "18px",
                              width: "18px",
                              marginRight: "15px",
                            }}
                          />
                          <label style={{ marginRight: "35px" }}>%</label>
                          <input
                            name="fareType"
                            type="radio"
                            checked={modalData.rule === "FLAT"}
                            value={"FLAT"}
                            id="fareType"
                            placeholder="Fare Type"
                            onChange={(e) => handleFareObjChange(e, "rule")}
                            style={{
                              height: "18px",
                              width: "18px",
                              marginRight: "15px",
                            }}
                          />
                          <label>FLAT</label>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        value={modalData.amount}
                        name="amount"
                        id="amount"
                        placeholder="Provider"
                        sx={{ margin: 1, width: "100%" }}
                        onChange={(e) => handleFareObjChange(e, "amount")}
                        label="Amount"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        value={modalData.name}
                        name="name"
                        id="name"
                        disabled={true}
                        placeholder="Provider"
                        sx={{ margin: 1, width: "100%" }}
                        onChange={(e) => handleFareObjChange(e, "name")}
                        label="Supplier"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fareType"
                        value={modalData.fareType}
                        id="farerType"
                        placeholder="Fare Type"
                        onChange={(e) => handleFareObjChange(e, "fareType")}
                        sx={{ margin: 1, width: "100%" }}
                        label=" Fare Type (Published,Regular)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fareTypeMarkup"
                        value={modalData.fareTypeMarkup}
                        id="fareTypeMarkup"
                        placeholder="Fare Type Markup (PUBLISHED:199,Regular:299,SMEFares:399)"
                        onChange={(e) =>
                          handleFareObjChange(e, "fareTypeMarkup")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="Fare Type Markup (PUBLISHED:199,Regular:299,SMEFares:399)"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="code"
                        value={modalData.code}
                        id="code"
                        placeholder="Airline Code (AI,6E ,G8)"
                        onChange={(e) => handleFareObjChange(e, "code")}
                        sx={{ margin: 1, width: "100%" }}
                        label="  Allowed Airline Codes (ALL,AI,6E)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="airlineMarkup"
                        value={modalData.airlineMarkup}
                        id="airlineMarkup"
                        placeholder="Airline Markup (6E:400,G8:50,UK:99)"
                        onChange={(e) =>
                          handleFareObjChange(e, "airlineMarkup")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label=" Airline Markup (6E:400,G8:50,UK:99)"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedAirportSource"
                        value={modalData.blockedAirportSource}
                        id="blockedAirportSource"
                        placeholder="blockedAirportSource"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedAirportSource")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label=" blockedAirportSource"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedAirport"
                        value={modalData.blockedAirport}
                        id="blockedAirport"
                        placeholder="AllowedAirport"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedAirport")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="AllowedAirport"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="opaqueData"
                        value={modalData.opaqueData}
                        id="opaqueData"
                        placeholder="OpaqueData (AA:AS:DL, NK:B6:F9)"
                        onChange={(e) => handleFareObjChange(e, "opaqueData")}
                        sx={{ margin: 1, width: "100%" }}
                        label="OpaqueData (AA:AS:DL, NK:B6:F9)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2} className=" align-items-center ">
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedCountrySource"
                        value={modalData.blockedCountrySource}
                        id="blockedCountrySource"
                        placeholder="blockedCountrySource"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedCountrySource")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="blockedCountrySource"
                        variant="filled"
                      />
                    </Grid>
                    <Grid
                      className="d-flex align-items-center "
                      style={{ marginTop: "12px", marginLeft: "17px" }}
                    >
                      <FormControl
                        variant="filled"
                        style={{ width: "250px" }}
                        className="me-5"
                      >
                        <InputLabel id="tripType">Select TripType</InputLabel>
                        <Select
                          labelId="tripTypeSource"
                          id="tripTypeSource"
                          name="tripTypeSource"
                          value={modalData.tripTypeSource}
                          sx={{ margin: 1, width: "100%" }}
                          onChange={(e) =>
                            handleFareObjChange(e, "tripTypeSource")
                          }
                        >
                          {TripType.map((item) => (
                            <MenuItem value={item.value}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="filled" style={{ width: "250px" }}>
                        <InputLabel id="noOfPAX">Select No of PAX</InputLabel>
                        <Select
                          labelId="noOfPAXSource"
                          id="noOfPAXSource"
                          name="noOfPAXSource"
                          value={modalData.noOfPAXSource}
                          sx={{ margin: 1, width: "100%" }}
                          onChange={(e) =>
                            handleFareObjChange(e, "noOfPAXSource")
                          }
                        >
                          {PAX.map((item) => (
                            <MenuItem value={item.value}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <div className="text-center">
                <Button
                  variant="contained"
                  onClick={() => UpdatedFareRuleObj(modalData)}
                  sx={{
                    marginBottom: "20px",
                    marginTop: 2,
                    height: "45px",
                    fontSize: "16px",
                    width: "140px",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Dialog>
          </Card>
        ) : (
          <div>
            {View}
            No fareRule Applied
          </div>
        )}

        <Modal
          show={showamrk}
          fullscreen={true}
          onHide={() => {
            setShowamrk(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Airport Markup{" "}
              {fullscreendata.airportMarkup &&
                fullscreendata.airportMarkup.length}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 overflow-hidden">
            <div className="scroll-vhfulls">
              <div className="d-flex h-100 flex-column">
                <FullViewDisplayForm
                  filter={filter}
                  handleChangeFilter={handleChangeFilter}
                  checkedairportMarkup={checkedairportMarkup}
                  buttonBorder={buttonBorder}
                  handleSelectAll={handleSelectAll}
                  isCheckAll={isCheckAll}
                  handlemultipleDelete={handlemultipleDelete}
                />

                <div className="flex-grow-1 overflow-auto">
                  <FullViewdisplayMarkup
                    filter={filter}
                    fullscreendata={fullscreendata}
                    checkedairportMarkupRuleName={checkedairportMarkupRuleName}
                    ActionStyle={ActionStyle}
                    handleslecterule={handleslecterule}
                    handleDeleteairportMarkup={handleDeleteairportMarkup}
                    fullscreendataprovider={fullscreendataprovider}
                    handleEditAirportMarkup={handleEditAirportMarkup}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={aiportedit} onHide={handleEditAirportMarkupclose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Airport Markup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditAirport
              Data={editAirportMarkup}
              ruleName={ruleName}
              setEditAirportMarkup={setEditAirportMarkup}
              fullscreendata={fullscreendata}
              fullscreendataprovider={fullscreendataprovider}
              handleEditAirportMarkupclose={handleEditAirportMarkupclose}
            />
          </Modal.Body>
        </Modal>
      </Container>
    </Page>
  );
}
