import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../routes/paths";
import { decryptData } from "src/utils/decryptcookie";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  // const login = sessionStorage.getItem("LoginState");
  const login = decryptData("@s_t2_ai_te");

  const isAuthenticated = login;

  console.log("isAuthenticated", isAuthenticated);

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
