import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Login from "../pages/authentication/Login";
import OTPVerification from "src/pages/authentication/OTPVerification";
import { decryptData } from "src/utils/decryptcookie";

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  // const login = sessionStorage.getItem("LoginState");
  const login = decryptData("@s_t2_ai_te");
  const isAuthenticated = login;
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    if (pathname === "/auth/otpverify") {
      return <OTPVerification />;
    } else {
      return <Login />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
