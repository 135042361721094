import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const choiceexcleSiteDownLoad = (checkedairportMarkupdata) => {
  const BulkMarkup = checkedairportMarkupdata.map((item) => {
    return {
      tripType:
        item.tripType == "1"
          ? "Oneway"
          : item.tripType == "2"
          ? "Roundway"
          : "Both",
      noOfPax: item.noOfPAX == 0 ? "Any" : item.noOfPAX,
      originAirport: item.From,
      originCountry: item.originCountry,
      destinationAirport: item.To,
      destinationCountry: item.destinationCountry,
      amount: item.Amount,
      capAmount: item.capAmount,
      travelStart: moment(item.RuleDateFrom).format("DD-MM-YYYY"),
      travelEnd: moment(item.RuleDateTo).format("DD-MM-YYYY"),
      cabinSegment: item.cabinSegment,
      airCode: item.AirCode,
      minAmount: item.minAmount,
      maxAmount: item.maxAmount,
      stops:
        item.stops == 0
          ? "Direct"
          : item.stops == 1
          ? "1Stop"
          : item.stops == 2
          ? "2Stop"
          : "ALL",
      ruleName: item.ruleName,
      cappingDays: item.cappingDays,
      cabinSegmentInbound: item.cabinSegmentInbound,
      arrTimeMarkup: item.arrTimeMarkup,
      depTimeMarkup: item.depTimeMarkup,
      cabinClass: item.cabinClass,
      markupType: item.markupType,
    };
  });
  const ws = XLSX.utils.json_to_sheet(BulkMarkup);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, "BulkAirportMarkup" + fileExtension);
};
