// routes
import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
  allData: getIcon("ic_allData"),
  add: getIcon("ic_add"),
};

const sidebarConfig = [
  // GENERAL
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: ICONS.home,
      },
    ],
  },

  {
    subheader: "Scrappy User",
    items: [
      {
        title: "Add New User",
        path: PATH_DASHBOARD.general.add_scrappy_user,
        icon: ICONS.add,
      },
      {
        title: "View all User",
        path: PATH_DASHBOARD.general.view_scrappy_user,
        icon: ICONS.home,
      },
    ],
  },

  // {
  //   subheader: "Fare Rule",
  //   items: [
  //     {
  //       title: "All Fare Rule",
  //       path: PATH_DASHBOARD.farerule.checkAgentRules,
  //       icon: ICONS.rule,
  //     },
  //   ],
  // },

  {
    subheader: "Scrapping Data Management",
    items: [
      {
        title: "View all data",
        path: PATH_DASHBOARD.general.scrap_all_data,
        icon: ICONS.allData,
      },
      {
        title: "Add data",
        path: PATH_DASHBOARD.general.add_Iternary,
        icon: ICONS.add,
      },
      {
        title: "Add bulk data",
        path: PATH_DASHBOARD.general.add_bulk_data,
        icon: ICONS.add,
      },
    ],
  },
];

export default sidebarConfig;
