import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const FullViewdisplayMarkup = ({
  filter,
  fullscreendata,
  checkedairportMarkupRuleName,
  ActionStyle,
  handleslecterule,
  handleDeleteairportMarkup,
  fullscreendataprovider,
  handleEditAirportMarkup,
}) => {
  const TableHeaderName = [
    {
      id: 0,
      name: "Select",
    },
    {
      id: 1,
      name: "Rule Name",
    },
    {
      id: 2,
      name: "TripType",
    },
    {
      id: 3,
      name: "CabinClass",
    },
    {
      id: 4,
      name: "CabinSeg. / CabinInbound",
    },
    {
      id: 5,
      name: "ArrTimeMrkp / DepTimeMrkp",
    },
    {
      id: 6,
      name: "Origin",
    },
    {
      id: 7,
      name: "Destination",
    },
    {
      id: 8,
      name: "TravelStart",
    },
    {
      id: 9,
      name: "TravelEnd",
    },
    {
      id: 10,
      name: "Airline",
    },
    {
      id: 11,
      name: "Amt.",
    },
    {
      id: 12,
      name: "CapAmt.",
    },
    {
      id: 13,
      name: "Ori.Ctry",
    },
    {
      id: 14,
      name: "Dest.Ctry",
    },
    {
      id: 15,
      name: "MinAmt.",
    },
    {
      id: 16,
      name: "MaxAmt.",
    },
    {
      id: 17,
      name: "CapDay",
    },
    {
      id: 18,
      name: "Stops",
    },
    {
      id: 19,
      name: "PAX",
    },
    {
      id: 20,
      name: "Action",
    },
  ];
  return (
    <TableContainer
      className="tableairp-responsive"
      style={{ maxHeight: "none", height: "100%", margin: "0" }}
    >
      <Table className="table-airp">
        <TableHead>
          <TableRow>
            {/* <TableCell>Select</TableCell>
            <TableCell>Rule Name</TableCell>
            <TableCell>TripType</TableCell>
            <TableCell>CabinClass</TableCell>
            <TableCell>CabinSeg. &nbsp;/ &nbsp;CabinInbound</TableCell>
            <TableCell>ArrTimeMrkp &nbsp;/ &nbsp;DepTimeMrkp</TableCell>
            <TableCell>Origin</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>TravelStart</TableCell>
            <TableCell>TravelEnd</TableCell>
            <TableCell>Airline</TableCell>
            <TableCell>Amt.</TableCell>
            <TableCell>CapAmt.</TableCell>
            <TableCell>Ori.Ctry</TableCell>
            <TableCell>Dest.Ctry</TableCell>
            <TableCell>MinAmt.</TableCell>
            <TableCell>MaxAmt.</TableCell>
            <TableCell>CapDay</TableCell>
            <TableCell>Stops</TableCell>
            <TableCell>PAX</TableCell>
            <TableCell>Action</TableCell> */}
            {TableHeaderName.map((items, index) => (
              <TableCell>{items.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {fullscreendata.airportMarkup &&
            fullscreendata.airportMarkup
              .sort((x, y) => x.capAmount - y.capAmount)
              .filter(
                (ite, ind) =>
                  ite.From.includes(filter.Origin) &&
                  ite.To.includes(filter.Destination) &&
                  ite.AirCode.includes(filter.Airline) &&
                  ite.ruleName.includes(filter.RuleName) &&
                  ite.cappingDays.toString().includes(filter.CappingAmount) &&
                  ite.tripType.includes(filter.TripType)
              )
              .map((item, ind) => {
                const abc = checkedairportMarkupRuleName.includes(
                  item.ruleName
                );

                return (
                  <TableRow key={ind}>
                    <TableCell>
                      <Checkbox
                        type="checkbox"
                        checked={abc}
                        value={item}
                        onChange={(e) => {
                          handleslecterule(e, item, ind);
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.ruleName}</TableCell>
                    <TableCell className="text-center">
                      {item.tripType === undefined
                        ? null
                        : item.tripType === "1"
                        ? 1
                        : item.tripType === "2"
                        ? 2
                        : "Both"}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.cabinClass}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.cabinSegment} &nbsp;/ &nbsp;
                      {item.cabinSegmentInbound}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.arrTimeMarkup} &nbsp;/ &nbsp;
                      {item.depTimeMarkup}
                    </TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 pr-2">
                          {" "}
                          <div className="maxw-eliptr">{item.From}</div>
                        </div>
                        {item.From.length > 30 ? (
                          <Tooltip title={item.From} placement="top" arrow>
                            <button className="btn px-0">
                              <InfoIcon color="red" className="text-muted" />
                            </button>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 pr-2">
                          {" "}
                          <div className="maxw-eliptr">{item.To}</div>
                        </div>
                        {item.To.length > 30 ? (
                          <Tooltip title={item.To} placement="top" arrow>
                            <button className="btn px-0">
                              <InfoIcon color="red" className="text-muted" />
                            </button>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(item.RuleDateFrom).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(item.RuleDateTo).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.AirCode}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.Amount}&nbsp;
                      {item.markupType}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.capAmount === undefined ? (
                        "N/A"
                      ) : (
                        <>{item.capAmount}</>
                      )}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.originCountry}
                    </TableCell>{" "}
                    <TableCell className="text-center">
                      {item.destinationCountry}
                    </TableCell>{" "}
                    <TableCell className="text-center">
                      {item.minAmount}
                    </TableCell>{" "}
                    <TableCell className="text-center">
                      {item.maxAmount}
                    </TableCell>{" "}
                    <TableCell className="text-center">
                      {item.cappingDays}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.stops === -1 ? "ALL" : <>{item.stops}</>}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.noOfPAX}
                    </TableCell>
                    <TableCell className="d-flex ustify-content-between">
                      <div className="border border-danger rounded p-1 me-2">
                        <button
                          onClick={() =>
                            handleDeleteairportMarkup(
                              fullscreendata.airportMarkup,
                              ind,
                              fullscreendataprovider,
                              fullscreendata,
                              item
                            )
                          }
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </button>
                      </div>

                      <div className="border border-success rounded p-1">
                        <button
                          onClick={() =>
                            handleEditAirportMarkup(
                              item,
                              fullscreendata.airportMarkup
                            )
                          }
                        >
                          <EditIcon style={ActionStyle} />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FullViewdisplayMarkup;
