import PropTypes from "prop-types";
// material
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import flashFill from "@iconify/icons-eva/flash-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Button, Link, Container, Typography, Stack } from "@material-ui/core";
// routes
// import { PATH_DASHBOARD } from "../../../routes/paths";S
//
import {
  varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight,
} from "../components/animate";
import useAuth from "src/hooks/useAuth";
// ----------------------------------------------------------------------

Traveloes.propTypes = {
  sx: PropTypes.object,
};
const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "10%",
  height: "50%",
  objectFit: "contain",
  position: "absolute",
  bottom: "25 %",
});
export default function Traveloes({ sx, path }) {
  const LoginData = localStorage.getItem("LoginData");
  const user = JSON.parse(LoginData);
  const login = localStorage.getItem("LoginState");
  const isAuthenticated = typeof Boolean(login);
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <>
      <Box sx={{ width: 150, ...sx }}>
        <img
          src={path}
          class="image-aslide-1 mx-auto mb-2"
          alt="login"
        /> 
      </Box>
    </>
  );
}
