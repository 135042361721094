import axios from "axios";
import { hostname } from "src/HostName";
export const otpVerificationApi = async (otp, userId) => {
  let otpParsed = otp.toString().replaceAll(",", "");
  const options = {
    method: "POST",
    url: `${hostname}otp/verification`,
    headers: { "Content-Type": "application/json" },
    data: { userid: userId, otp: otpParsed },
  };
  try {
    const data = await axios.request(options);
    return data;
  } catch (error) {
    return error;
  }
};
