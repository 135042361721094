import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  providervalue: [],
  agentvalue: [],
  Radiovalue: [],
};

const Adminvalues = createSlice({
  name: "values",
  initialState,
  reducers: {
    saveValues(state, action) {

      state.providervalue = action.payload;
    },
    AgentValue(state, action) {

      state.agentvalue = action.payload;
    },
    RadioButtonValue(state, action) {
 
      state.Radiovalue = action.payload;
    },
  },
});

export const { saveValues, AgentValue, RadioButtonValue } = Adminvalues.actions;
export default Adminvalues.reducer;
