import { useState } from "react";
import AirPortData from "../Api/SampleData";
import Favourite from "../Api/Favourite";
import { Input } from "@mui/material";
import { useEffect } from "react";
// import plain from "../Image/plain.png";

const people = AirPortData;
const Fav = Favourite;

export default function To(props) {
  const [selected, setSelected] = useState(people);
  const [FavData, SetFavData] = useState(Fav);
  const [city, setcity] = useState(selected[60].cityName);
  const [finalcity, setfinalcity] = useState(
    "Chhatrapati Shivaji International"
  );
  const [generate, setgenerate] = useState("none");
  const [inputselect, setinputselect] = useState(" ");
  const [FavGen, setFavGen] = useState("none");

  function changecity(e) {
    const cities = e.target.value;
    setcity(cities);
    setfinalcity(e.target.value);
    {
      cities === "" ? setgenerate("none") : setgenerate("block");
    }
    setFavGen("none");
  }
  function changeAirport(e) {
    setgenerate("none");
    props.setDisplayTo("none");
    const airport = e.target.value;
    setfinalcity(airport);
    props.setToAirport(finalcity);
    setinputselect(selected);
  }
  function clickCity() {
    props.setDisplayTo("block");
    props.setDisplayFrom("none");
    props.setDisplayPax("none");
    props.setDisplayClass("none");
  }

  const ending_data = selected.filter((item) => item.airportName == finalcity);
  if (ending_data[0] == undefined) {
    props.setdeparture("");
    props.setToCountryCode("");
  } else {
    props.setdeparture(ending_data[0].cityCode);
    props.setToAirport(ending_data[0].airportName);
    props?.setToCountryCode(ending_data[0].countryCode);
  }
  return (
    <>
      <div className="relative down border-1 rounded-[8px] border-gray-100 mb-1 w-full px-2 pr-10 py-1 text-left  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
        <div className="grid grid-cols-1 gap-2 text-black">
          <span className="block  col-span-6 font-bold text-sm   py-1">
            {" "}
            <i class="fas fa-plane-arrival  text-[#e95419]"></i> &nbsp;
            <Input
              type="text"
              id="selecteds"
              className="city updateBefore w-[90%]"
              value={finalcity}
              defaultValue={
                props.to === undefined
                  ? "Chhatrapati Shivaji International"
                  : props.to
              }
              placeholder="Country, City or Airport"
              autoComplete="Off"
              onChange={(e) => changecity(e)}
              onClick={() => clickCity()}
            />
          </span>

          {/* {selected
            .filter((items) => items.airportName == finalcity)
            .map((items, i) => (
              <span className="foot rounded-lg w-full col-span-3 text-lg px-4 font-bold ">
                {items.cityCode}
              </span>
            ))} */}
        </div>
      </div>
      {selected
        .filter((items) => items.airportName == finalcity)
        .map((items, i) => (
          <>
            <span className=" text-[12px] text-black ">
              <i class="fas fa-map-marker-alt     text-[#e95419]"></i> &nbsp;{" "}
              {items.cityName}
            </span>
            <span className="foot rounded-lg w-full  pl-[5px] font-bold text-[14px]">
              {items.cityCode}
            </span>
          </>
        ))}
      <div className="">
        <ul
          role="listbox"
          className="dataResult pl-0 w-1/3 pr-0 absolute z-50 bg-white shadow rounded-xl overflow-hidden"
          style={{ display: props.displayTo }}
        >
          {FavData.slice(0, 15).map((item, i) => (
            <>
              <div className="flex flex-row p-[10px] items-center backUpdate">
                <button
                  className="dataItem w-full px-3  text-left"
                  onClick={(e) => changeAirport(e)}
                  value={item.airportName}
                >
                  {" "}
                  <i class="fas fa-plane-departure  mr-4  text-[#ee4c26] "></i>{" "}
                  {item.airportName}, {item.cityName}{" "}
                </button>
                <button
                  onClick={(e) => changeAirport(e)}
                  className="float-right shadow foot px-4 text-white rounded-xl mt-2 mr-2 w-24 h-6  bg-gradient-to-r from-blue-500 to-blue-900  tabBgColor text-[12px]"
                  value={item.airportName}
                >
                  {item.airportCode}
                </button>
              </div>
            </>
          ))}
        </ul>
      </div>

      <div className="search">
        <div className="searchInputs">
          <div className="searchIcon">
            <ul
              role="listbox"
              className="dataResult pl-0 w-1/3 pr-0 absolute z-50 bg-white shadow rounded-xl "
              style={{ display: generate }}
            >
              {selected
                .filter((item) => {
                  if (city == "") {
                    return "string";
                  } else if (
                    item.cityCode.toUpperCase().includes(city.toUpperCase())
                  ) {
                    return item;
                  } else if (
                    item.airportCode.toUpperCase().includes(city.toUpperCase())
                  ) {
                    return item;
                  } else if (
                    item.airportName
                      .replace(
                        /(^\w|\s\w)(\S*)/g,
                        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                      )
                      .toUpperCase()
                      .includes(city.toUpperCase())
                  ) {
                    return item;
                  } else if (
                    item.cityName
                      .replace(
                        /(^\w|\s\w)(\S*)/g,
                        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                      )
                      .toUpperCase()
                      .includes(city.toUpperCase())
                  ) {
                    return item;
                  }
                })
                .map((item, i) => (
                  <>
                    <button
                      className="dataItem w-full px-3 py-2 text-left"
                      onClick={(e) => changeAirport(e)}
                      value={item.airportName}
                    >
                      {" "}
                      <i class="fas fa-plane-departure  mr-2  text-blue-500"></i>{" "}
                      {item.airportName}, {item.cityName}{" "}
                      <span className="float-right shadow foot px-4 rounded-xl">
                        {item.airportCode}
                      </span>{" "}
                    </button>
                  </>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
