import { useState, createContext } from "react";
import { Tab } from "@headlessui/react";
import Main from "../../../../assets/Image/Main.png";
import { addDays } from "date-fns";
import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRange } from "react-date-range";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Passanger from "../../../../FlightEngine/passanger";
import Class from "../../../../FlightEngine/Class";
import From from "../../../../FlightEngine/From";
import To from "../../../../FlightEngine/to";
import { Box } from "@material-ui/system";
import DatePicker from "@mui/lab/DatePicker";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkFlights } from "../../../../redux/slices/Action";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import AppWidgets1 from "../AppWidgets1";
import AccountPopover from "src/layouts/dashboard/AccountPopover";
import { Stack } from "@material-ui/core";
import { decryptData } from "src/utils/decryptcookie";
// import Favourite from "../Sample_Data/Favourite.json";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #3b82f6 20%, #1e3a8a 90%)",
    border: 0,
    borderRadius: 3,
    width: "20rem",
    height: 48,
    marginTop: "20rem",
    padding: "0 32px",
  },
});

export default function Engine({
  display,
  setReRender,
  reRender,
  from,
  to,
  date,
  adult,
  children,
  infant,
  PreferredClass,
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(new Date());
  const [displayTo, setDisplayTo] = useState("none");
  const [displayFrom, setDisplayFrom] = useState("none");
  const [displaypax, setDisplayPax] = useState("none");
  const [displayClass, setDisplayClass] = useState("none");
  const [airportName, setAirportName] = useState("");
  const [toAirport, setToAirport] = useState("");
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    setValues(newValue);
  };
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  function setdate() {
    setIsOpen(false);
    setstartDate(state[0].startDate);
    setendDate(state[0].endDate);
  }

  const [startDate, setstartDate] = useState(state[0].startDate);
  const [endDate, setendDate] = useState(state[0].endDate);

  // Full data
  const [departure, setdeparture] = useState("");
  const [arrival, setarrival] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [ToCountryCode, setToCountryCode] = useState("");
  const [classe, setclasse] = useState([]);
  const [travelleradult, settravelleradult] = useState([]);
  const [travellerchildren, settravellerchildren] = useState([]);
  const [travellerInfant, settravellerInfant] = useState([]);
  const [nameofclass, setnameofclass] = useState("");
  const [tripType, settripType] = useState("1");

  const dateFormat = moment(values).format("MM/DD/YYYY");
  const startDateFormat = moment(startDate).format("MM/DD/YYYY");
  const endDateFormat = moment(endDate).format("MM/DD/YYYY");
  const classes = useStyles();
  /*------------------------------------------APi CAll---------------------------------------------------- */

  //  useEffect(() => GetAllRuleMaded(), []);
  /*------------------------------------------APi CAll---------------------------------------------------- */

  const dispatch = useDispatch();
  dispatch(
    checkFlights({
      departure: arrival,
      arrival: departure,
      adult: travelleradult,
      children: travellerchildren,
      infant: travellerInfant,
      class: classe,
      startDates: startDateFormat,
      endDates: endDateFormat,
      singleDate: dateFormat,
      nameClass: nameofclass,
      tripType: tripType,
      CountryCode: CountryCode,
      totalpassanger: travellerchildren + travellerInfant + travelleradult,
      login: true,
    })
  );

  var data = {
    departure: arrival,
    arrival: departure,
    adult: travelleradult,
    children: travellerchildren,
    infant: travellerInfant,
    class: classe,
    startDates: startDateFormat,
    endDates: endDateFormat,
    singleDate: dateFormat,
    nameClass: nameofclass,
    tripType: tripType,
    CountryCode: CountryCode,
    ToCountryCode: ToCountryCode,
    totalpassanger: travellerchildren + travellerInfant + travelleradult,
  };
  const navigateToTwoWayResult = (data) => {
    const {
      departure,
      arrival,
      startDates,
      endDates,
      adult,
      children,
      infant,
      nameClass,
      CountryCode,
      ToCountryCode,
    } = data;
    const params = {
      departure: departure,
      arrival: arrival,
      startDates: startDates,
      endDates: endDates,
      adult: adult,
      children: children,
      infant: infant,
      class: nameClass,
      tripType: 2,
      airportName: airportName,
      toAirport: toAirport,
      CountryCode: CountryCode,
      ToCountryCode: ToCountryCode,
    };
    setReRender(!reRender);
    navigate({
      pathname: "/Flight/resulttwoway",
      search: `?${createSearchParams(params)}`,
    });
  };
  const navigateToOneWay = (data) => {
    const {
      adult,
      children,
      infant,
      departure,
      arrival,
      singleDate,
      nameClass,
      CountryCode,
      ToCountryCode,
    } = data;
    const params = {
      adult: adult,
      children: children,
      infant: infant,
      departure: departure,
      arrival: arrival,
      singleDate: singleDate,
      tripType: 1,
      class: nameClass,
      airportName: airportName,
      toAirport: toAirport,
      CountryCode: CountryCode,
      ToCountryCode: ToCountryCode,
    };
    setReRender(!reRender);
    navigate({
      pathname: "/Flight/resultoneway",
      search: `?${createSearchParams(params)}`,
    });
  };
  const currentDate = new Date(); // Current date
  const currentYear = currentDate.getFullYear(); // Get the current year
  const currentMonth = currentDate.getMonth(); // Get the current month
  const newDate = new Date(); // New date object
  newDate.setFullYear(currentYear + 1); // Add one year to the current year
  newDate.setMonth(currentMonth); // Set the month to the current month
  const user = decryptData("_b60-g6fr(+"); // userData decrypted here from cookies
  return (
    <>
      <Stack>
        <Stack
          direction="row-reverse"
          sx={{ marginRight: 3, marginTop: 3 }}
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <AppWidgets1 /> */}
        </Stack>
      </Stack>
      <Box>
        <div className="container mb-[80px]">
          <HeaderBreadcrumbs
            heading="Flight Booking "
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              {
                name: "Flight Booking",
                href: PATH_DASHBOARD.Flight.flightbooking,
              },
            ]}
          />
        </div>
        <section className=" min-h-100">
          <div className="container bg-white cardBorder">
            <Tab.Group>
              <Tab.List className="flex py-2 px-3 w-1/4 items-center  space-x-1 foot rounded-lg someCss tabBgColor">
                <Tab
                  key="1"
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2.5 text-sm  leading-5 font-medium text-blue-500 rounded-lg",
                      "",
                      selected
                        ? "bg-white text-black "
                        : "text-blue-500 text-white hover:bg-white/[0.12] hover:text-dark"
                    )
                  }
                >
                  <i className="fas fa-map-signs"> </i>&nbsp; &nbsp; One Way
                </Tab>

                <Tab
                  key="2"
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2.5 text-sm  leading-5 font-medium text-blue-500 rounded-lg",
                      "focus:outline-none ring-white ring-opacity-60",
                      selected
                        ? "bg-white text-black"
                        : "text-blue-500 text-white hover:bg-white/[0.12] hover:text-dark"
                    )
                  }
                >
                  <i class="fas fa-plane-departure"> </i>&nbsp; &nbsp; Round
                  Trip
                </Tab>
              </Tab.List>

              <Tab.Panel
                key="1"
                className={classNames(
                  " rounded-xl ",
                  "focus:outline-none   ring-white ring-opacity-60"
                )}
              >
                <div className="grid grid-cols-1 pb-[15px]">
                  <div className="col-span-2 ">
                    <div className="grid grid-cols-3 gap-[15px] updateGridDesign">
                      <div className="">
                        <span className="text-[12px] ml-2 text-[#919EAB]">
                          From Where..{" "}
                        </span>
                        <From
                          setarrival={setarrival}
                          setCountryCode={setCountryCode}
                          setDisplayTo={setDisplayTo}
                          displayFrom={displayFrom}
                          setDisplayFrom={setDisplayFrom}
                          setDisplayClass={setDisplayClass}
                          setDisplayPax={setDisplayPax}
                          from={from}
                          setAirportName={setAirportName}
                        />
                      </div>
                      <div className="">
                        <span className="text-[12px] ml-2 text-[#919EAB]">
                          To Where..{" "}
                        </span>
                        <To
                          setdeparture={setdeparture}
                          displayTo={displayTo}
                          setToCountryCode={setToCountryCode}
                          setDisplayTo={setDisplayTo}
                          setDisplayFrom={setDisplayFrom}
                          setDisplayClass={setDisplayClass}
                          setDisplayPax={setDisplayPax}
                          setToAirport={setToAirport}
                          to={to}
                        />
                      </div>

                      <div className=" date py-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date"
                            inputFormat="dd/MM/yyyy"
                            value={date === undefined ? values : date}
                            onChange={handleChange}
                            minDate={new Date()}
                            maxDate={newDate}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="">
                        <Passanger
                          settravelleradult={settravelleradult}
                          settravellerchildren={settravellerchildren}
                          settravellerInfant={settravellerInfant}
                          setDisplayPax={setDisplayPax}
                          setDisplayTo={setDisplayTo}
                          setDisplayFrom={setDisplayFrom}
                          setDisplayClass={setDisplayClass}
                          displaypax={displaypax}
                          adult={adult}
                          infant={infant}
                          children={children}
                        />
                      </div>

                      <div className="">
                        <Class
                          setclasse={setclasse}
                          setnameofclass={setnameofclass}
                          setDisplayPax={setDisplayPax}
                          setDisplayTo={setDisplayTo}
                          setDisplayFrom={setDisplayFrom}
                          setDisplayClass={setDisplayClass}
                          displayClass={displayClass}
                          PreferredClass={PreferredClass}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1  mt-[15px] text-right">
                      {" "}
                      <>
                        <Button
                          variant="contained"
                          className={`${classes.root} tabBgColor !ml-auto !w-[200px]`}
                          onClick={() => navigateToOneWay(data)}
                          disabled={
                            arrival === "" || departure === "" ? true : false
                          }
                        >
                          Search
                        </Button>
                      </>
                    </div>
                  </div>
                </div>
              </Tab.Panel>

              <Tab.Panels className="w-full rounded-3xl ">
                <Tab.Panel
                  key="2"
                  className={classNames(
                    " rounded-xl  ",
                    " focus:outline-none  ring-white ring-opacity-60"
                  )}
                >
                  <div className="grid grid-cols-1 pb-[15px]">
                    <div className="col-span-3  ">
                      <div className="grid grid-cols-3 gap-[15px] updateGridDesign">
                        <div className="">
                          <span className="text-[12px] ml-2 text-[#919EAB]">
                            From Where..{" "}
                          </span>
                          <From
                            setarrival={setarrival}
                            setCountryCode={setCountryCode}
                            setDisplayTo={setDisplayTo}
                            displayFrom={displayFrom}
                            setDisplayFrom={setDisplayFrom}
                            setDisplayClass={setDisplayClass}
                            setDisplayPax={setDisplayPax}
                            setAirportName={setAirportName}
                          />
                        </div>

                        <div className="">
                          <span className="text-[12px] ml-2 text-[#919EAB]">
                            To Where..{" "}
                          </span>
                          <To
                            setdeparture={setdeparture}
                            displayTo={displayTo}
                            setDisplayTo={setDisplayTo}
                            setToCountryCode={setToCountryCode}
                            setDisplayFrom={setDisplayFrom}
                            setDisplayClass={setDisplayClass}
                            setDisplayPax={setDisplayPax}
                            setToAirport={setToAirport}
                          />
                        </div>
                        <div className="relative flex items-center  pl-3 pr-10 text-left mt-4 h-[50px] down border-1 rounded-[8px] border-gray-100 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                          <span>
                            <i class="fas fa-calendar-alt text-[#e95419]"></i>
                            <input
                              type="text"
                              value={startDateFormat}
                              onClick={openModal}
                              className=" date text-black  ml-5 inline-block text-sm  mb-0  mr-1   hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                            />
                          </span>
                        </div>
                        <div className="relative flex items-center  pl-3 pr-10 text-left h-[50px] down border-1 rounded-[8px] border-gray-100  mt-0   down  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                          <span>
                            <i class="fas fa-calendar-alt text-[#e95419]"></i>
                            <input
                              type="text"
                              value={endDateFormat}
                              onClick={openModal}
                              className=" date text-black  ml-5  inline-block text-sm  mb-0  mr-1  w:4/5  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                            />
                          </span>
                        </div>
                        {/* <div className="date col-span-2 bg-opacity-100"> */}
                        <Transition appear show={isOpen} as={Fragment}>
                          <Dialog
                            as="div"
                            className="fixed bg-slate-200 bg-opacity-0  inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                          >
                            <div className="min-h-screen px-4 text-center bg-opacity-100 ">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Dialog.Overlay className="fixed inset-0" />
                              </Transition.Child>

                              {/* This element is to trick the browser into centering the modal contents. */}
                              <span
                                className="inline-block h-screen  align-middle"
                                aria-hidden="true"
                              >
                                &#8203;
                              </span>
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <div className="inline-block w-full max-w-3xl p-6 my-8  bg-opacity-100 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                  <Dialog.Title
                                    as="h3"
                                    className="text-sm  font-medium leading-6  bg-opacity-100 text-gray-900"
                                  >
                                    <DateRange
                                      onChange={(item) =>
                                        setState([item.selection])
                                      }
                                      moveRangeOnFirstSelection={true}
                                      ranges={state}
                                      months={2}
                                      minDate={new Date()}
                                      maxDate={newDate}
                                      direction="horizontal"
                                    />
                                  </Dialog.Title>

                                  <Button
                                    className="btn btn-primary float-right"
                                    onClick={() => setdate()}
                                  >
                                    Done
                                  </Button>
                                </div>
                              </Transition.Child>
                            </div>
                          </Dialog>
                        </Transition>
                        {/* </div> */}

                        <div className="">
                          <Passanger
                            settravelleradult={settravelleradult}
                            settravellerchildren={settravellerchildren}
                            settravellerInfant={settravellerInfant}
                            setDisplayPax={setDisplayPax}
                            setDisplayTo={setDisplayTo}
                            setDisplayFrom={setDisplayFrom}
                            setDisplayClass={setDisplayClass}
                            displaypax={displaypax}
                          />
                        </div>

                        <div className="">
                          <Class
                            setclasse={setclasse}
                            setnameofclass={setnameofclass}
                            setDisplayPax={setDisplayPax}
                            setDisplayTo={setDisplayTo}
                            setDisplayFrom={setDisplayFrom}
                            setDisplayClass={setDisplayClass}
                            displayClass={displayClass}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1  mt-[15px] text-right">
                        {" "}
                        <>
                          <Button
                            variant="contained"
                            onClick={() => navigateToTwoWayResult(data)}
                            className={`${classes.root} tabBgColor !ml-auto !w-[200px]`}
                            disabled={
                              arrival === "" || departure === "" ? true : false
                            }
                          >
                            Search
                          </Button>
                        </>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            {/* <div className="mt-10">
          <FlightSearchHistory />
        </div> */}
          </div>
        </section>
      </Box>
    </>
  );
}
