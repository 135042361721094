import axios from "axios";
import { hostname } from "src/HostName";

export const updateFarerule = async (modalData, ads, handleClose) => {
  console.log("ads111111111111111", ads);
  const Data = {
    _id: modalData._id,
    provider: ads,
    blockedAirportSource: modalData.blockedAirportSource,
    rule: modalData.rule,
    amount: modalData.amount,
    fromDate: modalData.fromDate,
    toDate: modalData.toDate,
    blockedCountrySource: modalData.blockedCountrySource,
    noOfPAXSource: modalData.noOfPAXSource,
    tripTypeSource: modalData.tripTypeSource,
  };

  const options = {
    method: "PATCH",
    url: `${hostname}update/farerule/info`,
    headers: { "Content-Type": "application/json" },
    data: Data,
  };
  axios
    .request(options)
    .then(function (response) {
      alert("Fare Rule updated");
      handleClose();
    })
    .catch(function (error) {
      console.error("error");
    });
};
