import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);
const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
};
const sidebarConfig = [
  // GENERAL
  {
    subheader: "Travel Agents",
    items: [
      {
        title: "Travel Agents",
        //  path: PATH_DASHBOARD.mail.root,
        path: PATH_DASHBOARD.general.add_info,
        icon: ICONS.rule,
      },
      {
        title: "Transactions",
        path: PATH_DASHBOARD.general.accounts_all_requests,
        icon: ICONS.rule,
      },

      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ],
  },

  // ----------------------------------------------------------------------
  // {
  //   subheader: "Transaction",
  //   items: [
  //     {
  //       title: "Transactions",
  //       path: PATH_DASHBOARD.general.accounts_all_requests,
  //       icon: ICONS.rule,
  //     },
  //   ],
  // },

  // {
  //   subheader: "Travel Agents",
  //   items: [
  //     {
  //       title: "Requests",
  //       path: PATH_DASHBOARD.general.all_requests,
  //       icon: ICONS.rule,
  //     },
  //   ],
  // },

  {
    subheader: "Flight",
    items: [
      // {
      //   title: "Flight Booking",
      //   path: PATH_PAGE.flightBooking,
      //   icon: ICONS.airport,
      // },
      // {
      //   title: "Booking History",
      //   path: PATH_DASHBOARD.history.book,
      //   icon: ICONS.bookingConfirmed,
      // },
      {
        title: "Amendments",
        path: PATH_DASHBOARD.general.get_all_amendments,
        icon: ICONS.airport,
      },
      // {
      //   title: "Queries",
      //   path: PATH_DASHBOARD.aboutus.terms,
      //   icon: ICONS.rule,
      // },
    ],
  },
];
export default sidebarConfig;
