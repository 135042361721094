import { PATH_DASHBOARD } from "../../routes/paths";
import SvgIconStyle from "../../components/SvgIconStyle";
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);
const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
};
const sidebarConfig = [
  // GENERAL

  // ----------------------------------------------------------------------
  {
    subheader: "Transaction",
    items: [
      {
        title: "Transactions",
        path: PATH_DASHBOARD.general.accounts_all_requests,
        icon: ICONS.bookingConfirmed,
      },
      {
        title: "Bookings",
        path: PATH_DASHBOARD.general.all_booking_details,
        icon: ICONS.airport,
      },
    ],
  },

  // {
  //   subheader: "Agents",
  //   items: [
  //     {
  //       title: "Requests",
  //       path: PATH_DASHBOARD.general.all_requests,
  //       icon: ICONS.rule,
  //     },
  //   ],
  // },
];
export default sidebarConfig;
