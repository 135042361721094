import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateWallet: true,
};

const updateWalletSlice = createSlice({
  name: "updateWallet",
  initialState,
  reducers: {
    updateWallet: (state, action) => {
      state.updateWallet = action.payload;
    },
  },
});

export const { updateWallet } = updateWalletSlice.actions;
export default updateWalletSlice.reducer;
