import { secreteKey } from "src/HostName";
import CryptoJS from "crypto-js";
import Cookies from "universal-cookie";

export const decryptData = (name) => {
  const cookies = new Cookies();
  const encryptedData =
    cookies.get(name) === undefined ? "" : cookies.get(name);
  const decryptedData = CryptoJS?.AES?.decrypt(
    encryptedData,
    secreteKey
  ).toString(CryptoJS.enc.Utf8);
  if (encryptedData !== "") {
    return JSON.parse(decryptedData);
  }
};
