import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Typography, Button, Card, CardContent } from "@material-ui/core";
import { SeoIllustration } from "../../../assets";
import { useSelector } from "src/redux/store";
import Login from "src/pages/authentication/Login";
import useAuth from "src/hooks/useAuth";
import Engine from "./FlightEngine/Engine";
import { decryptData } from "src/utils/decryptcookie";
// import FlightEngine from "src/FlightEngine/FlightEngine";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string,
};

export default function AppWelcome({ displayName }) {
  const LoginData = localStorage.getItem("LoginData");
  const user = decryptData("_b60-g6fr(+");

  return (
    <RootStyle>
      {/* <div>
        <Engine />
      </div> */}
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: "grey.800",
        }}
      >
        <Typography gutterBottom variant="h4">
          Welcome back,
          <br /> {!user.username ? "..." : user.username}!
        </Typography>

        <Typography
          variant="body2"
          sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: "auto" }}
        >
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything
        </Typography>
      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: 360,
          margin: { xs: "auto", md: "inherit" },
        }}
      />
      {/* <Button variant="contained" to="/" component={RouterLink}>
        Go Now
      </Button> */}
    </RootStyle>
  );
}
